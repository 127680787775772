import * as api from '../../api'
import { get } from 'lodash'
import Vue from 'vue'
import SeasonTicket from '../../Classes/season/SeasonTicket'

export const season = {
  namespaced: true,
  state: {
    tickets: {},
    prequotes: {},
    form: {}
  },
  getters: {
    tickets: state => resourceOspId => get(state.tickets, resourceOspId, []),
    prequote: state => resourceOspId => get(state.prequotes, resourceOspId),
    form: state => state.form
  },
  actions: {
    getPrequote ({ commit, state }, { resourceOspId, budgetOspId }) {
      return state.prequotes.resourceOspId || api.season.getPrequote({ serviceResourceOspId: resourceOspId, budgetOspId }, response => {
        if (!response?.error) {
          commit('setPrequote', { response, resourceOspId })
        }
      })
    },
    getSeasonTicket ({ commit }, resourceOspId) {
      return api.season.getSeasonTicket(resourceOspId, response => {
        commit('setSeasonTickets', { tickets: response || [], resourceOspId })
      })
    },
    updateForm ({ commit }, form) {
      commit('setForm', form)
    },
    resetForm ({ commit }) {
      commit('setForm', {})
    }
  },
  mutations: {
    setSeasonTickets (state, { tickets, resourceOspId }) {
      Vue.set(state.tickets, resourceOspId, tickets.map(ticket => new SeasonTicket(ticket)))
    },
    setPrequote (state, { response, resourceOspId }) {
      Vue.set(state.prequotes, resourceOspId, response)
    },
    setForm (state, form) {
      state.form = form
    }
  }
}
