import {
  PostMessage as IPostMessage,
  PostMessageSender as IPostMessageSender
} from '@/Classes/postMessageService/postMessage.types'
import { store } from '../../store'
import { appLog, POSTMESSAGE_API_VERSION } from '../../helpers'

/**
 * Class for sending postMessages
 */
class PostMessageSender implements IPostMessageSender {
  private static instance: PostMessageSender

  public static getInstance (): PostMessageSender {
    if (!PostMessageSender.instance) {
      PostMessageSender.instance = new PostMessageSender()
    }
    return PostMessageSender.instance
  }

  get sendAllowed () {
    return store.state.app.clientId || store.state.app.hybridweb
  }

  private send (message: IPostMessage) {
    if (!this.sendAllowed) {
      return false
    }
    this.logger(message)
    parent.postMessage(JSON.stringify(message), '*')
    return true
  }

  logger (message: IPostMessage) {
    const time = new Date()
    appLog(time.toTimeString())
    appLog(message, 'sendPostMessage')
  }

  request (api: string, data?: {}) {
    const message = {
      api,
      version: POSTMESSAGE_API_VERSION,
      data
    }
    return this.send(message)
  }

  response (api: string, data?: {}) {
    const message = {
      api,
      version: POSTMESSAGE_API_VERSION,
      response: data
    }
    return this.send(message)
  }

  token (api: string, token: string) {
    const message = {
      api,
      version: POSTMESSAGE_API_VERSION,
      token
    }
    return this.send(message)
  }
}

export default PostMessageSender.getInstance()
