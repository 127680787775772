<template>
  <o-notification
    :text="message"
    :show="!!message"
    @close="clear"
  />
</template>

<script>
import ONotification from '../utils/ONotification'
import { mapActions } from 'vuex'
export default {
  name: 'OAlertBottom',
  components: {
    ONotification
  },
  props: {
    message: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  methods: {
    ...mapActions({
      clear: 'alert/clear'
    })
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">

</style>
