import Vue from 'vue'
import { trans } from '../helpers'
import * as api from '../api'
import { store } from '../store'
import postMessage from '../Classes/postMessageService/postMessage'

Vue.prototype.$trans = trans
Vue.prototype.$api = api
// Vue.prototype.$sleep = async (seconds) => {
//   setTimeout(() => {
//     Promise.resolve()
//   }, seconds * 1000)
// }
Vue.prototype.$sleep = seconds => new Promise(resolve => {
  setTimeout(() => {
    resolve(true)
  }, seconds * 1000)
})
Vue.prototype.$postMessage = postMessage

Vue.prototype.$isTablet = store.getters['app/isTablet']

// Vue.prototype.$back = store.dispatch('navigation/back')
