/**
 * @module POI API
 */
import { Api, appEndPoint, appEndPointV2 } from './api'
import { GEOLOCATION_DEFAULT } from '../helpers'

export const getPoi = (filters, cb) => {
  if ((!filters.range || !filters.source)) {
    return false
  }
  if (filters.source.latitude === 0 && filters.source.longitude === 0) {
    filters.source.latitude = GEOLOCATION_DEFAULT[1]
    filters.source.longitude = GEOLOCATION_DEFAULT[0]
  }
  const api = new Api(cb, { loader: false })
  return api.query('post', `${appEndPoint}/poi/get`, filters)
}

export const getPoiDetail = (ospId, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/poi/detail`, { ospId })
}

export const getPoiImageUrl = (poi, selected = false) => {
  let url = `${appEndPoint}/images/poi/${poi.mrpOspId.ospId}/${poi.mobilityMode}/get?selected=${selected}`
  if (poi?.brand) {
    url += `&brand=${poi.brand}`
  }
  return url
}

export const getPoiV2 = (filters, cb) => {
  const api = new Api(cb, { loader: false })
  return api.query('post', `${appEndPointV2}/poi/get`, filters)
}
