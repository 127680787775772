<template>
  <div
    class="c-header-image"
    :style="`background-image: url(${image})`"
  />
</template>

<script>
export default {
  name: 'OHeaderImage',
  props: {
    image: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss">
.c-header-image {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  flex: none;
  height: 22rem;
  transition: height 2s;
}
.c-tablet-sales-view--header {
  display: flex;
  align-items: center;
  justify-content: center;
  .c-header-image {
    height: 100%;
    width: 100%;
  }
}
</style>
