import Vue from 'vue'
import { Vuelidate } from 'vuelidate'
import { VueMaskDirective } from 'v-mask'
import App from './App'
import { store } from './store'
import { router, beforeInit, appLog } from './helpers'
import i18n from './i18n'
import VueCurrencyInput from 'vue-currency-input'
import Croppa from 'vue-croppa'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import { RecycleScroller } from 'vue-virtual-scroller'
import './plugins/helpers'
import postMessage from './Classes/postMessageService/postMessage'
import { Buffer } from 'buffer'

// window.openUrl = (url) => {
//   console.log('window.openUrl')
//   console.log(url)
// }

// Import and register global base components

window.Buffer = Buffer

const requireComponent = require.context(
  './components/global',
  false,
  /O[A-Z]\w+\.(vue|js)$/
)

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)
  const componentName = fileName.replace(/^\.\/(.*)\.\w+$/, '$1')
  Vue.component(
    componentName,
    componentConfig.default || componentConfig
    // () => import(componentConfig.default || componentConfig)
  )
})

Vue.config.productionTip = false

Vue.use(Vuelidate)

Vue.use(VueCurrencyInput, {})

Vue.use(Croppa)

Vue.component('RecycleScroller', RecycleScroller)

Vue.directive('model', {
  bind: function (el, binding, vnode) {
    el.oninput = () => (vnode.context[binding.expression] = el.value)
  }
})
Vue.directive('mask', VueMaskDirective)

beforeInit(store).then(async (data) => {
  appLog(data, 'beforeInit then')

  /* eslint-disable no-new */
  new Vue({
    i18n,
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
  try {
    if (data.next && router.resolve(data.next)) {
      await router.push(data.next)
    }
  } catch (err) {
    appLog(err)
  }
  const body = document.getElementsByTagName('body')[0]
  body.addEventListener('touchend', function () {
    postMessage.keepAlive()
    // sendPostMessage('keepAlive')
  }, false)
})
