import _ from 'lodash'
// import Agreement from './Agreement'
export default class Passenger {
  constructor (passenger, agreement) {
    _.assign(this, passenger)
    // for (let index in passenger) {
    //   this[index] = passenger[index]
    // }
    this.agreement = agreement
  }
  // get youDrive () {
  //   return this.agreement.youDrive
  // }

  get name () {
    return this.you ? 'You' : this.firstName + ' ' + this.lastName
  }

  get hasBudget () {
    return !!this.userOspId && this.userOspId.indexOf('EXT_USER') < 0
  }

  get isNotInitiated () {
    return !this.paymentStatus
  }

  get isPaid () {
    return this.paymentStatus === 'PAID'
  }

  get isRefused () {
    return this.paymentStatus === 'REFUSED'
  }

  get isOpened () {
    return this.paymentStatus === 'OPEN'
  }

  get isPassenger () {
    return this.passengerType === 'PASSENGER'
  }

  get isDriver () {
    return this.passengerType === 'DRIVER'
  }

  // Show Buttons permissions

  // Allow to decline an agreement only if it has status 'REQUESTED'
  get allowApprove () {
    return this.state === 'REQUESTED' && !this.agreement.isRideInPast
  }

  // Allow to decline an agreement only if it has status 'REQUESTED'
  get allowDecline () {
    return this.state === 'REQUESTED' && !this.agreement.isRideInPast
  }

  // Allow to send message only if agreement is not expired
  get allowSendMessage () {
    if (this.agreement.youDrive) {
      return (!this.agreement.isExpired && !this.paymentStatus && !this.allowApprove)
    } else {
      return !this.agreement.isRideInPast ||
        (this.agreement.isRideInPast && !this.agreement.driver.hasBudget && this.agreement.driver.isNotInitiated)
    }
  }

  // allow to cancel ride if agreement wasn't canceled yet and ride date-time is not in past
  get allowCancelRequest () {
    if (this.agreement.youDrive) {
      return !this.isRejected && !this.agreement.isRideInPast && !this.allowApprove
    } else {
      return !this.isRejected && !this.agreement.isRideInPast
    }
  }

  // Allow to request payment only if passenger has Olympus budget, payment wasn't initiated, auth user is driver and
  // current time is after ride`s date-time
  get allowRequestPayment () {
    return this.hasBudget && this.isNotInitiated && this.agreement.youDrive && this.isComplete
  }

  // allow init register payment if user is not Olympus user and payment status is opened
  get allowRegisterPayment () {
    return !this.hasBudget && this.agreement.isRideInPast && this.isNotInitiated && this.isConfirmed
  }

  // State
  get isConfirmed () {
    return this.state !== 'REQUESTED' && this.state !== 'REJECTED' && this.state !== 'CANCELED_BY_PASSENGER'
  }

  get isComplete () {
    return this.agreement.isRideInPast && this.state === 'ACCEPTED'
  }

  get isRejected () {
    return this.state === 'CANCELED_BY_PASSENGER' || this.state === 'REJECTED'
  }
}
