import Parking from './Parking'
import * as api from '@/api'
import { get } from 'lodash'
import { parseFloat, trans } from '../helpers'
import { store } from '../store'

export default class ParkingSession {
  constructor (data) {
    // const statuses = ['RUNNING', 'STOPPED']
    // data.status = statuses[Math.floor(Math.random() * 2)]// 'RUNNING' 'STOPPED'
    Object.assign(this, data)
    this.parking = Parking.createFromHistory(data)
    // STANDARD, DAYTICKET, FREE
  }

  get price () {
    if (!this.totalCostVatIncl && this.totalCostVatIncl !== 0) {
      return null
    }
    const price = parseFloat(this.totalCostVatIncl)
    const numberDelimiter = store.getters['app/numberDelimiter']
    return '€ ' + price.replace('.', numberDelimiter)
  }

  get parkingZoneLabel () {
    return this.zoneCode + (this.city ? ` (${this.city})` : '')
  }

  get sessionType () {
    return trans(get(this, ['parkingInfo', 'captionLabel'], this.type))
  }

  get isActive () {
    return this.status === 'RUNNING'
  }

  get isStandard () {
    return this.type === 'STANDARD'
  }

  get isDaily () {
    return this.type === 'DAYTICKET'
  }

  get isFree () {
    return this.type === 'FREE'
  }

  get canBeStopped () {
    return this.isActive && this.isStandard
  }

  fetchParkingInfo () {
    return api.parking.parkingInfo({
      mrpOspId: this.mrpOspId,
      parkingCode: this.zoneCode
    }, response => {
      this.parkingInfo = response.find(r => r.sessionType === this.type)
    })
  }

  setMrpOspId (mrpOspId) {
    this.mrpOspId = mrpOspId
  }
}
