<template>
  <div class="c-textinput-wrapper">
    <label v-if="label && showLabel" class="c-textinput-label" :for="uniqueId">{{ label }}</label>
    <o-icon
      :icon="icon"
      v-if="icon"
    />
    <div class="c-textinput-input">
      <span v-if="prepend" class="c-textinput-prepend" v-html="prepend"></span>
      <input v-if="inputMask"
             :key="inputKey"
             ref="input"
             :type="type"
             class="c-textinput"
             :class="{
      'c-textinput-no_clear': !allowClear
      }"
             :value="value"
             :placeholder="placeholder"
             :readonly="readonly"
             :autofocus="autofocus"
             :min="min"
             :max="max"
             :pattern="pattern"
             :maxlength="maxlength"
             :step="step"
             v-model="inputVal"
             v-mask="inputMask"
             @focus="e => $emit('focus', e)"
             @blur="e => $emit('blur', e)"
      >
      <input v-else
             ref="input"
             :key="inputKey"
             :type="type"
             class="c-textinput"
             :class="{
      'c-textinput-no_clear': !allowClear
      }"
             :value="value"
             :placeholder="placeholder"
             :readonly="readonly"
             :autofocus="autofocus"
             :min="min"
             :max="max"
             :pattern="pattern"
             :maxlength="maxlength"
             :step="step"
             @focus="e => $emit('focus', e)"
             @blur="e => $emit('blur', e)"
             v-model="inputVal"
      >
      <div v-if="copyable" class="help-icon-wrapper" @click.prevent="copyToClipboard">
        <o-icon :icon="copied ? 'check' : 'copy'"/>
      </div>
      <div v-if="inputHelp" class="help-icon-wrapper" @click.prevent="inputHelp">
        <o-icon icon="info"/>
      </div>
    </div>
    <template v-if="validations && validations.$params && validations.$dirty">
      <div class="c-error"
           v-if="('required' in validations.$params) && !validations.required">
        <template v-if="noLabelInError">
          {{ getErrorMessage($t('html5.validations.no_label.required')) }}
        </template>
        <template v-else>
          {{ getErrorMessage($t('html5.validations.required'), label) }}
        </template>
      </div>
      <div class="c-error"
           v-if="('minLength' in validations.$params) && !validations.minLength">
        <template v-if="noLabelInError">
          {{ getErrorMessage($t('html5.validations.no_label.min_length', { n: validations.$params.minLength.min })) }}
        </template>
        <template v-else>
          {{ getErrorMessage($t('html5.validations.min_length', { n: validations.$params.minLength.min }), label) }}
        </template>
      </div>
      <div class="c-error"
           v-if="('maxLength' in validations.$params) && !validations.maxLength">
        <template v-if="noLabelInError">
          {{ getErrorMessage($t('html5.validations.no_label.max_length', { n: validations.$params.maxLength.max })) }}
        </template>
        <template v-else>
          {{ getErrorMessage($t('html5.validations.max_length', { n: validations.$params.maxLength.max }), label) }}
        </template>
      </div>
      <div class="c-error"
           v-if="('integer' in validations.$params) && !validations.integer">
        <template v-if="noLabelInError">
          {{ getErrorMessage($t('html5.validations.no_label.numeric')) }}
        </template>
        <template v-else>
          {{ getErrorMessage($t('html5.validations.numeric'), label) }}
        </template>
      </div>
      <div class="c-error"
           v-if="('alphaNum' in validations.$params) && !validations.alphaNum">
        <template v-if="noLabelInError">
          {{ getErrorMessage($t('html5.validations.no_label.alphanumeric')) }}
        </template>
        <template v-else>
          {{ getErrorMessage($t('html5.validations.alphanumeric'), label) }}
        </template>
      </div>
      <div class="c-error"
           v-if="('email' in validations.$params) && !validations.email">
        <template v-if="noLabelInError">
          {{ getErrorMessage($t('html5.validations.no_label.email')) }}
        </template>
        <template v-else>
          {{ getErrorMessage($t('html5.validations.email'), label) }}
        </template>
      </div>
      <div class="c-error"
           v-if="('luhnAlgorithm' in validations.$params) && !validations.luhnAlgorithm && validations.integer">
        <template v-if="noLabelInError || true">
          {{ getErrorMessage($t('html5.validations.no_label.valid')) }}
        </template>
        <template v-else>
          {{ getErrorMessage($t('html5.validations.valid'), label) }}
        </template>
      </div>
      <div class="c-error"
           v-if="('moduloAlgorithm' in validations.$params) && !validations.moduloAlgorithm">
        <template v-if="noLabelInError || true">
          {{ getErrorMessage($t('html5.validations.no_label.valid')) }}
        </template>
        <template v-else>
          {{ getErrorMessage($t('html5.validations.valid'), label) }}
        </template>
      </div>
      <div class="c-error"
           v-if="('iban' in validations.$params) && !validations.iban">
        <template v-if="noLabelInError">
          {{ getErrorMessage($t('html5.validations.no_label.valid')) }}
        </template>
        <template v-else>
          {{ getErrorMessage($t('html5.validations.valid'), label) }}
        </template>
      </div>
      <div class="c-error"
           v-if="('phoneNumber' in validations.$params) && !validations.phoneNumber">
        <template v-if="noLabelInError">
          {{ getErrorMessage($t('html5.validations.no_label.valid')) }}
        </template>
        <template v-else>
          {{ getErrorMessage($t('html5.validations.valid'), label) }}
        </template>
      </div>
      <div class="c-error"
           v-if="('numberplate' in validations.$params) && !validations.numberplate">
        <template v-if="noLabelInError">
          {{ getErrorMessage($t('html5.validations.no_label.valid')) }}
        </template>
        <template v-else>
          {{ getErrorMessage($t('html5.validations.valid'), label) }}
        </template>
      </div>
      <div class="c-error"
           v-if="('vin' in validations.$params) && !validations.vin">
          {{ getErrorMessage($t('html5.aod.add_vin.vin_error_message')) }}
      </div>
    </template>
    <template v-else-if="errorMessage">
      <div class="c-error">{{errorMessage}}</div>
    </template>
  </div>
</template>

<script type="text/ecmascript-6">
import { getErrorMessage } from '../../helpers'
import OIcon from './OIcon'

export default {
  name: 'TextInput',
  components: { OIcon },
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    label: {
      type: String,
      default: null
    },
    pattern: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: null
    },
    readonly: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    },
    min: {
      type: [Number, String],
      default: ''
    },
    max: {
      type: [String, Number],
      default: ''
    },
    maxlength: {
      type: [String, Number],
      default: ''
    },
    validations: {
      type: Object,
      default: null
    },
    prepend: {
      type: String,
      default: null
    },
    noLabelInError: {
      type: Boolean,
      default: false
    },
    allowClear: {
      type: Boolean,
      default: true
    },
    step: {
      type: [Number, String],
      default: 1
    },
    inputMask: {
      type: [String, RegExp, Array, Function],
      default: null
    },
    inputHelp: {
      type: Function,
      default: null
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    inputKey: {
      type: String,
      default: ''
    },
    errorMessage: {
      type: String,
      default: ''
    },
    copyable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      inputVal: this.value,
      uniqueId: '',
      copied: false
    }
  },
  methods: {
    generateUniqueId () {
      return (
        '_' +
        Math.random()
          .toString(36)
          .substr(2, 9)
      )
    },
    getErrorMessage (message, label = '') {
      return getErrorMessage(message, label)
    },
    copyToClipboard () {
      navigator.clipboard.writeText(this.inputVal).then(
        () => {
          this.copied = true
        }
      )
    }
  },
  watch: {
    inputVal (val) {
      this.$emit('input', val)
    }
  },
  created () {
    this.uniqueId = this.generateUniqueId()
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
@import '~@/assets/styles/variables.scss';
.c-error {
  width: 100%;
  display: block;
  font-size: 80%;
  color: red;
  margin-top: 0.5rem;
}

.c-textinput-wrapper {
  display: flex;
  flex-wrap: wrap;
  line-height: normal;

  .c-icon {
    color: $primary-color;
    color: var(--primary-color);
    flex: none;
    margin-right: 1.2rem;
  }

  .c-error ~ .c-error {
    display: none;
  }
}

.c-textinput {
  appearance: none;
  background: none;
  border: 0;
  color: inherit;
  display: block;
  flex: 1 1 0;
  font: inherit;
  outline: 0;
  box-shadow: none;
  /*border-bottom: 1px solid rgba(0, 0, 0, .1);*/
}

.c-textinput-input {
  display: flex;
  flex-direction: row;
  flex: 1 1 0;
  max-width: 100%;

  input {
    max-width: 100%;
    width: auto;
    padding: 0;
    margin: 0;
  }
}

.c-textinput-label {
  color: $blue-kbc-cement;
  flex: none;
  font-size: 1.3rem;
  margin-bottom: 0.8rem;
  width: 100%;
}

.c-textinput-prepend {
  margin-right: .5rem;
  margin-top: .1rem;
}

.c-textinput-no_clear {
  &::-webkit-clear-button {
    display: none;
  }

  &::-ms-clear {
    display: none;
  }
}

.help-icon-wrapper {
  height: 24px;

  .c-icon {
    cursor: pointer;
    margin: 0;
  }
}
</style>
