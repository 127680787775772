/**
 * @module Quotes API
 */
import { Api, appEndPointV3 } from './api'
import { appLog } from '../helpers'

export const getProviderQuote = ({ mobilityMode, mrpOspId, resourceOspId, ...filters }, cb) => {
  const api = new Api(cb, { returnError: true, excludeMessageAlert: ['MIVB_CARD_FULL', 'NMBS_TICKET_NOT_NEEDED_FOR_CHILD'] })
  const query = {
    mobilityMode: mobilityMode ? mobilityMode.toUpperCase() : undefined,
    resourceOspId,
    ...filters
  }
  return api.query('post', `${appEndPointV3}/sales/${mrpOspId}/quote`, query)
}

export const getProviderQuoteByBookingId = ({ mrpOspId, resourceOspId, previousBookingId }, cb) => {
  const api = new Api(cb)
  const query = {
    previousBookingId,
    resourceOspId
  }
  return api.query('post', `${appEndPointV3}/sales/${mrpOspId}/quote`, query)
}

export const getQuotes = (mrpOspId, query, cb) => {
  const api = new Api(cb)
  appLog(query)
  return api.query('post', `${appEndPointV3}/sales/${mrpOspId}/quote`, query)
}
