// eslint-disable-next-line
import moment from 'moment'
import { trans, compareByName, DATE_TIME_FORMAT, DATE_TIME_FORMAT_WITH_SEC, DATE_TIME_ISO_FORMAT } from '../../helpers'
import * as api from '../../api/'
import __ from 'lodash'
import Vue from 'vue'
const SECOND_CLASS = 'SECOND_CLASS'

const DEPARTURE = 'departure'

export const train = {
  namespaced: true,
  state: {
    trainStations: [],
    reservationRouteStations: {},
    history: {},
    timetable_history: {},
    timetableForm: {
      origin: null,
      destination: null,
      outbound: {
        type: DEPARTURE,
        date: moment().format(DATE_TIME_ISO_FORMAT)
      }
    },
    seasonForm: {
      origin: null,
      destination: null,
      classOfService: SECOND_CLASS,
      seasonTicketOptionTypes: [],
      includeParking: false,
      remark: '',
      reason: null
    },
    trainForm: {
      origin: null,
      destination: null,
      outbound: moment().format('YYYY-MM-DD'),
      returnTrip: 'no',
      classOfService: null,
      forWhom: 'myself'
    },
    journeys: {}
  },
  getters: {
    stations: (state) => {
      const stations = []
      state.trainStations.forEach(station => {
        const code = station.ospId.ospId.split('.').pop()
        const translations = []
        // push name default
        translations.push(station.name.defaultValue)
        // push name translations
        if (station.name.labelTranslations.length) {
          station.name.labelTranslations.forEach(t => {
            if (translations.indexOf(t.text) === -1) {
              translations.push(t.text)
            }
          })
        }
        stations.push({
          code,
          translations,
          name: trans(station.name)
        })
      })
      return stations.sort(compareByName)
    },
    reservationRouteStations: state => state.reservationRouteStations,
    historyList: state => state.history,
    timetableHistoryList: state => state.timetable_history,
    timetableForm: state => state.timetableForm,
    trainForm: state => state.trainForm,
    journeys: state => state.journeys,
    journeysGroupByDate: state => {
      if (!state.journeys || !state.journeys.journeys) {
        return {}
      }
      const data = { ...state.journeys }
      const journeys = {}
      const journeysList = state.journeys.journeys
      for (let i = 0; i < journeysList.length; i++) {
        const journey = journeysList[i]
        journeys[journey.departure.date] = journeys[journey.departure.date] || []
        journeys[journey.departure.date].push(journey)
      }
      data.journeys = journeys
      return data
    },
    getStationByCode: (state, getters) => code => getters.stations.find(s => s.code === code),
    getStationNameByCode: (state, getters) => code => getters.stations.find(s => s.code === code).name,
    getFavoriteStations: (state, getters) => {
      const mrpOspId = 'NmbsSncb'
      const filter = []
      if (!state.history[mrpOspId] || !getters.stations.length) {
        return []
      }
      __.each(state.history[mrpOspId] || [], value => {
        if (filter.length > 4) {
          return true
        }
        if (!filter.includes(value.originCode)) {
          filter.push(value.originCode)
        }
        if (!filter.includes(value.destinationCode)) {
          filter.push(value.destinationCode)
        }
      })
      const stations = getters.stations
      return filter.slice(0, 5).map(v => {
        v = stations.find(s => s.code === v)
        v.isFavorite = true
        return v
      })
    },
    seasonForm: state => state.seasonForm
  },
  actions: {
    getTrainStations ({ commit }) {
      return api.getTrainStations(response => {
        commit('setTrainStations', response)
      })
    },
    getReservationRouteStations ({ commit }, serviceResourceOspId) {
      return api.getReservationRouteStations(serviceResourceOspId, response => {
        commit('setReservationRouteStations', { serviceResourceOspId, response })
      })
    },
    getHistoryList ({ commit }, mrpOspId) {
      return api.getHistoryList(mrpOspId, response => {
        commit('setHistoryList', { mrpOspId, response })
      })
    },
    getTimetableHistoryList ({ commit }, mrpOspId) {
      return api.getTimetableHistoryList(mrpOspId, response => {
        commit('setTimetableHistoryList', { mrpOspId, response })
      })
    },
    getJourneys ({ commit }, query) {
      return api.getNmbsJourneyPlan(query, response => {
        commit('setJourney', { query, response })
      })
    },
    resetTimetableForm ({ commit }) {
      const form = {
        origin: null,
        destination: null,
        outbound: {
          type: DEPARTURE,
          date: moment().format(DATE_TIME_FORMAT_WITH_SEC)
        }
      }
      commit('setTimetableForm', form)
    },
    updateTimetableOutbound ({ commit }, value) {
      commit('setTimetableOutbound', value)
    },
    updateTimetableDestination ({ commit }, value) {
      commit('setTimetableDestination', value)
    },
    updateTimetableOrigin ({ commit }, value) {
      commit('setTimetableOrigin', value)
    },
    patchSeasonForm ({ commit, state }, value) {
      commit('setSeasonForm', {
        ...state.seasonForm,
        ...value
      })
    }
  },
  mutations: {
    setTrainStations (state, stations) {
      state.trainStations = stations
    },
    setReservationRouteStations (state, { serviceResourceOspId, response }) {
      Vue.set(state.reservationRouteStations, serviceResourceOspId, response)
    },
    setHistoryList (state, { mrpOspId, response }) {
      Vue.set(state.history, mrpOspId, response)
    },
    setTimetableHistoryList (state, { mrpOspId, response }) {
      Vue.set(state.timetable_history, mrpOspId, response)
    },
    setTimetableOutbound (state, value) {
      state.timetableForm.outbound = value
    },
    setTimetableForm (state, form) {
      Vue.set(state, 'timetableForm', form)
    },
    setTimetableDestination (state, value) {
      state.timetableForm.destination = value
    },
    setTimetableOrigin (state, value) {
      state.timetableForm.origin = value
    },
    setJourney (state, { query, response }) {
      if (response.journeys && response.journeys.length) {
        const journeys = response.journeys
        for (let i in journeys) {
          journeys[i].id = ++i
        }
        const timestamp = moment().format(DATE_TIME_FORMAT_WITH_SEC)
        Vue.set(state, 'journeys', { timestamp, journeys, ...query })
      }
    },
    setSeasonForm (state, form) {
      state.seasonForm = form
    }
  }
}
