/**
 * @module Fuel API
 */
import { lowerFirst } from 'lodash'
import { Api, appEndPoint, appEndPointV2 } from './api'
import { poiFilterV1ToV2 } from '@/helpers'
const API_PREFIX = `${appEndPoint}/fuel`

/**
 * @description The name of the lotalty programme and the info text explaining what the loaylty programme is.
 * @param mrpOspId String
 * @param cb Callback
 * @apiResponse {"mrpOspId": "string", "loyaltyProgrammeName": "string", "loyaltyProgrammeInfoText": "string", "loyaltyCodeInfoText": "string", "poiQualificationRange": string}
 * @return Promise
 * @exception NOT_FOUND
 */
export const getConfig = (mrpOspId, cb) => {
  // return new Promise(resolve => resolve(
  //   {
  //     'mrpOspId': 'string',
  //     'loyaltyProgrammeName': 'string',
  //     'loyaltyProgrammeInfoText': 'string',
  //     'loyaltyCodeInfoText': 'string',
  //     'poiQualificationRange': 200
  //   }
  // ))
  const api = new Api(cb, { loader: true })
  return api.query('post', `${API_PREFIX}/getConfig`, { mrpOspId })
}

/**
 * @description This endpoint checks the Olympus back-end to see if we already have the user's loyalty customer ID
 *          for the given MRP. If so, we return the loyalty account information. If not we throw a NOT_FOUND exception
 * @param mrpOspId String
 * @param cb Callback
 * @apiResponse {"userOspId": "string", "loyaltyAccountId": "string", "loyaltyProgrammeName": "string", "loyaltyCodeInfoText": "string", "poiQualificationRange": "string"}
 * @return Promise
 * @exception NOT_FOUND
 */
export const getUserProfile = (mrpOspId, cb) => {
  // return new Promise(resolve => resolve(
  //   {
  //     'userOspId': 'string',
  //     'loyaltyAccountId': 'string',
  //     'loyaltyProgrammeName': 'string',
  //     'loyaltyCodeInfoText': 'string',
  //     'poiQualificationRange': 'string'
  //   }
  // ))
  const api = new Api(cb, { loader: true, returnError: true, excludeMessageAlert: ['NOT_FOUND'] })
  return api.query('post', `${API_PREFIX}/getUserProfile`, { mrpOspId })
}

/**
 * @description create a new loyalty account in the provider's back-end; this is only called if the user indicated that
 *              he does not have a loyalty account yet for that provider. The provider API may need additional fields
 *              like firstname, lastname, birthdate, etc, but this is handled in our back-end based on the user's token
 * @param mrpOspId String
 * @param externalAccountLogin String in the case of Q8 this field is filled in with the email address provided by the user
 * @param cb Callback
 *
 * @apiResponse {"userOspId": "string", "loyaltyAccountId": "string", "loyaltyProgrammeName": "string", "loyaltyCodeInfoText": "string", "poiQualificationRange": "string"}
 * @return Promise
 * @exception NOT_FOUND
 */
export const createAccount = (data, cb) => {
  // return new Promise(resolve => resolve({
  //   'userOspId': 'string',
  //   'loyaltyAccountId': 'string',
  //   'loyaltyProgrammeName': 'string',
  //   'loyaltyCodeInfoText': 'string',
  //   'poiQualificationRange': 'string'
  // }))
  // return new Promise(resolve => resolve({
  //   error: 1
  // }))
  const api = new Api(cb, { loader: true, returnError: true, excludeMessageAlert: ['FUEL_SERVICE_BLOCKED'] })
  return api.query('post', `${API_PREFIX}/account/create`, data)
}

/**
 * @description Retrieve the loyalty customer identifier from the provider based on the login and code
 * @param mrpOspId String
 * @param externalAccountLogin String In the case of Q8 this field is filled in with the email address provided by the user
 * @param externalAccountCode String The 13 digit value of the EAN-13 barcode on the user's Smiles card.
 * @param cb Callback
 *
 * @apiResponse {"userOspId": "string", "loyaltyCustomerId": "string", "loyaltyProgrammeName": "string"}
 * @return Promise
 * @exception ACCOUNT_MISMATCH This exception is thrown when the email login and loyalty code do not match on the provider side (so the link fails)
 * @exception UNKNOWN_ACCOUNT This exception is thrown when the email address is not known with the provider
 */
export const accountLink = ({ mrpOspId, externalAccountLogin, externalAccountCode }, cb) => {
  const api = new Api(cb, { loader: true, returnError: true, excludeMessageAlert: ['FUEL_SERVICE_BLOCKED'] })
  return api.query('post', `${API_PREFIX}/account/link`, { mrpOspId, externalAccountLogin, externalAccountCode })
}

/**
 * @description Return the last fueling session information for the user identified by the calling token and for
 *              the service resource passed in as argument
 * @param serviceResourceOspId String
 * @param cb Callback
 *
 * @apiResponse {"mrpOspId": "string", "productCode": "string"}
 * @return Promise
 */
export const userLastSession = (serviceResourceOspId, cb) => {
  const api = new Api(cb, { loader: true, excludeMessageAlert: ['*'] })
  return api.query('post', `${API_PREFIX}/lastSession`, { serviceResourceOspId })
}

/**
 * @description The loyalty balance (Smiles) can be retrieved from the back-end by calling the following endpoint
 * @param mrpOspId
 * @param cb Callback
 *
 * @apiResponse {"mrpName": "string", "loyaltyProgrammeName": {}, "balance": "string", "loyaltyUnit": "string"}
 * @return Promise
 */
export const loyaltyBalance = (mrpOspId, cb) => {
  // return new Promise(resolve => {
  //   setTimeout(() => {
  //     // eslint-disable-next-line
  //     cb({
  //       'mrpName': 'string',
  //       'loyaltyProgrammeName': 'Q8 Smiles',
  //       'loyaltyBalance': 122,
  //       'loyaltyUnit': 'smiles'
  //     })
  //     resolve({
  //       'mrpName': 'string',
  //       'loyaltyProgrammeName': 'Q8 Smiles',
  //       'loyaltyBalance': 122,
  //       'loyaltyUnit': 'smiles'
  //     })
  //   }, 2000)
  // })
  const api = new Api(cb, { loader: true })
  return api.query('post', `${API_PREFIX}/loyalty/balance`, { mrpOspId })
}

/**
 * @description Returns the fuel products available at a specified pump.
 * @param gasStationResourceOspId gas station osp id
 * @param pumpNumber int
 * @param cb Callback
 *
 * @apiResponse {"productCode": "string", "productName": "string"}
 * @return Promise
 */
export const fuelProducts = ({ gasStationResourceOspId, pumpNumber }, cb) => {
  const api = new Api(cb, { loader: true })
  return api.query('post', `${API_PREFIX}/pump/products`, { gasStationResourceOspId, pumpNumber })
}

/**
 * @description The capture amount and some additional info can be retrieved by calling the endpoint:
 * @param data {budgetOspId, externalReference, gasStationResourceOspId, productCode, pumpNumber, usageReason, userOspId}
 * @param cb Callback
 *
 * @apiResponse {"status": "string", "captureAmount ": "string", "bookingId  ": "string", "displayMessage   ": "string"}
 * @return Promise
 * @exception INSUFFICIENT_BUDGET
 * @exception LIMITED_CAPTURE_AMOUNT
 */
export const checkBudget = ({ budgetOspId, externalReference, gasStationResourceOspId, productCode, pumpNumber, usageReason, userOspId }, cb) => {
  // return new Promise(resolve => {
  //   // eslint-disable-next-line
  //   cb({error: true})
  //   resolve({error: true})
  // })
  const api = new Api(cb, { loader: true, returnError: true, excludeMessageAlert: ['FUEL_INSUFFICIENT_BUDGET'] })
  return api.query('post', `${API_PREFIX}/checkBudget`, { budgetOspId, externalReference, gasStationResourceOspId, productCode, pumpNumber, usageReason, userOspId })
}

/**
 * @description
 * @param bookingId
 * @param cb Callback
 *
 * @apiResponse NULL
 * @return Promise
 */
export const unlockPump = (bookingId, cb) => {
  const api = new Api(cb, { loader: true, returnError: true, excludeMessageAlert: ['FUEL_PUMP_NOT_AVAILABLE'] })
  return api.query('post', `${API_PREFIX}/unlockPump`, { bookingId })
}

/**
 * @description Returns information about the selected gas station, including the number of pumps.
 * @param gasStationResourceOspId
 * @param cb Callback
 *
 * @apiResponse {"loyaltyParticipation": true, "numberOfPumps": 0}
 * @return Promise
 */
export const getGasStation = (gasStationResourceOspId, cb) => {
  const api = new Api(cb, { loader: true })
  return api.query('post', `${API_PREFIX}/gasStation/get`, { gasStationResourceOspId })
}

/**
 * @description The gas stations that are within a specified range
 * @param filter {"range": 200, "zoom": 7, "mobilityMode": "FUEL_FLOW", "source": {latitude: 0, longitude: 0}}
 * @param cb Callback
 *
 * @return Promise
 */
export const getFuelStations = (filter, cb) => {
  const api = new Api(cb, { loader: false })
  const payload = poiFilterV1ToV2(filter)
  return api.query('post', `${appEndPointV2}/poi/get`, payload)
}

export const mockEndSession = (data, cb) => {
  const api = new Api(cb, { loader: false })
  return api.query('post', `${API_PREFIX}/mockEndSession`, data)
}
