import * as api from '@/api'
import Vue from 'vue'
import { get, set, keys } from 'lodash'
const defaultBlockForm = {
  cardExtId: null,
  contractExtId: null,
  stopReasonCode: null,
  replaceCard: undefined
}
export const fuelcard = {
  namespaced: true,
  state: {
    config: {},
    prequote: {},
    stopConditions: {},
    options: {},
    orderForm: {},
    blockForm: defaultBlockForm,
    invoices: []
  },
  getters: {
    serviceConfig: state => resourceOspId => get(state.config, resourceOspId),
    prequote: state => resourceOspId => get(state.prequote, resourceOspId),
    stopConditions: state => resourceOspId => get(state.stopConditions, resourceOspId),
    options: state => (resourceOspId, cardTypeCode) => get(state.options, [resourceOspId, cardTypeCode]),
    orderForm: state => state.orderForm,
    blockForm: state => state.blockForm,
    requestForm: state => ({
      ...state.orderForm,
      cardConfiguration: {
        ...state.orderForm.cardConfiguration,
        cardOptions: [
          ...keys(state.orderForm.cardConfiguration.cardOptions).map(option => {
            return {
              categoryCode: option,
              optionValue: state.orderForm.cardConfiguration.cardOptions[option].code
            }
          })
        ]
      }
    }),
    invoices: state => state.invoices
  },
  actions: {
    getServiceConfig ({ commit }, resourceOspId) {
      return api.fuelcard.contactSearch(resourceOspId, config => {
        commit('setServiceConfig', { resourceOspId, config })
      })
    },
    getPrequote ({ commit }, resourceOspId) {
      return api.fuelcard.getPrequote(resourceOspId, prequote => {
        commit('setPrequote', { resourceOspId, prequote })
      })
    },
    getStopConditions ({ commit }, { cardExtId, serviceResourceOspId, cb = () => {} }) {
      return api.fuelcard.getStopConditions({ cardExtId, serviceResourceOspId }, stopConditions => {
        commit('setStopConditions', { resourceOspId: serviceResourceOspId, stopConditions })
        cb && cb(stopConditions)
      })
    },
    getOptions ({ commit }, { cardTypeCode, serviceResourceOspId }) {
      return api.fuelcard.getOptions({ cardTypeCode, serviceResourceOspId }, options => {
        commit('setOptions', { resourceOspId: serviceResourceOspId, cardTypeCode, options })
      })
    },
    getInvoices ({ commit }, contractId) {
      return api.fuelcard.getInvoices(contractId, response => {
        commit('setInvoices', response)
      })
    },
    updateOrderForm ({ commit }, form = {}) {
      commit('setOrderForm', form)
    },
    updateBlockForm ({ commit }, form = {}) {
      commit('setBlockForm', form)
    },
    resetBlockForm ({ commit }) {
      commit('setBlockForm', defaultBlockForm)
    }
  },
  mutations: {
    setServiceConfig (state, { resourceOspId, config }) {
      Vue.set(state.config, resourceOspId, config)
    },
    setPrequote (state, { resourceOspId, prequote }) {
      Vue.set(state.prequote, resourceOspId, prequote)
    },
    setStopConditions (state, { resourceOspId, stopConditions }) {
      Vue.set(state.stopConditions, resourceOspId, stopConditions)
    },
    setOptions (state, { resourceOspId, cardTypeCode, options }) {
      set(state.options, [resourceOspId, cardTypeCode], options)
    },
    setOrderForm (state, form) {
      state.orderForm = form
    },
    setInvoices (state, invoices) {
      // state.invoices = invoices
      state.invoices = invoices
    },
    setBlockForm (state, form) {
      state.blockForm = {
        ...state.blockForm,
        ...form
      }
    }
  }
}
