import { appLog } from '../../helpers'
import PostMessageHandler from './PostMessageHandler'

/**
 * Class response for registering of listeners, receiving, parsing and sending to handler data
 */
export class PostMessageReceiver {
  /**
   * Register listener
   */
  static register () {
    if (window.addEventListener) {
      window.addEventListener('message', this.receiveMessage, false)
    } else {
      // @ts-ignore
      window.attachEvent('onmessage', this.receiveMessage)
    }
  }

  /**
   * Receive message handler
   * @param event
   */
  static receiveMessage (event: MessageEvent) {
    if (!event || !event.data) {
      return false
    }
    try {
      const message = JSON.parse(event.data)
      const time = new Date()
      appLog(time.toTimeString())
      appLog(message, 'receiveMessage')
      return PostMessageHandler.handle(message)
    } catch (e) {
      // console.error(e)
    }
    return false
  }
}
