<template>
    <a
      href="#"
      class="c-navbar__refresh"
      @click="refresh"
    >
      <o-icon
        icon="refresh"
      />

    </a>
</template>

<script>
export default {
  name: 'ORefreshButton',
  methods: {
    refresh (e) {
      e.preventDefault()
      this.$emit('refresh')
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
  .c-navbar__refresh {
    display: block;
    opacity: 0.6;
    .c-icon {
      display: block;
    }
  }
</style>
