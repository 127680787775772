/**
 *  @module Vehicle Usage API
 */
import { Api, appEndPoint } from './api'

export const getVehicleusageSessionStatus = (mrpOspId, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/vehicleusage/session/status`, { mrpOspId })
}

export const checkSessionStatus = (mrpOspId, cb) => {
  const api = new Api(cb, { loader: false })
  return api.query('post', `${appEndPoint}/vehicleusage/session/status`, { mrpOspId })
}

export const pauseVehicleusageSession = (mrpOspId, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/vehicleusage/session/pause`, { mrpOspId })
}

export const vehicleusageSessionEnd = (mrpOspId, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/vehicleusage/session/end`, { mrpOspId })
}

export const vehicleOpen = ({ mrpOspId, vehicleCode }, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/vehicleusage/vehicle/open`, { mrpOspId, vehicleCode })
}
