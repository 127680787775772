import _ from 'lodash'
import moment from 'moment'
import Passenger from './Passenger'

const DRIVER = 'DRIVER'
const PASSENGER = 'PASSENGER'

export default class Agreement {
  constructor (agreement) {
    _.assign(this, agreement)
    this.passengersList = this.passengers.filter(v => v.passengerType === PASSENGER && !v.you).map(v => this.definePassenger(v))
    this.you = this.passengers.find(v => v.you) ? this.definePassenger(this.passengers.find(v => v.you)) : {}
    this.driver = this.defineDriver(this.you)
  }

  get youDrive () {
    return this.myPassengerRole !== PASSENGER
  }

  get isExpired () {
    return this.isRideInPast && this.you.state !== 'ACCEPTED'
  }

  get isComplete () {
    return this.isRideInPast && this.you.state === 'ACCEPTED'
  }

  get isRideInPast () {
    return moment().isAfter(moment(this.date + ' ' + this.time, 'YYYY-MM-DD HH:mm'))
  }

  get isRejected () {
    return this.you.state === 'CANCELED_BY_PASSENGER' || this.you.state === 'REJECTED'
  }

  get hasOpenedPaymentRequest () {
    return this.myPassengerRole === 'PASSENGER' && this.driver.isOpened
  }

  definePassenger (passenger) {
    return passenger ? new Passenger(passenger, this) : {}
  }

  defineDriver (you) {
    return this.definePassenger(this.passengers.find(v => v.passengerType === DRIVER && v.agreementId === you.agreementId))
  }
}
