/**
 * Created by Miran on 10.12.2018.
 */
import * as api from '../../api/'
import Vue from 'vue'
import moment from 'moment'
import _ from 'lodash'

const mobitWaitingTime = 5000 // ms

export const sale = {
  namespaced: true,
  state: {
    bookingId: null,
    trainQuotes: null,
    support: {},
    bParkingInfo: {
      infoContent: '',
      mobibCards: []
    },
    bParkingInfoWatched: true,
    mobibsList: {},
    mobibsBunList: {},
    mobitDelay: 0
  },
  getters: {
    bParkingInfo: state => state.bParkingInfo,
    bParkingInfoWatched: state => state.bParkingInfoWatched,
    mobibsList: state => resourceOspId => {
      const availableTokens = (state.mobibsList[resourceOspId] && state.mobibsList[resourceOspId].availableTokens) || []
      const selectedToken = state.mobibsList[resourceOspId] && state.mobibsList[resourceOspId].selectedToken
      const mobibsBunList = state.mobibsBunList[resourceOspId] || []
      let list = availableTokens.filter(card => {
        return !mobibsBunList.includes(card.tokenUuid)
      })
      if (selectedToken) {
        list = list.sort((a, b) => {
          return a.cardNumber === selectedToken.cardNumber ? 1 : 0
        })
      }
      return list
    },
    getSupport: (state) => mrpOspId => {
      if (!mrpOspId) {
        return {}
      }
      if (state.support[mrpOspId]) {
        const cleaned = ('' + state.support[mrpOspId].phone).replace(/\D/g, '')
        const match = cleaned.match(/^(32|)?(\d)(\d{3})(\d{2})(\d{3}|\d{2})$/)

        if (match) {
          const intlCode = (match[1] ? '+32' : '')
          state.support[mrpOspId].phoneFormatted = [intlCode, match[2], match[3], match[4], match[5], match[6]].join(' ')
        }
        return state.support[mrpOspId]
      }
      return {}
    },
    isMobitAvailable: state => {
      return (1 * moment().format('x')) >= state.mobitDelay
    },
    mobitDelay: state => state.mobitDelay,
    mobitLeftTime: state => {
      // timestamp in milliseconds
      return state.mobitDelay - (1 * moment().format('x'))
    },
    bookingId: state => state.bookingId
  },
  actions: {
    addToMobibsBunList ({ commit }, { resourceOspId, tokenUUID }) {
      commit('addToMobibsBunList', { resourceOspId, tokenUUID })
    },
    async checkSupport ({ commit, state }, mrpOspId) {
      if (!state.support[mrpOspId]) {
        const response = await api.getSupport(mrpOspId, () => {})
        if (_.isObject(response)) {
          commit('setSupport', { mrpOspId, response })
        }
      }
      return true
    },
    getTrainQuotes ({ commit }, filters) {
      return api.saleNmbsSncbQuote(filters, response => {
        commit('setTrainQuotes', response)
      })
    },
    getReservationQuotes ({ commit }, filters) {
      return api.saleReservationQuote(filters, response => {
      })
    },
    getMobibChooser ({ commit }, resourceOspId) {
      // appLog(resourceOspId, 'getMobibChooser')
      return api.getMobibChooser(resourceOspId, (response) => {
        if (!response.error) {
          commit('setMobibsList', { resourceOspId, response })
        }
      })
    },
    reservationAlert ({ commit }, filters) {
      return api.reservationAlert(filters, response => {
      })
    },
    getAvailableBudgets ({ commit }, serviceResourceOspId) {
      return api.getAvailableBudgets(serviceResourceOspId, response => {})
    },
    getBParkingInfo ({ commit }) {
      return api.getBParkingInfo(response => {
        commit('setBParkingInfo', response)
      })
    },
    mobitLeftTime: ({ state }) => {
      const now = 1 * moment().format('x')
      return state.mobitDelay > now ? state.mobitDelay - now : 0
    },
    pauseVehicleusageSession ({ commit }, mrpOspId) {
      return api.pauseVehicleusageSession(mrpOspId)
    },
    setMobitDelay ({ commit }) {
      // next 5 seconds
      const time = mobitWaitingTime + 1 * moment().format('x')
      commit('setMobitDelay', time)
    },
    setBookingId ({ commit }, bookingId) {
      commit('setBookingId', bookingId)
    }
  },
  mutations: {
    addToMobibsBunList (state, { resourceOspId, tokenUUID }) {
      const list = state.mobibsBunList[resourceOspId] || []
      list.push(tokenUUID)
      Vue.set(state.mobibsBunList, resourceOspId, list)
    },
    setSupport (state, { mrpOspId, response }) {
      Vue.set(state.support, mrpOspId, response)
    },
    setBookingId (state, bookingId) {
      state.bookingId = bookingId
    },
    setTrainQuotes (state, quotes) {
      state.trainQuotes = quotes
    },
    setBParkingInfo (state, bParkingInfo) {
      state.bParkingInfo = {
        ...bParkingInfo
      }
    },
    setBParkingInfoWatched (state) {
      state.bParkingInfoWatched = true
    },
    setMobibsList (state, { resourceOspId, response }) {
      Vue.set(state.mobibsList, resourceOspId, response)
    },
    setMobitDelay (state, delay) {
      state.mobitDelay = delay
    }
  }
}
