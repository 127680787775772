/**
 * @module Information Payment API
 */
import { Api, appEndPointV2 } from './api'

export const session = (olympusSessionId, cb) => {
  const api = new Api(cb)
  return api.query('get', `${appEndPointV2}/payment/session/${olympusSessionId}`)
}

export const finalise = (adyenSessionId, cb) => {
  const api = new Api(cb, { loader: false })
  return api.query('get', `${appEndPointV2}/payment/finalise?adyenSessionId=${adyenSessionId}`)
}
