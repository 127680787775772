<template>
  <div class="c-base-textinput-wrapper c-base-textinput-wrapper--time c-time-input-wrapper"
       :class="{
      'c-base-textinput-wrapper--nowrap': !label
    }"
  >
    <label v-if="label" class="c-time-input-label" :for="uniqueId">{{ label }}</label>
    <o-icon
      :icon="icon"
      v-if="icon"
    />
    <span v-if="prepend" class="c-time-input-prepend" v-html="prepend"></span>
    <div class="c-base-textinput-container">
    <input
      :key="formatedValue"
      type="text"
      class="c-base-textinput c-time-input"
      :id="uniqueId"

      :placeholder="placeholder"
      :readonly="true"
      :autofocus="autofocus"
      :value="formatedValue"
      @click="!readonly && (showTimePicker=true)"
    >
    </div>
    <o-icon
      class="c-base-textinput__chevron"
      icon="chevron-right"
      v-if="chevron"
    />
    <div v-if="inputHelp" class="help-icon-wrapper" @click.prevent="inputHelp">
      <o-icon icon="info"/>
    </div>
    <template v-if="validations && validations.$params && validations.$dirty">
      <div class="c-error"
           v-if="('required' in validations.$params) && !validations.required">
        <template v-if="noLabelInError">
          {{ getErrorMessage($t('html5.validations.no_label.required')) }}
        </template>
        <template v-else>
          {{ getErrorMessage($t('html5.validations.required'), label) }}
        </template>
      </div>
      <div class="c-error"
           v-if="('minTime' in validations.$params) && !validations.minTime">
        <template v-if="noLabelInError">
          {{ getErrorMessage($t('html5.validations.no_label.min_time', { n: validations.$params.minTime.value })) }}
        </template>
        <template v-else>
          {{ getErrorMessage($t('html5.validations.min_time', { n: (validations.$params.minTime.value) }), label) }}
        </template>
      </div>
      <div class="c-error"
           v-if="('maxTime' in validations.$params) && !validations.maxTime">
        <template v-if="noLabelInError">
          {{ getErrorMessage($t('html5.validations.no_label.max_time', { n: (validations.$params.maxTime.value) })) }}
        </template>
        <template v-else>
          {{ getErrorMessage($t('html5.validations.max_time', { n: (validations.$params.maxTime.value) }), label) }}
        </template>
      </div>
    </template>
    <o-time-picker
      v-if="showTimePicker"
      @apply="setTime"
      @close="showDateTimePicker = false"
      :value="inputVal"
      :step="step"
      :min="min"
    />
  </div>
</template>

<script type="text/ecmascript-6">
import { getErrorMessage } from '../../helpers'
import OIcon from './OIcon'
import OTimePicker from '../utils/OTimePicker'

export default {
  name: 'TimeInput',
  components: { OIcon, OTimePicker },
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: ''
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: null
    },
    readonly: {
      type: Boolean,
      default: false
    },
    min: {
      type: [String, Number],
      default: ''
    },
    max: {
      type: [String, Number],
      default: ''
    },
    validations: {
      type: Object,
      default: null
    },
    prepend: {
      type: String,
      default: null
    },
    chevron: {
      type: Boolean,
      default: false
    },
    noLabelInError: {
      type: Boolean,
      default: false
    },
    step: {
      type: [Number, String],
      default: 1
    },
    format: {
      type: String,
      default: null
    },
    inputHelp: {
      type: Function,
      default: null
    }
  },
  data () {
    return {
      inputVal: this.value,
      uniqueId: '',
      showTimePicker: false
    }
  },
  computed: {
    formatedValue () {
      return this.inputVal
    }
  },
  methods: {
    generateUniqueId () {
      return (
        '_' +
        Math.random()
          .toString(36)
          .substr(2, 9)
      )
    },
    getErrorMessage (message, label = '') {
      return getErrorMessage(message, label)
    },
    setTime (selection) {
      this.inputVal = selection
      this.showTimePicker = false
      // console.log(selection)
    }
  },
  watch: {
    inputVal (val) {
      this.$emit('input', val)
    }
  },
  created () {
    this.uniqueId = this.generateUniqueId()
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
@import '~@/assets/styles/variables.scss';
.c-time-input-wrapper {
  display: flex;
  flex-wrap: wrap;
  line-height: normal;

  .c-icon {
    color: $primary-color;
    color: var(--primary-color);
    flex: none;
    margin-right: 1.2rem;
  }

  .c-error ~ .c-error {
    display: none;
  }
}

.c-time-input {
  appearance: none;
  background: none;
  border: 0;
  color: inherit;
  display: block;
  flex: 1 1 0;
  font: inherit;
  outline: 0;
  box-shadow: none;
  /*border-bottom: 1px solid rgba(0, 0, 0, .1);*/
}

.c-time-input-label {
  color: $blue-kbc-cement;
  flex: none;
  font-size: 1.3rem;
  margin-bottom: 0.8rem;
  width: 100%;
}

.c-time-input-prepend {
  margin-right: .5rem;
  margin-top: .1rem;
}

.c-time-input-no_clear {
  &::-webkit-clear-button {
    display: none;
  }

  &::-ms-clear {
    display: none;
  }
}

.help-icon-wrapper {
  height: 24px;

  .c-icon {
    cursor: pointer;
    margin: 0;
  }
}
</style>
