<template>
  <select v-model="selectedTab"  v-on:change="$emit('select-tab', selectedTab)">
    <option v-for="(item, index) in items" v-bind:key="index" v-bind:value="index" :selected="item.isSelected">
      {{ item.label }}
    </option>
  </select>
</template>

<script>
export default {
  name: 'OTabs',
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      selectedTab: null
    }
  },
  created () {
    this.items.forEach((item, index) => {
      if (item.isSelected) {
        this.selectedTab = index
        return true
      }
    })
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
@import '~@/assets/styles/variables.scss';
.c-tabs {
  background: #fff;
  display: flex;
  flex: none;
}

.c-tab {
  box-shadow: inset 0 -0.1rem 0 rgba(#000, 0.1);
  color: $gray-500;
  flex: 1;
  font-weight: 600;
  padding: 1.6rem;
  text-align: center;
}

.c-tab--active {
  box-shadow: inset 0 -0.2rem $primary-color;
  box-shadow: inset 0 -0.2rem var(--primary-color-overlay);
  color: $text-color;
  color: var(--text-color);
}
</style>
