// import CarpoolPage from '../../components/carpool/CarpoolPage'
// import CarpoolRegistrationPage from '../../components/carpool/CarpoolRegistrationPage'
// import CarpoolServicePage from '../../components/carpool/CarpoolServicePage'
// import CarpoolRideCreatePage from '../../components/carpool/CarpoolRideCreatePage'
// import CarpoolRideConfirmationPage from '../../components/carpool/CarpoolRideConfirmationPage'
// import CarpoolRouteSelectionPage from '../../components/carpool/CarpoolRouteSelectionPage'
// import CarpoolDriverPreferencesPage from '../../components/carpool/CarpoolDriverPreferencesPage'
// import CarpoolAvailableRidesPage from '../../components/carpool/CarpoolAvailableRidesPage'
// import CarpoolMyRidesPage from '../../components/carpool/CarpoolMyRidesPage'
// import CarpoolAgreementsPage from '../../components/carpool/CarpoolAgreementsPage'
// import CarpoolRideDetailsPage from '../../components/carpool/CarpoolRideDetailsPage'
// import CarpoolAgreementRequestConfirmationPage from '../../components/carpool/CarpoolAgreementRequestConfirmationPage'
// import CarpoolRequestPaymentPage from '../../components/carpool/CarpoolRequestPaymentPage'

// const lazyLoad = (component) => () => import(/* webpackChunkName: "carpool" */ '../../components/carpool/' + component)
const lazyLoad = (component) => {
  if (process.env.NODE_ENV !== 'production') {
    return require('../../components/carpool/' + component).default
  } else {
    return () => import(/* webpackChunkName: "carpool" */ '../../components/carpool/' + component)
  }
}
// component: () => import(/* webpackChunkName="carpool" */ '../../components/carpool/CarpoolPage'),

const carpoolRoutes = [
  // Carpool
  {
    path: '/sales/carpool/:resourceOspId',
    name: 'sales_carpool',
    component: lazyLoad('CarpoolPage'),
    children: [
      {
        path: '',
        name: 'sales_carpool_available_rides',
        component: lazyLoad('CarpoolAvailableRidesPage')
        // component: CarpoolAvailableRidesPage
      },
      {
        path: 'my-rides',
        name: 'sales_carpool_my_rides',
        component: lazyLoad('CarpoolMyRidesPage')
        // component: CarpoolMyRidesPage
      },
      {
        path: 'agreements',
        name: 'sales_carpool_agreements',
        component: lazyLoad('CarpoolAgreementsPage')
        // component: CarpoolAgreementsPage
      }
    ]
  },
  {
    path: '/sales/carpool/:resourceOspId/ride/:rideUuid/details',
    name: 'sales_carpool_ride_details',
    component: lazyLoad('CarpoolRideDetailsPage')
    // component: CarpoolRideDetailsPage
  },
  {
    path: '/sales/carpool/:resourceOspId/home-address',
    name: 'sales_carpool_registration_ha',
    component: lazyLoad('CarpoolRegistrationPage')
    // component: CarpoolRegistrationPage
  },
  {
    path: '/sales/carpool/:resourceOspId/work-address',
    name: 'sales_carpool_registration_wa',
    component: lazyLoad('CarpoolRegistrationPage')
    // component: CarpoolRegistrationPage
  },
  {
    path: '/sales/carpool/:resourceOspId/avatar',
    name: 'sales_carpool_avatar',
    component: lazyLoad('CarpoolAvatarPage')
  },
  {
    path: '/sales/carpool/:resourceOspId/avatar-crop',
    name: 'sales_carpool_avatar_crop',
    component: lazyLoad('CarpoolAvatarCropPage')
  },
  {
    path: '/sales/carpool/:resourceOspId/service-info',
    name: 'sales_carpool_service',
    component: lazyLoad('CarpoolServicePage')
    // component: CarpoolServicePage
  },
  {
    path: '/sales/carpool/:resourceOspId/ride/create',
    name: 'sales_carpool_ride_create',
    component: lazyLoad('CarpoolRideCreatePage')
    // component: CarpoolRideCreatePage
  },
  {
    path: '/sales/carpool/:resourceOspId/route-selection/:rideUuid',
    name: 'sales_carpool_route_selection',
    component: lazyLoad('CarpoolRouteSelectionPage')
    // component: CarpoolRouteSelectionPage
  },
  {
    path: '/sales/carpool/:resourceOspId/driver-preferences/:rideUuid',
    name: 'sales_carpool_preferences',
    component: lazyLoad('CarpoolDriverPreferencesPage')
    // component: CarpoolDriverPreferencesPage
  },
  {
    path: '/sales/carpool/:resourceOspId/ride/confirmation',
    name: 'sales_carpool_ride_confirmation',
    component: lazyLoad('CarpoolRideConfirmationPage')
    // component: CarpoolRideConfirmationPage
  },
  {
    path: '/sales/carpool/:resourceOspId/agreements/confirmation',
    name: 'sales_carpool_agreements_confirmation',
    component: lazyLoad('CarpoolAgreementRequestConfirmationPage')
    // component: CarpoolAgreementRequestConfirmationPage
  },
  {
    path: '/sales/carpool/:resourceOspId/request-payment',
    name: 'sales_carpool_request_payment',
    component: lazyLoad('CarpoolRequestPaymentPage')
    // component: CarpoolRequestPaymentPage
  },
  {
    path: '/sales/carpool/:resourceOspId/request-payment/budgets',
    name: 'sales_carpool_request_payment_budgets',
    component: lazyLoad('CarpoolRequestPaymentBudgetsPage')
    // component: CarpoolRequestPaymentPage
  },
  {
    path: '/sales/carpool/:resourceOspId/request-payment-confirmation',
    name: 'sales_carpool_request_payment_confirmation',
    component: lazyLoad('CarpoolPaymentRequestConfirmationPage')
    // component: CarpoolRequestPaymentPage
  }
]

export default carpoolRoutes
