import { CONVENIENT_INVOICING_RESOURCE } from '@/helpers'
import { Func } from 'mocha'
import { Api, appEndPoint, appEndPointV3 } from './api'

const API_PREFIX = `${appEndPoint}/convenientinvoicing`

export const getConvenientInvoicing = (cb: Function) => {
  const api = new Api(cb, { returnError: true, excludeMessageAlert: ['NOT_FOUND', 'SECURITY_RULE_VIOLATION'] })
  return api.query('get', `${API_PREFIX}/get`)
}

export const getConvenientInvoicingContract = (cb: Function) => {
  const api = new Api(cb, { returnError: true, excludeMessageAlert: ['NOT_FOUND', 'SECURITY_RULE_VIOLATION'] })
  return api.query('get', `${API_PREFIX}/contract`)
}

export const getContractStatus = (cb: Function) => {
  const api = new Api(cb, { returnError: true, excludeMessageAlert: ['NOT_FOUND', 'SECURITY_RULE_VIOLATION'] })
  return api.query('get', `${API_PREFIX}/contract/status`)
}

export const updateContractStatus = (data: any, cb: Function) => {
  const api = new Api(cb)
  return api.query('post', `${API_PREFIX}/contract/status`, data)
}

export const updateConvenientInvoicingContract = (data: any, cb: Function) => {
  const api = new Api(cb)
  return api.query('post', `${API_PREFIX}/contract`, data)
}

export const hasContract = (cb: Function = () => {}) => {
  const api = new Api(cb)
  return api.query('get', `${API_PREFIX}/hascontract`)
}

export const getQuote = ({ budgetOspId, referrer = undefined }: { budgetOspId: string, referrer?: string }, cb: Function) => {
  const form = {
    resourceOspId: CONVENIENT_INVOICING_RESOURCE,
    mobilityMode: 'NONE',
    budgetOspId,
    referrer
  }
  const api = new Api(cb, { returnError: true })
  return api.query('post', `${appEndPointV3}/sales/ConvenientInvoicing/quote`, form)
}

export const getTokenServices = (cb: Function) => {
  const api = new Api(cb, { returnError: true, excludeMessageAlert: ['NOT_FOUND'] })
  return api.query('get', `${API_PREFIX}/tokenservices`)
}

export const getTokenTransfer = (cb: Function) => {
  const api = new Api(cb, { returnError: true, excludeMessageAlert: ['NOT_FOUND'] })
  return api.query('get', `${API_PREFIX}/tokenTransfer`)
}

export const getInvoicingEmail = (cb: Function) => {
  const api = new Api(cb)
  return api.query('get', `${API_PREFIX}/email`)
}

export const updateInvoicingEmail = (emailAddress: string, cb: Function) => {
  const api = new Api(cb)
  return api.query('post', `${API_PREFIX}/email/update`, { emailAddress })
}

export const cancelUser = (userOspId: string, cb: Function) => {
  const api = new Api(cb)
  return api.query('delete', `${API_PREFIX}/cancel/${userOspId}`)
}

type TransferMobibsRequest = {
  userOspId: string,
  mobibCards: [
    {
      tokenUUID: string,
      cardNumber: string,
      cardLabel: any
    }
  ]
}
export const transferMobibs = (form: TransferMobibsRequest, cb: Function) => {
  const api = new Api(cb)
  return api.query('post', `${API_PREFIX}/transferMobibs`, form)
}

type TransferLicensePlatesRequest = {
  userOspId: string,
  licensePlates: [
    {
        licensePlateUuid: string,
        licensePlate: string,
        mrpOspId: string,
        licensePlateLabel?: any
      }
    ]
}
export const transferLicensePlates = (form: TransferLicensePlatesRequest, cb: Function) => {
  const api = new Api(cb)
  return api.query('post', `${API_PREFIX}/transferLicensePlates`, form)
}

type SendInvoiceRequest = {
  userOspId: string,
  invoiceOspId: string
}
export const sendInvoice = (form: SendInvoiceRequest, cb: Function) => {
  const api = new Api(cb)
  return api.query('post', `${API_PREFIX}/invoice/send`, form)
}

export const reenroll = (userOspId: string, cb: Function) => {
  const api = new Api(cb)
  return api.query('post', `${API_PREFIX}/reenroll/${userOspId}`)
}
