/**
 * @module PARKING API
 */
import { Api, appEndPoint } from './api'

export const parkingInfo = ({ mrpOspId, parkingCode }, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/parking/info`, { mrpOspId, parkingCode })
}

export const parkingSessionsGetByFilter = (filter, cb) => {
  const api = new Api(cb, { returnError: true, excludeMessageAlert: ['*'] })
  return api.query('post', `${appEndPoint}/parking/session/getByFilter`, filter)
}

export const parkingCodeAutocomplete = ({ mrpOspId, mrpZoneCodeSearchString }, cb) => {
  const api = new Api(cb, { loader: false })
  return api.query('post', `${appEndPoint}/autocomplete/parkingCode`, { mrpOspId, mrpZoneCodeSearchString })
}

export const parkingCodeByLocation = (form, cb) => {
  const api = new Api(cb, { loader: false, excludeMessageAlert: ['*'] })
  return api.query('post', `${appEndPoint}/autocomplete/parkingCodeByLocation`, form)
}

export const parkingSessionStart = (form, cb) => {
  const api = new Api(cb, {
    returnError: true,
    excludeMessageAlert: ['DUPLICATE_BOOKING']
  })
  return api.query('post', `${appEndPoint}/parking/session/start`, form)
}

export const parkingSessionStop = (form, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/parking/session/stop`, form)
}

export const resellerConfig = (serviceResourceOspId, cb) => {
  const api = new Api(cb, { showError: false })
  return api.query('post', `${appEndPoint}/parking/reseller/config/get`, { serviceResourceOspId })
}

export const getParkingUser = (mrpOspId, cb) => {
  const api = new Api(cb, { excludeMessageAlert: ['NOT_FOUND'], returnError: true })
  return api.query('post', `${appEndPoint}/parking/${mrpOspId}/user`)
}

export const getParkingEntranceCode = (serviceResourceOspId, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/parking/entranceCode/get`, { serviceResourceOspId })
}

export const userParkingRevoke = (mrpOspId, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/parking/${mrpOspId}/user/revoke`)
}
