/**
 * @module User API
 */
import { Api, userEndPoint, appEndPoint, appEndPointV2 } from './api'

export const userAuth = (data, cb) => {
  const api = new Api(cb)
  return api.query('get', `${userEndPoint}/auth/login?username=${data.username}&passdigest=${data.passdigest}`)
}

export const userAuthReseller = (data, cb) => {
  const api = new Api(cb)
  return api.query('get', `${userEndPoint}/auth/login/reseller?client_id=${data.clientId}&authorization_code=${data.authorizationCode}`)
}

export const userAuthRefresh = (refreshToken, cb) => {
  const api = new Api(cb, {
    loader: false,
    extraHeaders: { Authorization: 'Bearer ' + refreshToken }
  })
  return api.query('post', `${userEndPoint}/auth/refresh`)
}

export const userMobilityMenu = cb => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/user/mobilityPreferences/get`)
}

export const checkConditionsStatus = (form, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/conditions/status${form && form.serviceResourceOspId ? '?serviceResourceOspId=' + form.serviceResourceOspId : ''}`)
}

export const getConditions = (requestForm, cb) => {
  const api = new Api(cb, {
    responseType: 'text'
  })
  return api.query('get', `${appEndPoint}/conditions/get${requestForm && requestForm.serviceResourceOspId ? '?serviceResourceOspId=' + requestForm.serviceResourceOspId : ''}`)
}

export const acceptConditions = (form, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/conditions/accept`, form)
}

export const updateUserLanguage = (language, cb) => {
  const api = new Api(cb, { loader: false })
  return api.query('post', `${appEndPoint}/user/language/update`, { languageCode: language })
}

export const userProfile = cb => {
  const api = new Api(cb)
  return api.query('post', `${appEndPointV2}/user/profile/get`)
}

export const updateUserProfile = (data, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/user/profile/update`, { user: data })
}

export const updateUserProfile2 = (data, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPointV2}/user/profile/update`, { user: data })
}

export const updateUserAddress = (data, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPointV2}/user/profile/address`, data)
}

export const updatePinCode = ({ newPincodeDigest, oldPincodeDigest }, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/user/pincode/update`, { newPincodeDigest, oldPincodeDigest })
}

export const resetPincode = (login, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}`, { login })
}

export const updateAddressInfo = (data, cb) => {
  const api = new Api(cb, { returnError: true })
  return api.query('patch', `${appEndPointV2}/user/profile/address`, data)
}

export const setAvatar = (data, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/user/avatar/set`, data)
}

/**
 * Favorites
 */
export const getFavorites = (cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/user/favorites/get`)
}
export const addFavorites = (data, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/user/favorites/add`, data)
}
export const removeFavorites = (data, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/user/favorites/remove`, data)
}

export const putAvatar = ({ avatar, avatarMimeType }, cb) => {
  const api = new Api(cb)
  return api.query('put', `${appEndPoint}/user/profile/avatar`, { avatar, avatarMimeType })
}

export const updateProfileIban = (iban, cb) => {
  const api = new Api(cb)
  return api.query('patch', `${appEndPointV2}/user/profile/iban`, { iban })
}
