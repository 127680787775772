// const data = {
//   'Authorized': true,
//   'Coordinate': { 'Latitude': 50.853306, 'Longitude': 4.349708 },
//   'MobilityMode': 'PARKING',
//   'OspId': { 'OspId': 'BeMobile.RESOURCE.GEO_RESOURCE.PARKINGLOT.040867' },
//   'MrpOspId': { 'OspId': 'BeMobile' },
//   'Street': null,
//   'City': null,
//   'Zipcode': null,
//   'PhoneNumber': null,
//   'Name': { 'DefaultValue': '040867', 'LabelTranslations': [{ 'LanguageCode': 'fr', 'Text': '040867' }] },
//   'InfoText': null,
//   'Properties': [],
//   'ServiceResources': []
// }

import Favorite from './Favorite'
import { get, pick, upperFirst, lowerFirst } from 'lodash'

class ParkingFavorite extends Favorite {
  static createForMobilityMode (mobilityMode, travelData) {
    const data = {
      ...this.getDefaultDataForMobilityMode(mobilityMode),
      Authorized: true,
      Coordinate: get(travelData, 'location'),
      OspId: { OspId: mobilityMode.resourceOspId },
      MrpOspId: pick(mobilityMode.mobilityProvider, 'ospId'),
      Name: mobilityMode.resourceName,
      SessionType: get(travelData, 'sessionType'),
      LicensePlate: get(travelData, 'licensePlate'),
      Parking: get(travelData, 'parking')
    }
    return new this(this.formatKeysTo(data, upperFirst))
  }

  get keysForComparison () {
    return ['ResourceProviderId', 'Parking.ParkingCode', 'LicensePlate.Uuid']
  }

  getForm () {
    const data = pick(this, ['SessionType', 'LicensePlate', 'Parking'])
    return ParkingFavorite.formatKeysTo(data, lowerFirst)
  }
}

export default ParkingFavorite
