import { router } from '../../helpers'
import _ from 'lodash'
import Vue from 'vue'
import postMessage from '../../Classes/postMessageService/postMessage'

const defaultState = () => {
  return {
    stack: [],
    startRoute: {}
  }
}
export const navigation = {
  namespaced: true,
  state: {
    ...defaultState()
  },
  getters: {
    stack: state => state.stack,
    startRoute: state => state.stack.find(v => !['/', '/login'].includes(v.path)),
    isStartRoute: state => routeName => {
      const startRoute = state.stack.find(v => !['/', '/login'].includes(v.path))
      return !startRoute || startRoute.name === routeName
    }
  },
  actions: {
    addRoute ({ commit, state }, { to, from }) {
      commit('stackPush', to)
    },
    back ({ state, dispatch }, steps = 1) {
      const prevStack = []
      for (let i = steps; i >= 1; --i) {
        prevStack.push(state.stack.pop())
      }
      return router.push(_.last(prevStack) || '').catch(e => {
        if (e.name !== 'NavigationDuplicated') {
          console.log('Error caught')
          console.log(e)
        }
        if (!state.stack.length) {
          prevStack.reverse().map(route => state.stack.push(route))
          postMessage.close()
          // return sendPostMessage('close')
        } else {
          return dispatch('back')
        }
      })
    },
    completeSalesFlow ({ commit, state }, salesFlowId) {
      commit('filterStack', salesFlowId)
    },
    reset ({ commit }) {
      commit('reset', defaultState())
    }
  },
  mutations: {
    stackPush (state, route) {
      if (!_.last(state.stack) || _.last(state.stack).path !== route.path) {
        state.stack.push(route)
      }
    },
    filterStack (state, salesFlowId) {
      state.stack = state.stack.filter(v => v.query.salesFlowId !== salesFlowId)
    },
    reset (state, data) {
      _.forEach(data, (value, key) => {
        Vue.set(state, key, value)
      })
    }
  }
}
