import { store } from '../../store'
import { appLog, router } from '../../helpers'
// @ts-ignore
import { get, has, isString } from 'lodash'
import { startFlowHandler } from '@/helpers/receivePostMessageHandlers'
import postMessage from './postMessage'
import { PostMessage } from '@/Classes/postMessageService/postMessage.types'
import * as api from '@/api'
import Flow from '@/helpers/Flow'

/**
 * Class handler for incoming postMessages
 */
export default class PostMessageHandler {
  /**
   * Base handle method which parse incoming postMessage and call proper class method
   * @param message
   */
  static handle (message: PostMessage) {
    // @ts-ignore
    if (this[message.api] && typeof this[message.api] === 'function') {
      // @ts-ignore
      return this[message.api](message.data || message.response)
    }
  }

  static init (authorizationCode: string) {
    appLog('receiveMessage: init')
    return store.commit('app/setAuthorizationCode', authorizationCode)
  }

  static navigation () {
    appLog('receiveMessage: navigation')
    return router.go(-1)
  }

  static async getLocation (location: any) {
    appLog(location, 'Recieved getLocation')
    store.commit('user/setGeolocationAct', location || null, { root: true })
    store.commit('poi/setFilters', { source: location || null }, { root: true })
    if (!location) {
      await store.dispatch('user/checkUserGeolocationAct')
    }
    return true
  }

  static getPicture (data: any) {
    appLog(data, 'Received getPicture')
    return store.dispatch('picture/setPicture', get(data, 'imageData', null))
  }

  static scanBarcode (data: { text: any }) {
    appLog(data, 'Received scanBarcode')
    store.commit('app/setQRCode', data.text)
    return true
  }

  static startPayment (data: { code?: any, result?: any }) {
    appLog('receiveMessage: startPayment')
    return store.dispatch('payment/paymentResult', data.code || data.result)
  }

  static startPaymentToken (data: { code?: any, result?: any }) {
    appLog('receiveMessage: startPaymentToken')
    return store.dispatch('payment/paymentResult', data.code || data.result)
  }

  static startFlow (data: any) {
    appLog('receiveMessage: startFlow')
    return startFlowHandler(data)
  }

  static async back () {
    // @ts-ignore
    let backMethod = router.history.current.matched[0].instances.default.goBack || undefined
    if (!backMethod) {
      // @ts-ignore
      const childComponent = router.history.current.matched[0].instances.default.$children.find(c => has(c, 'goBack'))
      backMethod = childComponent && childComponent.goBack ? childComponent.goBack : undefined
    }
    if (backMethod && typeof backMethod === 'function') {
      backMethod()
      return postMessage.navigateTo()
    }
    await store.dispatch('navigation/back')
    return postMessage.navigateTo()
  }

  static navigateTo (data: { type: string }) {
    // "managelicenseplates"
    // "parkingentrancecode"
    const resourceOspId = get(new Flow(), ['flow', 'resource'])
    switch (data.type) {
    case 'back':
      return this.back()
    case 'managemobib':
      router.push({ name: 'mobib' })
      return postMessage.navigateTo()
    case 'discount_rights':
      router.push({ name: 'discount_rights' })
      return postMessage.navigateTo()
    case 'managelicenseplates':
      if (resourceOspId) {
        return router.push({
          name: 'off_street_parking_license_plates',
          params: {
            resourceOspId: btoa(resourceOspId)
          }
        })
      }
      return postMessage.navigateTo('NOK')
    case 'parkingentrancecode':
      if (resourceOspId) {
        return router.push({
          name: 'off_street_parking_entrance_code',
          params: {
            resourceOspId: btoa(resourceOspId)
          }
        })
      }
      return postMessage.navigateTo('NOK')
    case 'revoke4411':
      return store.dispatch('streetParking/confirmRevoke', '4411')
    case 'revokeqpark':
      return store.dispatch('streetParking/confirmRevoke', 'QPark')
    case 'RESELLERB2BSETTINGS':
      return router.push({
        name: 'convenient_invoicing_settings'
      })
    default:
      return postMessage.navigateTo('NOK')
    }
  }

  static token (data: any) {
    return store.commit('user/setTokens', data)
  }

  static confirmBiometrics (data: { biometricResult: boolean | string, code: string, pinDigest: string | null}) {
    if (isString(data.biometricResult)) {
      data.biometricResult = data.biometricResult.toLowerCase() === 'true'
    }
    return store.commit('biometrics/SET_BIOMETRICS', data)
  }
}
