<template>
  <label class="c-checkbox" :class="{'branded-checkbox': branded}">
    <input type="checkbox"
           :checked="checkbox"
           @click="checkbox = !checkbox"
           @change="$emit('change', name, checkbox)"
           :disabled="readonly"
          name="name">
    <span class="c-checkbox__checkmark">
      <o-icon icon="check" class="c-checkbox__checkmark--icon"></o-icon>
    </span>
    <div class="c-checkbox__text">

      <p>
        <slot/>
      </p>
    </div>
  </label>
</template>

<script type="text/ecmascript-6">
export default {
  data () {
    return {
      checkbox: false
    }
  },
  name: 'OCheckbox',
  props: {
    name: {
      type: String,
      required: true
    },
    checked: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    branded: {
      type: Boolean,
      default: false
    }
  },
  created () {
    this.checkbox = this.checked
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.c-checkbox {
  align-items: flex-start;
  display: flex;
  .c-checkbox__checkmark {
    display: none;
    position: absolute;
    top: 50%;
    left: 0px;
    height: 20px;
    width: 20px;
    background-color: white;
    transform: translateY(-50%);
    border-radius: 4px;
    color: white;
    border: 1px solid;
    border-color: var(--primary-color);
    .c-icon {
      max-width: 20px;
    }
  }

  input[type='checkbox'] {
    flex: none;
    height: 2.4rem;
    margin-right: 0.8rem;
    width: 2.4rem;
  }

  &__text {
    margin: auto 0;
  }
  &.branded-checkbox {
    position: relative;
    .c-checkbox__text {
      margin-left: 36px;
    }
    .c-checkbox__checkmark {
      display: flex;
      transition: background-color 0.2s ease;
    }
     input {
      display: none;
      &:checked {
        & ~ .c-checkbox__checkmark {
          background: var(--primary-color);
        }
      }
    }
  }
}
</style>
