<template>
  <div class="c-bottom-buttons">
    <div
      class="c-bottom-buttons__note c-bottom-buttons__note--top"
      v-if="$slots.noteTop"
    >
      <slot name="noteTop" />
    </div>
    <div class="c-bottom-buttons__buttons">
      <slot name="buttons" />
    </div>
    <div class="c-bottom-buttons__note" v-if="$slots.note">
      <slot name="note" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'OBottomButtons'
}
</script>

<style lang="scss">

</style>
