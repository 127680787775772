<template>
<div class="c-textarea-wrapper">
  <label v-if="label && showLabel" class="c-textarea-label">{{ label }}</label>
    <o-icon
      :icon="icon"
      v-if="icon"
    />
    <div
    class="c-textarea-input"
    :class="{
      'c-textarea-input--focus': focus
      }"
    >
      <span v-if="prepend" class="c-textarea-prepend" v-html="prepend"></span>
      <textarea
      v-if="!readonly"
        ref="textarea"
        v-model.trim="inputVal"
        :name="name"
        :rows="rows"
        class="c-textarea"
        :autofocus="autofocus"
        :placeholder="placeholder"
        @focus="onFocus"
        @blur="onBlur"
        >
      </textarea>
      <div
        v-else
        ref="textarea"
        class="c-textarea"
      >
        {{inputVal}}
      </div>
      <span v-if="clearable && value" class="c-textarea-clear" @click="clear">&times;</span>
    </div>
    <template v-if="validations && validations.$params && validations.$dirty">
     <div class="c-error"
           v-if="('maxLength' in validations.$params) && !validations.maxLength">
        <template v-if="noLabelInError">
          {{ getErrorMessage($t('html5.validations.no_label.max_length', { n: validations.$params.maxLength.max })) }}
        </template>
        <template v-else>
          {{ getErrorMessage($t('html5.validations.max_length', { n: validations.$params.maxLength.max }), label) }}
        </template>
      </div>
    </template>
    <template v-if="errorMessage">
      <div class="c-error">{{errorMessage}}</div>
    </template>
</div>
</template>

<script>
import { getErrorMessage } from '../../helpers'
import OIcon from './OIcon'
export default {
  name: 'OTextarea',
  components: { OIcon },
  props: {
    autofocus: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number],
      default: ''
    },
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: null
    },
    prepend: {
      type: String,
      default: null
    },
    rows: {
      type: [String, Number],
      default: '2'
    },
    readonly: {
      type: Boolean,
      default: false
    },
    validations: {
      type: Object,
      default: null
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    noLabelInError: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      inputVal: this.value,
      focus: false
    }
  },
  watch: {
    inputVal (val) {
      this.$emit('input', val)
    }
  },
  methods: {
    clear () {
      this.inputVal = ''
      this.$refs.textarea.focus()
      this.focus = true
    },
    onFocus (e) {
      this.focus = true
    },
    onBlur (e) {
      setTimeout(() => {
        this.focus = false
      }, 50)
    },
    getErrorMessage (message, label = '') {
      return getErrorMessage(message, label)
    }
  }
}
</script>

<style lang='scss'>
.c-textarea-wrapper {
  display: flex;
  flex-wrap: wrap;
  line-height: normal;
  // align-items: center;

  .c-icon {
    color: var(--primary-color);
    flex: none;
    margin-right: 1.2rem;
  }

  .c-textarea-input {
    flex: 1 1 0;
    display: flex;
    flex-direction: row;
    // flex: 1 1 0;
    max-width: 100%;

    .c-textarea {
      padding-left: 0;
      padding-right: 0;
      margin: 0;
      appearance: none;
      background: none;
      border: 0;
      color: inherit;
      display: block;
      flex: 1 1 0;
      font: inherit;
      line-height: 1.3;
      outline: 0;
      box-shadow: none;
    }

    .c-textarea-clear {
      padding: 0 .5rem;
      font-size: 2rem;
      font-weight: bold;
      color: var(--primary-color);
    }
  }
}
</style>
