<template>
  <div
    class="c-navbar"
    :class="{
      'c-navbar--overlay': overlay,
      'c-navbar--android': os === 'android',
      'c-navbar--centered': isCentered,
      'c-navbar--extended': showHeader && isExtended,
      'c-navbar--background': backgroundImage !== null
    }"
    :style="{
      backgroundImage: backgroundImage ? `url('${backgroundImage}')` : 'none'
    }"
    v-if="(logo || showHeader) && false"
  >
    <o-back-button v-if="showHeader && hasBackButton" @go-back="goBack" />
    <h1 v-if="showHeader" class="c-navbar__title" v-html="title"></h1>
    <div class="c-navbar__right" v-if="showHeader && ($store.state.showSupportButton || hasRefreshButton || hasCloseButton)">
      <slot name="right">
        <o-icon
          icon="support"
          v-if="$store.state.showSupportButton"
        />
        <o-refresh-button v-if="showHeader && hasRefreshButton" @refresh="$emit('refresh')"></o-refresh-button>
        <router-link
          v-if="hasCloseButton"
          :to="closeLink"
          class="c-navbar__close"
        >
          <o-icon icon="close"/>
        </router-link>
      </slot>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import { mapGetters } from 'vuex'
import Flow from '../../helpers/Flow'

export default {
  name: 'ONavBar',
  props: {
    backgroundImage: {
      type: String,
      default: null
    },
    backLink: {
      type: String,
      default: ''
    },
    closeLink: {
      type: String,
      default: () => {
        return new Flow().start || '/'
      }
    },
    emitBackClick: {
      type: Boolean,
      default: false
    },
    hasCloseButton: {
      type: Boolean,
      default: true
    },
    hasRefreshButton: {
      type: Boolean,
      default: false
    },
    hasBackButton: {
      type: Boolean,
      default: false
    },
    isCentered: {
      type: Boolean,
      default: false
    },
    isExtended: {
      type: Boolean,
      default: false
    },
    logo: {
      type: String,
      default: null
    },
    overlay: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters({
      showHeader: 'app/showHeader'
    }),
    os () {
      const ua = navigator.userAgent.toLowerCase()
      return ua.indexOf('android') > -1 ? 'android' : 'ios'
    }
  },
  methods: {
    goBack () {
      if (this.emitBackClick) {
        this.$emit('back')
      } else if (this.backLink) {
        this.$router.push(this.backLink)
      } else {
        this.$store.dispatch('navigation/back')
        // this.$router.go(-1)
      }
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
@import '~@/assets/styles/variables.scss';
  .c-navbar {
    align-items: flex-start;
    background-color: $primary-color;
    background-color: var(--primary-color-overlay);
    color: #fff;
    display: flex;
    font-size: 1.7rem;
    font-weight: 600;
    letter-spacing: -0.033rem;
    line-height: 2.4rem;
    min-height: 4.4rem;
    padding: 1rem;
    position: relative;
    z-index: 10;
  }

  .c-navbar--overlay {
    background: rgba(#000, 0.05);
  }

  .c-navbar__back {
    display: block;
    margin-right: 3.2rem;
    opacity: 0.6;

    .c-icon {
      display: block;
    }
  }

  /*.c-navbar:not(.c-navbar--android) .c-navbar__title,*/
  .c-navbar--centered .c-navbar__title {
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .c-navbar--android {
    font-size: 2rem;
    min-height: 5.6rem;
    padding: 1.6rem;
  }

  .c-navbar--extended {
    min-height: 7.2rem;
  }

  .c-navbar--android.c-navbar--extended {
    min-height: 8.4rem;
  }

  .c-navbar--background {
    box-shadow: inset 0 100rem 0 rgba($primary-color, 0.98);
    box-shadow: inset 0 100rem 0 var(--primary-color-overlay);
    background-position: center center;
    background-size: cover;
  }

  .c-navbar__right {
    align-items: center;
    display: flex;
    margin-left: auto;

    & > * {
      opacity: 0.6;
    }

    & > *:not(:last-child) {
      margin-right: 16px;
    }
  }

  .c-navbar__close {
    font-weight: normal;

    .c-icon {
      display: block;
    }
  }
</style>
