export const API_URL = process.env.VUE_APP_API_URL

export const BIKE_FLOW = 'BIKE'
export const PARKING_FLOW = 'PARKING'
export const FUEL_FLOW = 'FUEL'
export const TRAIN_CLASS1_FLOW = 'TRAIN_CLASS1_SERVICE'
export const TRAIN_CLASS2_FLOW = 'TRAIN_CLASS2_SERVICE'
export const MOBILITY_ASSISTANT_FLOW = 'MOBILITY_ASSISTANT'
export const ACCOUNT_SETTINGS_FLOW = 'ACCOUNT_SETTINGS'
export const TIMETABLE_FLOW = 'TIMETABLE'

export const DATE_FORMAT = 'DD-MM-YYYY'
// export const DATE_FORMAT = 'YYYY-MM-DD'
export const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm'
// export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm'
export const DATE_TIME_FORMAT_WITH_SEC = 'YYYY-MM-DD HH:mm:ss'
export const DATE_TIME_ISO_FORMAT = 'YYYY-MM-DDTHH:mm:ss'
export const TIME_FORMAT = 'HH:mm'

export const DEPARTURE = 'departure'
export const ARRIVAL = 'arrival'

export const HOME_TO_WORK = 'HOME_TO_WORK'
export const WORK_TO_HOME = 'WORK_TO_HOME'
export const RETURN_TRIP = 'RETURN_TRIP'

export const BPARKING_RESOURCE = 'BParking.RESOURCE.SERVICE_RESOURCE.BPARKING_PARKEREN'
export const AOD_RESOURCE = 'VAB.RESOURCE.SERVICE_RESOURCE.AOD'
export const CONVENIENT_INVOICING_RESOURCE = 'OlympusMobility.RESOURCE.SERVICE_RESOURCE.CONVENIENT_INVOICING'

export const MOBILITY_MODE_ICONS = {
  CAR: 'car',
  BIKE: 'bike',
  TRAIN: 'train',
  PARKING: 'parking',
  FUEL: 'fuel',
  BUS_TRAM_METRO: 'tram'
}

export const CACHE_TIMEOUT = 2 * 60

export const SESSION_TIMEOUT = 2 * 60

export const GEOLOCATION_DEFAULT = [4.610271, 50.717996] // [3.7204682020631274, 51.05218117752551]

export const MAP_ZOOM = 15
export const MAP_ZOOM_MIN = 7
export const POI_RANGE = 250 // 0.3

export const POSTMESSAGE_API_VERSION = 1.0

export const RESOURCE_404 = 'RESOURCE_404'

export const POST_MESSAGE = {
  PAGE_LOADED: 'pageLoaded',
  PAGE_READY: 'pageReady',
  NEXT_STEP: 'nextStep',
  SET_GEOFENCE: 'setGeofence',
  GET_LOCATION: 'getLocation',
  REFRESH_TICKETS: 'refreshTickets',
  GET_PICTURE: 'getPicture',
  REFRESH_WALLET: 'refreshWallet',
  SCAN_BARCODE: 'scanBarcode',
  START_PAYMENT: 'startPayment',
  START_PAYMENT_TOKEN: 'startPaymentToken',
  OPEN_URL: 'openUrl',
  SEND_EMAIL: 'sendEmail',
  MAKE_PHONE_CALL: 'makePhoneCall',
  OPEN_URL_EMBEDDED: 'openUrlEmbedded',
  CLOSE: 'close',
  ENABLE_DEVICE_SETTINGS: 'enableDeviceSettings',
  GET_DEVICE_SETTINGS_STATUS: 'getDeviceSettingsStatus',
  SET_FUTURE_NOTIFICATIONS: 'setFutureNotifications',
  MANAGE_FUTURE_NOTIFICATIONS: 'manageFutureNotifications',
  NAVIGATE_TO: 'navigateTo',
  KEEP_ALIVE: 'keepAlive',
  CONFIRM_BIOMETRICS: 'confirmBiometrics',
  GET_ACCOUNT: 'getAccount',
  PROCESS_TICKETS: 'processTickets'
}

export const BREAKDOWN_CODE = {
  TIRE_FLAT_PRESENT: 'LBRA',
  TIRE_FLAT_PARKED_NOT_PRESENT: 'LBZZS',
  TIRE_FLAT_DRIVING_NOT_PRESENT: 'LBMZS',
  TIRE_MULTIPLE: 'LBM',
  START_ON_DEPARTURE: 'MKO',
  START_STALLED: 'STIL',
  ACCIDENT: 'ONG',
  ACCIDENT_MINOR: 'ONG_MINOR',
  ACCIDENT_HEAVY: 'ONG_HEAVY',
  FUEL: 'VGT',
  OTHER_RED_LIGHT_ON: 'MOPR',
  OTHER_YELLOW_ORANGE_LIGHT: 'MGV',
  OTHER_OTHER: 'AWTF'
}

export const RESELLER_B2B_USER_ROLE = 'reseller_b2b_user'
