const lazyLoad = (component) => {
  return () => import(/* webpackChunkName: "nmbs-season-ticket" */ '../../components/nmbsSeason/' + component)
}

const BASE_PATH = '/sales/train-season-ticket/:resourceOspId'
export default [
  {
    path: BASE_PATH,
    name: 'nmbs_season',
    component: lazyLoad('NMBSSeasonPrequotePage.vue')
  },
  {
    path: BASE_PATH,
    name: 'nmbs_season_station',
    component: lazyLoad('NMBSSeasonPage.vue')
  },
  {
    path: `${BASE_PATH}/quote`,
    name: 'nmbs_season_quote',
    component: lazyLoad('NMBSSeasonQuotesPage.vue')
  },
  {
    path: `${BASE_PATH}/confirmation`,
    name: 'nmbs_season_confirmation',
    component: lazyLoad('NMBSSeasonConfirmationPage.vue')
  },
  {
    path: `${BASE_PATH}/error`,
    name: 'nmbs_season_error',
    component: lazyLoad('NMBSSeasonErrorPage.vue')
  }
]
