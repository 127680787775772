/**
 * @module Tickets API
 */
import { Api, appEndPoint } from './api'

export const getTickets = (ticketType, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/ticketbooth/getAll/${ticketType}`)
}

export const getAllTickets = ({ ticketOspIds, ticketType }, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/ticketbooth/ticket/getAll`, { ticketOspIds, ticketType })
}

export const getTicketDetails = (ospId, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/ticketbooth/ticket/getOne`, { ospId })
}

export const getTicketAction = (action, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/ticketbooth/ticket/action`, action)
}

export const getBudgetTransferAllowed = (ticketOspId, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/ticketbooth/ticket/budgetTransferAllowed`, { ticketOspId })
}

export const budgetTransfer = ({ ticketOspId, destinationBudgetOspId }, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/ticketbooth/ticket/budgetTransfer`, { ticketOspId, destinationBudgetOspId })
}
