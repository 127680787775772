<template>
  <div class="c-tabs" v-if="items.length < 4">
    <a
      href="#"
      class="c-tab"
      v-on:click="$emit('select-tab', index)"
      :class="{ 'c-tab--active': item.isSelected }"
      v-for="(item, index) in items"
      :key="item.label"
    >
      <p v-html="item.label"></p>
      <p v-if="item.subLabel" v-html="item.subLabel"></p>
    </a>
  </div>
  <div class="c-tabs c-tabs-select" v-else>
    <div class="c-tab c-tab--active"  @click="toggleTabs()">
      <span v-html="selected.label" />
      <o-icon
        icon="chevron-down"
      />
    </div>
    <ul class="dropdown-menu" v-if="showMenu">
      <li v-for="(item, index) in items" :key="index+item.label">
        <a href="javascript:void(0)" @click="select(index)" v-html="item.label" />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'OTabs',
  data () {
    return {
      showMenu: false
    }
  },
  props: {
    // items:Array[3]
    //    0:Object
    //        isSelected:true
    //        label:"Large families (code 40)"
    //    1:Object
    //        isSelected:false
    //        label:"Rail Pass ticket"
    //    2:Object
    //        isSelected:false
    //        label:"Railease pass ticket"
    items: {
      type: Array,
      required: true
    }
  },
  computed: {
    selected () {
      return this.items.find(v => v.isSelected)
    }
  },
  methods: {
    toggleTabs () {
      this.showMenu = !this.showMenu
    },
    select (index) {
      this.showMenu = false
      this.$emit('select-tab', index)
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
@import '~@/assets/styles/variables.scss';
.c-tabs {
  background: #fff;
  display: flex;
  flex: none;
}

.c-tab {
  box-shadow: inset 0 -0.1rem 0 rgba(#000, 0.1);
  color: $gray-500;
  flex: 1;
  font-weight: 600;
  padding: 1.6rem;
  text-align: center;
}

a.c-tab {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.c-tab--active {
  box-shadow: inset 0 -0.2rem $primary-color;
  box-shadow: inset 0 -0.2rem  var(--primary-color-overlay);;
  color: $text-color;
  color: var(--text-color);
}
  .c-tabs-select {
    position: relative;
    .c-tab--active {
      padding-right: 3.2rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;

      .c-icon {
        height: auto;
        width: 29px;
        content: '';
        display: inline-block;
        position: absolute;
        margin-left: 2px;
        vertical-align: middle;
        float: right;
        // background: url();
        // height: 7px;
        background-repeat: no-repeat;
        background-position: center;
        right: 1rem;
        top: 1.1rem;
      }
    }

    .dropdown-menu {
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 1000;
      float: left;
      min-width: 160px;
      padding: 0;
      list-style: none;
      font-size: 14px;
      text-align: left;
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
      background-clip: padding-box;
      width: 100%;
      // max-height: 220px;
      overflow-x: hidden;
      >li {
        list-style: none;
        overflow: hidden;
        width: 100%;
        position: relative;
        margin: 0;
        > a {
          padding: 10px 30px;
          display: block;
          clear: both;
          font-weight: normal;
          line-height: 1.6;
          color: #333333;
          white-space: nowrap;
          text-decoration: none;
          &:hover {
            background-color: rgba(0,0,0,0.1);
          }
        }
      }
    }
  }
</style>
