/**
 * Created by Miran on 13.11.2018.
 */
import { store } from '../store'

/**
 * Get base http headers
 * @returns {{}}
 */
export const authHeader = function () {
  const headers = {}
  if (store.state.user.token) {
    headers.Authorization = 'Bearer ' + store.state.user.token
  }
  if (store.state.app.appVersion) {
    headers['X-APP-BUILDNUMBER'] = store.state.app.appVersion
  }
  return headers
}
