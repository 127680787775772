<template>
  <a
    class="c-floating-button"
    href="#"
    @click="handleClick"
  >
    <o-icon :icon="icon"/>
  </a>
</template>

<script>
export default {
  name: 'OFloatingButton',
  props: {
    icon: {
      type: String,
      default: 'plus'
    }
  },
  methods: {
    handleClick (e) {
      e.preventDefault()
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
@import '~@/assets/styles/variables.scss';
.c-floating-button {
  background: $primary-color;
  border-radius: 2.4rem;
  box-shadow: $box-shadow-list;
  color: #fff !important;
  display: block;
  padding: 1.2rem;
  position: absolute;
  right: 1.6rem;
  width: 4.8rem;
  z-index: 5;

  .c-icon {
    display: block;
  }
}
</style>
