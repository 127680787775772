import Vue from 'vue'
import * as api from '../../api/'
import { trans } from '../../helpers'
import _ from 'lodash'

export const budget = {
  namespaced: true,
  state: {
    types: [],
    budget: null,
    details: null,
    reasons: [],
    timestamp: null,
    availableBudgets: {},
    defaultBudgets: [],
    resourceSelectedBudget: {}
  },
  getters: {
    budgetTypes: state => {
      const types = []
      state.types.forEach(b => {
        types.push({
          id: b.budgetType,
          name: trans(b.budgetTypeName),
          link: '/budget/' + btoa(b.budgetType)
        })
      })
      return types
    },
    budgetReasons: state => {
      const reasons = {
        defaultValue: state.reasons.defaultUsageReason,
        items: {}
      }
      if (state.reasons.usageReasons !== undefined) {
        state.reasons.usageReasons.forEach(b => {
          reasons.items[b] = b
        })
      }
      return reasons
    },
    defaultBudgets: state => state.defaultBudgets,
    getDefaultBudget: state => resourceOspId => state.defaultBudgets.find(({ serviceResourceOspId }) => serviceResourceOspId === resourceOspId),
    availableBudgets: state => resourceOspId => _.get(state.availableBudgets, resourceOspId, []),
    resourceSelectedBudget: state => resourceOspId => _.get(state.resourceSelectedBudget, resourceOspId, null)
  },
  actions: {
    getBudget ({ commit }, data) {
      return api.getBudget(data, response => {
        commit('setBudget', response)
      })
    },
    getBudgetTypes ({ commit }) {
      return api.getBudgetTypes(response => {
        commit('setBudgetTypes', response)
      })
    },
    getBudgetReasons ({ commit }, data) {
      return api.getBudgetReasons(data, response => {
        commit('setBudgetReasons', response)
      })
    },
    getBudgetDetails ({ commit }, data) {
      return api.getBudgetDetails(data, response => {
        commit('setBudgetDetails', response)
      })
    },
    updateBudgetDetails ({ commit }, data) {
      return api.updateBudgetDetails(data).then(response => {
        if (response !== false) {
          commit('updateBudgetDetails', data)
        }
      })
    },
    getAvailableBudgets ({ commit }, resourceOspId) {
      const responseHandler = response => {
        console.log('responseHandler', responseHandler)
        if (response !== false) {
          commit('setAvailableBudgets', { resourceOspId, response })
        }
      }
      if (`${process.env.VUE_APP_NEW_BUDGET_SELECTION}` === '1') {
        return api.getBudgetSelectionDetails(resourceOspId, ({ budgets }) => responseHandler(budgets))
      }
      return api.getAvailableBudgets(resourceOspId, responseHandler)
    },
    getAvailableBudgetsCb ({ commit }, { resourceOspId, cb }) {
      const responseHandler = response => {
        console.log('responseHandler', responseHandler)
        if (response !== false) {
          commit('setAvailableBudgets', { resourceOspId, response })
        }
        cb(response)
      }
      if (`${process.env.VUE_APP_NEW_BUDGET_SELECTION}` === '1') {
        return api.getBudgetSelectionDetails(resourceOspId, ({ budgets }) => responseHandler(budgets))
      }
      return api.getAvailableBudgets(resourceOspId, responseHandler)
    },
    getDefaultBudgets ({ commit }) {
      return api.getDefaultBudgets(response => {
        if (response !== false) {
          commit('setDefaultBudgets', response)
        }
      })
    }
  },
  mutations: {
    setBudget (state, budget) {
      state.budget = budget
    },
    setBudgetTypes (state, types) {
      state.types = types
    },
    setBudgetReasons (state, reasons) {
      state.reasons = reasons
    },
    setBudgetDetails (state, details) {
      state.details = details
    },
    updateBudgetDetails (state, data) {
      if (state.details && state.details.events.length) {
        const index = state.details.events.findIndex(e => e.eventOspId === data.id)
        if (index !== -1) {
          state.details.events[index].remark = data.remark
          state.details.events[index].reason = data.reason
        }
      }
    },
    setTimestamp (state, timestamp) {
      state.timestamp = timestamp
    },
    setAvailableBudgets (state, { resourceOspId, response }) {
      Vue.set(state.availableBudgets, resourceOspId, response)
    },
    setDefaultBudgets (state, { defaultBudgets }) {
      state.defaultBudgets = defaultBudgets
    },
    setResourceBudget (state, { resourceOspId, budgetOspId }) {
      Vue.set(state.resourceSelectedBudget, resourceOspId, budgetOspId)
    }
  }
}
