const lazyLoad = (component) => {
  return () => import(/* webpackChunkName: "aod" */ '../../components/aod/' + component)
}

const BASE_PATH = '/assistance-on-demand'

export default [
  {
    path: BASE_PATH,
    name: 'aod_initial_checks',
    component: lazyLoad('InitialChecksPage')
  },
  {
    path: `${BASE_PATH}/contact-vab`,
    name: 'aod_contact_vab',
    component: lazyLoad('ContactVabPage')
  },
  {
    path: `${BASE_PATH}/contact-vab-not-belgium`,
    name: 'aod_contact_vab_not_belgium',
    component: lazyLoad('ContactVabNotBelgiumPage')
  },
  {
    path: `${BASE_PATH}/contact-police`,
    name: 'aod_contact_police',
    component: lazyLoad('ContactPolicePage')
  },
  {
    path: `${BASE_PATH}/license-plate`,
    name: 'aod_license_plate',
    component: lazyLoad('LicensePlatePage')
  },
  {
    path: `${BASE_PATH}/prequote/:licensePlate`,
    name: 'aod_prequote',
    component: lazyLoad('PrequotePage')
  },
  {
    path: `${BASE_PATH}/vin-info`,
    name: 'aod_vin_info',
    component: lazyLoad('VinInfoPage')
  },
  {
    path: `${BASE_PATH}/add-vin`,
    name: 'aod_add_vin',
    component: lazyLoad('AddVinPage')
  },
  {
    path: `${BASE_PATH}/confirm-vehicle`,
    name: 'aod_confirm_vehicle',
    component: lazyLoad('ConfirmVehiclePage')
  },
  {
    path: `${BASE_PATH}/vehicle-not-found`,
    name: 'aod_vehicle_not_found',
    component: lazyLoad('VehicleNotFoundPage')
  },
  {
    path: `${BASE_PATH}/car-problem`,
    name: 'aod_car_problem',
    component: lazyLoad('CarProblemPage')
  },
  {
    path: `${BASE_PATH}/car-problem/:type`,
    name: 'aod_car_problem_type',
    component: lazyLoad('CarProblemTypePage'),
    props: true
  },
  {
    path: `${BASE_PATH}/car-problem/other/details`,
    name: 'aod_car_problem_other',
    component: lazyLoad('CarProblemOtherPage'),
    props: true
  },
  {
    path: `${BASE_PATH}/location`,
    name: 'aod_location',
    component: lazyLoad('LocationPage')
  },
  {
    path: `${BASE_PATH}/location/search`,
    name: 'aod_location_search',
    component: lazyLoad('LocationSearchPage')
  },
  // {
  //   path: `${BASE_PATH}/location/address`,
  //   name: 'aod_location_address',
  //   component: lazyLoad('LocationAddressPage')
  // },
  {
    path: `${BASE_PATH}/location/confirmation`,
    name: 'aod_location_confirmation',
    component: lazyLoad('LocationConfirmationPage')
  },
  {
    path: `${BASE_PATH}/contact`,
    name: 'aod_contact',
    component: lazyLoad('ContactForm')
  },
  {
    path: `${BASE_PATH}/contact/new/form`,
    name: 'aod_contact_new_form',
    component: lazyLoad('ContactNewFormPage')
  },
  {
    path: `${BASE_PATH}/terms-and-conditions`,
    name: 'aod_terms_conditions',
    component: lazyLoad('TermsConditionsPage')
  },
  {
    path: `${BASE_PATH}/contact/old/form`,
    name: 'aod_contact_old_form',
    component: lazyLoad('ContactOldFormPage')
  },
  {
    path: `${BASE_PATH}/contact/new/form/edit`,
    name: 'aod_contact_new_form_edit',
    component: lazyLoad('ContactNewFormEditPage')
  },
  {
    path: `${BASE_PATH}/contact/old/form/edit`,
    name: 'aod_contact_old_form_edit',
    component: lazyLoad('ContactOldFormEditPage')
  },
  {
    path: `${BASE_PATH}/quote`,
    name: 'aod_quotes',
    component: lazyLoad('QuotePage')
  },
  {
    path: `${BASE_PATH}/request-status`,
    name: 'aod_request_status',
    component: lazyLoad('RequestStatusPage')
  },
  {
    path: `${BASE_PATH}/request-closed`,
    name: 'aod_request_closed',
    component: lazyLoad('RequestClosedPage')
  },
  {
    path: `${BASE_PATH}/cancel-request`,
    name: 'aod_cancel_request',
    component: lazyLoad('CancelRequestPage')
  },
  {
    path: `${BASE_PATH}/cancel-confirmation`,
    name: 'aod_cancel_confirmation',
    component: lazyLoad('CancelConfirmationPage')
  },
  {
    path: `${BASE_PATH}/cancel-failed`,
    name: 'aod_cancel_failed',
    component: lazyLoad('CancelFailedPage')
  }
]
