const lazyLoad = (component) => {
  if (process.env.NODE_ENV && process.env.NODE_ENV !== 'production') {
    return require(`../../components/payment/${component}`).default
  } else {
    // eslint-disable-next-line
    return () => import(/* webpackChunkName: "payment" */ `../../components/payment/${component}`)
  }
}

export default [
  {
    path: '/payment',
    name: 'payment',
    component: lazyLoad('Payment')
  },
  {
    path: '/payment/session',
    name: 'payment_session',
    component: lazyLoad('PaymentSessionPage')
  },
  {
    path: '/payment/session/confirmation',
    name: 'payment_confirmation',
    component: lazyLoad('PaymentConfirmationPage')
  },
  {
    path: '/payment/finalise',
    name: 'payment_finalise',
    component: lazyLoad('PaymentFinalisePage')
  }
]
