/**
 * Fuel
 */
// import FuelPage from '../../components/fuel/FuelPage'
// import FuelAccountCreatePage from '../../components/fuel/FuelAccountCreatePage'
// import FuelAccountCreateConfirmationPage from '../../components/fuel/FuelAccountCreateConfirmationPage'
// import FuelAccountAddPage from '../../components/fuel/FuelAccountAddPage'
// import FuelAccountAddConfirmationPage from '../../components/fuel/FuelAccountAddConfirmationPage'
// import FuelStationPumps from '../../components/fuel/FuelStationPumps'
// import FuelStationProducts from '../../components/fuel/FuelStationProducts'
// import FuelErrorPage from '../../components/fuel/FuelErrorPage'
// import FuelInfoPage from '../../components/fuel/FuelInfoPage'
// import FuelConfirmationPage from '../../components/fuel/FuelConfirmationPage'

// const lazyLoad = (component) => () => import(/* webpackChunkName: "fuel" */ /* webpackPrefetch: true */ '../../components/fuel/' + component)
const lazyLoad = (component) => {
  if (process.env.NODE_ENV && process.env.NODE_ENV !== 'production') {
    return require('../../components/fuel/' + component).default
  } else {
    return () => import(/* webpackChunkName: "fuel" */ '../../components/fuel/' + component)
  }
}

const fuelRoutes = [
  {
    path: '/sales/fuel/:resourceOspId',
    name: 'sales_fuel',
    component: lazyLoad('FuelPage')
    // component: FuelPage
  },
  {
    path: '/sales/fuel/:resourceOspId/info',
    name: 'fuel_info_page',
    component: lazyLoad('FuelInfoPage')
    // component: FuelInfoPage
  },
  {
    path: '/sales/fuel/:resourceOspId/confirmation',
    name: 'sales_fuel_confirmation',
    component: lazyLoad('FuelConfirmationPage')
    // component: FuelConfirmationPage
  },
  {
    path: '/sales/fuel/:resourceOspId/account/create',
    name: 'fuel_account_create',
    component: lazyLoad('FuelAccountCreatePage')
    // component: FuelAccountCreatePage
  },
  {
    path: '/sales/fuel/:resourceOspId/account/create/confirmation',
    name: 'fuel_account_create_confirmation',
    component: lazyLoad('FuelAccountCreateConfirmationPage')
    // component: FuelAccountCreateConfirmationPage
  },
  {
    path: '/sales/fuel/:resourceOspId/account/add',
    name: 'fuel_account_add',
    component: lazyLoad('FuelAccountAddPage')
    // component: FuelAccountAddPage
  },
  {
    path: '/sales/fuel/:resourceOspId/account/add/confirmation',
    name: 'fuel_account_add_confirmation',
    component: lazyLoad('FuelAccountAddConfirmationPage')
    // component: FuelAccountAddConfirmationPage
  },
  {
    path: '/sales/fuel/:resourceOspId/stations/:ospId/pumps',
    name: 'fuel_station_pumps',
    component: lazyLoad('FuelStationPumps')
    // component: FuelStationPumps
  },
  {
    path: '/sales/fuel/:resourceOspId/stations/:ospId/pumps/:pumpNumber/products',
    name: 'fuel_station_pump_products',
    component: lazyLoad('FuelStationProducts')
    // component: FuelStationProducts
  },
  {
    path: '/sales/fuel/error',
    name: 'sales_fuel_error',
    component: lazyLoad('FuelErrorPage')
    // component: FuelErrorPage
  }
]

export default fuelRoutes
