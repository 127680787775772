<template>
  <div class="c-embed">
    <iframe ref="iframe" :src="url" :srcdoc="srcDoc" @load="loaded" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import postMessage from '../../Classes/postMessageService/postMessage'
import { store } from '@/store'

// @group Base Components
export default {
  name: 'OEmbed',
  props: {
    url: {
      type: String,
      default: undefined
    },
    srcDoc: {
      type: String,
      default: undefined
    }
  },
  computed: {
    ...mapGetters({
      isNative: 'app/isNative'
    })
  },
  methods: {
    loaded () {
      const linkClicked = (link) => {
        return e => {
          e.stopPropagation()
          e.preventDefault()
          const url = link.href
          const openUrl = this.isNative
            ? postMessage.openUrl.bind(postMessage)
            : postMessage.openUrlEmbedded.bind(postMessage)

          if (url.indexOf('tel:') === 0 && !this.isNative) {
            postMessage.makePhoneCall(url.split('tel:')[1]) || window.open(url, '_system')
          } else if (url.indexOf('mailto:') === 0 && !this.isNative) {
            postMessage.sendEmail(url.split('mailto:')[1]) || window.open(url, '_system')
          } else if (url.indexOf('http') === 0) {
            if (!openUrl(url)) {
              window.open(encodeURI(url), '_system')
            }
          } else {
            if (!postMessage.openUrl(url)) {
              window.open(encodeURI(url), '_system')
            }
          }
        }
      }
      const userAgent = navigator.userAgent.toLowerCase() // detect the user agent
      const type = userAgent.match(/(iphone|ipod|ipad)/) && ('ontouchstart' in window) ? 'touchstart' : 'click' // check ios devices
      this.$refs.iframe.contentWindow.document.body.querySelectorAll('a[href^="http"], a[href^="mailto:"], a[href^="tel:"]').forEach(element => {
        element.removeEventListener(type, linkClicked(element))
        element.addEventListener(type, linkClicked(element))
      })
    }
  }
}
</script>

<style lang="scss">
.c-embed {
  flex: 1 1 0;
  position: relative;

  iframe {
    border: 0;
    height: 100%;
    left: 0;
    margin: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
</style>
