// const data = {
//   'ResourceOspId': 'NmbsSncb.RESOURCE.SERVICE_RESOURCE.TRAIN_CLASS_1_SERVICE',
//   'OspId': 'NmbsSncb',
//   'FromId': '108811304',
//   'FromLabel': 'BRUSSEL-LUXEMBURG',
//   'ToId': '108821857',
//   'ToLabel': 'BOOISCHOT',
//   'Retour': false,
//   'Class': 0,
//   'Remark': '',
//   'Reason': null
// }

import Favorite from './Favorite'

class NmbsFavorite extends Favorite {
  static createForMobilityMode (mobilityMode, travelData) {
    const data = {
      ...this.getDefaultDataForMobilityMode(mobilityMode),
      ResourceProviderId: mobilityMode.resourceOspId,
      FromId: travelData.originCode,
      FromLabel: travelData.originName,
      ToId: travelData.destinationCode,
      ToLabel: travelData.destinationName,
      Class: travelData.classOfService === 'FIRST_CLASS' ? 0 : 1,
      Retour: travelData.return === 'yes'
    }
    return new this(data)
  }

  getForm () {
    return {
      ...super.getForm(),
      originCode: this.FromId,
      destinationCode: this.ToId,
      classOfService: this.Class === 0 ? 'FIRST_CLASS' : 'SECOND_CLASS',
      returnTrip: this.Retour
    }
  }

  get keysForComparison () {
    return ['ResourceProviderId', 'FromId', 'ToId', 'Class', 'Retour']
  }
}

export default NmbsFavorite
