<template>
  <div id="device-preview" class="c-device" :class="isTablet ? 'c-tablet' : ''">
    <slot/>
  </div>
</template>

<script type="text/ecmascript-6">
import { mapGetters } from 'vuex'

export default {
  name: 'ODevicePreview',
  computed: {
    ...mapGetters({
      isMobile: 'app/isMobile',
      isTablet: 'app/isTablet'
    })
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
@import '~@/assets/styles/variables.scss';
//.c-device {
//  box-shadow: 0 0 0 0.1rem rgba($blue-dark, 0.1), 0 4rem 8rem rgba($blue-dark, 0.25);
//  height: 64.8rem;
//  margin: auto;
//  overflow: hidden;
//  position: relative;
//  width: 41.2rem;
//}

//.c-browser {
//  height: 100%;
//  margin: auto;
//  overflow: hidden;
//  position: relative;
//  width: 100%;
//}
//
//@media (max-width: 36em) {
.c-device {
  box-shadow: none;
  height: 100%;
  margin: 0;
  width: 100%;
}

//}
</style>
