/**
 * @module Information Wizard API
 */
import { Api, appEndPoint } from './api'

export const getNotice = (serviceResourceOspId, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/notice/get`, { name: serviceResourceOspId })
}

export const retrieve = ({ wizardIdentifier, pageNumber = 1, version = 1 }, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/wizard/retrievePage`, { pageNumber, wizardIdentifier, version })
}

export const disableNotice = ({ serviceResourceOspId, version = 1 }, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/notice/disable`, { name: serviceResourceOspId, version })
}
