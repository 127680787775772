/**
 * Created by Miran on 26.11.2018.
 */
// eslint-disable-next-line
import {
  POI_RANGE,
  appLog,
  asyncImageBase64,
  poiFilterV1ToV2
} from '../../helpers'
import * as api from '../../api/'
import _, { assign, filter } from 'lodash'
import { store } from '../index.js'
import Vue from 'vue'

export const poi = {
  namespaced: true,
  state: {
    filters: {
      mobilityMode: null,
      pagination: {
        page: 0,
        size: 100
      },
      range: POI_RANGE,
      source: {
        latitude: 50.717996,
        longitude: 4.610271
      },
      poiOwner: {
        ospId: ''
      }
    },
    zones: [],
    elements: [],
    modeElements: {}
  },
  getters: {
    elements: state => _.get(state.elements, 'content', []),
    modeElements: state => {
      return _.groupBy(state.elements.content, v => v.mobilityMode)
    },
    getFilters: () => {
      const filters = store.state.poi.filters
      try {
        filters.source.latitude = (store.state.user && store.state.user.geolocation && store.state.user.geolocation.latitude) ? store.state.user.geolocation.latitude : 0
        filters.source.longitude = (store.state.user && store.state.user.geolocation && store.state.user.geolocation.longitude) ? store.state.user.geolocation.longitude : 0
      } catch (e) {
        appLog(e)
      }

      filters.range = POI_RANGE
      return filters
    }
  },
  actions: {
    getElements ({ dispatch, commit, state }) {
      const payload = poiFilterV1ToV2(state.filters)
      return api.getPoiV2(payload, response => {
        if (response && response.pois && response.pois.length) {
          dispatch('loadIcons', { content: response.pois }).then(() => {
            commit('setElements', { content: response.pois })
          })
        }
        // commit('setElements', response)
      })
    },
    getZones ({ commit, state }, mrpOspId) {
      return api.getZone({
        mrpOspId,
        range: state.filters.range * 1000,
        source: state.filters.source
      }, response => {
        commit('setZones', response)
      })
    },
    getModePOI ({ dispatch, commit, state }, mode) {
      const filters = _.cloneDeep(state.filters)
      if (mode) {
        filters.mobilityMode = mode
      }
      const payload = poiFilterV1ToV2(filters)
      return api.getPoiV2(payload, response => {
        if (response && response.pois && response.pois.length) {
          dispatch('loadIcons', { content: response.pois }).then(() => {
            commit('setElements', { content: response.pois })
          })
        }
        // commit('setModeElements', {mode: mode, elements: response})
      })
    },
    getOwnPOI ({ dispatch, commit, state }, { mode, poiOwner }) {
      const filters = _.cloneDeep(state.filters)
      if (poiOwner) {
        filters.poiOwner.ospId = poiOwner
      }
      if (mode) {
        filters.mobilityMode = mode
      }

      const payload = poiFilterV1ToV2(filters)
      return api.getPoiV2(payload, response => {
        if (response && response.pois && response.pois.length) {
          dispatch('loadIcons', { content: response.pois }).then(() => {
            commit('setElements', { content: response.pois })
          })
        }
        commit('setElements', { content: response.pois })
      })
      // redirect to getPop2

      // return api.getPoi(filters, response => {
      //   if (response && response.pois && response.pois.length) {
      //     dispatch('loadIcons', response).then(() => {
      //       commit('setElements', response)
      //     })
      //   }
      //   commit('setElements', response)
      // })
    },
    getOwnPOIV2 ({ dispatch, commit, state, rootGetters, rootState }, payload) {
      const defaultFilter = {
        address: null,
        center: null,
        radius: null,
        userLocation: rootState.user.geolocationAct && rootState.user.geolocationLoaded
          ? {
            latitude: rootState.user.geolocationAct.latitude,
            longitude: rootState.user.geolocationAct.longitude
          }
          : null,
        mobilityModes: [],
        mrpOspIds: []
      }
      return api.getPoiV2(assign(defaultFilter, payload), async response => {
        if (payload.cb) {
          await payload.cb(response)
        }
        if (response && response.pois && response.pois.length) {
          dispatch('loadIcons', { content: response.pois }).then(() => {
            commit('setElements', { content: response.pois })
          })
        }
        commit('setElements', [])
      })
    },
    loadIcons (store, { content }) {
      const links = _.uniqWith(content, (n, o) => {
        return n.mrpOspId.ospId === o.mrpOspId.ospId && n.mobilityMode === o.mobilityMode
      })
      const promises = links.map(v => asyncImageBase64(api.getPoiImageUrl(v)))
      return Promise.all(promises)
    }
  },
  mutations: {
    setZones (state, zones) {
      state.zones = zones
    },
    setElements (state, elements) {
      state.elements = elements
    },
    setModeElements (state, { mode, elements }) {
      Vue.set(state.modeElements, mode, elements)
    },
    setFilters (state, filters) {
      // let zoom = MAP_ZOOM
      for (const filter in filters) {
        if (filter === 'source') {
          if (!filters.range && !state.filters.range) {
            state.filters.range = POI_RANGE
          }
        }
        Vue.set(state.filters, filter, filters[filter])
      }
      if (!(state.filters.range && state.filters.range >= 0.1)) {
        Vue.set(state.filters, 'range', 0.1)
      }
      // set calculated range to zoom
      // state.filters.range = zoom !== MAP_ZOOM ? zoomedRange(zoom) : POI_RANGE
    },
    clearFilters (state) {
      state.filters = {
        mobilityMode: null,
        pagination: {
          page: 0,
          size: 100
        }
      }
    }
  }
}
