// import CambioPage from '../../components/cambio/CambioPage'
// import CambioBudgetPage from '../../components/cambio/CambioBudgetPage'
// import CambioManagePage from '../../components/cambio/reservation/CambioManagePage'
// import CambioMobib from '../../components/cambio/CambioMobib'
// import CambioMobibCreate from '../../components/cambio/CambioMobibCreate'
// import CambioMobibPicture from '../../components/cambio/CambioMobibPicture'
// import CambioIdentityCardPhoto from '../../components/cambio/CambioIdentityCardPhoto'
// import CambioDriverLicenseForm from '../../components/cambio/CambioDriverLicenseForm'
// import CambioDriverLicenseInvalid from '../../components/cambio/CambioDriverLicenseInvalid'
// import CambioDriverLicensePhoto from '../../components/cambio/CambioDriverLicensePhoto'
// import CambioQuotesPage from '../../components/cambio/CambioQuotesPage'
// import CambioNewReservationPage from '../../components/cambio/reservation/CambioNewReservationPage'
// import CambioReservationAlternatives from '../../components/cambio/reservation/CambioReservationAlternatives'
// import CambioReservationSummary from '../../components/cambio/reservation/CambioReservationSummary'
// import InsuranceProposal from '../../components/cambio/reservation/InsuranceProposal'
// import CambioReservationDetailsPage from '../../components/cambio/reservation/CambioReservationDetailsPage'
// import CambioReservationConfirmation from '../../components/cambio/reservation/CambioReservationConfirmation'
// import UnlockedConfirmation from '../../components/cambio/reservation/UnlockedConfirmation'
// import CambioConfirmationPage from '../../components/cambio/CambioConfirmationPage'
// import UnlockedInstructions from '../../components/cambio/reservation/UnlockedInstructions'
// import LockedConfirmation from '../../components/cambio/reservation/LockedConfirmation'
// import CambioEditActiveReservationPage from '../../components/cambio/reservation/CambioEditActiveReservationPage'
// import CambioEditFutureReservationPage from '../../components/cambio/reservation/CambioEditFutureReservationPage'
// import CambioClassInfo from '../../components/cambio/reservation/CambioClassInfo'
// import CambioCarInfo from '../../components/cambio/reservation/CambioCarInfo'
// import CambioStationPage from '../../components/cambio/reservation/CambioStationPage'

// const lazyLoad = (componentPath) => () => import(/* webpackChunkName: "cambio" */ '../../components/cambio/' + componentPath)
const lazyLoad = (component) => {
  if (process.env.NODE_ENV !== 'production') {
    return require('../../components/cambio/' + component).default
  } else {
    return () => import(/* webpackChunkName: "cambio" */ '../../components/cambio/' + component)
  }
}

export const cambioRoutes = [
  {
    path: '/sales/cambio/:resourceOspId',
    name: 'sales_cambio',
    component: lazyLoad('CambioPage')
  },
  {
    path: '/sales/cambio/:resourceOspId/:ospId',
    name: 'idCardData',
    component: lazyLoad('CambioBudgetPage')
    // component: CambioBudgetPage
  },
  {
    path: '/sales/cambio/:resourceOspId/:ospId/manage',
    name: 'sales_cambio_manage',
    component: lazyLoad('reservation/CambioManagePage')
    // component: CambioManagePage
  },
  {
    path: '/sales/cambio/:resourceOspId/:ospId/mobib-select',
    name: 'sales_cambio_mobib_select',
    component: lazyLoad('reservation/CambioMobibSelectPage')
    // component: CambioMobib
  },
  {
    path: '/sales/cambio/:resourceOspId/:ospId/mobib',
    name: 'sales_cambio_mobib',
    component: lazyLoad('CambioMobib')
    // component: CambioMobib
  },
  {
    path: '/sales/cambio/:resourceOspId/:ospId/mobib/create',
    name: 'sales_cambio_mobib_create',
    component: lazyLoad('CambioMobibCreate')
    // component: CambioMobibCreate
  },
  {
    path: '/sales/cambio/:resourceOspId/:ospId/mobib/picture',
    name: 'sales_cambio_mobib_picture',
    component: lazyLoad('CambioMobibPicture')
    // component: CambioMobibPicture
  },
  {
    path: '/sales/cambio/:resourceOspId/:ospId/id-card-photos',
    name: 'idCardPhotos',
    component: lazyLoad('CambioIdentityCardPhoto')
    // component: CambioIdentityCardPhoto
  },
  {
    path: '/sales/cambio/:resourceOspId/:ospId/driver-license',
    name: 'driversLicenseData',
    component: lazyLoad('CambioDriverLicenseForm')
    // component: CambioDriverLicenseForm
  },
  {
    path: '/sales/cambio/:resourceOspId/:ospId/driver-license-invalid',
    name: 'driverLicenseInvalid',
    component: lazyLoad('CambioDriverLicenseInvalid')
    // component: CambioDriverLicenseInvalid
  },
  {
    path: '/sales/cambio/:resourceOspId/:ospId/driver-license-photos',
    name: 'driversLicensePhotos',
    component: lazyLoad('CambioDriverLicensePhoto')
    // component: CambioDriverLicensePhoto
  },
  {
    path: '/sales/cambio/:resourceOspId/:ospId/quotes',
    name: 'cambio_quotes',
    component: lazyLoad('CambioQuotesPage')
    // component: CambioQuotesPage
  },
  {
    path: '/sales/cambio/:resourceOspId/:ospId/confirmation',
    name: 'cambio_confirmation',
    component: lazyLoad('CambioConfirmationPage')
    // component: CambioConfirmationPage
  },
  {
    path: '/sales/cambio/:resourceOspId/:ospId/reservations/new',
    name: 'cambio_reservation_new',
    component: lazyLoad('reservation/CambioNewReservationPage')
    // component: CambioNewReservationPage
  },
  {
    path: '/sales/cambio/:resourceOspId/:ospId/reservations/alternatives',
    name: 'cambio_reservation_alternatives',
    component: lazyLoad('reservation/CambioReservationAlternatives')
    // component: CambioReservationAlternatives
  },
  {
    path: '/sales/cambio/:resourceOspId/:ospId/station/:stationId',
    name: 'cambio_station',
    component: lazyLoad('reservation/CambioStationPage')
    // component: CambioStationPage
  },
  {
    path: '/sales/cambio/:resourceOspId/:ospId/reservations/new-insurance',
    name: 'cambio_reservation_insurance',
    component: lazyLoad('reservation/InsuranceProposal')
    // component: InsuranceProposal
  },
  {
    path: '/sales/cambio/:resourceOspId/:ospId/reservations/summary',
    name: 'cambio_reservation_summary',
    component: lazyLoad('reservation/CambioReservationSummary')
    // component: CambioReservationSummary
  },
  {
    path: '/sales/cambio/:resourceOspId/:ospId/reservations/confirmation',
    name: 'cambio_reservation_confirmation',
    component: lazyLoad('reservation/CambioReservationConfirmation')
    // component: CambioReservationConfirmation
  },
  {
    path: '/sales/cambio/:resourceOspId/:ospId/reservations/unlocked-confirmation',
    name: 'cambio_unlocked_confirmation',
    component: lazyLoad('reservation/UnlockedConfirmation')
    // component: UnlockedConfirmation
  },
  {
    path: '/sales/cambio/:resourceOspId/:ospId/reservations/locked-confirmation',
    name: 'cambio_locked_confirmation',
    component: lazyLoad('reservation/LockedConfirmation')
    // component: LockedConfirmation
  },
  {
    path: '/sales/cambio/:resourceOspId/:ospId/reservations/unlocked-instructions',
    name: 'cambio_unlocked_instructions',
    component: lazyLoad('reservation/UnlockedInstructions')
    // component: UnlockedInstructions
  },
  {
    path: '/sales/cambio/:resourceOspId/:ospId/reservations/:reservationId',
    name: 'cambio_reservation_details',
    component: lazyLoad('reservation/CambioReservationDetailsPage')
    // component: CambioReservationDetailsPage
  },
  {
    path: '/sales/cambio/:resourceOspId/:ospId/reservations/:reservationId/edit-active',
    name: 'cambio_active_reservation_edit',
    component: lazyLoad('reservation/CambioEditActiveReservationPage')
    // component: CambioEditActiveReservationPage
  },
  {
    path: '/sales/cambio/:resourceOspId/:ospId/reservations/:reservationId/edit-future',
    name: 'cambio_future_reservation_edit',
    component: lazyLoad('reservation/CambioEditFutureReservationPage')
    // component: CambioEditFutureReservationPage
  },
  {
    path: '/sales/car/cambio/:resourceOspId/:ospId/reservations/class-info/:vehicleClassId',
    name: 'cambio_class_info',
    component: lazyLoad('reservation/CambioClassInfo')
    // component: CambioClassInfo
  },
  {
    path: '/sales/car/cambio/:resourceOspId/:ospId/reservations/car-info/:carTypeId',
    name: 'cambio_car_info',
    component: lazyLoad('reservation/CambioCarInfo')
    // component: CambioCarInfo
  }
]
