import * as api from '../../api'
import { CONVENIENT_INVOICING_RESOURCE, RESELLER_B2B_USER_ROLE } from '../../helpers/constants'
import Flow from '../../helpers/Flow'
export const convenientInvoicing = {
  namespaced: true,
  state: {
    convenientInvoicing: {},
    enrollment: false,
    status: null
  },
  getters: {
    config: state => state.convenientInvoicing,
    status: state => state.status,
    enrollment: state => state.enrollment
  },
  actions: {
    getConvenientInvoicing ({ commit }) {
      return api.convenientInvoicing.getConvenientInvoicingContract(res => {
        if (res && !res.error) {
          commit('setConvenientInvoicing', res)
        }
      })
    },

    getConvenientInvoicingStatus ({ commit }) {
      return api.convenientInvoicing.getContractStatus(res => {
        if (res && !res.error) {
          commit('setStatus', res)
        }
      })
    },
    showEnrollment ({ commit }, payload) {
      return commit('setEnrollment', true)
    },
    clearEnrollment ({ commit }) {
      return commit('setEnrollment', false)
    },
    async checkContract ({ commit, rootGetters }) {
      const hasRoleB2BRole = rootGetters['user/hasRole'](RESELLER_B2B_USER_ROLE)
      if (!hasRoleB2BRole || ['DAKT', 'RESELLERB2B', 'RESELLERB2BSETTINGS', 'WIZARD', 'ACCOUNT_SETTINGS'].includes(new Flow().getFlow().toUpperCase())) {
        return true
      }
      const { answer } = await api.convenientInvoicing.hasContract()
      commit('setEnrollment', !answer)
      return true
    }
  },
  mutations: {
    setConvenientInvoicing (state, value) {
      state.convenientInvoicing = value
    },
    setEnrollment (state, payload) {
      state.enrollment = payload
    },
    setStatus (state, payload) {
      state.status = payload
    }
  }
}
