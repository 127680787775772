const lazyLoad = (component) => {
  return () => import(/* webpackChunkName: "km-registration" */ '../../components/kmRegistration/' + component)
}

export default [
  {
    path: '/km-registration',
    name: 'km_registration',
    component: lazyLoad('KmRegistrationPage')
  },
  {
    path: '/km-registration/distances',
    name: 'km_registration_distances',
    component: lazyLoad('KmRegistrationDistancesPage')
  },
  {
    path: '/km-registration/distances/create',
    name: 'km_registration_distances_create',
    component: lazyLoad('KmRegistrationDistancesCreatePage')
  },
  {
    path: '/km-registration/distances/:commuteDistanceId',
    name: 'km_registration_distances_edit',
    component: lazyLoad('KmRegistrationDistancesCreatePage')
  },
  {
    path: '/km-registration/repeat-pattern',
    name: 'km_registration_repeat_pattern',
    component: lazyLoad('KmRegistrationRepeatPatternPage')
  },
  {
    path: '/km-registration/business-trips/:date',
    name: 'km_registration_business_trips',
    component: lazyLoad('KmRegistrationBusinessTripsPage'),
    props: true
  },
  {
    path: '/km-registration/business-trips/:date/create',
    name: 'km_registration_business_trips_create',
    component: lazyLoad('KmRegistrationBusinessTripsCreatePage'),
    props: true
  },
  {
    path: '/km-registration/business-trips/:date/:uuid',
    name: 'km_registration_business_trips_edit',
    component: lazyLoad('KmRegistrationBusinessTripsCreatePage'),
    props: true
  }
]
