<template>
  <o-modal class="time-picker-container" :maskClick="true" @close="$emit('close')">
    <template slot="body">
      <div class="time-picker">
        <swiper :options="swiperOptionTime" class="swiper-container-v" ref="hoursSwiper" @slideChange="selectHours">
          <swiper-slide v-for="(slide, index) in hoursList" :key="index">{{ slide.label }}</swiper-slide>
          <div class="swiper-pagination"  slot="pagination"></div>
        </swiper>

        <span class="divider">:</span>
        <swiper :options="swiperOptionTime" class="swiper-container-v" ref="minutesSwiper" @slideChange="selectMinutes">
          <swiper-slide v-for="(slide, index) in minutesList" :key="index">{{ slide.label }}</swiper-slide>
          <div class="swiper-pagination"  slot="pagination"></div>
        </swiper>
        <!--<o-icon icon='chevron-left'></o-icon>-->
        <!--<o-icon icon='chevron-right'></o-icon>-->
      </div>
    </template>
    <template slot="footer">
      <o-button
        :label="$t('html5.timetable.date_time_picker.apply')"
        :is-primary="false"
        :is-floating="false"
        @click="$emit('apply', selection)"
      />
    </template>
  </o-modal>
</template>
<script>

import moment from 'moment'
import OModal from './OModal'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { TIME_FORMAT } from '../../helpers'

export default {
  name: 'OTimePicker',
  components: {
    OModal,
    Swiper,
    SwiperSlide
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    step: {
      type: [Number, String],
      default: 1
    },
    value: {
      type: String,
      default: moment().format(TIME_FORMAT)
    }
  },
  data () {
    return {
      hours: {
        value: 0,
        label: '00'
      },
      minutes: {
        value: 0,
        label: '00'
      },
      swiperOptionTime: {
        direction: 'vertical',
        slidesPerView: 3,
        loop: true,
        mousewheel: true,
        centeredSlides: true,
        loopAdditionalSlides: 3
      }
    }
  },
  computed: {
    minutesList () {
      let i = 0
      const list = []
      while (i < 60) {
        if (i !== 0 && (i % this.step !== 0)) {
          i++
          continue
        }
        let label = ''
        if (i < 10) {
          label = '0' + i
        } else {
          label = `${i}`
        }
        list.push({
          value: i,
          label
        })
        i++
      }
      return list
    },
    hoursList () {
      let h = 0
      const list = []
      while (h < 24) {
        let label = ''
        if (h < 10) {
          label = '0' + h
        } else {
          label = `${h}`
        }
        list.push({
          value: h,
          label
        })
        h++
      }
      return list
    },
    hoursSwiper () {
      return this.$refs.hoursSwiper.swiperInstance
    },
    minutesSwiper () {
      return this.$refs.minutesSwiper.swiperInstance
    },
    selection () {
      return `${this.hours.label}:${this.minutes.label}`
    }
  },
  methods: {
    selectHours () {
      this.hours = this.hoursList[this.hoursSwiper.realIndex]
    },
    selectMinutes () {
      this.minutes = this.minutesList[this.minutesSwiper.realIndex]
    },
    setHours (h) {
      this.hours.value = parseInt(h)
      this.hoursSwiper.slideTo(this.hoursList.findIndex(v => v.value === this.hours.value) + 6, 0)
    },
    setMinutes (m) {
      this.minutes.value = parseInt(m)
      this.minutesSwiper.slideTo(this.minutesList.findIndex(v => v.value === this.minutes.value), 0)
    }
  },

  mounted () {
    const start = moment(this.value, 'HH:mm')
    const hours = start.format('H')
    this.setHours(hours)
    const minutes = start.format('m')
    this.setMinutes(minutes)
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
@import '~@/assets/styles/variables.scss';
  .time-picker-container {
    position: fixed;
    .time-picker {
      position: relative;
      flex-direction: row;
      display: flex;
      align-items: center;
      justify-content: center;
      .swiper-container{
        height: 10rem!important;
        width: 7rem!important;
        margin: 0;
        pointer-events: unset;
        .swiper-slide {
          text-align: center;
          font-size: 16px;
          font-weight: 400;
        }
        &:before {
          content: '';
          position: absolute;
          width: 100%;
          height: 33%;
          top: 0;
          border-bottom: 1px solid $primary-color;
          border-bottom: 1px solid  var(--primary-color);
          background: linear-gradient(180deg, #fff 10%, rgba(255, 255, 255, 0.2));
          z-index: 100;
        }
        &:after {
          content: '';
          position: absolute;
          width: 100%;
          bottom: 0;
          height: 33%;
          border-top: 1px solid $primary-color;
          border-top: 1px solid  var(--primary-color);
          background: linear-gradient(0deg, #fff 10%, rgba(255, 255, 255, 0.2));
          z-index: 100;
        }
      }
      .divider {
        margin: 1rem;
        color: rgba(0, 0, 0, .1);
        font-size: 20px;
      }
      .vue-scroll-picker {
        max-height: 10rem;
        max-width: 7rem;
        .vue-scroll-picker-list-rotator {
          padding-top: 3.2rem;
          .vue-scroll-picker-item {
            font-size: 18px;
            margin: 1rem 0;
          }
        }
        .vue-scroll-picker-layer {
          .top, .middle, .bottom {
            height: 33%;
          }
          .top, .bottom {
            background: linear-gradient(0deg, #fff 10%, rgba(255, 255, 255, 0.2));
          }
          .middle {
            top: 33%;
            bottom: 33%;
          }
          .top {
            border-bottom: 1px solid $primary-color;
            border-bottom: 1px solid var(--primary-color);
          }
          .bottom {
            border-top: 1px solid $primary-color;
            border-top: 1px solid var(--primary-color);
          }
        }
      }
    }
    .VueCarousel-navigation-button {
      svg {
        height: 30px;
      }
    }

    .modal-container {
      overflow: hidden;
      .c-tabs {
        margin: -20px -30px 0;
        .c-tab {
          padding: 2rem 1.6rem;
        }
      }
    }
    .c-button {
      pointer-events: unset;
    }
  }
</style>
