<template>
  <label class="c-checkbox">
    <input type="checkbox" :checked="checked" @change="onChange">
    <div class="c-checkbox__text">
      <p>
        <slot/>
      </p>
      <div v-if="error" class="c-error">{{ error }}</div>
    </div>
  </label>
</template>

<script>
export default {
  name: 'OCheckbox',
  props: {
    checked: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: null
    }
  },
  methods: {
    onChange (e) {
      this.$emit('change', e.target.checked)
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.c-checkbox {
  align-items: flex-start;
  display: flex;

  input[type='checkbox'] {
    flex: none;
    height: 2.4rem;
    margin-right: 0.8rem;
    margin-top: 0.1rem;
    width: 2.4rem;
  }

  p {
    font-size: 100%;
    margin-bottom: 0;
  }

  .c-error {
    position: relative;
    left: -32px;
  }
}
</style>
