<template>
  <div id="app">
    <o-device-preview>

      <o-loader v-if="loader"/>
      <!--<o-alert v-if="alertMessage" :message="alertMessage" :type="alertType" />-->

      <o-alert-modal
        v-if="modalError && modalError.message"
        :alert="modalError"
      />
        <router-view :key="appKey"/>

      <o-alert-bottom :message="alertMessage" :type="alertType"/>

      <!-- INTERNAL OVERLAY -->
      <o-notification
        :show="showInternalOverlay"
        :type="'internal'"
        @close="toggleInternalOverlay"
      >
        <div class="c-notification__iframe" v-html="internalHtml"></div>
      </o-notification>

      <confirm-revoke-modal />
    </o-device-preview>
  </div>
</template>

<script type="text/ecmascript-6">
import { setBranding, trans, appLog } from './helpers'
import OAlertModal from './components/utils/OAlertModal'
import ONotification from './components/utils/ONotification'
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
import * as api from './api'

import { has, last } from 'lodash'
import ODevicePreview from './components/utils/ODevicePreview'
import postMessage from './Classes/postMessageService/postMessage'
import ConfirmRevokeModal from './components/streetParking/ConfirmRevokeModal'

let self = null
export default {
  name: 'App',
  components: {
    ODevicePreview,
    OAlertModal,
    ONotification,
    ConfirmRevokeModal
  },
  data () {
    return {
      internalHtml: '',
      initialScreenHeight: 0,
      transitionName: 'slide-left',
      showInternalOverlay: false
    }
  },
  computed: {
    ...mapState({
      loader: state => state.app.loader,
      alertType: state => state.alert.type,
      alertMessage: state => state.alert.message,
      authorizationCode: state => state.app.authorizationCode,
      token: state => state.user.token,
      refreshToken: state => state.user.refreshToken,
      geolocation: state => state.user.geolocation,
      clientId: state => state.app.clientId
    }),
    ...mapGetters({
      bgColor: 'app/bgColor',
      customStyles: 'app/getCustomStyles',
      appKey: 'app/appKey',
      modalError: 'alert/modalError',
      isNative: 'app/isNative'
    })
  },
  watch: {
    bgColor: () => {
      setBranding()
    },
    $route (to, from) {
      if (from.name === 'home' && to.name === 'tickets') {
        this.transitionName = 'slide-up'
      } else if (to.name === 'home' && from.name === 'tickets') {
        this.transitionName = 'slide-down'
      } else if (to.name === 'kbc') {
        this.transitionName = 'slide-right'
      } else if (from.name === 'kbc') {
        this.transitionName = 'slide-left'
      } else if (to.name === 'home') {
        this.transitionName = null
      } else {
        const toDepth = to.path.split('/').length
        const fromDepth = from.path.split('/').length
        this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
      }
    },
    customStyles () {
      if (!this.customStyles) return false
      const styleId = 'generatedStyles'
      let style = document.getElementById(styleId)
      if (!style) {
        style = document.createElement('style')
        style.id = styleId
      }
      style.type = 'text/css'

      document.head.appendChild(style)
      let html = ''
      Object.keys(this.customStyles).map((className, index) => {
        html += `.${className} ${this.customStyles[className]}`
      })
      style.innerHTML = html
      return true
    }
  },
  methods: {
    ...mapActions({
      showModalError: 'alert/showModalError',
      addToReceiver: 'app/addToReceiver',
      authRefresh: 'user/authRefresh'
    }),
    ...mapMutations({
      setKeyboardShown: 'app/setKeyboardShown',
      setIsTablet: 'app/setIsTablet'
    }),
    linkHandler (e) {
      if (e.target.tagName !== 'A' || !e.target.dataset.target) {
        return
      }
      // console.log(e.target)
      if (e.target.dataset.target === 'external') {
        e.preventDefault()
        e.stopPropagation()
        const url = e.target.href
        // if (!sendPostMessage(POST_MESSAGE.OPEN_URL, { url })) {
        if (!this.isNative && url.indexOf('tel:') === 0) {
          postMessage.makePhoneCall(url.split('tel:')[1]) || window.open(url, '_system')
        } else {
          if (!postMessage.openUrl(url)) {
            if (url.indexOf('mailto:') === 0) {
              window.open(url, '_system')
            } else {
              window.open(encodeURI(url), '_system')
            }
          }
        }
      } else if (e.target.dataset.target === 'internal') {
        e.preventDefault()
        e.stopPropagation()
        const url = e.target.href
        this.internalHtml = `<iframe src="${encodeURI(url)}" id="internalAppFrame"></iframe>`
        this.toggleInternalOverlay()
      } else if (e.target.dataset.target === 'notification') {
        e.preventDefault()
        e.stopPropagation()
        const pathname = e.target.pathname
        api.getLabel(pathname).then(response => {
          if (response && has(response, 'defaultValue')) {
            this.showModalError(trans(response))
          }
        })
      } else if (e.target.dataset.target === 'info') {
        e.preventDefault()
        e.stopPropagation()
        let loco = e.target.dataset.loco
        loco = self.$t(loco)
        this.addToReceiver(loco).then(receiver => {
          return this.$router.push({
            name: 'info_page',
            query: {
              receiver
            }
          })
        })
      } else if (e.target.dataset.target === 'router-path') {
        e.preventDefault()
        e.stopPropagation()
        const getRoute = path => {
          let link = this.$router.resolve({
            name: e.target.dataset.href
          })
          if (link) {
            return { name: e.target.dataset.href }
          }
          link = this.$router.resolve({
            path
          })
          if (link) {
            return { path }
          }
          return null
        }
        const path = e.target.pathname
        // console.log(path)
        const route = getRoute(path)
        if (route) {
          return this.$router.push(route)
        }
        return false
      } else if (e.target.dataset.target === 'expand' && e.target.dataset.toggle) {
        e.preventDefault()
        e.stopPropagation()
        const targetSectionId = e.target.dataset.toggle
        const targetSection = document.getElementById(targetSectionId)
        targetSection.classList.remove('collapsed')
        e.target.classList.add('hidden')
        document.querySelectorAll(`[data-toggle="${targetSectionId}"][data-target="collapse"]`)[0].classList.remove('hidden')
      } else if (e.target.dataset.target === 'collapse' && e.target.dataset.toggle) {
        e.preventDefault()
        e.stopPropagation()
        const targetSectionId = e.target.dataset.toggle
        const targetSection = document.getElementById(targetSectionId)
        targetSection.classList.add('collapsed')
        e.target.classList.add('hidden')
        document.querySelectorAll(`[data-toggle="${targetSectionId}"][data-target="expand"]`)[0].classList.remove('hidden')
      } else if (e.target.dataset.target === 'embedded') {
        e.preventDefault()
        e.stopPropagation()
        const url = e.target.href
        if (!postMessage.openUrlEmbedded(url)) {
          window.open(encodeURI(url), '_system')
        }
      } else if (e.target.dataset.target === 'copy') {
        e.preventDefault()
        e.stopPropagation()
        const text = e.target.dataset.copy
        navigator.clipboard.writeText(text)
      }
    },
    toggleInternalOverlay () {
      this.showInternalOverlay = !this.showInternalOverlay
    },
    tokenHandler () {
      if (this.token) {
        try {
          const encoded = this.token.split('.')[1]
          const { exp } = JSON.parse(Buffer.from(encoded, 'base64').toString('utf-8'))
          const timeLeft = exp - Math.floor(Date.now() / 1000)
          appLog(timeLeft, 'timeLeft')
          if (timeLeft < 40) {
            this.authRefresh()
          }
        } catch (e) {
          console.log(e)
          return {}
        }
      }
    },
    kbCheck () {
      let fix = false
      let maxViewHeight = 10000
      const pages = ['cambio-registration', 'cambio-mobib']
      const cViewBlock = document.querySelector('#app .cambio-registration div.c-block-list:first-child')
      if (cViewBlock) {
        maxViewHeight = cViewBlock.offsetHeight + 68
      }
      pages.forEach(p => {
        if (document.getElementsByClassName(p).length) {
          fix = true
        }
      })
      const winHeight = window.innerHeight
      if (fix) {
        this.setKeyboardShown(winHeight < this.initialScreenHeight && maxViewHeight > winHeight)
      }

      document.documentElement.style.setProperty('--container-height', `${winHeight}px`)
    },
    checkIsTablet () {
      const windowHeight = window.innerHeight
      const windowWidth = window.innerWidth
      if (windowWidth > windowHeight && windowWidth >= 1020) {
        this.setIsTablet(true)
      } else {
        this.setIsTablet(false)
      }
    }
  },
  mounted () {
    self = this
    setBranding()
    const userAgent = navigator.userAgent.toLowerCase() // detect the user agent
    const type = userAgent.match(/(iphone|ipod|ipad)/) && ('ontouchstart' in window) ? 'touchstart' : 'click' // check ios devices
    // const type = ('ontouchstart' in window) ? 'touchstart' : 'click'
    if (window.addEventListener) {
      window.removeEventListener(type, this.linkHandler)
      window.addEventListener(type, this.linkHandler, false)
    } else {
      window.attachEvent(type, this.linkHandler)
    }
    // watch mobile keyboard
    this.initialScreenHeight = window.innerHeight

    this.initialScreenHeight && document.documentElement.style.setProperty('--container-height', `${this.initialScreenHeight}px`)
    window.addEventListener('resize', this.kbCheck)
    window.addEventListener('resize', this.checkIsTablet)

    setInterval(this.tokenHandler, 10000)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.kbCheck)
    window.removeEventListener('resize', this.checkIsTablet)
  },
  beforeMount () {
    this.checkIsTablet()
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
@import "./assets/styles/variables";
@import './assets/styles/vue-swipe-actions.scss';
@import './assets/styles/reset.scss';
@import './assets/styles/generic.scss';
@import './assets/styles/modal.scss';
@import './assets/styles/buttons.scss';

:root {
  --primary-color: #{$primary-color};
  --primary-color-light: #{rgba($primary-color, 0.1)};
  --primary-color-overlay: #{rgba($primary-color, 0.98)};
  --text-color: #{$text-color};
  --container-height: 100%;
  font-size: 62.5%;
}

html,
body {
  height: 100%;
  height: var(--container-height);
  /*background-color: #{$primary-color};*/
}

body {
  box-sizing: border-box;
  color: $text-color;
  color: var(--text-color);
  display: flex;
  flex-direction: column;
  font: $font;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  &.keyboard-is-open {
    #device-preview {
      overflow: auto;

      .cambio-registration, .cambio-mobib {
        overflow-y: auto;
        max-height: unset;

        .c-view--bottom-padding {
          display: block;
          position: unset;
          height: auto !important;

          &:after {
            padding: 10px;
          }
        }

        .c-button--floating {
          margin: 0 8px 8px;
          display: block;
          position: unset;
        }
      }
    }
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

#app {
  background-color: $background-color;
  display: flex;
  flex-direction: column;
  height: 100%;
}

@import "assets/styles/tablet";
</style>
