/**
 * Plugin
 */
const lazyLoad = (component) => {
  if (process.env.NODE_ENV && process.env.NODE_ENV !== 'production') {
    return require(`../../components/plugin/${component}`).default
  } else {
    // eslint-disable-next-line
    return () => import(/* webpackChunkName: "plugin" */ `../../components/plugin/${component}`)
  }
}

const pluginRoutes = [
  {
    path: '/sales/plugin/:resourceOspId',
    name: 'plugin_page',
    component: lazyLoad('PluginPage')
    // component: FuelPage
  },
  {
    path: '/sales/plugin/:resourceOspId/iban',
    name: 'plugin_iban_page',
    component: lazyLoad('IbanPage')
    // component: FuelPage
  },
  {
    path: '/sales/plugin/:resourceOspId/activation',
    name: 'plugin_activation_page',
    component: lazyLoad('ServiceActivationPage')
    // component: FuelPage
  },
  {
    path: '/sales/plugin/:resourceOspId/iban-edit',
    name: 'plugin_iban_edit_page',
    component: lazyLoad('EditIbanPage')
    // component: FuelPage
  },
  {
    path: '/sales/plugin/:resourceOspId/default-budget',
    name: 'plugin_default_budget_page',
    component: lazyLoad('DefaultBudgetPage')
    // component: FuelPage
  }
]

export default pluginRoutes
