import Vue from 'vue'
import { get } from 'lodash'
import * as api from '../../api'

const defaultPrequote = () => {
  return {
    accountStatus: 'NOT_ACTIVATED',
    defaultBudgetName: null,
    defaultBudgetOspId: null,
    iban: null,
    ibanCaption: {},
    ibanInfoText: {},
    pluginUserEmail: null
  }
}

export const plugin = {
  namespaced: true,
  state: {
    prequotes: {},
    activation: {}
  },
  getters: {
    prequote: state => resourceOspId => get(state.prequotes, resourceOspId, defaultPrequote()),
    activation: state => resourceOspId => get(state.activation, resourceOspId, {})
  },
  actions: {
    getPrequote ({ commit }, resourceOspId) {
      return api.plugin.prequote(resourceOspId, response => {
        commit('SET_PREQUOTE', { resourceOspId, response })
      })
    },
    activate ({ commit }, {
      resourceOspId, cb = () => {
      }
    }) {
      return api.plugin.activate(resourceOspId, response => {
        commit('SET_ACTIVATION', { resourceOspId, response })
        cb()
      })
    }
  },
  mutations: {
    SET_PREQUOTE: (state, { resourceOspId, response }) => {
      Vue.set(state.prequotes, resourceOspId, response)
    },
    SET_ACTIVATION: (state, { resourceOspId, response }) => {
      Vue.set(state.activation, resourceOspId, response)
    }
  }
}
