<template>
  <div class="c-keyvalue" :class="{ 'c-keyvalue--full': fullWidth }">
    <div class="c-keyvalue__key" v-if="label">
      {{ label }}
    </div>
    <div class="c-keyvalue__value" v-if="$slots.value">
      <template v-if="$slots.value">
        <slot name="value" />
      </template>
    </div>
    <div class="c-keyvalue__value" v-else v-html="value">
    </div>
  </div>
</template>

<script>
export default {
  name: 'OKeyValue',
  props: {
    label: {
      type: String,
      default: null
    },
    value: {
      default: null
    },
    fullWidth: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
@import '~@/assets/styles/variables.scss';
.c-keyvalue {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  line-height: 2.4rem;

  & + &,
  p + &,
  & + p {
    margin-top: 1.6rem;
  }
}

.c-keyvalue--full {
  display: block;

  .c-keyvalue__key {
    margin-bottom: 0.8rem;
  }

  .c-keyvalue__value {
    text-align: left;
  }
}

.c-keyvalue__key,
.c-keyvalue__value {
  flex: 1 1 auto;
}

.c-keyvalue__key {
  color: $blue-kbc-cement;
  word-break: break-word;
  white-space: normal;
}

.c-keyvalue__value {
  text-align: right;
  table {
    margin-left: auto;
    td {
      text-align: left;
      white-space: nowrap;
      word-break: keep-all;
      & + td {
        padding-left: 2rem;
      }
    }
  }
}
</style>
