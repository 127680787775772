<template>
  <span class="c-reseller">Reseller screen</span>
</template>

<script>
export default {
  name: 'OResellerBadge'
}
</script>

<style lang="scss" rel="stylesheet/scss">
.c-reseller {
  background: red;
  border-radius: 0.3rem;
  color: #fff;
  display: block;
  font-size: 1.4rem;
  line-height: 1.6rem;
  margin: 1.6rem auto;
  padding: 0.4rem 0.8rem;
  text-align: center;
  text-transform: uppercase;
  width: 15rem;
}
</style>
