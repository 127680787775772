import { cambioRoutes } from './cambio'
import { generalRoutes } from './general'
import { onStreetParkingRoutes } from './streetParking'
import { seasonRoutes } from './season'
import aod from './aod'
import carpoolRoutes from './carpool'
import convenientInvoicing from './convenientInvoicing'
import daktRoutes from './dakt'
import fuelRoutes from './fuel'
import kmRegistration from './kmRegistration'
import offStreetParkingRoutes from './offstreetParking'
import pluginRoutes from './plugin'
import nmbsSeason from './nmbsSeason'
import paymentRoutes from './payment'

const routes = generalRoutes.concat(
  aod,
  cambioRoutes,
  carpoolRoutes,
  convenientInvoicing,
  daktRoutes,
  fuelRoutes,
  kmRegistration,
  offStreetParkingRoutes,
  onStreetParkingRoutes,
  pluginRoutes,
  seasonRoutes,
  nmbsSeason,
  paymentRoutes
)

export default routes
