<template>
  <div
    :key="minHeight"
    :style="style"
    class="footer-placeholder"
    ref="footerPlaceholder"
  >
  <slot></slot>
  </div>
</template>

<script>

export default {
  name: 'OFooterPlaceholder',
  data () {
    return {
      minHeight: 0
    }
  },
  computed: {
    style () {
      return {
        'min-height': `${this.minHeight}px`
      }
    }
  },
  props: ['target', 'watchFor'],
  methods: {
    resize () {
      this.minHeight = this.target.$el.clientHeight
    }
  },
  mounted () {
    this.resize()
    this.$nextTick(() => {
      this.$watch(
        'watchFor',
        function () {
          this.resize()
        }
      )
    })
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">

</style>
