import postMessage from '../../Classes/postMessageService/postMessage'

export const payment = {
  namespaced: true,
  state: {
    processing: false,
    result: null
  },
  getters: {
    processing: state => state.processing,
    result: state => state.result
  },
  actions: {
    start ({ commit, dispatch }, data) {
      if (postMessage.startPayment(data)) {
      // if (sendPostMessage('startPayment', data)) {
        commit('setProcessing', true)
        commit('setResult', null)
        dispatch('app/showLoader', undefined, { root: true })
        return true
      }

      dispatch('app/hideLoader', undefined, { root: true })
      return false
    },
    paymentResult ({ commit, dispatch }, result) {
      commit('setProcessing', false)
      commit('setResult', result)
      dispatch('app/hideLoader', undefined, { root: true })
    }
  },
  mutations: {
    setProcessing (state, processing) {
      state.processing = processing
    },
    setResult (state, result) {
      state.result = result
    }
  }
}
