import { store } from '../store'
import { getTicketAction, plugin as pluginApi } from '../api'
import postMessage from '../Classes/postMessageService/postMessage'
import * as api from '@/api'
import { router } from '@/helpers/router'

const BUY_TICKET = 'BUY_TICKET'
const SHOW_TIMETABLE = 'SHOW_TIMETABLE'
const BUY_AGAIN = 'BUY_AGAIN'
const MANAGE_RIDE = 'MANAGE_RIDE'
const STOP_SESSION = 'STOP_SESSION'
const NAVIGATE_TO = 'NAVIGATE_TO'
const ACCEPT_PURCHASE = 'ACCEPT_PURCHASE'
const DECLINE_PURCHASE = 'DECLINE_PURCHASE'

/**
 * Ticket action class
 */
export class Action {
  constructor () {
    this.params = this.getParams()
  }

  resolve () {
    if (!this.params.action) {
      return true
    }
    switch (this.params.action) {
    case BUY_TICKET:
    case BUY_AGAIN:
    case MANAGE_RIDE:
      return this.startResourceFlow()
    case SHOW_TIMETABLE:
      return this.showTimetableAction()
    case STOP_SESSION:
      return this.stopSessionAction()
    case NAVIGATE_TO:
      return this.navigateToAction()
    case ACCEPT_PURCHASE:
      return this.acceptPurchase()
    case DECLINE_PURCHASE:
      return this.declinePurchase()
    }
  }

  startResourceFlow () {
    store.commit('app/setFlowResource', this.params.serviceResourceOspId || null)
    return true
  }

  showTimetableAction () {
    store.commit('app/setFlow', 'TIMETABLE')
    store.commit('app/setFlowResource', this.params.serviceResourceOspId.toUpperCase() || null)
    return true
  }

  stopSessionAction () {
    store.commit('app/setFlow', '4411')
    getTicketAction({
      action: 'STOP_SESSION',
      parameters: [
        {
          name: 'sessionId',
          value: this.params.sessionId
        }
      ]
    }, () => Action.handleStopSession({ originalTicketOspId: this.params.originalTicketOspId || this.params.ticketOspId }))
  }

  navigateToAction () {
    return Action.handleNavigateTo(this.params)
  }

  acceptPurchase () {
    if (store) {
      const params = { ...this.params }
      params.flow = 'ACCEPT_PURCHASE'
      store.commit('app/setParams', params)
    }
    return true
  }

  async declinePurchase () {
    try {
      await pluginApi.decline(this.params.purchaseUuid)
    } catch (e) {
      console.log(e)
    }

    postMessage.refreshWallet()
    postMessage.close()
    return true
  }

  static async handleStopSession ({ originalTicketOspId }) {
    postMessage.refreshTickets()
    if (!store.getters['app/isSSO']) {
      return postMessage.clearFutureNotifications()
    }

    if (!originalTicketOspId) {
      return router.push({ name: 'stop_session_confirmation' })
    }

    return api.getTicketDetails(originalTicketOspId, ({ ticketId, bookingId, resourceOspId }) => {
      api.parking.resellerConfig(resourceOspId,
        ({ providerId, serviceId, notificationDelta }) => {
          postMessage.clearFutureNotifications({
            serviceId,
            providerId,
            ticketId
          })
          postMessage.nextStep({
            bookingId,
            providerId
          })
        })
    })
  }

  static handleNavigateTo ({ latitude, longitude }) {
    let domain = 'maps.google.com'
    if ((navigator.platform.indexOf('iPhone') !== -1) ||
      (navigator.platform.indexOf('iPad') !== -1) ||
      (navigator.platform.indexOf('iPod') !== -1)) {
      domain = 'maps.APPLE.com'
    }
    const getUrl = domain => `https://${domain}/maps?daddr=${latitude},${longitude}&amp;ll=`
    const targetUrl = getUrl(domain)
    if (!postMessage.openUrl(targetUrl)) {
      window.open(encodeURI(targetUrl), '_system')
    }
  }

  getParams () {
    return store && store.getters['app/params']
  }
}
