import * as api from '../../api'
import Vue from 'vue'
import _ from 'lodash'
export const wizard = {
  namespaced: true,
  state: {
    notice: {},
    wizard: {}
  },
  getters: {
    notice: state => serviceResourceOspId => _.get(state.notice, serviceResourceOspId, {}),
    wizard: state => serviceResourceOspId => _.get(state.wizard, serviceResourceOspId, null)
  },
  actions: {
    checkNotifications ({ commit }, serviceResourceOspId) {
      return api.wizard.getNotice(serviceResourceOspId, notice => {
        commit('setNotice', { serviceResourceOspId, notice })
      })
    }
  },
  mutations: {
    setNotice (state, { serviceResourceOspId, notice }) {
      Vue.set(state.notice, serviceResourceOspId, notice)
    },
    setWizard (state, wizard) {
      Vue.set(state.wizard, wizard.resourceOspId, wizard)
    }
  }
}
