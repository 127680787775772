/**
 * @module Commute API
 */
import {
  Api, appEndPoint
} from './api'

export const getByFilter = ({ month, year }, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/travel/getByFilter`, { month, year })
}

export const getEnabled = (cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/travel/enabled`)
}

export const commuteUpdate = ({ silent = false, ...form }, cb) => {
  const options = { loader: false }
  if (silent) {
    options.excludeMessageAlert = ['*']
  }
  const api = new Api(cb, options)
  return api.query('post', `${appEndPoint}/commute/update`, form)
}

export const homeworkUpdate = (form, cb) => {
  const api = new Api(cb, { loader: false })
  return api.query('post', `${appEndPoint}/homework/update`, form)
}

export const getDistances = (mobilityMode, cb) => {
  const api = new Api(cb, { loader: false })
  return api.query('post', `${appEndPoint}/commute/distance/get`, { mobilityMode })
}

export const commuteDistanceUpdate = (form, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/commute/distance/update`, form)
}

export const commuteDistanceDelete = (commuteDistanceId, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/commute/distance/delete`, { commuteDistanceId })
}

export const businessTripUpdate = (form, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/businessTrip/update`, form)
}

export const businessTripDelete = (uuid, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/businessTrip/delete`, { uuid })
}

export const businessTripLastOrigins = (cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/businessTrip/getLastOrigins`)
}

export const businessTripLastDestinations = (cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/businessTrip/getLastDestinations`)
}

export const applyPattern = (form, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/registration/pattern/apply`, form)
}

export const getPatternDistances = (form, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/registration/pattern/distances/get`, form)
}
