import Favorite from './Favorite'

class BusTramMetroFavorite extends Favorite {
  static createForMobilityMode (mobilityMode, travelData) {
    const data = {
      ...this.getDefaultDataForMobilityMode(mobilityMode),
      Registration: false
    }
    return new this(data)
  }
}

export default BusTramMetroFavorite
