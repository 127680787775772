// import MobilityAssistantPage from '../../components/flow/MobilityAssistantPage.vue'
// import WebPage from '../../components/flow/WebPage.vue'
// import TrainPage from '../../components/flow/TrainPage.vue'
// import TrainFellowPage from '../../components/flow/TrainFellowPage.vue'
// import TrainTimetablePage from '../../components/flow/TrainTimetablePage.vue'
// import NmbsConnectionsPage from '../../components/flow/NmbsConnectionsPage.vue'
// import NmbsConnectionDetailsPage from '../../components/flow/NmbsConnectionDetailsPage.vue'
// import ReservationRoutePage from '../../components/flow/ReservationRoutePage'
// import ReservationRouteQuotesPage from '../../components/flow/ReservationRoutesQuotesPage'
// import NMBSQuotesPage from '../../components/flow/NMBSQuotesPage'
// import TicketsPage from '../../components/flow/TicketsPage.vue'
// import TicketDetailsPage from '../../components/app/TicketDetailsPage.vue'
// import WalletPage from '../../components/app/WalletPage'
// import SalesFlowPage from '../../components/flow/SalesFlowPage'
// import BParkingFlowPage from '../../components/flow/BParkingFlowPage'
// import MultiProductQuotesPage from '../../components/flow/MultiProductQuotesPage'
// import MultiProductScanFlowPage from '../../components/flow/MultiProductScanFlowPage'
// import PoiDetailPage from '../../components/flow/PoiDetailPage'
// import LoginPage from '../../components/user/LoginPage.vue'

const MobilityAssistantPage = () => import('../../components/flow/MobilityAssistantPage')
const WebPage = () => import('../../components/flow/WebPage')
const TrainPage = () => import('../../components/flow/TrainPage')
const TrainFellowPage = () => import('../../components/flow/TrainFellowPage')
const TrainTimetablePage = () => import('../../components/flow/TrainTimetablePage')
const NmbsConnectionsPage = () => import('../../components/flow/NmbsConnectionsPage')
const NmbsConnectionDetailsPage = () => import('../../components/flow/NmbsConnectionDetailsPage')
const ReservationRoutePage = () => import('../../components/flow/ReservationRoutePage')
const ReservationRouteQuotesPage = () => import('../../components/flow/ReservationRoutesQuotesPage')
const TicketsPage = () => import('../../components/flow/TicketsPage')
const TicketDetailsPage = () => import('../../components/app/TicketDetailsPage')
const WalletPage = () => import('../../components/app/WalletPage')
const SalesFlowPage = () => import('../../components/flow/SalesFlowPage')
const BParkingFlowPage = () => import('../../components/flow/BParkingFlowPage')
const MultiProductQuotesPage = () => import('../../components/flow/MultiProductQuotesPage')
const MultiProductScanFlowPage = () => import('../../components/flow/MultiProductScanFlowPage')
const PoiDetailPage = () => import('../../components/flow/PoiDetailPage')
const LoginPage = () => import('../../components/user/LoginPage')

/**
 * Settings
 */
// import SettingsPage from '../../components/app/Settings.vue'
// import PassesPage from '../../components/app/settings/Passes'
// import AddressPage from '../../components/app/settings/Address'
// import MobibCardPage from '../../components/app/settings/Mobib'
// import SettingsFaqPage from '../../components/app/settings/FAQ'
// import SettingsVersionPage from '../../components/app/settings/Version'
// import LicensePlatesPage from '../../components/app/settings/LicensePlates'
// import PersonalInformationPage from '../../components/app/settings/Personal'
// import DiscountRightsPage from '../../components/app/settings/DiscountRights'
// import LinkedAccountsPage from '../../components/app/settings/LinkedAccounts'
// import ChangeLanguagePage from '../../components/app/settings/ChangeLanguage'
// import ChangePincodePage from '../../components/app/settings/ChangePincode'
// import SettingsConditionsPage from '../../components/app/settings/Conditions'
const SettingsPage = () => import(/* webpackChunkName: "settings" */ '../../components/app/Settings')
const PassesPage = () => import(/* webpackChunkName: "settings" */ '../../components/app/settings/Passes')
const AddressPage = () => import(/* webpackChunkName: "settings" */ '../../components/app/settings/Address')
const MobibCardPage = () => import(/* webpackChunkName: "settings" */ '../../components/app/settings/Mobib')
const SettingsFaqPage = () => import(/* webpackChunkName: "settings" */ '../../components/app/settings/FAQ')
const SettingsVersionPage = () => import(/* webpackChunkName: "settings" */ '../../components/app/settings/Version')
const LicensePlatesPage = () => import(/* webpackChunkName: "settings" */ '../../components/app/settings/LicensePlates')
const PersonalInformationPage = () => import(/* webpackChunkName: "settings" */ '../../components/app/settings/Personal')
const DiscountRightsPage = () => import(/* webpackChunkName: "settings" */ '../../components/app/settings/DiscountRights')
const LinkedAccountsPage = () => import(/* webpackChunkName: "settings" */ '../../components/app/settings/LinkedAccounts')
const ChangeLanguagePage = () => import(/* webpackChunkName: "settings" */ '../../components/app/settings/ChangeLanguage')
const ChangePincodePage = () => import(/* webpackChunkName: "settings" */ '../../components/app/settings/ChangePincode')
const SettingsConditionsPage = () => import(/* webpackChunkName: "settings" */ '../../components/app/settings/Conditions')

/**
 * Budgets
 */
const BudgetTypesPage = () => import(/* webpackChunkName: "budget" */ '../../components/budget/BudgetTypes')
const BudgetScreenPage = () => import(/* webpackChunkName: "budget" */ '../../components/budget/BudgetScreen')
const BudgetExpensesPage = () => import(/* webpackChunkName: "budget" */ '../../components/budget/BudgetExpenses')
// import BudgetTypesPage from '../../components/budget/SBudgetTypes'
// import BudgetScreenPage from '../../components/budget/BudgetScreen'
// import BudgetExpensesPage from '../../components/budget/BudgetExpenses'

/**
 * Conditions
 */
// import ConditionsPage from '../../components/user/ConditionsPage'
const ConditionsPage = () => import('../../components/user/ConditionsPage')
// import ConditionsTextPage from '../../components/user/ConditionsTextPage'
const ConditionsTextPage = () => import('../../components/user/ConditionsTextPage')

// import NotFoundPage from '../../components/app/NotFoundPage.vue'
const NotFoundPage = () => import('../../components/app/NotFoundPage')

// import EmptyPage from '../../components/app/EmptyPage.vue'
const EmptyPage = () => import('../../components/app/EmptyPage')

// import InfoPage from '../../components/app/InfoPage'
const InfoPage = () => import('../../components/app/InfoPage')

// import AvailableBudgetsPage from '../../components/budget/AvailableBudgetsPage'
const AvailableBudgetsPage = () => import('../../components/budget/AvailableBudgetsPage')

// import AvailableBudgetsPage from '../../components/budget/AvailableBudgetsPage'
const TransferBudgetsPage = () => import('../../components/budget/TransferBudgetsPage')

// import UserBirthdatePage from '../../components/flow/UserBirthdatePage'
const UserBirthdatePage = () => import('../../components/flow/UserBirthdatePage')

// import AvatarPage from '../../components/app/settings/AvatarPage'
const AvatarPage = () => import('../../components/app/settings/AvatarPage')

// import AvatarCropPage from '../../components/app/settings/AvatarCropPage'
const AvatarCropPage = () => import('../../components/app/settings/AvatarCropPage')

const AuthorizePage = () => import('../../components/4411/Authorize')

// import InformationWizardPage from '../../components/wizard/InformationWizardPage'

export const generalRoutes = [
  {
    path: '/login',
    name: 'login',
    component: LoginPage
  },
  {
    path: '/reseller/authorize',
    name: 'resellerAuthorize',
    component: AuthorizePage
  },
  {
    path: '/conditions',
    name: 'conditions',
    component: ConditionsPage
  },
  {
    path: '/conditions-text',
    name: 'conditions_text',
    component: ConditionsTextPage
  },
  // SETTINGS
  {
    path: '/settings',
    name: 'settings',
    component: SettingsPage
  },
  {
    path: '/settings/avatar',
    name: 'avatar',
    component: AvatarPage
  },
  {
    path: '/settings/avatar-crop',
    name: 'avatar_crop',
    component: AvatarCropPage
  },
  {
    path: '/settings/personal',
    name: 'personal_information',
    component: PersonalInformationPage
  },
  {
    path: '/settings/address',
    name: 'address',
    component: AddressPage
  },
  {
    path: '/settings/mobib',
    name: 'mobib',
    component: MobibCardPage
  },
  {
    path: '/settings/license-plates',
    name: 'license_plates',
    component: LicensePlatesPage
  },
  {
    path: '/settings/passes',
    name: 'passes',
    component: PassesPage
  },
  {
    path: '/settings/discount',
    name: 'discount_rights',
    component: DiscountRightsPage
  },
  {
    path: '/settings/linked-accounts',
    name: 'linked_accounts',
    component: LinkedAccountsPage
  },
  {
    path: '/settings/change-language',
    name: 'change_language',
    component: ChangeLanguagePage
  },
  {
    path: '/settings/change-pin',
    name: 'change_pin',
    component: ChangePincodePage
  },
  {
    path: '/settings/faq',
    name: 'settings_faq',
    component: SettingsFaqPage
  },
  {
    path: '/settings/conditions',
    name: 'settings_conditions',
    component: SettingsConditionsPage
  },
  {
    path: '/settings/version',
    name: 'settings_version',
    component: SettingsVersionPage
  },
  // BUDGETS
  {
    path: '/budgets',
    name: 'budget_types',
    component: BudgetTypesPage
  },
  {
    path: '/budget/:budgetType',
    name: 'budget_screen',
    component: BudgetScreenPage
  },
  {
    path: '/budget/:mobilityMode/:timestamp',
    name: 'budget_expenses',
    component: BudgetExpensesPage
  },
  // FLOW
  {
    path: '/budgets/:resourceOspId',
    name: 'available_budgets',
    component: AvailableBudgetsPage
  },
  {
    path: '/transfer-budgets/:ticketOspId',
    name: 'transfer_budgets',
    component: TransferBudgetsPage
  },
  {
    path: '/user-birthdate/:resourceOspId',
    name: 'user_birthdate',
    component: UserBirthdatePage
  },
  {
    path: '/',
    name: 'home',
    component: MobilityAssistantPage
  },
  {
    path: '/sales/b-parking',
    name: 'bparking_flow',
    component: BParkingFlowPage
  },
  {
    path: '/sales/:mobilityMode',
    name: 'sales_flow',
    component: SalesFlowPage
  },
  {
    path: '/sales/:mobilityMode/multi/:resourceOspId',
    name: 'sales_details',
    component: MultiProductQuotesPage
  },
  {
    path: '/sales/:mobilityMode/multi-scan/:resourceOspId',
    name: 'sales_multi_scan_details',
    component: MultiProductScanFlowPage
  },
  {
    path: '/sales/:mobilityMode/web/:resourceOspId',
    name: 'sales_web',
    component: WebPage
  },
  {
    path: '/sales/train/:resourceOspId',
    name: 'sales_train',
    component: TrainPage
  },
  {
    path: '/sales/train/:resourceOspId/for-fellow',
    name: 'sales_train_fellow',
    component: TrainFellowPage
  },
  {
    path: '/sales/:mobilityMode/timetable/:resourceOspId',
    name: 'sales_timetable',
    component: TrainTimetablePage
  },
  {
    path: '/sales/:mobilityMode/timetable/:resourceOspId/connections',
    name: 'sales_connections',
    component: NmbsConnectionsPage
  },
  {
    path: '/sales/:mobilityMode/timetable/:resourceOspId/connections/:id',
    name: 'sales_connection_details',
    component: NmbsConnectionDetailsPage
  },
  // {
  //   path: '/sales/:mobilityMode/:resourceOspId/quote',
  //   name: 'sales_train_quote',
  //   component: NMBSQuotesPage
  // },
  {
    //  ROUTE_RESERVATION
    path: '/sales/reservation/route/:resourceOspId',
    name: 'sales_reservation_route',
    component: ReservationRoutePage
  },
  {
    path: '/sales/reservation/route/:resourceOspId/quote',
    name: 'sales_reservation_route_quote',
    component: ReservationRouteQuotesPage
  },
  {
    path: '/poi/:ospId/:budgetOspId?',
    name: 'poi_details',
    component: PoiDetailPage
  },
  {
    path: '/tickets',
    name: 'tickets',
    component: TicketsPage
  },
  {
    path: '/wallet',
    name: 'wallet',
    component: WalletPage
  },
  {
    path: '/info',
    name: 'info_page',
    component: InfoPage
  },
  {
    path: '/ticket-details/:ticketOspId',
    name: 'ticket_details',
    component: TicketDetailsPage
  },

  // Wizard
  {
    path: '/wizard/:resourceOspId',
    name: 'information_wizard',
    component: () => import(/* webpackChunkName: "wizard" */ '../../components/wizard/InformationWizardPage')
  },

  {
    path: '/accept-purchase/:resourceOspId/:purchaseUuid',
    name: 'accept_purchase',
    component: () => import(/* webpackChunkName: "accept-purchase" */ '../../components/flow/AcceptPurchasePage')
  },
  {
    path: '/empty',
    name: 'empty',
    component: EmptyPage
  },
  // 404
  {
    path: '*',
    component: NotFoundPage
  }
]
