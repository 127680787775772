import { trans } from '../helpers'
import * as api from '../api'

export default class Parking {
  constructor ({ ospId, parkingCode, name, city = null, coordinate = null, mrpOspId = null }) {
    this.ospId = ospId
    this.parkingCode = parkingCode
    this.name = trans(name)
    this.city = city
    this.coordinate = coordinate
    this.mrpOspId = mrpOspId
  }

  static createFromPoi ({ ospId, title }) {
    const data = {
      parkingCode: title,
      ospId,
      name: title
    }
    return new Parking(data)
  }

  static createFromHistory ({ city, zoneCode, userCoordinate, mrpOspId, parkingCode }) {
    const data = {
      parkingCode: zoneCode || parkingCode,
      ospId: null,
      name: zoneCode,
      coordinate: null,
      city,
      mrpOspId
    }
    return new Parking(data)
  }

  async fillDataFromAutocomplete (mrpOspId) {
    this.mrpOspId = mrpOspId || this.mrpOspId
    return api.parking.parkingCodeAutocomplete({
      mrpOspId: this.mrpOspId,
      mrpZoneCodeSearchString: this.parkingCode
    }, response => {
      if (response.length) {
        this.ospId = response[0].ospId
        this.coordinate = response[0].coordinate
        this.name = trans(response[0].name)
      }
    })
  }
}
