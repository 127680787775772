var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-view",class:{
    'c-view--white': _vm.hasWhiteBackground,
    'c-view--no-scroll': _vm.noScroll,
    'c-view--bottom-padding': _vm.bottomPadding,
    'c-view--double-bottom-padding': _vm.doubleBottomPadding,
    'c-view--flexible': _vm.isFlex,
    'c-view--relative': _vm.isRelative,
    'c-view--horizontal-scroll': _vm.horizontalScroll,
  },style:(_vm.style)},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }