import { store } from '@/store'
import { cloneDeep } from 'lodash'

export default class MobilityResource {
  constructor (resource) {
    Object.assign(this, cloneDeep(resource))
  }

  static getByOspId (resourceOspId) {
    let ospId
    try {
      ospId = atob(resourceOspId)
    } catch (e) {
      ospId = resourceOspId
    }
    return store.getters['user/mobilityModeItem'](ospId)
  }
}
