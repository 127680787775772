/**
 * @module Carpool API
 */

import { Api, appEndPoint } from './api'

const API_PREFIX = `${appEndPoint}/carpool`

// /**
//  * @description return the user's profile for the selected Carpool provider (of course there is only 1 provider which
//  * is Carpool.be, but we always try to be generic in our development so that we maintain maximum flexibility);
//  * in case the user activated the menu item for the first time, this endpoint will return an HTTP 404 NOT_FOUND error
//  * @param mrpOspId String
//  * @param cb Callback
//  * @apiResponse {"userOspId": "string", "loyaltyAccountId": "string", "loyaltyProgrammeName": "string", "loyaltyCodeInfoText": "string", "poiQualificationRange": "string"}
//  * @return Promise
//  * @exception NOT_FOUND
//  */
export const getUserProfile = (mrpOspId, cb) => {
  const api = new Api(cb, { loader: true, returnError: true, excludeMessageAlert: ['NOT_FOUND'] })
  return api.query('get', `${API_PREFIX}/user/${mrpOspId}`)
}

export const getServiceInfo = (serviceResourceOspId, cb) => {
  const api = new Api(cb)
  return api.query('get', `${API_PREFIX}/getServiceInfo/${serviceResourceOspId}`)
}

export const register = (mrpOspId, cb) => {
  const api = new Api(cb, { returnError: true })
  return api.query('post', `${API_PREFIX}/user/register/${mrpOspId}`)
}

export const createRide = ({ serviceResourceOspId, carpoolRide }, cb) => {
  const api = new Api(cb)
  return api.query('post', `${API_PREFIX}/ride/create`, { serviceResourceOspId, ...carpoolRide })
}

export const updateRide = (data, cb) => {
  const api = new Api(cb)
  return api.query('post', `${API_PREFIX}/ride/update`, data)
}

export const deleteRide = (rideUuid, cb) => {
  const api = new Api(cb)
  return api.query('post', `${API_PREFIX}/ride/delete`, { rideUuid })
}

export const rideDetails = (rideUuid, cb) => {
  const api = new Api(cb)
  return api.query('get', `${API_PREFIX}/ride/detail/${rideUuid}`)
}

export const rideList = (mrpOspId, cb) => {
  const api = new Api(cb)
  return api.query('post', `${API_PREFIX}/ride/list`, { mrpOspId })
}

export const rideConfirm = (rideUuid, cb) => {
  const api = new Api(cb)
  return api.query('post', `${API_PREFIX}/ride/confirm`, { rideUuid })
}

export const matchList = ({ date, mrpOspId, rideDirection }, cb) => {
  const api = new Api(cb, { excludeMessageAlert: ['NOT_FOUND'], returnError: true })
  return api.query('post', `${API_PREFIX}/match/list`, { date, mrpOspId, rideDirection })
}

export const matchPrice = ({ driverId, mrpOspId, requestRideId, requestTimeId, rideId, timeId }, cb) => {
  const api = new Api(cb, { returnError: true })
  return api.query('post', `${API_PREFIX}/match/price`, { driverId, mrpOspId, requestRideId, requestTimeId, rideId, timeId })
}

export const routeList = (data, cb) => {
  const api = new Api(cb)
  return api.query('post', `${API_PREFIX}/route/list`, data)
}

export const agreementRequest = (data, cb) => {
  const api = new Api(cb)
  return api.query('post', `${API_PREFIX}/agreement/request`, data)
}

export const agreementList = ({ mrpOspId, limit = 100, silent = false }, cb) => {
  const api = new Api(cb, { loader: !silent })
  return api.query('post', `${API_PREFIX}/agreement/list`, { mrpOspId, limit })
}

export const agreementCancel = (data, cb) => {
  const api = new Api(cb)
  return api.query('post', `${API_PREFIX}/agreement/cancel`, data)
}

export const agreementAccept = (data, cb) => {
  const api = new Api(cb)
  return api.query('post', `${API_PREFIX}/agreement/accept`, data)
}

export const agreementDecline = (data, cb) => {
  const api = new Api(cb)
  return api.query('post', `${API_PREFIX}/agreement/decline`, data)
}
export const agreementDelete = (data, cb) => {
  const api = new Api(cb)
  return api.query('post', `${API_PREFIX}/agreement/delete`, data)
}

export const sendMessage = (data, cb) => {
  const api = new Api(cb)
  return api.query('post', `${API_PREFIX}/sendMessage`, data)
}

export const settlementInitiate = (data, cb) => {
  const api = new Api(cb, { returnError: true })
  return api.query('post', `${API_PREFIX}/settlement/initiate`, data)
}

export const settlementAccept = (data, cb) => {
  const api = new Api(cb)
  return api.query('post', `${API_PREFIX}/settlement/accept`, data)
}

export const settlementRefuse = (data, cb) => {
  const api = new Api(cb)
  return api.query('post', `${API_PREFIX}/settlement/refuse`, data)
}

export const cashReceived = (data, cb) => {
  const api = new Api(cb)
  return api.query('post', `${API_PREFIX}/payment/cashReceived`, data)
}

export const cashPaid = (data, cb) => {
  const api = new Api(cb)
  return api.query('post', `${API_PREFIX}/payment/cashPaid`, data)
}
