import * as api from '../../api/'
import { store } from '../../store'
import { router, pluck } from '../../helpers'
import _ from 'lodash'
import moment from 'moment'
import postMessage from '../../Classes/postMessageService/postMessage'

const PASSES_TYPE = 'PASS'
const TICKET_TYPE = 'TICKET'

export const settings = {
  namespaced: true,
  state: {
    menu: [],
    mobibCards: [],
    licensePlates: [],
    passes: [],
    tickets: [],
    wallet: [],
    discounts: [],
    showFirstInWallet: false,
    deviceSettingsStatus: null
  },
  getters: {
    menu: state => state.menu,
    mobibCardsAll: state => {
      const mobibCards = []
      state.mobibCards.forEach(c => {
        mobibCards.push({
          label: c.label.defaultValue,
          cardNumber: c.mobibCardNumber,
          serialNumber: c.mobibChipId,
          tokenUuid: c.tokenUuid
        })
      })
      return mobibCards
    },
    licensePlatesAll: state => {
      const licensePlates = []
      state.licensePlates.forEach(lp => {
        licensePlates.push({
          label: lp.label.defaultValue,
          licensePlate: lp.licensePlate,
          tokenUuid: lp.tokenUuid
        })
      })
      return licensePlates
    },
    discounts: state => state.discounts,
    discountsAll: (state, getters, rootState) => {
      const discounts = []
      state.discounts.forEach(d => {
        discounts.push({
          code: d.discount,
          label: d.description,
          checked: rootState.user.profile.data.discounts.indexOf(d.discount) > -1
        })
      })
      return discounts
    },
    passes: state => state.passes,
    tickets: state => state.tickets,
    wallet: state => state.wallet,
    showFirstInWallet: state => state.showFirstInWallet,
    deviceSettingsStatus: state => state.deviceSettingsStatus
  },
  actions: {
    getMobibCards ({ commit }) {
      return api.getMobibCards(response => {
        commit('setMobibCards', response)
      })
    },
    addMobibCard ({ commit, dispatch }, { card, cb }) {
      return api.addMobibCard(card, card => {
        commit('addMobibCard', card)
        cb(card)
      })
    },
    updateMobibCard ({ commit }, oldCard) {
      return api.updateMobibCard(oldCard, newCard => {
        commit('updateMobibCard', [oldCard, newCard])
      })
    },
    getLicensePlates ({ commit }) {
      return api.getLicensePlates(response => {
        commit('setLicensePlates', response)
      })
    },
    addLicensePlate ({ commit }, lp) {
      return api.addLicensePlate(lp, lp => {
        commit('addLicensePlate', lp)
      })
    },
    updateLicensePlate ({ commit }, oldLP) {
      return api.updateLicensePlate(oldLP, newLP => {
        commit('updateLicensePlate', [oldLP, newLP])
      })
    },
    removeIdentificationToken ({ commit }, [id, type = 'mobib']) {
      return api.removeIdentificationToken(id, response => {
        if (response !== false) {
          commit('removeIdentificationToken', [id, type])
        }
      })
    },
    getPasses ({ commit }) {
      return api.getTickets(PASSES_TYPE, response => {
        commit('setPasses', response)
      })
    },
    getTickets ({ commit }) {
      return api.getTickets(TICKET_TYPE, response => {
        commit('setTickets', response)
      })
    },
    getWalletTickets ({ commit, state }, cb = () => {}) {
      const filters = { ticketOspIds: pluck(state.wallet, 'ticketOspId'), ticketType: undefined }
      return api.getAllTickets(filters, response => {
        commit('unsetWalletTickets', response.invalidatedTicketOspIds)
        commit('setWalletTickets', response.tickets)
        cb(response)
      })
    },
    resetWallet ({ commit }) {
      commit('resetWallet')
    },
    getDiscounts ({ commit }) {
      return api.getDiscounts({}, response => {
        commit('setDiscounts', response.discounts)
      })
    },
    updateDiscounts ({ commit }, discounts) {
      return api.updateDiscounts({ discounts }).then(response => {
        if (response !== false) {
          const profileDiscounts = []
          discounts.forEach(discount => {
            profileDiscounts.push(discount.discount)
          })
          commit('user/setProfileDiscounts', profileDiscounts, { root: true })
        }
      })
    },
    updatePinCode ({ getters }, { data, message }) {
      return api.updatePinCode(data).then(response => {
        if (response !== false) {
          router.go(-1)
          this.dispatch('alert/success', message)
        }
      })
    },
    prepareSettingsMenu ({ commit }) {
      const isSSO = store.getters['app/isSSO']
      const settings = [
        {
          category: {
            title: 'Account',
            id: 'account',
            show: true
          },
          items: [
            {
              id: 'personal_information',
              label: 'Personal Information',
              icon: 'user',
              link: 'settings/personal',
              show: true
            },
            {
              id: 'budgets',
              label: 'Budgets',
              icon: 'budget',
              link: 'budgets',
              show: true
            },
            {
              id: 'mobib',
              label: 'Mobib Card',
              icon: 'mobib',
              link: 'settings/mobib',
              show: true
            },
            {
              id: 'license_plates',
              label: 'License Plate',
              icon: 'car',
              link: 'settings/license-plates',
              show: true
            },
            {
              id: 'passes',
              label: 'Passes',
              icon: 'tickets',
              link: 'settings/passes',
              show: true
            },
            {
              id: 'discount_rights',
              label: 'Discount Rights',
              icon: 'discount',
              link: 'settings/discount',
              show: true
            },
            {
              id: 'linked_accounts',
              label: 'Linked Accounts',
              icon: 'link',
              link: 'settings/linked-accounts',
              show: true
            }
          ]
        },
        {
          category: {
            title: 'App',
            id: 'app',
            show: !isSSO
          },
          items: [
            {
              id: 'change_language',
              label: 'Change Language',
              icon: 'inbox',
              link: 'settings/change-language',
              show: true
            },
            {
              id: 'pin',
              label: 'Change PIN Code',
              icon: 'lock',
              link: 'settings/change-pin',
              show: true
            }
          ]
        },
        {
          category: {
            title: 'Other',
            id: 'other',
            show: !isSSO
          },
          items: [
            {
              id: 'faq',
              label: 'FAQ',
              icon: 'support2',
              link: 'settings/faq',
              show: true
            },
            {
              id: 'conditions',
              label: 'General Conditions',
              icon: 'info',
              link: 'settings/conditions',
              show: true
            },
            {
              id: 'version',
              label: 'Version',
              icon: 'device',
              link: 'settings/version',
              show: true
            }
          ]
        }
      ]
      commit('setSettingsMenu', settings)
    },
    changeShowFirstInWallet ({ commit }, val) {
      commit('setShowFirstInWallet', val)
    },
    async getDeviceSettingsStatus ({ commit }) {
      const response = await postMessage.getDeviceSettingsStatus()
      commit('setDeviceSettingsStatus', response)
      return response
    }
  },
  mutations: {
    setSettingsMenu (state, menu) {
      state.menu = menu
    },
    setMobibCards (state, cards) {
      state.mobibCards = cards
    },
    addMobibCard (state, card) {
      state.mobibCards.push(card)
    },
    updateMobibCard (state, [oldCard, newCard]) {
      const index = state.mobibCards.findIndex(c => c.tokenUuid === oldCard.tokenUuid)
      if (index !== -1) {
        return state.mobibCards.splice(index, 1, newCard)
      }
      state.mobibCards.push(newCard)
    },
    setLicensePlates (state, plates) {
      state.licensePlates = plates
    },
    addLicensePlate (state, lp) {
      state.licensePlates.push(lp)
    },
    updateLicensePlate (state, [oldLP, newLP]) {
      const index = state.licensePlates.findIndex(lp => lp.tokenUuid === oldLP.tokenUuid)
      if (index !== -1) {
        state.licensePlates.splice(index, 1, newLP)
      }
    },
    removeIdentificationToken (state, [id, type]) {
      const instance = type === 'mobib' ? 'mobibCards' : 'licensePlates'
      const index = state[instance].findIndex(c => c.tokenUuid === id)
      if (index !== -1) {
        state[instance].splice(index, 1)
      }
    },
    setPasses (state, passes) {
      state.passes = passes
    },
    setTickets (state, tickets) {
      state.tickets = tickets
    },
    setWalletTickets (state, tickets) {
      state.wallet = _.orderBy(state.wallet.concat(tickets), v => moment(v.createdOn).unix(), 'desc')
    },
    unsetWalletTickets (state, invalidatedTicketOspIds) {
      state.wallet = state.wallet.filter(ticket => invalidatedTicketOspIds.indexOf(ticket.ticketOspId) === -1)
    },
    resetWallet (state) {
      state.wallet = []
    },
    setDiscounts (state, discounts) {
      state.discounts = discounts
    },
    setShowFirstInWallet (state, val) {
      state.showFirstInWallet = val
    },
    setDeviceSettingsStatus (state, payload) {
      state.deviceSettingsStatus = payload || null
    }
  }
}
