import * as api from '@/api'
import ParkingSession from '../../Classes/ParkingSession'
import { appLog, compareAppVersions } from '../../helpers'
import { forEach } from 'lodash'
import Vue from 'vue'
const defaultState = () => ({
  sessionHistory: [],
  sessionForm: {},
  showRevokeConfirmation: '',
  applySafariFix: true,
  userSearch: ''
})
export const streetParking = {
  namespaced: true,
  state: {
    ...defaultState()
  },
  getters: {
    sessionHistory: state => state.sessionHistory,
    allowGetDeviceStatus: (state, _1, _2, rootGetters) => {
      return rootGetters['app/isSSO'] || (compareAppVersions(rootGetters['app/appVersion'], '2.4.4') > 0)
    },
    sessionForm: state => state.sessionForm,
    sessionDetails: state => (sessionUuid) => state.sessionHistory.find(item => item.sessionUuid === sessionUuid),
    showRevokeConfirmation: state => state.showRevokeConfirmation,
    applySafariFix: state => state.applySafariFix,
    userSearch: state => state.userSearch
  },
  actions: {
    getSessionHistory ({ commit }, mrpOspId) {
      return api.parking.parkingSessionsGetByFilter({
        mrpOspId,
        pagination: {
          page: 0,
          size: 50,
          sort: {
            sortOrders: [
              {
                direction: 'DESCENDING',
                property: 'startTime'
              }
            ]
          }
        }
      }, ({ content }) => {
        commit('setSessionHistory', content.map(v => new ParkingSession(v)))
      })
    },
    resetSessionForm ({ commit }) {
      commit('setSessionForm', {})
    },
    updateSessionForm ({ commit }, form = {}) {
      commit('setSessionForm', form)
    },
    confirmRevoke ({ commit }, show = '') {
      commit('setRevokeConfirmation', show)
    },
    resetState ({ commit }) {
      appLog('resetState')
      commit('resetState', defaultState())
    },
    updateApplySafariFix ({ commit }) {
      commit('setApplySafariFix', false)
    },
    updateUserSearch ({ commit }, search) {
      commit('setUserSearch', search)
    }
  },
  mutations: {
    setSessionHistory (state, data) {
      state.sessionHistory = data
    },
    setSessionForm (state, value) {
      state.sessionForm = value
    },
    setRevokeConfirmation (state, value) {
      state.showRevokeConfirmation = value
    },
    resetState (state, data) {
      forEach(data, (value, key) => {
        Vue.set(state, key, value)
      })
    },
    setApplySafariFix (state, val) {
      state.applySafariFix = val
    },
    setUserSearch (state, search) {
      state.userSearch = search
    }
  }
}
