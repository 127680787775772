<template>
  <o-view class="c-pincode-view" :no-scroll="true">
      <div class="navbar">
        <o-back-button @go-back="goBack" />
        <h1 class="navbar__title">{{ navBarTitle }}</h1>
      </div>
      <o-section :isTransparent="true">
        <div class="c-pincode-info">
          <div class="c-pincode-info_text">
            <span v-html="infoText"></span>
          </div>
          <div class="c-pincode-info_pin">
            <div
              v-for="key in [1,2,3,4,5]"
              v-bind:key="key"
              class="c-pincode-info_pin-item"
              :class="{
                'c-pincode-info_pin-item--active': key === (pin.length + 1),
                'c-pincode-info_pin-item--selected': (pin.length >= key) && !showEnter,
                'c-pincode-info_pin-item--digit': (pin.length >= key) && showEnter
              }" >
              <span class="" v-if="showEnter">{{pin[key-1]}}</span>
            </div>
          </div>

        </div>
        <div class="c-pincode-numpad">
          <template v-for="parentKey in [0, 1, 2]" >
            <div class="c-pincode-numpad_row" v-bind:key="parentKey">
              <template v-for="key in [1, 2, 3]">
                <button @click="setPin(key + 3 * parentKey)" class="c-pincode-numpad_item"  v-bind:key="key">
                  {{ key + 3 * parentKey }}
                </button>
              </template>
            </div>
          </template>
          <div class="c-pincode-numpad_row">
            <button class="c-pincode-numpad_item" @click="bakespace">
              <o-icon
                icon="back-ios"
                v-if="os !== 'android'"
              />
              <o-icon
                icon="back-android"
                v-if="os === 'android'"
              />
            </button>
            <button class="c-pincode-numpad_item" @click="setPin(0)">
              0
            </button>
            <button class="c-pincode-numpad_item"
                    :class="{'c-pincode-numpad_item--disabled': pin.length < 5}"
                    @click="confirm" >
              <o-icon icon="check" />
            </button>
          </div>

        </div>
      </o-section>
  </o-view>

</template>

<script>
import sha1 from 'js-sha1'

export default {
  name: 'OPinCode',
  data: function () {
    return {
      pin: ''
    }
  },
  props: {
    infoText: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: 'Confirm'
    },
    encode: {
      type: Boolean,
      default: true
    },
    showEnter: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    confirm: function () {
      this.$emit('pincode', this.pincode)
    },
    setPin: function (d) {
      this.pin += `${d}`
    },
    goBack: function () {
      this.$emit('close')
    },
    bakespace: function () {
      if (this.pin.length) {
        this.pin = this.pin.substring(0, this.pin.length - 1)
      }
    }
  },
  computed: {
    navBarTitle () {
      return this.title
    },
    os () {
      const ua = navigator.userAgent.toLowerCase()
      const os = ua.indexOf('android') > -1 ? 'android' : 'ios'
      return os
    },
    pincode () {
      return this.encode ? sha1(this.pin) : this.pin
    }
  },
  watch: {
    pin: function () {
      if (this.pin.length === 5) {
        this.confirm()
      }
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
@import '~@/assets/styles/variables.scss';
  .c-pincode-view {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    max-height: 100%;
    height: 100%;
    background: var(--primary-color-overlay);
    .navbar {
      align-items: flex-start;
      color: #fff;
      display: flex;
      font-size: 1.7rem;
      font-weight: 600;
      letter-spacing: -0.033rem;
      line-height: 2.4rem;
      min-height: 4.4rem;
      padding: 1rem;
      position: relative;
      z-index: 11;
    }
    .c-section {
      display: flex;
      flex-direction: column;
      flex: 1;
      .c-pincode-info {
        background: $background-color;
        border-radius: $border-radius-small;
        color: $text-color;
        color: var(--text-color);
        font-size: 1.4rem;
        line-height: 2rem;
        padding: 0.8rem;
        text-align: center;
        a {
          border-bottom: 1px solid currentColor;
        }
        b {
          font-weight: bold;
        }
        .c-pincode-info_pin {
          background: #E4F1FD;
          display: flex;
          flex-direction: row;
          justify-items: center;
          justify-content: center;
          padding: 1rem;
          margin: 0.8rem -0.8rem -0.8rem -0.8rem;
          border-radius: 0 0 0.8rem 0.8rem;
          .c-pincode-info_pin-item {
            flex: 1;
            width: 10px;
            max-width: 10px;
            height: 10px;
            max-height: 10px;
            margin: 10px;
            border-radius: 50%;
            border: 1px solid var(--primary-color);
            &--active {
              box-shadow: 0 0 10px var(--primary-color);
            }
            &--selected {
              background: var(--primary-color);
            }
            &--digit {
              display: flex;
              align-items: center;
              font-size: 2.2rem;
              border: none;
              color: var(--primary-color);
              font-weight: 500;
            }
          }
        }
      }
      .c-pincode-numpad {
        display: flex;
        justify-content: center;
        justify-items: center;
        flex-direction: column;
        margin-top: 2rem;
        flex: 1;
        max-height: 35rem;
        //max-width: 46rem;
        .c-pincode-numpad_row {
          display: flex;
          flex-direction: row;
          flex: 1;
          justify-content: space-evenly;
          .c-pincode-numpad_item {
            position: relative;
            flex: 1;
            max-width: 7rem;
            max-height: 7rem;
            min-width: 7rem;
            min-height: 7rem;
            background-color: rgba($background-color, 0.2);
            border: 1px solid white;
            margin: 1.3rem;
            border-radius: 50%;
            color: white;
            font-size: 2.5rem;
            font-weight: bold;
            cursor: pointer;
            &--disabled {
              cursor: inherit;
              background: $background-color;
              color: var(--primary-color);
            }
          }
        }
      }
    }
  }
</style>
