<template>
  <div class="c-select-wrapper" :class="{ 'c-select--disabled': disabled }">
    <label v-if="label" class="c-select-label" :for="uniqueId">
      {{ label }}
    </label>
    <o-icon :icon="icon" v-if="icon"/>
    <div class="c-select-inner">
      <select
        :id="uniqueId"
        class="c-select"
        :readonly="readonly"
        :disabled="disabled"
        v-model="value"
        v-on:change="$emit('input', value)"
      >
        <option value="" v-if="placeholder" disabled>{{ placeholder }}</option>
        <slot :value="value"/>
      </select>
    </div>
    <template v-if="validations && validations.$params && validations.$dirty">
      <div class="c-error"
           v-if="('required' in validations.$params) && !validations.required">
        <template v-if="noLabelInError">
          {{ getErrorMessage($t('html5.validations.no_label.required')) }}
        </template>
        <template v-else>
          {{ getErrorMessage($t('html5.validations.required'), label) }}
        </template>
      </div>
    </template>
  </div>
</template>

<script>
// @group Base Components
import { getErrorMessage } from '../../helpers'

export default {
  name: 'OSelectInput',
  props: {
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    validations: {
      type: Object,
      default: null
    },
    initial: {
      type: String,
      default: null
    },
    noLabelInError: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      uniqueId: '',
      value: ''
    }
  },
  methods: {
    generateUniqueId () {
      return (
        '_' +
        Math.random()
          .toString(36)
          .substr(2, 9)
      )
    },
    getErrorMessage (message, label = '') {
      return getErrorMessage(message, label)
    }
  },
  created () {
    this.uniqueId = this.generateUniqueId()
    this.value = this.initial
  }
}
</script>

<style lang="scss">
@import '~@/assets/styles/variables.scss';
.c-select-wrapper {
  align-items: center;
  display: flex;
  flex-wrap: wrap;

  .c-icon {
    color: $primary-color;
    color: var(--primary-color);
    flex: none;
    margin-right: 1.2rem;
  }
}

.c-select-inner {
  flex: 1 1 0;
}

.c-select {
  -webkit-appearance: none;
  appearance: none;
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4IiBoZWlnaHQ9IjUiIHZpZXdCb3g9IjAgMCA4IDUiPgogIDxwYXRoIGZpbGw9IiM5QjlCOUIiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTguNzEsMTEuNzEgTDExLjMsMTQuMyBDMTEuNjksMTQuNjkgMTIuMzIsMTQuNjkgMTIuNzEsMTQuMyBMMTUuMywxMS43MSBDMTUuOTMsMTEuMDggMTUuNDgsMTAgMTQuNTksMTAgTDkuNDEsMTAgQzguNTIsMTAgOC4wOCwxMS4wOCA4LjcxLDExLjcxIFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC04IC0xMCkiLz4KPC9zdmc+Cg==') no-repeat right center;
  border: 0;
  color: inherit;
  display: block;
  font: inherit;
  width: 100%;
  outline: 0;
  padding-right: 1.6rem;
}
.c-select--disabled {
  .c-select {
    background: none;
  }
}

.c-select-label {
  color: $blue-kbc-cement;
  flex: none;
  font-size: 1.3rem;
  margin-bottom: 0.8rem;
  width: 100%;
}
</style>
