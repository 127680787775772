import _ from 'lodash'
import axios from 'axios'
import qs from 'qs'
import * as turf from '@turf/turf'
import tinycolor from 'tinycolor2'
import * as auth from './authorization'
import { trans, getResourceLink, getURLParameter } from './functions'
import { helpers } from 'vuelidate/lib/validators'

import * as api from '../api/'
import { store } from '../store'
import { router } from './router'

import Flow from '../helpers/Flow'
import moment from 'moment'

import { GEOLOCATION_DEFAULT, MAP_ZOOM, POI_RANGE, RESOURCE_404 } from './constants'
import postMessage from '../Classes/postMessageService/postMessage'
import { PostMessageReceiver } from '../Classes/postMessageService/PostMessageReceiver'
export * from './functions'

/**
 * Function is called before mounted Vue app is mounted
 * @returns {Promise<unknown>}
 */
export const beforeInit = (store) => {
  appLog('beforeInit')
  appLog('v2023-11-23')
  let clientId = getURLParameter('clientId')
  const language = getURLParameter('language')
  const flow = getURLParameter('flow')
  const hybridweb = getURLParameter('hybridweb') || flow === 'PAYMENT'
  const ssoTokenKBC = getURLParameter('ssoTokenKBC') || getURLParameter('authorization_code')
  if (ssoTokenKBC) {
    store.commit('app/setAuthorizationCode', { authorizationCode: ssoTokenKBC })
  }

  const refreshToken = getURLParameter('refreshToken') || getURLParameter('token')

  if (refreshToken) {
    store.commit('user/setTokens', { token: null, refreshToken })
  }
  store.dispatch('app/defineParams')
  const appVersion = getURLParameter('appVersion')
  if (appVersion) {
    store.commit('app/setAppVersion', appVersion)
  }
  // Define custom http headers
  axios.defaults.headers.common['x-HTML5-CLIENTID'] = (clientId && clientId.trim()) || 'OlympusMobility'
  axios.defaults.headers.common['X-OLYMPUS-CLIENTID'] = (clientId && clientId.trim()) || 'OlympusMobility'
  if (hybridweb) {
    store.commit('app/setHybridweb', true)
    clientId = null
  }

  PostMessageReceiver.register() // register postMessage handler listener

  return new Promise((resolve) => {
    if (language) {
      store.commit('app/setLanguage', language)
    }

    // if url path contain "authorize", init oauth flow
    if (window.location.pathname.indexOf('authorize') > -1) {
      resolve({ next: '/reseller/authorize' })
    }

    if (!clientId || !clientId.trim().length) { // user is not reseller
      // postMessage.pageLoaded()
      if (hybridweb) { // user is native (source is native Olympus mobile app)
        auth.hybrid().then(response => {
          if (response) {
            resolve({ next: response })
          } else {
            resolve({ next: '/login' })
          }
        })
      } else { // user is not native and not reseller so show login page
        resolve({ next: '/login' })
      }
    } else { // user is reseller
      store.commit('app/setClientId', clientId)

      auth.sso(clientId).then(response => {
        if (response) {
          resolve({ next: response })
        } else {
          resolve({ next: '/login' })
        }
      })
    }
  })
}

export const defaultGeoReversed = () => {
  const defaultGeolocation = GEOLOCATION_DEFAULT.slice()
  return defaultGeolocation.reverse()
}

export const filterBy = (option, ref) => {
  const searchText = ref.searchText.toString().toLowerCase()
  if (option.translations) {
    let include = false
    option.translations.forEach(t => {
      if (lowerCaseStringIncludes(t, searchText)) {
        return (include = true)
      }
    })
    return include
  }

  if (ref.optionLabel && ref.optionKey) {
    return (
      lowerCaseStringIncludes(option[ref.optionLabel], searchText) ||
      lowerCaseStringIncludes(option[ref.optionKey], searchText)
    )
  }

  if (ref.optionLabel) {
    return lowerCaseStringIncludes(option[ref.optionLabel], searchText)
  }

  if (ref.optionKey) {
    return lowerCaseStringIncludes(option[ref.optionKey], searchText)
  }

  return lowerCaseStringStartsWith(option, searchText)
}

// check if string start with char/string
const lowerCaseStringStartsWith = (text, q) => {
  return text.toString().toLowerCase().startsWith(q)
}

// check if string includes char/string
const lowerCaseStringIncludes = (text, q) => {
  try {
    return text.toString().toLowerCase().includes(q)
  } catch (e) {
    return false
  }
}

export const parseFloat = (num, digits = 2) => {
  const numFloat = Number.parseFloat(num).toFixed(digits)
  return !isNaN(numFloat) ? numFloat : null
}

// get base64 image from ur;
export const _imageBase64 = (url, { svg = false, final = false, download = false, serviceResourceOspId = null } = {}) => {
  if (!url) {
    return ''
  }
  const base64Images = store.getters['app/base64Images'] // all base 64 images in store
  const expirationTime = 24 * 60 * 60 * 1000 // expiration time 24 hours
  const expireBefore = Date.now().toString() - expirationTime // expiration timestamp
  const imageExists = base64Images && base64Images[url] && base64Images[url].date && base64Images[url].date > expireBefore
  if (imageExists) {
    if (!svg) {
      return base64Images[url].getBase64
    }
    return atob(base64Images[url].origin)
  }
  if (final) return '' // stop trying to retriev an image
  const requests = store.getters['app/base64ImagesRequests'] // all image requests
  if (!requests || !requests[url] || (requests[url] < (Date.now().toString() - 5000))) { // url is not requested yet
    if (download) {
      store.dispatch('app/downloadImage', { imageUrl: url, serviceResourceOspId }).then(() => {
        return _imageBase64(url, { final: true, download: true, serviceResourceOspId })
      })
    } else {
      store.dispatch('app/getImage', url).then(() => {
        return _imageBase64(url, { svg, final: true, download, serviceResourceOspId })
      })
    }
  }
  return ''
}

// load base64 image to the store
export const asyncImageBase64 = (url, { svg = false, final = false, download = false, serviceResourceOspId = null } = {}) => {
  appLog('asyncImageBase64 init')
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async resolve => {
    if (!url) {
      resolve(false)
    }
    const expirationTime = 24 * 60 * 60 * 1000 // expiration time 24 hours
    const base64Images = store.getters['app/base64Images']
    const expireBefore = Date.now().toString() - expirationTime
    const imageExists = base64Images && base64Images[url] && base64Images[url].date && base64Images[url].date > expireBefore
    if (!imageExists) {
      const requests = store.getters['app/base64ImagesRequests']
      if (!requests || !requests[url] || (requests[url] < (Date.now().toString() - 5000))) { // image is not requested
        if (download) {
          await store.dispatch('app/downloadImage', { imageUrl: url, serviceResourceOspId })
        } else {
          await store.dispatch('app/getImage', url)
        }
        resolve(true)
        return true
      }
      resolve(false)
    }
    resolve(true)
    return true
  })
}

/**
 * compare user mobility preferences by "score"
 */
export const compareByScore = (a, b) => {
  if (a.score > b.score) {
    return -1
  } else if (a.score < b.score) {
    return 1
  } else {
    return 0
  }
}

/**
 * compare by parae "name"
 * @param a
 * @param b
 * @returns {number}
 */
export const compareByName = (a, b) => {
  if (a.name < b.name) {
    return -1
  } else if (a.name > b.name) {
    return 1
  } else {
    return 0
  }
}

/**
 * Flow starting
 * @param redirect
 * @returns {Promise<string|undefined>}
 */
export const flowNavigation = (redirect = true) => {
  appLog(new Flow().getStart(), 'resolve')
  return new Flow().getStart(redirect)
}

/**
 * Redirect to flow path
 * @param flow
 * @returns {Promise<Route|boolean>}
 */
export const flowResourceRedirect = async (flow = null) => {
  try {
    appLog(flow, 'flowResourceRedirect flow')
    const p = await flowResourcePath(flow)
    appLog(p, 'flowResourceRedirect')
    return await router.push({ path: p })
  } catch (e) {
    appLog(e, 'flowResourceNavigate ERROR')
    return false
  }
}

/**
 * Get flow url
 * @param flow
 * @returns {Promise<unknown>}
 */
export const flowResourcePath = async (flow = null) => {
  flow = flow === 'null' ? null : flow
  const flowResource = store.state.app.flowResource
  let start = 0
  const delay = 100
  const end = process.env.VUE_APP_AWAIT_TIMER * 1000
  return new Promise((resolve, reject) => {
    if (!flowResource) {
      reject(new Error('Empty flow resource'))
      return false
    }
    const timer = setInterval(() => {
      appLog('flowResourcePath', 'setInterval')
      start += delay
      let mobilityMenu = store.getters['user/mobilityModeMenuNative']
      mobilityMenu = mobilityMenu.filter(e => (!flow || e.mobilityMode === flow) && e.authorized)

      if (flowResource && mobilityMenu.length) {
        // clearInterval(timer)
        const resource = getResource(flowResource, mobilityMenu, flow)

        appLog(resource, 'flowResourcePath resource')

        const path = getResourceLink(resource)
        appLog(path, 'flowResourcePath path')
        if (path) {
          store.commit('app/setFlowResource', null)
        }
        clearInterval(timer)
        resolve(path)
      }
      if (start > end) {
        clearInterval(timer)
        store.commit('app/setFlowResource', null)
        reject(new Error('Timeout'))
      }
    }, delay)
  })
}

/**
 * Find flow resource
 * @param flowResource
 * @param mobilityMenu
 * @param flow
 * @returns {string|*}
 */
export const getResource = (flowResource, mobilityMenu, flow = null) => {
  appLog({ flowResource, mobilityMenu, flow }, 'getResource')
  function findResource (flowResource, mobilityMenu, flow = null) {
    appLog({ flowResource, mobilityMenu, flow }, 'findResource')
    return mobilityMenu.find(r => r.resourceOspId.toUpperCase() === flowResource.toUpperCase()) ||
    (flow && mobilityMenu.find(r => r.mobilityMode === flow && r.resourceOspId.toUpperCase().indexOf(flowResource) === 0))
  }
  const resource = findResource(flowResource, mobilityMenu, flow)
  if (resource) {
    return resource
  }

  const nmbsResource = (classNumber) => `NmbsSncb.RESOURCE.SERVICE_RESOURCE.TRAIN_CLASS_${classNumber}_SERVICE`
  // switcher is used if some nmbs class is not available for user but another class is available
  const nmbsSwitcher = {
    [nmbsResource(1)]: nmbsResource(2),
    [nmbsResource(2)]: nmbsResource(1)
  }
  if (nmbsSwitcher[flowResource]) {
    const resource = findResource(nmbsSwitcher[flowResource], mobilityMenu, flow)
    if (resource) {
      return resource
    }
  }
  return RESOURCE_404
}

/**
 * Prepare POI markers
 * @param elements
 * @param selectedList
 * @param onClick
 * @returns {*[]|*}
 */
export const filteredMarkers = (elements, { selectedList = [], onClick = undefined } = {}) => {
  if (!elements) {
    return []
  }
  return elements.map(e => {
    let isSelected = false
    if (selectedList && selectedList.length) {
      isSelected = _.has(e, 'ospId.ospId') && e.ospId.ospId && selectedList.includes(e.ospId.ospId)
    }
    return {
      title: trans(e.name),
      mobilityMode: e.mobilityMode,
      imageBase64: _imageBase64(api.getPoiImageUrl(e, isSelected)),
      ospId: _.get(e, 'ospId.ospId', ''),
      coordinates: [e.coordinate.latitude, e.coordinate.longitude],
      mrpOspId: _.get(e, 'mrpOspId.ospId', '').toLowerCase(),
      type: _.has(e, 'mrpOspId.ospId') ? e.mrpOspId.ospId.toLowerCase() + (isSelected ? '-selected' : '') : null,
      classSuffix: _.has(e, 'mrpOspId.ospId') ? e.mrpOspId.ospId.toLowerCase() + (isSelected ? '-selected' : '') : null,
      status: _.get(e, 'status', null),
      city: _.get(e, 'city', null),
      selected: isSelected,
      color: _.get(e, 'color', null),
      zone: _.get(e, 'zone', null),
      class: '',
      onClick,
      onZoneClick: () => {}
      // status: 'UNAVAILABLE'
    }
  })
}

/**
 * Filter POI items by mobility mode
 * @param elements
 * @param mode
 * @param provider
 * @returns {*[]|*}
 */
export const filteredElements = (elements, mode, provider) => {
  if (mode && elements[mode] && elements[mode].length) {
    return elements[mode].filter((e) => {
      const providerFilter = provider ? e.mrpOspId.ospId === provider : true
      return (e.mobilityMode.toUpperCase() === mode) && providerFilter
    })
  }
  return []
}

/**
 * Define UI branding based on there user's company
 */
export const setBranding = () => {
  const theme = {
    app: {
      // 'background-color': store.getters['app/bgColor']
    }
  }
  for (const elId in theme) {
    const el = document.getElementById(elId)
    if (el) {
      for (const prop in theme[elId]) {
        el.style.setProperty(prop, theme[elId][prop])
      }
    }
  }
  const brandColor = store.getters['app/brandColor']
  const branding = store.getters['app/branding']
  const baseColor = tinycolor(brandColor).setAlpha(1)
  const lightColor = tinycolor(brandColor).setAlpha(0.1)
  const overlayColor = tinycolor(brandColor).setAlpha(0.9)
  const otherIconColor = tinycolor(branding.otherIconColor || branding).setAlpha(1)
  document.documentElement.style.setProperty('--primary-color', baseColor.toRgbString())
  document.documentElement.style.setProperty('--primary-color-light', lightColor.toRgbString())
  document.documentElement.style.setProperty('--primary-color-overlay', overlayColor.toRgbString())
  document.documentElement.style.setProperty('--other-icon-color', otherIconColor.toRgbString())
}

export const zoomedRange = (zoom) => {
  // As we starts from all country view , fix factor to allow poi range less
  const factor = (MAP_ZOOM / zoom)
  return factor * POI_RANGE
}

/**
 * Print formatted console message
 * @param data
 * @param name
 */
export const appLog = (data, name = 'appLog') => {
  // TODO: uncomment on production
  // if (process.env.NODE_ENV === 'development') {
  console.log(`${name} => `, data)
  try {
    if (typeof data === 'object') {
      console.groupCollapsed(`${name} => JSON`)
      console.log(JSON.stringify(data))
      console.groupEnd()
    }
  } catch (e) {
    console.groupEnd()
  }
  // }
}

export const pluck = (array, key) => array.map(o => o[key])

export const ucFirst = (string) => {
  string = string.trim()
  if (string) {
    string = string[0].toUpperCase() + string.slice(1)
  }
  return string
}

/**
 * Get formatted date string
 * @param dateString
 * @returns {string}
 */
export const getDate = (dateString) => {
  let fullDate = {}
  if (!(fullDate = new Date(dateString))) {
    return ''
  }
  const date = fullDate.getDate()
  const year = fullDate.getFullYear()
  const month = getDateMonth(dateString)
  dateString = `${date} ${month} ${year}`
  return dateString
}

export const addLineBreaks = (string) => {
  return string.replace(/(\n)/g, '<br />')
}

export const getDateMonth = (dateString) => {
  const locale = store.getters['app/language']
  let fullDate = {}
  if (!(fullDate = new Date(dateString))) {
    return ''
  }
  return fullDate.toLocaleDateString(locale, { month: 'long' }).toLowerCase()
}

/**
 * Calculate target map range based on distance
 * @param map
 * @returns {number|number}
 */
export const calculateRange = (map) => {
  const boundes = map.getBounds()
  const from = turf.point(Object.values(boundes._northEast))
  const to = turf.point(Object.values(boundes._southWest))
  const options = { units: 'kilometers' }
  let distance = turf.distance(from, to, options)
  distance = (distance / 2).toFixed(2)
  return (distance && distance >= 0.1) ? distance : 0.1
}

/**
 * Calculate distance between 2 points on the map
 * @param from
 * @param to
 * @param options
 * @returns {number}
 */
export const calculateDistance = (from, to, options = { units: 'kilometers' }) => {
  const point1 = {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'Point',
      coordinates: [from.longitude, from.latitude]
    }
  }
  const point2 = {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'Point',
      coordinates: [to.longitude, to.latitude]
    }
  }
  const distance = turf.distance(point1, point2, options)
  return distance.toFixed(3) * 1000
}

/**
 * Format flow query parameter
 * @param flow
 * @returns {*}
 */
export const parseFlow = (flow) => {
  return flow.trim().toUpperCase()
}

/**
 * Parse query params
 * @returns {any}
 */
export const extractParamsFromUrl = () => {
  // http://localhost:8080//#/?clientId=KBCGroepUAT&language=nl&flow=DELIJN
  let search = _.last(location.href.split('?'))
  search = _.first(search.split('#'))
  console.log(search)
  return qs.parse(search)
}

/**
 * Get input error message
 * @param message
 * @param label
 * @returns {*}
 */
export const getErrorMessage = (message, label = '') => {
  let text = ''
  if (label) {
    if (label.indexOf('*') === (label.length - 1)) {
      label = label.slice(0, label.length - 1)
    }
    label = label.toLowerCase()
    text = label + ' ' + message
  } else {
    text = message
  }
  if (text.charAt(text.length - 1) !== '.') {
    text += '.'
  }
  if (text.charAt(text.length - 1) !== '.') {
    text += '.'
  }
  return ucFirst(text)
}

/**
 * Check string for compliance with the luhn algorithm
 * @param digits
 * @returns {boolean}
 */
export const luhnAlgorithm = (digits) => {
  if (!digits) {
    return false
  }
  let sum = 0
  for (let i = 0; i < digits.length; i++) {
    let cardNum = parseInt(digits[i])
    if ((digits.length - i) % 2 === 0) {
      cardNum = cardNum * 2
      if (cardNum > 9) {
        cardNum = cardNum - 9
      }
    }
    sum += cardNum
  }
  return sum % 10 === 0
}

/**
 * Check string for compliance with the modulo algorithm
 * @param digits
 * @returns {boolean}
 */
export const moduloAlgorithm = (digits) => {
  if (!digits) {
    return false
  }
  const clearString = digits.replace(/-/g, '')
  if (clearString.length < 12) {
    return false
  }
  const firstTen = clearString.substr(0, 10)
  const lastTwo = clearString.substr(10)
  const rest = firstTen % 97 || 97
  return rest === (lastTwo * 1)
}

/**
 * Max date input validation rule
 * @param date
 * @returns {ValidationRule}
 */
export const maxDate = (date) =>
  helpers.withParams(
    { type: 'maxDate', value: date },
    (v) => moment(v).isSameOrBefore(moment(date))
  )

/**
 * Min date input validation rule
 * @param date
 * @returns {ValidationRule}
 */
export const minDate = (date) =>
  helpers.withParams(
    { type: 'minDate', value: date },
    (v) => moment(v).isSameOrAfter(moment(date))
  )

/**
 * Min time input validation rule
 * @param time
 * @returns {ValidationRule}
 */
export const minTime = (time) =>
  helpers.withParams(
    { type: 'minTime', value: time },
    (v) => {
      return moment(v, 'HH:mm').isSameOrAfter(moment(time, 'HH:mm'))
    }
  )
