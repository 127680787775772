<template>
  <div
    class="c-loader-wrapper"
    :class="type ? `c-${type}-loader` : ''"
  >
    <div class="c-loader"></div>
  </div>
</template>

<script>
export default {
  name: 'OLoader',
  props: {
    type: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.c-loader-wrapper {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  z-index: 1000;
  //display: block;
  /*position: fixed;*/
  position: absolute;
  padding-top: 65%;
  background-color: #FFFFFF;
  //display: flex;
  //align-items: center;
}

.c-loader {
  margin-top: 45%;
  width: 50px;
  height: 50px;
  margin: 0 auto;
  border: 3px solid rgb(115, 200, 200);
  border-radius: 50%;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: cssload-spin 575ms infinite linear;
  -o-animation: cssload-spin 575ms infinite linear;
  -ms-animation: cssload-spin 575ms infinite linear;
  -webkit-animation: cssload-spin 575ms infinite linear;
  -moz-animation: cssload-spin 575ms infinite linear;
}

.c-map-loader {
  position: static;
  padding-top: 0;

  .c-loader {
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
  }
}

.c-flex-loader {
  display: flex;
  align-items: center;
}

@keyframes cssload-spin {
  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes cssload-spin {
  100% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-ms-keyframes cssload-spin {
  100% {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes cssload-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes cssload-spin {
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
