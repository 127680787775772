/**
 * @module Train API
 */
import { Api, appEndPoint, appEndPointV3 } from './api'

export const saleNmbsSncbQuote = (filters, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPointV3}/sales/NmbsSncb/quote`, filters)
}

export const getTrainStations = cb => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/poi/stations`, {
    mobilityMode: 'TRAIN',
    pagination: {
      page: 0,
      size: 1000
    }
  })
}

export const getHistoryList = (mrpOspId, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/history/${mrpOspId}/list`)
}

export const getTimetableHistoryList = (mrpOspId, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/history/${mrpOspId}/TIMETABLE`)
}

export const getHistoryOne = ({ mrpOspId, bookingId }, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/history/${mrpOspId}/getOne`, { bookingId })
}

export const getNmbsJourneyPlan = ({ arrivalTime = '', departureTime = '', destinationCode = '', originCode = '' }, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/nmbs/journey/plan`, {
    arrivalTime, departureTime, destinationCode, originCode
  })
}

export const getNmbsJourneyLegDetail = ({ arrivalId = null, departureId = null, travelDate = null, vehicleId = null }, cb) => {
  const api = new Api(cb)
  /*
  "arrivalId": "string",
  "departureId": "string",
  "travelDate": "yyyy-MM-dd",
  "vehicleId": "string"
   */
  return api.query('post', `${appEndPoint}/nmbs/journey/leg/detail`, {
    arrivalId, departureId, travelDate, vehicleId
  })
}

export const getNmbsSeasonPrequote = ({ serviceResourceOspId, budgetOspId }, cb) => {
  const api = new Api(cb)

  return api.query('post', `${appEndPoint}/sales/NmbsSncb/seasonTicket/${serviceResourceOspId}/prequote`, { budgetOspId })
}

export const getNmbsSeasonQuote = (form, cb) => {
  const api = new Api(cb, { returnError: true })
  return api.query('post', `${appEndPoint}/sales/NmbsSncb/seasonTicket/${form.resourceOspId}/quote`, form)
}
