// const data = {
//   'ResourceOspId': 'NmbsSncb.RESOURCE.SERVICE_RESOURCE.TRAIN_CLASS_1_SERVICE',
//   'OspId': 'NmbsSncb',
//   'FromId': '108811304',
//   'FromLabel': 'BRUSSEL-LUXEMBURG',
//   'ToId': '108821857',
//   'ToLabel': 'BOOISCHOT',
//   'Retour': false,
//   'Class': 0,
//   'Remark': '',
//   'Reason': null
// }

import Favorite from './Favorite'

class OtherPurchasesFavorite extends Favorite {
  static createForMobilityMode (mobilityMode, travelData) {
    const data = {
      ...this.getDefaultDataForMobilityMode(mobilityMode),
      Registration: false
    }
    return new this(data)
  }
}

export default OtherPurchasesFavorite
