import { appLog } from './app'
import { BPARKING_RESOURCE, RESOURCE_404, POI_RANGE, AOD_RESOURCE } from './constants'
import { router } from './router'
import { store } from '@/store'
import _ from 'lodash'
import Flow from '@/helpers/Flow'
import moment from 'moment'

/**
 * Translate HTTP response label
 * @param object
 * @returns {*|string}
 */
export const trans = (object) => {
  if (!_.isObject(object) || !object) {
    return object
  }
  let translated = ''
  if ('labelTranslations' in object) {
    const labelTranslation = object.labelTranslations.find(t => t.languageCode === store.getters.langDefault)
    translated = labelTranslation ? labelTranslation.html || labelTranslation.text : ''
  }
  return translated || object.defaultValueHtml || object.defaultValue || ''
}

/**
 * Format hyperlink from template
 * @param text
 * @returns {*}
 */
export const textHyperlinkFormat = (text) => {
  let infoText = text
  const links = infoText.match(/\[(.*?)\|(external|internal)\]/gi)
  if (links) {
    links.forEach((l) => {
      const link = l.replace(/\[|\]/g, '')
      const params = link.split('|')
      if (params.length === 3) {
        let hyperlink = '<a class="c-link '
        if (params[2] === 'external') {
          hyperlink += 'c-link-external" data-target="external"'
        } else {
          hyperlink += 'c-link-internal" data-target="internal"'
        }
        hyperlink += ' href="' + params[0] + '" target="_blank">' + params[1] + '</a>'
        infoText = infoText.replace(l, hyperlink)
      }
    })
  }
  return infoText
}

export const translate = (textDefault, translations = []) => {
  if (!textDefault) {
    return ''
  }
  if (!translations || !translations.length) {
    return textDefault
  }
  const filteredTranslations = translations.filter(t => {
    return t.languageCode === store.getters.langDefault
  })
  return filteredTranslations.length ? filteredTranslations[0].text : textDefault
}

/**
 * Get service resource link
 * @param resource
 * @returns {string|null}
 */
export const getResourceLink = (resource) => {
  let link = getLink(resource)
  if (link && resource) {
    if (resource.params) {
      link += '/' + resource.params.join('/')
    }
    if (resource.query) {
      link += getQueryString(resource.query)
    }
  }
  if (!link) {
    return null
  }
  try {
    return router.resolve(link) ? link : null
  } catch (e) {
    appLog(e, 'getResourceLink ERROR')
    return null
  }
}

/**
 * Get link for specific resource
 * @param resource
 * @returns {string|null}
 */
const getLink = (resource) => {
  if (resource === RESOURCE_404) {
    return '/404'
  }
  if (!_.has(resource, 'resourceOspId')) {
    return null
  }
  if (resource.resourceOspId === BPARKING_RESOURCE) {
    return '/sales/b-parking'
  }
  if (resource.resourceOspId === 'OlympusMobility.RESOURCE.SERVICE_RESOURCE.CONVENIENT_INVOICING') {
    return '/convenient-invoicing'
  }
  const flow = resource.mobilityMode.toLowerCase()
  const resourceOspId = btoa(resource.resourceOspId)
  switch (resource.appFlowType) {
  case 'MULTI_PRODUCT':
    return `/sales/${flow}/multi/${resourceOspId}`
  case 'WEB':
    return `/sales/${flow}/web/${resourceOspId}`
  case 'ROUTE_RESERVATION':
    return `/sales/reservation/route/${resourceOspId}`
  case 'MULTI_PRODUCT_SCAN':
    return `/sales/${flow}/multi-scan/${resourceOspId}`
  case 'TIMETABLE':
    return `/sales/${flow}/timetable/${resourceOspId}`
  case 'FUEL':
    return `/sales/fuel/${resourceOspId}`
  case 'CARPOOL':
    return `/sales/carpool/${resourceOspId}`
  case 'CARSHARE':
    return `/sales/cambio/${resourceOspId}`
  case 'SEASON_TICKET_V2':
    return `/sales/season-ticket/v2/${resourceOspId}`
  case 'ONSTREET_PARKING':
    return `/sales/on-street-parking/${resourceOspId}`
  case 'PLUGIN_SERVICE':
    return `/sales/plugin/${resourceOspId}`
  case 'DAKT':
    return `/sales/dakt/${resourceOspId}`
  case 'OFFSTREET_PARKING':
    return `/sales/off-street-parking/${resourceOspId}`
  default:
    if (flow === 'train' && resource.appFlowType === 'SEASON_TICKET') {
      return `/sales/${flow}-season-ticket/${resourceOspId}`
    }
    if (flow === 'train') {
      return `/sales/${flow}/${resourceOspId}`
    }
    if (flow === 'fuel') {
      return `/sales/fuel/${resourceOspId}`
    }
    appLog({ appFlowType: resource.appFlowType, flow, resourceOspId }, 'UNDEFINED_FLOW')
    return ''
  }
}

const getQueryString = (query) => {
  let response = ''
  const keys = Object.keys(query)
  if (keys.length) {
    response = '?'
    const array = []
    for (const v of keys) {
      array.push(`${v}=${query[v]}`)
    }
    response += array.join('&')
  }
  return response
}

export const getURLParameter = (name, href = null) => {
  return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(href || window.location.href) || [null, ''])[1].replace(/\+/g, '%20')) || null
}

/**
 * Get Cambio budget status
 * @param budget
 * @returns {string|*}
 */
export const getBudgetStatus = (budget) => {
  if (_.has(budget, 'profile.activationStatus')) {
    return getCambioProfileStatus(budget.profile)
  } else {
    return 'BLOCKED'
  }
}

export const getCambioProfileStatus = profile => {
  switch (profile.activationStatus) {
  case 'DEACTIVATION_REQUESTED':
    return 'ACTIVATED'
  case 'SUBSCRIPTION_UPDATE_REQUESTED':
    return 'ACTIVATED'
  default:
    return profile.activationStatus
  }
}

/**
 * Get next business day.
 * @param date
 * @param format
 * @returns {string|moment.Moment}
 */
export const getNextBusinessDay = (date = null, format = 'YYYY-MM-DD') => {
  date = date || moment().format('YYYY-MM-DD')
  let defaultRepeatUntil = moment(date).add(1, 'days')
  // appLog(defaultRepeatUntil.day(), 'defaultRepeatUntil.day()')
  if (defaultRepeatUntil.day() === 6 || defaultRepeatUntil.day() === 7) {
    defaultRepeatUntil = moment(date).weekday(8)
  }
  return format ? defaultRepeatUntil.format(format) : defaultRepeatUntil
}

/**
 * Define subflow. It's used to remove marked with "salesFlowId" query string pages from router history to not show that page again if user click back button.
 * @param to
 * @param from
 * @param next
 * @returns {*}
 */
export const salesSubFlowStart = (to, from, next) => {
  if (to.query.salesFlowId === undefined) {
    return router.push({
      name: to.name,
      params: to.params,
      query: {
        ...to.query,
        salesFlowId: Date.now().toString()
      }
    }).catch(() => {})
  }
  next()
}

/**
 * check if mobib card is required for the flow
 * @param mrpOspId
 * @param resourceOspId
 * @returns {boolean}
 */
export const isMobibRequired = (mrpOspId, resourceOspId) => {
  return (mrpOspId === 'MivbStib' && _.last(resourceOspId.split('.')) !== 'MOBIB') || mrpOspId === 'Tec'
}

/**
 * Compare app versions
 * @param firstVersion
 * @param secondVersion
 * @returns {number|number}
 */
export const compareAppVersions = (firstVersion, secondVersion) => {
  const firstVersionArray = firstVersion.split('.').map(Number)
  const secondVersionArray = secondVersion.split('.').map(Number)
  const loopLength = Math.max(
    firstVersionArray.length,
    secondVersionArray.length
  )
  for (let i = 0; i < loopLength; ++i) {
    const a = firstVersionArray[i] || 0
    const b = secondVersionArray[i] || 0
    if (a !== b) {
      return a > b ? 1 : -1
    }
  }
  return 0
}

/**
 * auth token to json
 * @param token
 * @returns {{}|any}
 */
export const parseUserToken = (token) => {
  try {
    const encoded = token.split('.')[1]
    const decoded = Buffer.from(encoded, 'base64').toString('utf-8')
    return JSON.parse(decoded)
  } catch (e) {
    console.log(e)
    return {}
  }
}

export const defaultPoiV2Filter = () => ({
  address: null,
  center: store.state.user.geolocationMap || null,
  radius: POI_RANGE,
  userLocation: null, // {latitude: "", longitude: ""}
  mobilityModes: [],
  mrpOspIds: []
})

export const poiFilterV1ToV2 = (filterV1) => {
  const filterV2 = defaultPoiV2Filter()
  if (filterV1.mobilityMode) {
    filterV2.mobilityModes.push(filterV1.mobilityMode)
  }
  if (filterV1.range) {
    const radius = filterV1.range * 1000

    filterV2.radius = radius > 20 ? radius : 20
  }

  if (filterV1.source) {
    filterV2.center = filterV1.source
  }
  if (filterV1.poiOwner && filterV1.poiOwner.ospId) {
    filterV2.mrpOspIds.push(filterV1.poiOwner.ospId)
  }
  if (filterV1.userLocation) {
    filterV2.userLocation = filterV1.userLocation
  }
  return filterV2
}

export const getDurationString = (from, to) => {
  const duration = moment.duration(to.diff(moment(from)))
  let hours = Number.parseInt(duration.asHours())
  let minutes = duration.minutes()
  let seconds = duration.seconds()
  let result = ''
  hours = hours < 10 ? `0${hours}` : hours
  minutes = minutes < 10 ? `0${minutes}` : minutes
  seconds = seconds < 10 ? `0${seconds}` : seconds
  result += `${hours}:${minutes}:${seconds}`
  return result
}

export const getConvenientInvoicingProviderId = () => {
  const flow = new Flow()
  if (_.get(flow, 'flow.isMenu', false)) {
    return _.get(flow, 'flow.mode', '')
  }
  const flowString = _.get(flow, 'flow.flowString', '').toUpperCase()

  let mrpOspId = _.get(flow, 'flow.menuItem.mobilityProvider.ospId', null)
  if (!mrpOspId && flowString === 'AOD') {
    mrpOspId = AOD_RESOURCE.split('.')[0]
  }
  return mrpOspId
}

export const reduceImageFileSize = async (base64Str, maxSize = 1280) => {
  const resizedBase64 = await new Promise((resolve) => {
    const img = new Image()
    img.src = base64Str
    img.onload = () => {
      const canvas = document.createElement('canvas')
      let width = img.width
      let height = img.height

      if (width > height) {
        if (width > maxSize) {
          height *= maxSize / width
          width = maxSize
        }
      } else {
        if (height > maxSize) {
          width *= maxSize / height
          height = maxSize
        }
      }
      canvas.width = width
      canvas.height = height
      const ctx = canvas.getContext('2d')
      ctx.drawImage(img, 0, 0, width, height)
      resolve(canvas.toDataURL('image/jpeg'))
    }
  })
  return resizedBase64
}
