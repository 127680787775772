<template>
  <label class="c-checkbox c-switch">
    <input type="checkbox"
           class="hidden"
           :checked="checkbox"
           @click="checkbox = !checkbox"
           @change="$emit('change', checkbox)">
    <div class="c-checkbox__text">
      <p>
        <slot/>
      </p>
    </div>
    <div
      class="toggle"
      :class="checkbox ? 'active' : ''">
      <div
        :style="style"
        class="draggable">
      </div>
    </div>
  </label>
</template>

<script>
export default {
  data () {
    return {
      checkbox: false,
      width: 100,
      state: false,
      pressed: 0,
      position: 0
    }
  },
  name: 'OSwitch',
  props: {
    name: {
      type: String,
      required: true
    }
  },
  methods: {
    toggle (state) {
      this.state = state
      this.position = !state
        ? 0
        : 100
    }
  },
  watch: {
    checkbox () {
      this.toggle(this.checkbox)
    }
  },
  computed: {
    style () {
      return {
        transform: `translateX(calc(${this.pos_percentage} + 3px))`
      }
    },
    pos_percentage () {
      return `${this.position / this.width * 100}%`
    }
  },
  mounted () {
    this.toggle(this.value)
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.c-checkbox.c-switch {
  align-items: flex-start;
  display: flex;

  input[type='checkbox'] {
    flex: none;
    height: 2.4rem;
    margin-right: 0.8rem;
    width: 2.4rem;
  }

  &__text {
    margin: auto 0 auto auto;
  }
}

$width: 46px;
$height:25px;
$background: #ddd;
$background-active: #72d09c;
$border-color: #ddd;
$button-size: 17px;
$button-color: #fff;

.toggle {
  width: $width;
  height: $height;
  background: $background;
  border-radius: 200px;
  padding: 4px;
  transition: background 0.6s;
  margin-left: 6px;
  margin-right: auto;

  .draggable {
    width: $button-size;
    height: $button-size;
    background: $button-color;
    border-radius: 100%;
    margin-left: -1px;
    transform: translateX(0%);
    transition: transform 0.05s ease-in-out;
  }

  &.active {
    background: $background-active;
    transition: background 0.6s;
  }
}
.hidden {
  display: none;
}
</style>
