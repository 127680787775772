var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-section",class:{
    'c-section--transparent': _vm.isTransparent,
    'c-section--center': _vm.centerAlign,
    'c-section--compact': _vm.compact,
    'c-section--large-padding': _vm.largePadding,
    'c-section--highlighted': _vm.highlighted,
    'c-section--no-border': _vm.noBorder,
    'c-section--split': _vm.split,
  }},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }