var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.to ? 'router-link' : 'div',{tag:"component",staticClass:"c-button",class:{
    'c-button--primary': _vm.isPrimary,
    'c-button--secondary': _vm.isSecondary,
    'c-button--dangerous': _vm.isDangerous,
    'c-button--disabled': _vm.isDisabled,
    'c-button--rectangular': _vm.isRectangular,
    'c-button--floating': _vm.isFloating,
    'c-button--floating-secondary': _vm.isFloating && _vm.isSecondary,
    'c-button--relative': _vm.isRelative,
    'c-button--small': _vm.size === 'small'
  },attrs:{"to":_vm.to ? _vm.to : null,"disabled":_vm.isDisabled},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('click')}}},[_vm._t("default",function(){return [(_vm.prependIcon)?_c('o-icon',{attrs:{"icon":_vm.prependIcon}}):_vm._e(),_vm._v(" "+_vm._s(_vm.label)+" ")]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }