<template>
  <div class="c-date-input-wrapper">

    <label v-if="label" class="c-date-input-label">
      {{ label }}
    </label>
    <div class="c-date-input-content">
      <o-icon
        :icon="icon"
        v-if="icon"
      />
      <span class="overlayer">{{date}}</span>
      <input
        ref="input"
        v-model="inputVal"
        :key="key"
        :type="readonly ? 'text' : (type || 'date')"
        class="c-date-input"
        :data-date="date"
        :data-placeholder="date ? '' : placeholder"
        :readonly="readonly"
        :min="min"
        :max="max"
      >
      <div v-if="inputHelp" class="help-icon-wrapper" @click.prevent="inputHelp">
        <o-icon icon="info"/>
      </div>
    </div>
    <template v-if="validations && validations.$params && validations.$dirty">
      <div class="c-error"
           v-if="('required' in validations.$params) && !validations.required">
        <template v-if="noLabelInError">
          {{ getErrorMessage($t('html5.validations.no_label.required')) }}
        </template>
        <template v-else>
          {{ getErrorMessage($t('html5.validations.required'), label) }}
        </template>
      </div>
      <div class="c-error"
           v-else-if="('minDate' in validations.$params) && !validations.minDate">
        <template v-if="noLabelInError">
          {{ getErrorMessage($t('html5.validations.no_label.min_date', { n: prepareDate(validations.$params.minDate.value) })) }}
        </template>
        <template v-else>
          {{ getErrorMessage($t('html5.validations.min_date', { n: prepareDate(validations.$params.minDate.value) }), label) }}
        </template>
      </div>
      <div class="c-error"
           v-else-if="('maxDate' in validations.$params) && !validations.maxDate">
        <template v-if="noLabelInError">
          {{ getErrorMessage($t('html5.validations.no_label.max_date', { n: prepareDate(validations.$params.maxDate.value) })) }}
        </template>
        <template v-else>
          {{ getErrorMessage($t('html5.validations.max_date', { n: prepareDate(validations.$params.maxDate.value) }), label) }}
        </template>
      </div>
    </template>
  </div>
</template>

<script type="text/ecmascript-6">
import moment from 'moment'
import { getErrorMessage } from '../../helpers'
export default {
  name: 'DateInput',
  props: {
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: null
    },
    format: {
      type: String,
      default: 'DD-MM-YYYY'
    },
    placeholder: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: null
    },
    readonly: {
      type: Boolean,
      default: false
    },
    min: {
      type: String,
      default: ''
    },
    max: {
      type: String,
      default: ''
    },
    validations: {
      type: Object,
      default: null
    },
    noLabelInError: {
      type: Boolean,
      default: false
    },
    inputHelp: {
      type: Function,
      default: null
    },
    type: {
      type: String,
      default: 'date'
    }
  },
  data () {
    return {
      inputVal: this.readonly ? moment(this.value).format(this.format) : this.value,
      uniqueId: '',
      key: ''
    }
  },
  computed: {
    date () {
      let date = moment(this.inputVal, 'YYYY-MM-DD')
      if (date.isValid()) {
        return date.format(this.format)
      }
      date = moment(this.inputVal, 'MM/DD/YYYY')
      if (date.isValid()) {
        return date.format(this.format)
      }
      date = moment(this.inputVal, 'DD/MM/YYYY')
      if (date.isValid()) {
        return date.format(this.format)
      }
      date = moment(this.inputVal, 'DD-MM-YYYY')
      if (date.isValid()) {
        return date.format(this.format)
      }
      return undefined
    }
  },
  methods: {
    generateUniqueId () {
      return (
        '_' +
        Math.random()
          .toString(36)
          .substr(2, 9)
      )
    },
    reset () {
      this.inputVal = moment().format('YYYY-MM-DD')
      this.key = Date.now().toString()
    },
    getErrorMessage (message, label = '') {
      return getErrorMessage(message, label)
    },
    prepareDate (date) {
      return moment(date).format(this.format)
    }
  },
  watch: {
    inputVal (val) {
      if (val) {
        this.$emit('input', val)
      } else {
        this.reset()
      }
    }
  },
  created () {
    this.uniqueId = this.generateUniqueId()
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
@import '~@/assets/styles/variables.scss';
.c-date-input-wrapper {
  display: flex;
  flex-wrap: wrap;
  line-height: normal;

  .c-icon {
    color: $primary-color;
    color: var(--primary-color);
    flex: none;
    margin-right: 1.2rem;
  }

  .overlayer {
    display: none;
    padding: 2px;
    position: absolute;
    left: 54px;
    background: white;
    width: 100px;
    z-index: 10;
    pointer-events: none;
  }
  @-moz-document url-prefix() {
    .overlayer {
      display: block;
    }
  }

  .c-date-input {
    position: relative;
    appearance: none;
    background: none;
    border: 0;
    color: inherit;
    display: block;
    flex: 1 1 0;
    font: inherit;
    outline: 0;

    &:before {
      position: absolute;
      top: 2px;
      left: 3px;
      content: attr(data-placeholder);
      color: rgb(117, 117, 117);
      display: inline-block;
      /*color: black;*/
      /*pointer-events: none;*/
    }

    &:after {
      position: absolute;
      top: 2px;
      left: 3px;
      content: attr(data-date);
      display: inline-block;
      /*pointer-events: none;*/
      /*color: black;*/
    }

    &::-webkit-date-and-time-value, &::-webkit-datetime-edit, &::-webkit-inner-spin-button, &::-webkit-clear-button {
      display: none;
    }

    &::-webkit-calendar-picker-indicator {
      position: absolute;
      top: 2px;
      right: 0;
      /*color: black;*/
      opacity: 1;
    }
  }

  .c-date-input-label {
    color: $blue-kbc-cement;
    flex: none;
    font-size: 1.3rem;
    margin-bottom: 0.8rem;
    width: 100%;
  }

  .c-date-input-content {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
  }

  .hidden {
    display: none !important;
  }
  &.inline-half-date {
    max-width: 50%;
  }
}
</style>
