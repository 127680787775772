import { v4 as uuid } from 'uuid'

class FavoriteItem {
  favoriteType = 'ticket'

  constructor ({ data, mobilityMode, favoriteType, source = 'olympus-mobile-app', uuid = null }) {
    this.data = data
    this.mobilityMode = mobilityMode
    this.source = source
    this.setFavoriteType(favoriteType)
    this.setUuid(uuid)
    this.saved = !!uuid
  }

  get types () {
    return ['ticket', 'poi']
  }

  static parseItem (favoriteItem) {
    const instance = new this(favoriteItem.data, favoriteItem.mobilityMode, favoriteItem.favoriteType)
    instance.setUuid(favoriteItem.uuid)
  }

  getRequestData () {
    return {
      ...this,
      data: this.data.toString(),
      saved: undefined
    }
  }

  getResourceOspId () {
    return this.data.getResourceOspId()
  }

  getForm () {
    return this.data.getForm()
  }

  getUsageReason () {
    return this.data.getUsageReason()
  }

  getRemark () {
    return this.data.getRemark()
  }

  setUuid (value = null) {
    this.uuid = value || uuid()
  }

  setRemark (remark) {
    this.data.setRemark(remark)
  }

  setUsageReason (reason) {
    this.data.setUsageReason(reason)
  }

  setFavoriteType (type) {
    if (this.types.includes(type)) {
      this.favoriteType = type
    }
  }

  isPoi () {
    return this.favoriteType === 'poi'
  }

  equalTo (favoriteItem = new FavoriteItem({})) {
    return favoriteItem.mobilityMode === this.mobilityMode &&
      favoriteItem.favoriteType === this.favoriteType &&
      favoriteItem.data.equalTo(this.data)
  }
}

export default FavoriteItem
