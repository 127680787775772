/**
 * Created by Miran on 13.11.2018.
 */
import Vue from 'vue'
import * as api from '../../api/'
import { get, has } from 'lodash'
import * as auth from '../../helpers/authorization'
import { CONVENIENT_INVOICING_RESOURCE } from '../../helpers/constants'
// eslint-disable-next-line
import {
  router,
  trans,
  compareByScore,
  flowNavigation,
  MAP_ZOOM,
  MOBILITY_MODE_ICONS,
  GEOLOCATION_DEFAULT, POI_RANGE, appLog, parseUserToken
} from '../../helpers'
import MobilityResource from '../../Classes/MobilityResource'
import FavoriteService from '../../Classes/favorite/FavoriteService'

export const user = {
  namespaced: true,
  state: {
    acceptedConditions: null,
    acceptedConditionsResponse: {},
    activeRoute: null,
    conditions: null,
    favorites: [],
    geolocationAct: null,
    geolocationMap: null,
    geolocationLoaded: false,
    isAccepted: null,
    lastRefresh: 0,
    mobilityMenu: [],
    profile: null,
    range: POI_RANGE,
    refreshToken: null,
    token: null,
    usePrevGeolocation: false,
    zoom: null
  },
  getters: {
    token: state => state.token,
    tokenObject: state => parseUserToken(state.token),
    tokenLanguage: state => get(parseUserToken(state.token), ['osp.user', 'data', 'language']),
    ospId: state => {
      return state.profile ? state.profile.data.corporateAccount.ospId : null
    },
    userOspId: state => get(state.profile, 'ospId', ''),
    profile: state => state.profile,
    hasRole: state => role => {
      return get(state.profile, 'data.roles', []).includes(role)
    },
    corporateAddress: state => get(state.profile, ['data', 'corporateAccount', 'address'], {}),
    fullHomeAddress: state => {
      const profile = get(state.profile, 'data', {})
      const city = get(profile, 'homeCity', '')
      // let country = get(profile, 'homeCountry', '')
      const street = get(profile, 'homeStreet', '')
      const streetNumber = get(profile, 'homeStreetNumber', '')
      const zip = get(profile, 'homeZipcode', '')
      if (city && street && streetNumber && zip) {
        return `${street} ${streetNumber}, ${zip} ${city}`
      }
      return ''
    },
    userEmail: state => {
      return get(state.profile, 'data.email', '')
    },
    authorized: state => {
      return Boolean(state.token && state.refreshToken)
    },
    acceptedConditions: state => {
      return Boolean(state.acceptedConditions)
    },
    zoom: state => {
      return state.zoom || MAP_ZOOM
    },
    favorites: state => state.favorites || [],
    geolocationAct: state => {
      if (state.geolocationAct && state.geolocationAct.longitude && state.geolocationAct.latitude) {
        return [state.geolocationAct.latitude, state.geolocationAct.longitude]
      } else {
        return null
      }
    },
    geolocationLoaded: state => !!state.geolocationLoaded,
    geolocationMap: state => {
      if (state.geolocationMap && state.geolocationMap.longitude && state.geolocationMap.latitude) {
        return [state.geolocationMap.latitude, state.geolocationMap.longitude]
      } else {
        return null
      }
    },
    mobilityModeMenuNative: state => {
      // if (!store.state.app.appVersion && store.state.app.clientId) {
      //   // to ignore Mobit if  appVersion not presented
      //   return state.mobilityMenu.filter(v => v.mobilityProvider.ospId !== 'Mobit')
      // }
      return state.mobilityMenu
    },
    mobilityModeMenu: state => {
      const menu = []
      const modes = []
      state.mobilityMenu.forEach(item => {
        const mode = item.mobilityMode
        if (modes.indexOf(mode) === -1 && item.authorized) {
          menu.push({
            label: trans(item.mobilityModeName),
            icon: MOBILITY_MODE_ICONS[mode] || null,
            link: `sales/${mode.toLowerCase()}`,
            score: item.mobilityModeScore
          })
          modes.push(mode)
        }
      })
      return menu.sort(compareByScore)
    },
    mobilityModeItem: state => resourceOspId => {
      return state.mobilityMenu.find(value => value && value.resourceOspId && value.resourceOspId === resourceOspId)
    },
    usePrevGeolocation: state => state.usePrevGeolocation
  },
  actions: {
    auth ({ commit, dispatch }, { username, passdigest }) {
      return auth.user(username, passdigest)
    },
    authHybrid ({ commit, state, dispatch }) {
      if (!state.token) {
        return dispatch('authRefresh')
      }
      return true
    },
    authRefresh ({ state, commit }) {
      if (!state.refreshToken) {
        return false
      }
      return api.userAuthRefresh(state.refreshToken, response => {
        if (response) {
          commit('setTokens', response)
          // commit('setLastRefresh', Math.round(new Date().getTime() / 1000))
          // this.dispatch('app/refreshAppKey')
        } else {
          router.push('/login')
        }
      })
    },
    authReseller ({ commit, rootState }) {
      if (!rootState.user.token &&
        rootState.app.requireSSO &&
        rootState.app.clientId &&
        rootState.app.authorizationCode) {
        return api.userAuthReseller({
          clientId: rootState.app.clientId,
          authorizationCode: rootState.app.authorizationCode
        }, response => {
          commit('setTokens', response)
        })
      }
    },
    getProfile ({ getters, commit }, cb = () => {
    }) {
      if (getters.authorized) {
        return api.userProfile(response => {
          commit('setProfile', response.user)
          cb()
        })
      } else {
        return false
      }
    },
    updateProfile ({ getters, commit }, data) {
      return api.updateUserProfile(data, response => {
        this.dispatch('user/getProfile')
      })
    },
    updateProfile2 ({ getters, commit, dispatch }, data) {
      return api.updateUserProfile2(data, response => {
        commit('setTokens', response)
        dispatch('getProfile')
      })
    },
    updateAddress ({ commit, state }, data) {
      // prepare address data
      const address = {}
      for (const key in data) {
        const keyHome = `home${key}`
        const keyWork = `work${key}`
        address[keyHome] = data[key]
        address[keyWork] = state.profile.data[keyWork] || null
      }
      return api.updateUserAddress(address, commit('setProfileAddress', address))
    },
    checkConditionsStatus ({ commit, rootGetters }) {
      const flow = rootGetters['app/findParam']('flow')
      const requestForm = {}
      if (flow && flow.toUpperCase() === 'DAKT') {
        requestForm.serviceResourceOspId = 'VAB.RESOURCE.SERVICE_RESOURCE.DAKT'
      }
      if (flow && ['RESELLERB2B', 'RESELLERB2BSETTINGS'].includes(flow.toUpperCase())) {
        requestForm.serviceResourceOspId = CONVENIENT_INVOICING_RESOURCE
      }
      return api.checkConditionsStatus(requestForm, response => {
        const accepted = (response.acceptedVersion !== undefined && response.acceptedVersion === response.effectiveVersion)
        commit('setAcceptedConditions', accepted)
        commit('setAcceptedConditionsResponse', response)
      })
    },
    getConditions ({ commit, rootGetters }) {
      const flow = rootGetters['app/findParam']('flow')
      const requestForm = {}
      if (flow && flow.toUpperCase() === 'DAKT') {
        requestForm.serviceResourceOspId = 'VAB.RESOURCE.SERVICE_RESOURCE.DAKT'
      }
      if (flow && ['RESELLERB2B', 'RESELLERB2BSETTINGS'].includes(flow.toUpperCase())) {
        requestForm.serviceResourceOspId = CONVENIENT_INVOICING_RESOURCE
      }
      return api.getConditions(requestForm, response => {
        commit('setConditions', response)
      })
    },
    acceptConditions ({ commit }, form) {
      return api.acceptConditions(form, response => {
        const accepted = (response.acceptedVersion !== undefined && response.acceptedVersion === response.effectiveVersion)
        // const accepted = false
        commit('setAcceptedConditions', accepted)
      }).then(() => {
        flowNavigation()
      })
    },
    getMobilityMenu ({ commit }) {
      return api.userMobilityMenu(response => {
        commit('setMobilityMenu', response)
      })
    },
    updateUserLanguage ({ commit }, language) {
      return api.updateUserLanguage(language, response => {
      })
        .then((response) => {
          commit('setTokens', response)
          commit('setProfileLanguage', language)
        })
    },
    checkUserGeolocationAct ({ commit, rootState }) {
      const isClient = !!rootState.app.clientId || !!rootState.app.hybridweb

      return new Promise(resolve => {
        let start = 0
        const delay = 100
        const end = isClient ? process.env.VUE_APP_AWAIT_TIMER * 1000 : 0

        // check token every second
        const timer = setInterval(() => {
          // appLog('checkUserGeolocationAct', 'setInterval')
          start += delay
          if (has(rootState.user, 'geolocationAct.longitude') && rootState.user.geolocationAct.longitude !== null) {
            clearInterval(timer)
            resolve(rootState.user.geolocationAct)
          }
          if (start > end) {
            clearInterval(timer)

            if (!isClient && navigator.geolocation) {
              navigator.geolocation.getCurrentPosition(position => {
                commit('setGeolocationAct', position.coords)
                resolve(rootState.user.geolocationAct)
              }, () => {
                commit('setGeolocationAct', {
                  longitude: GEOLOCATION_DEFAULT[0],
                  latitude: GEOLOCATION_DEFAULT[1]
                })
                resolve(rootState.user.geolocationAct)
              })
            } else {
              commit('setGeolocationAct', {
                longitude: GEOLOCATION_DEFAULT[0],
                latitude: GEOLOCATION_DEFAULT[1]
              })
              resolve(rootState.user.geolocationAct)
            }
          }
        }, delay)
      })
    },
    awaitTokenUpdate ({ state }) {
      const oldToken = state.token
      return new Promise((resolve, reject) => {
        let start = 0
        const delay = 100
        const end = process.env.VUE_APP_AWAIT_TIMER * 1000
        // check token every second
        const timer = setInterval(() => {
          appLog('awaitTokenUpdate', 'setInterval')
          start += delay
          if (state.token !== oldToken) {
            clearInterval(timer)
            resolve(state.token)
          }
          if (start > end) {
            clearInterval(timer)
            reject(new Error('Session expired, please try again.'))
          }
        }, delay)
      })
    },
    getFavorites ({ commit }) {
      return api.getFavorites(response => {
        commit('setFavorites', response.map(v => FavoriteService.getFavoriteItemFromResponse(v)))
      })
    }
  },
  mutations: {
    setTokens (state, data) {
      state.token = data.token
      if (data.refreshToken) {
        state.refreshToken = data.refreshToken
      }
    },
    setProfile (state, profile) {
      state.profile = profile
      if (profile.data && profile.data.language) {
        // localStorage.setItem('lang', profile.data.language)
      }
    },
    checkAccepted (state, accept) {
      state.isAccepted = accept
    },
    setAcceptedConditions (state, condition) {
      state.acceptedConditions = condition
    },
    setAcceptedConditionsResponse (state, response) {
      state.acceptedConditionsResponse = response
    },
    setConditions (state, conditions) {
      state.conditions = conditions.match(/<body[^>]*>([\w|\W]*)<\/body>/im)[0] || ''
    },
    setGeolocationAct (state, geolocation) {
      // longitude: GEOLOCATION_DEFAULT[0],
      //   latitude: GEOLOCATION_DEFAULT[1]
      Vue.set(state, 'geolocationLoaded', geolocation && geolocation.longitude && (geolocation.longitude !== GEOLOCATION_DEFAULT[0] || geolocation.latitude !== GEOLOCATION_DEFAULT[1]))

      Vue.set(state, 'geolocationAct', geolocation)
    },
    setGeolocationMap (state, geolocation) {
      state.geolocationMap = geolocation
    },
    setMobilityMenu (state, menu) {
      Vue.set(state, 'mobilityMenu', menu.map(v => new MobilityResource(v)))
    },
    setProfileAddress (state, address) {
      for (const key in address) {
        state.profile.data[key] = address[key]
      }
    },
    setProfileLanguage (state, language) {
      state.profile.data.language = language
      // localStorage.setItem('lang', language)
    },
    setProfileDiscounts (state, discounts) {
      state.profile.data.discounts = discounts
    },
    setZoom (state, zoom) {
      state.zoom = zoom
    },
    setRange (state, range) {
      state.range = (range && range >= 0.1) ? range : 0.1
    },
    setLastRefresh (state, time) {
      state.lastRefresh = time
    },
    toggleUsePrevGeolocation (state, usePrev) {
      state.usePrevGeolocation = usePrev
    },
    setFavorites (state, favorites) {
      Vue.set(state, 'favorites', favorites)
    }
  }
}
