<template>
  <div class="c-alert" :class="type" @click="onClick">
    <div v-html="message"/>
  </div>
</template>

<script>
export default {
  name: 'OAlert',
  props: {
    message: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  methods: {
    onClick () {
      this.$store.dispatch('alert/clear')
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
@import '~@/assets/styles/variables.scss';
.c-alert {
  background: rgba($primary-color, 0.1);
  background: var(--primary-color-light);
  color: $text-color;
  color: var(--text-color);
  font-size: 1.4rem;
  line-height: 2rem;
  padding: 0.8rem;
  border: 1px solid transparent;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1000;
}
.c-alert.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.c-alert.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
</style>
