// const data = {
//   'Authorized': true,
//   'Coordinate': { 'Latitude': 50.852499, 'Longitude': 4.353402 },
//   - 'MobilityMode': 'CAR',
//   'OspId': { 'OspId': 'Cambio.RESOURCE.GEO_RESOURCE.CARSHARE.cambio018' },
//   'MrpOspId': { 'OspId': 'Cambio' },
//   'Street': {
//     'DefaultValue': 'Bld Emile Jacqmain 53',
//     'LabelTranslations': [{ 'LanguageCode': 'fr', 'Text': 'Bld Emile Jacqmain 53' }]
//   },
//   'City': { 'DefaultValue': 'Bruxelles', 'LabelTranslations': [{ 'LanguageCode': 'fr', 'Text': 'Bruxelles' }] },
//   'Zipcode': '1000',
//   'PhoneNumber': null,
//   'Name': { 'DefaultValue': 'Brouckère', 'LabelTranslations': [{ 'LanguageCode': 'fr', 'Text': 'Brouckère' }] },
//   'InfoText': {
//     'DefaultValue': 'La station se trouve sur le Boulevard Jacqmain à hauteur du bâtiment AG Insurance.',
//     'LabelTranslations': [{
//       'LanguageCode': 'fr',
//       'Text': 'La station se trouve sur le Boulevard Jacqmain à hauteur du bâtiment AG Insurance.'
//     }]
//   },
//   'Properties': [],
//   'ServiceResources': []
// }

// appFlowType:"CARSHARE"
// authorized:true
// menuCategory:"TICKET"
// menuCategoryName:null
// mobilityMode:"CAR"
// mobilityModeData:Object
// html5Ready:false
// url:null
// mobilityModeName:Object
// mobilityModeScore:5
// mobilityProvider:Object
// data:Object
// ospId:"Cambio"
// mobilityProviderScore:2
// resourceInfoText:Object
// resourceName:Object
// resourceOspId:"Cambio.RESOURCE.SERVICE_RESOURCE.CAMBIO_CAR_SERVICE"
// serviceData:Object
// appFlowType:"CARSHARE"
// html5Ready:true
// requiresToken:false
// tokenScope:null
// tokenTransmissionStrategy:null
// url:"htt

// city:null
// displayName:"ANT Kievit - Station S1"
// fuelType:null
// id:"22-8457"
// mandator:Object
// displayName:"NN"
// id:"22"
// rangeRequired:false
// station:Object
// displayName:"ANT KIEVIT - STATION"
// id:"22-1707"
// vehicleClass:Object
// displayName:"S Mini"
// id:"22-469"

import Favorite from './Favorite'
import { pick } from 'lodash'

class CarshareFavorite extends Favorite {
  static createForMobilityMode (mobilityMode, travelData) {
    const data = {
      ...this.getDefaultDataForMobilityMode(mobilityMode),
      Authorized: true,
      Coordinate: { Latitude: travelData.geoposition.latitude, Longitude: travelData.geoposition.longitude },
      OspId: { ospId: mobilityMode.resourceOspId },
      MrpOspId: pick(mobilityMode.mobilityProvider, 'ospId'),
      Street: {
        DefaultValue: '',
        LabelTranslations: []
      },
      City: {
        DefaultValue: travelData.bookingObject.city,
        LabelTranslations: []
      },
      CityId: travelData.cityId,
      Station: travelData.bookingObject.station,
      Equipment: travelData.equipment,
      VehicleClass: travelData.bookingObject.vehicleClass,
      Zipcode: null,
      PhoneNumber: null,
      Name: {
        DefaultValue: travelData.bookingObject.displayName,
        LabelTranslations: []
      },
      InfoText: {
        DefaultValue: '',
        LabelTranslations: []
      },
      Properties: [],
      ServiceResources: []
    }
    return new this(data)
  }

  get keysForComparison () {
    return ['ResourceProviderId', 'Station.id', 'VehicleClass.id']
  }

  getForm () {
    return {
      cityId: this.CityId,
      stationId: this.Station.id,
      equipmentIds: this.Equipment,
      vehicleClassId: this.VehicleClass.id
    }
  }
}

export default CarshareFavorite
