/**
 * @module Information Season Tickets API
 */
import { Api, appEndPointV3 } from './api'
import { parseFloat } from '../helpers'

export const getSeasonTicket = (serviceResourceOspId, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPointV3}/sales/seasonTicket/get`, { serviceResourceOspId })
}

export const getPrequote = ({ budgetOspId, serviceResourceOspId }, cb) => {
  const api = new Api(cb, {
    excludeMessageAlert: ['*'],
    returnError: true
  })
  return api.query('post', `${appEndPointV3}/sales/seasonTicket/prequote`, { serviceResourceOspId, budgetOspId })
}

export const quotes = (form, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPointV3}/sales/seasonTicket/quote`, form)
}

export const confirm = (salesRequest, cb) => {
  if (!salesRequest) {
    return false
  }
  const request = {
    ...salesRequest,
    priceVat: parseFloat(salesRequest.priceVat),
    priceVatExcl: parseFloat(salesRequest.priceVatExcl),
    priceVatIncl: parseFloat(salesRequest.priceVatIncl)
  }
  if (salesRequest.costComposition) {
    request.costComposition = {
      ...salesRequest.costComposition
    }
    if (salesRequest.costComposition.fixedCost) {
      request.costComposition.fixedCost = {
        ...salesRequest.costComposition.fixedCost,
        costVatIncl: parseFloat(salesRequest.costComposition.fixedCost.costVatIncl),
        costVatExcl: parseFloat(salesRequest.costComposition.fixedCost.costVatExcl),
        costVat: parseFloat(salesRequest.costComposition.fixedCost.costVat),
        vatPercentage: parseFloat(salesRequest.costComposition.fixedCost.vatPercentage)
      }
      if (salesRequest.costComposition.variableCost) {
        request.costComposition.variableCost = {
          ...salesRequest.costComposition.variableCost,
          costVatIncl: parseFloat(salesRequest.costComposition.variableCost.costVatIncl),
          costVatExcl: parseFloat(salesRequest.costComposition.variableCost.costVatExcl),
          costVat: parseFloat(salesRequest.costComposition.variableCost.costVat),
          vatPercentage: parseFloat(salesRequest.costComposition.variableCost.vatPercentage)
        }
      }
    }
  }
  const api = new Api(cb, { returnError: true, excludeMessageAlert: ['DUPLICATE_BOOKING'] })
  return api.query('post', `${appEndPointV3}/sales/seasonTicket/confirm`, request)
}
