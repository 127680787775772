<template>
  <transition name="modal">
    <div class="modal-mask" @click="maskClicked">
      <div class="modal-wrapper">
        <div
          class="modal-container"
          :class="{
            'modal-container--flex': flex
          }"
        >
          <div
            class="modal-header"
            :class="{'modal-header--right-padding': closeBtn}"
          >
            <slot name="header">
            </slot>
            <o-icon
              v-if="closeBtn"
              class="modal-header__close"
              icon="close"
              @click.native="$emit('close')"
            />
          </div>
          <div class="modal-body">
            <slot name="body">
            </slot>
          </div>
          <div class="modal-footer">
            <slot name="footer">
              <o-button
                :label="closeBtnLabel"
                :is-primary="true"
                :is-floating="false"
                @click="$emit('close')"
              />
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'OModal',
  props: {
    maskClick: {
      type: Boolean,
      default: false
    },
    flex: {
      type: Boolean,
      default: false
    },
    closeBtnLabel: {
      type: String,
      default: 'OK'
    },
    closeBtn: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    maskClose () {
      if (this.maskClick) {
        this.$emit('close')
      }
    },
    maskClicked (e) {
      const classList = e.target.classList
      if (this.maskClick && (classList.contains('modal-wrapper') || classList.contains('modal-mask'))) {
        this.$emit('close')
      }
    }
  }
}

</script>
