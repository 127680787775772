/**
 * @module Mobib API
 */
import { Api, appEndPoint } from './api'

export const getMobibCards = cb => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/settings/identificationToken/findAllMobib`)
}

export const getMobibChooser = (serviceResourceOspId, cb) => {
  const api = new Api(cb, {
    excludeMessageAlert: [
      'NOT_FOUND'
      // 'MIVB_UNKNOWN_CARD_RESELLER',
      // 'MIVB_UNKNOWN_CARD'
    ],
    returnError: true
  })
  return api.query('post', `${appEndPoint}/sales/getMobibChooser`, { serviceResourceOspId })
}

export const getValidMobibCards = ({ serviceResourceOspId, userOspId }, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/mobib/getValid`, { serviceResourceOspId, userOspId })
}

export const getMobibCardCompatibility = (mrpOspId, card, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/mobib/${mrpOspId}/compatibility/get`, card)
}

export const addMobibCard = (card, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/settings/identificationToken/addMobib`, card)
}

export const updateMobibCard = (card, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/settings/identificationToken/updateMobib`, card)
}
