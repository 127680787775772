/**
 * @module Cambio API
 */

import { Api, appEndPoint, appEndPointV2 } from './api'

const API_PREFIX = `${appEndPoint}/cambio`
const API_PREFIX_V2 = `${appEndPointV2}/cambio`

export const getUserProfile = (budgetOspId, cb) => {
  const api = new Api(cb, { returnError: true, excludeMessageAlert: 'CAMBIO_HOME_ADDRESS_REQUIRED' })
  return api.query('post', `${API_PREFIX}/user/getProfile`, { budgetOspId })
}

export const updateCambioMobib = ({ budgetOspId, mobibToken }, cb) => {
  const api = new Api(cb, { returnError: true, excludeMessageAlert: 'CAMBIO_HOME_ADDRESS_REQUIRED' })
  return api.query('post', `${API_PREFIX}/user/mobib/update`, { budgetOspId, mobibToken })
}

export const getCities = (budgetOspId, cb) => {
  const api = new Api(cb)
  return api.query('post', `${API_PREFIX}/cities/get`, { budgetOspId })
}

export const getCityStations = ({ budgetOspId, cityId, mandatorId, bookingId = undefined }, cb) => {
  const api = new Api(cb)
  return api.query('post', `${API_PREFIX}/stations/get`, { budgetOspId, cityId, mandatorId, bookingId })
}

export const getVehicleClasses = ({ budgetOspId, mandatorId, bookingId = undefined }, cb) => {
  const api = new Api(cb)
  return api.query('post', `${API_PREFIX}/vehicleClasses/get`, { budgetOspId, mandatorId, bookingId })
}

export const getEquipment = ({ budgetOspId, mandatorId, bookingId = undefined }, cb) => {
  const api = new Api(cb)
  return api.query('post', `${API_PREFIX}/equipment/get`, { budgetOspId, mandatorId, bookingId })
}

export const getActivationParams = (data, cb) => {
  const api = new Api(cb)
  return api.query('post', `${API_PREFIX}/parameter/activation`, data)
}

export const validateDriverLicense = (startdate, cb) => {
  const api = new Api(cb, { returnError: true, excludeMessageAlert: ['*'] })
  return api.query('post', `${API_PREFIX}/driver/validate`, { licenceStartDate: startdate })
}

export const register = (mrpOspId, cb) => {
  const api = new Api(cb, { returnError: true })
  return api.query('post', `${API_PREFIX_V2}/user/register/${mrpOspId}`)
}

export const quotes = (form, cb) => {
  const api = new Api(cb)
  return api.query('post', `${API_PREFIX_V2}/user/activate/quote`, form)
}

export const confirm = (form, cb) => {
  const api = new Api(cb)
  return api.query('post', `${API_PREFIX_V2}/user/activate/confirm`, form)
}
export const getVideoUrl = (cb) => {
  const api = new Api(cb, { responseType: 'text' })
  return api.query('get', `${API_PREFIX}/getVideoUrl`)
}

export const getStation = ({ budgetOspId, stationId }, cb) => {
  const api = new Api(cb)
  return api.query('post', `${API_PREFIX}/station/getInfo`, { budgetOspId, stationId })
}

export const getReservations = (budgetOspId, cb) => {
  const api = new Api(cb)
  return api.query('post', `${API_PREFIX}/reservation/get`, { budgetOspId })
}

export const reservationRequest = (form, cb) => {
  const api = new Api(cb, { returnError: true, excludeMessageAlert: ['*'] })
  return api.query('post', `${API_PREFIX}/reservation/request`, form)
}

export const reservationConfirm = (form, cb) => {
  const api = new Api(cb)
  return api.query('post', `${API_PREFIX}/reservation/confirm`, form)
}

export const changeRequest = (form, cb) => {
  const api = new Api(cb, { returnError: true, excludeMessageAlert: ['*'] })
  return api.query('post', `${API_PREFIX}/reservation/change/request`, form)
}

export const changeActiveRequest = (form, cb) => {
  const api = new Api(cb, { returnError: true, excludeMessageAlert: ['*'] })
  return api.query('post', `${API_PREFIX}/reservation/change/request`, form)
}

export const changeConfirm = (form, cb) => {
  const api = new Api(cb, { returnError: true, excludeMessageAlert: ['*'] })
  return api.query('post', `${API_PREFIX}/reservation/change/confirm`, form)
}

export const cancelRequest = (form, cb) => {
  const api = new Api(cb, { returnError: true, excludeMessageAlert: ['*'] })
  return api.query('post', `${API_PREFIX}/reservation/cancel/request`, form)
}

export const cancelConfirm = (form, cb) => {
  const api = new Api(cb)
  return api.query('post', `${API_PREFIX}/reservation/cancel/confirm`, form)
}

export const calculateCost = (form, cb) => {
  const api = new Api(cb, { showError: false })
  return api.query('post', `${API_PREFIX}/calculateCost`, form)
}

export const reservationStatus = ({ budgetOspId, reservationId }, cb) => {
  const api = new Api(cb)
  return api.query('post', `${API_PREFIX}/session/status`, { budgetOspId, reservationId })
}

export const refreshStatus = ({ budgetOspId, reservationId }, cb) => {
  const api = new Api(cb, { loader: false })
  return api.query('post', `${API_PREFIX}/session/status`, { budgetOspId, reservationId })
}

export const sessionStart = ({ budgetOspId, bookingObjectId }, cb) => {
  const api = new Api(cb, { loader: false, timeout: 30000, returnError: true, excludeMessageAlert: ['*'] })
  return api.query('post', `${API_PREFIX}/session/start`, { budgetOspId, bookingObjectId })
}

export const sessionEnd = ({ budgetOspId, bookingObjectId }, cb) => {
  const api = new Api(cb, { loader: false, timeout: 30000, returnError: true, excludeMessageAlert: ['*'] })
  return api.query('post', `${API_PREFIX}/session/end`, { budgetOspId, bookingObjectId })
}

export const getPincode = (budgetOspId, cb) => {
  const api = new Api(cb, { responseType: 'text' })
  return api.query('get', `${API_PREFIX}/pincode/get/${budgetOspId}`)
}

export const getCarsInfo = ({ budgetOspId, vehicleClassId }, cb) => {
  const api = new Api(cb, { responseType: 'text' })
  return api.query('post', `${API_PREFIX}/vehicleClass/info/get`, { budgetOspId, vehicleClassId })
}

export const getCurrentCarInfo = ({ budgetOspId, carTypeId }, cb) => {
  const api = new Api(cb, { responseType: 'text' })
  return api.query('post', `${API_PREFIX}/vehicleType/info/get`, { budgetOspId, carTypeId })
}

export const updateInsurance = ({ cambioBookingId, extraInsurance }, cb) => {
  const api = new Api(cb)
  return api.query('post', `${API_PREFIX}/reservation/insurance/update`, { cambioBookingId, extraInsurance })
}

export const getNotification = (budgetOspId, cb) => {
  const api = new Api(cb, { responseType: 'text', returnError: true, excludeMessageAlert: ['*'] })
  // return api.fake(
  //   [{
  //     'code': '10-11-0000',
  //     'displayTitle': 'confirmation required',
  //     'origin': 'BUZE',
  //     'type': 'INFORMATION',
  //     'confirmType': 'CONFIRM_LATER',
  //     'displayText': 'this is test 1 message',
  //     'id': 'NB-903716',
  //     'phoneNumber': null,
  //     'validation': false
  //   }, {
  //     'code': '10-11-0000',
  //     'displayTitle': 'confirmation required',
  //     'origin': 'BUZE',
  //     'type': 'INFORMATION',
  //     'confirmType': 'CONFIRM_LATER',
  //     'displayText': 'this is test 2 message',
  //     'id': 'NB-903717',
  //     'phoneNumber': null,
  //     'validation': false
  //   }]
  // )
  return api.query('post', `${API_PREFIX}/notifications/get`, { budgetOspId })
}

export const confirmNotification = ({ budgetOspId, notificationId }, cb) => {
  const api = new Api(cb, { responseType: 'text', returnError: true, excludeMessageAlert: ['*'] })
  return api.query('post', `${API_PREFIX}/notifications/confirm`, { budgetOspId, notificationId })
}

export const getPricingInfo = cb => {
  const api = new Api(cb, { excludeMessageAlert: ['*'] })
  return api.query('get', `${API_PREFIX}/pricingInfo `)
}
