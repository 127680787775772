/**
 * @module PARKING API
 */
import { Api, appEndPoint, appEndPointV3 } from './api'

export const prequote = (serviceResourceOspId, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPointV3}/sales/plugin/prequote`, { serviceResourceOspId })
}

export const activate = (serviceResourceOspId, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPointV3}/sales/plugin/activate`, { serviceResourceOspId })
}

export const confirm = (serviceResourceOspId, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPointV3}/sales/plugin/confirm`, { serviceResourceOspId })
}

export const accept = (form, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/plugin/purchase/accept`, form)
}

export const decline = (purchaseUuid, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/plugin/purchase/decline`, { purchaseUuid })
}
