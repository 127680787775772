<template>
  <div
    class="c-view"
    :class="{
      'c-view--white': hasWhiteBackground,
      'c-view--no-scroll': noScroll,
      'c-view--bottom-padding': bottomPadding,
      'c-view--double-bottom-padding': doubleBottomPadding,
      'c-view--flexible': isFlex,
      'c-view--relative': isRelative,
      'c-view--horizontal-scroll': horizontalScroll,
    }"
    :style="style"
  >
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'OView',
  data () {
    return {
      windowHeight: 0,
      style: {
        height: '100%'
      }
    }
  },
  props: {
    hasWhiteBackground: {
      type: Boolean,
      default: false
    },
    noScroll: {
      type: Boolean,
      default: false
    },
    bottomPadding: {
      type: Boolean,
      default: false
    },
    doubleBottomPadding: {
      type: Boolean,
      default: false
    },
    isFlex: {
      type: Boolean,
      default: false
    },
    isRelative: {
      type: Boolean,
      default: false
    },
    horizontalScroll: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    windowHeight (v) {
      this.style.height = v && this.noScroll ? v + 'px' : '100%'
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.windowHeight = window.innerHeight
      })
    })
  },
  beforeDestroy () {
    window.removeEventListener('resize', () => {
      this.windowHeight = window.innerHeight
    })
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.c-view {
  bottom: 0;
  height: 100%;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  /*overflow-y: scroll;+*/
  position: absolute;
  right: 0;
  top: 0;
  .footer-placeholder {
    display: block;
    height: 0;
  }
}

.c-view--white {
  background: #fff;
}

.c-view--no-scroll {
  overflow: hidden;
  max-height: 100%;
}

.c-view--bottom-padding {
  /*padding-bottom: 6.8rem;*/
  &:after {
    content:'';
    display:block;
    padding-bottom: 9rem;
  }
}
.c-view--double-bottom-padding {
  /*padding-bottom: 6.8rem;*/
  &:after {
    content:'';
    display:block;
    padding-bottom: 14rem;
  }
}
.c-view--flexible {
  display: flex;
  flex-flow: column;
  height: 100%;
}
.c-view--relative {
  position: relative;
}
.c-view--horizontal-scroll {
  overflow-x: auto;
}
</style>
