import { DATE_FORMAT, trans } from '../../helpers'
import moment from 'moment'

export default class SeasonTicket {
  constructor (ticket) {
    this.origin = ticket
  }

  get budget () {
    return this.origin.budgetName
  }

  get budgetOspId () {
    return this.origin.budgetOspId
  }

  get isVelo () {
    return this.origin.serviceResourceOspId.indexOf('Velo') > -1
  }

  get validFrom () {
    if (this.origin.validFrom) {
      return moment(this.origin.validFrom).format(DATE_FORMAT)
    } else if (this.isVelo) {
      return this.validFromText
    }
  }

  get validFromText () {
    return trans(this.origin.validFromText || '')
  }

  get validTo () {
    if (this.origin.validTo) {
      return moment(this.origin.validTo).format(DATE_FORMAT)
    } else if (this.isVelo) {
      return this.validToText
    }
  }

  get nextValidTo () {
    if (this.origin.validTo) {
      return moment(this.origin.validTo).add(1, 'year').format(DATE_FORMAT)
    }
    return ''
  }

  get renewUntil () {
    if (this.origin.validTo) {
      return moment(this.origin.validTo).subtract(14, 'days').format(DATE_FORMAT)
    }
    return ''
  }

  get validToText () {
    return trans(this.origin.validToText || '')
  }

  get showVeloActivation () {
    return this.isVelo && this.origin.validFrom === null
  }

  get renewable () {
    return this.origin.renewable
  }

  get renewButtonLabel () {
    return trans(this.origin.renewButtonLabel)
  }

  get activateButtonLabel () {
    return trans(this.origin.activateButtonLabel)
  }

  get newButtonLabel () {
    return trans(this.origin.newButtonLabel)
  }

  get infoTextLabel () {
    return trans(this.origin.infoTextLabel)
  }

  get productNameLabel () {
    return trans(this.origin.productName)
  }
}
