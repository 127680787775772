<template>
  <label class="c-checkbox" :class="{'branded-radio': branded}">
    <input type="radio"
           :name="name"
           :value="value"
           :checked="shouldBeChecked"
           @change="$emit('change', value)"
           :disabled="readOnly"
           :readonly="readOnly"
           ref="radio"/>
    <div class="c-checkbox__text">
      <slot/>
    </div>
  </label>
</template>

<script>
// @group Base Components
export default {
  name: 'ORadio',
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    name: String,
    value: {
      default: ''
    },
    modelValue: {
      default: ''
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    branded: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    shouldBeChecked () {
      return this.modelValue === this.value
    }
  }
}
</script>

<style lang="scss">
.c-checkbox {
  align-items: flex-start;
  display: flex;
  margin: 0.8rem 0;

  input[type='radio'] {
    flex: none;
    height: 2.4rem;
    margin: 0 0.8rem 0 0;
    width: 2.4rem;
  }
}

.c-checkbox__text {
  line-height: 2.4rem;
}
</style>
