<template>
  <o-confirm-modal
    class="revoke-confirmation"
    v-if="!!showRevokeConfirmation"
    :label-yes="$t('html5.revoke.linkedaccount.dialog.okbutton')"
    :label-no="$t('html5.revoke.linkedaccount.dialog.cancelbutton')"
    @confirm="confirm"
    @close="close"
  >
    <p slot="body" v-html="bodyContent"></p>
  </o-confirm-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import OConfirmModal from '../utils/OConfirmModal'
import postMessage from '../../Classes/postMessageService/postMessage'

export default {
  name: 'ConfirmRevokeModal',
  components: {
    OConfirmModal
  },
  computed: {
    ...mapGetters({
      showRevokeConfirmation: 'streetParking/showRevokeConfirmation'
    }),
    bodyContent () {
      return this.$t(`html5.revoke.linkedaccount.dialog.content.revoke${this.showRevokeConfirmation.toLowerCase()}`)
    }
  },
  methods: {
    ...mapActions({
      confirmRevoke: 'streetParking/confirmRevoke'
    }),
    confirm () {
      if (this.showRevokeConfirmation === '4411') {
        return this.$api.oauth.revoke('BeMobile', this.handleConfirmResponse)
      }
      if (this.showRevokeConfirmation === 'QPark') {
        return this.$api.parking.userParkingRevoke('QPark', this.handleConfirmResponse)
      }
    },
    handleConfirmResponse (response) {
      if (response && response.error) {
        return postMessage.navigateTo('NOK')
      }
      postMessage.navigateTo()
      this.close()
      return postMessage.close(true)
    },
    close () {
      this.confirmRevoke()
    }
  }
}
</script>

<style lang="scss">
.revoke-confirmation {
.modal-container_confirmation--button-no {
  background: var(--primary-color-overlay);
  color: white;
}
}
</style>
