// eslint-disable-next-line
import * as api from '../../api/'
import Vue from 'vue'
import { appLog, FUEL_FLOW, trans } from '../../helpers'
import _, { lowerFirst } from 'lodash'

const FUEL_DEFAULT_RANGE = 0.2

export const fuel = {
  namespaced: true,
  state: {
    userProfile: {},
    form: {
      email: '',
      barcode: '',
      commercial: false
    },
    hasAccount: null,
    attempts: {},
    config: {},
    stations: {},
    loyaltyBalance: {},
    lastSessions: {},
    registerForm: {

    }
  },
  getters: {
    userProfile: state => mrpOspId => state.userProfile[mrpOspId] || null,
    blocked: state => mrpOspId => state.attempts[mrpOspId] > 2,
    form: state => state.form,
    attempts: state => state.attempts,
    config: state => mrpOspId => state.config[mrpOspId] || null,
    stations: state => mrpOspId => state.stations[mrpOspId] || null,
    station: state => (mrpOspId, ospId) => state.stations[mrpOspId] && state.stations[mrpOspId].find(v => _.has(v, 'ospId.ospId') && v.ospId.ospId === ospId),
    loyaltyBalance: state => mrpOspId => {
      const balance = state.loyaltyBalance[mrpOspId] || {}
      let loyaltyProgramName = ''
      if (_.has(balance, 'loyaltyProgramName')) {
        loyaltyProgramName = trans(balance.loyaltyProgramName)
      }
      return {
        ...balance,
        loyaltyProgramName
      }
    },
    lastSession: state => resourceOspId => state.lastSessions[resourceOspId] || null,
    hasAccount: state => state.hasAccount
  },
  actions: {
    getUserProfile ({ commit }, mrpOspId) {
      return api.getUserProfile(mrpOspId, response => {
        commit('setUserProfile', { mrpOspId, response })
      })
    },
    getLastSession ({ commit }, resourceOspId) {
      return api.userLastSession(resourceOspId, response => {
        commit('setLastSession', { resourceOspId, lastSession: response })
      })
    },
    getLoyaltyBalance ({ commit }, mrpOspId) {
      return api.loyaltyBalance(mrpOspId, response => {
        commit('setLoyaltyBalance', { mrpOspId, response })
      })
    },
    createAccount ({ commit }, { mrpOspId, email }) {
      return api.createAccount({ mrpOspId, externalAccountLogin: email }, () => {
      })
    },
    addAccount ({ commit }, { mrpOspId, email, barcode }) {
      return api.accountLink({ mrpOspId, externalAccountLogin: email, externalAccountCode: barcode }, () => {
      })
    },
    updateForm ({ commit }, { email, barcode, commercial }) {
      commit('setForm', { email, barcode, commercial })
    },
    resetForm ({ commit }) {
      commit('setForm', { email: '', barcode: '', commercial: false })
    },
    incrementAttempts ({ commit, state }, mrpOspId) {
      let attempts = state.attempts[mrpOspId] || 0
      commit('setAttempts', { mrpOspId, value: ++attempts })
    },
    resetAttempts ({ commit }, mrpOspId) {
      commit('setAttempts', { mrpOspId, value: 0 })
    },
    async getFuelStations ({ commit, state, rootState, dispatch }, mrpOspId) {
      const range = _.has(state.config, `${mrpOspId}.poiQualificationRange`)
        ? state.config[mrpOspId].poiQualificationRange / 1000
        : FUEL_DEFAULT_RANGE
      try {
        const { latitude, longitude } = rootState.user.geolocationAct
        const filter = {
          range,
          zoom: 7,
          mobilityMode: FUEL_FLOW,
          poiOwner: {
            ospId: mrpOspId
          },
          source: {
            latitude,
            longitude
          },
          pagination: {
            page: 0,
            size: 100
          },
          userLocation: { latitude, longitude }
        }

        const response = await api.getFuelStations(filter, () => {
        })
        commit('setStations', { mrpOspId, stations: response.pois })
        return false
      } catch (e) {
        appLog(e)
        return false
      }
    },
    getConfig ({ commit }, mrpOspId) {
      return api.getConfig(mrpOspId, (response) => {
        commit('setConfig', { mrpOspId, config: response })
      })
    }
  },
  mutations: {
    setAttempts (state, { mrpOspId, value }) {
      Vue.set(state.attempts, mrpOspId, value)
    },
    setConfig (state, { mrpOspId, config }) {
      Vue.set(state.config, mrpOspId, config)
    },
    setUserProfile (state, { mrpOspId, response }) {
      if (!response.error) {
        Vue.set(state.userProfile, mrpOspId, response)
      } else {
        Vue.set(state.userProfile, mrpOspId, null)
      }
    },
    setLoyaltyBalance (state, { mrpOspId, response }) {
      Vue.set(state.loyaltyBalance, mrpOspId, response)
    },
    setLastSession (state, { resourceOspId, lastSession }) {
      Vue.set(state.lastSessions, resourceOspId, lastSession)
    },
    setForm (state, { email, barcode, commercial }) {
      state.form.email = email
      state.form.barcode = barcode
      state.form.commercial = commercial
    },
    setStations (state, { mrpOspId, stations }) {
      appLog(stations, 'stations')
      Vue.set(state.stations, mrpOspId, stations)
    },
    setHasAccount (state, value) {
      state.hasAccount = value
    }
  }
}
