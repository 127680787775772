<template>
  <div ref="vueselect">
    <div  :class="classes.wrapper">
      <o-icon :icon="icon" v-if="icon" />
      <div class="relative inline-block w-full">
        <span
          class="cursor-pointer"
          :class="[classes.input]"
          @click="toggleDropdown">
          {{getOptionDescription(selectedOption)}}
        </span>
        <input :id="inputId"
               :disabled="disabled"
               class="cursor-pointer"
               :class="[classes.input]"
               readonly="readonly"
               ref="match"
               :required="required"
               type="hidden"
               :value="getOptionDescription(selectedOption)"
               @click="toggleDropdown"
        >
        <input type="hidden" :name="name"  ref="selectedValue" :value="getOptionValue(selectedOption)">

        <div @click="toggleDropdown"  v-if="!disabled" :class="[classes.icons]" class="cursor-pointer absolute flex items-center">
          <svg v-if="!dropdownOpen" aria-hidden="true" viewBox="0 0 448 512">
            <path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
          </svg>
          <svg v-else aria-hidden="true" viewBox="0 0 448 512">
            <path d="M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z"></path>
          </svg>
        </div>

        <ul tabindex="-1" ref="options" v-if="matchingOptions && dropdownOpen"
            :style="{'max-height': maxHeight}" style="z-index: 100; "
            :class=[classes.dropdown]
            class="absolute w-full overflow-auto appearance-none mt-px list-reset"
        >
          <li v-for="(option) in matchingOptions" :key="getOptionValue(option)"
              class="cursor-pointer outline-none"
              @click.prevent="setOption(option)"
          >
            <slot name="option" v-bind="{option}">
              {{ getOptionDescription(option) }}
            </slot>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'OVueSelect',
  props: {
    value: {
      required: true
    },
    icon: {
      type: String,
      required: false,
      default: () => ''
    },
    name: {
      type: String,
      required: false,
      default: () => ''
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    options: {
      type: Array,
      required: false,
      default: () => []
    },
    optionLabel: {
      type: String,
      required: false,
      default: () => null
    },
    optionKey: {
      type: String,
      required: false,
      default: () => null
    },
    placeholder: {
      type: String,
      required: false,
      default: () => 'Search Here'
    },
    maxHeight: {
      type: String,
      default: () => '220px',
      required: false
    },
    inputId: {
      type: String,
      default: () => 'single-select',
      required: false
    },
    classes: {
      type: Object,
      required: false,
      default: () => {
        return {
          pointer: -1,
          wrapper: 'single-select-wrapper',
          input: 'search-input',
          icons: 'icons',
          required: 'required',
          activeClass: 'active',
          dropdown: 'dropdown'
        }
      }
    },
    initial: {
      type: String,
      required: false,
      default: () => null
    },
    required: {
      type: Boolean,
      required: false,
      default: () => false
    },
    maxResults: {
      type: Number,
      required: false,
      default: () => 30
    },
    tabindex: {
      type: String,
      required: false,
      default: () => {
        return ''
      }
    },
    getOptionDescription: {
      type: Function,
      default: function (option) {
        if (this.optionKey && this.optionLabel) {
          return option[this.optionKey] + ' ' + option[this.optionLabel]
        }
        if (this.optionLabel) {
          return option[this.optionLabel]
        }
        if (this.optionKey) {
          return option[this.optionKey]
        }
        return option
      }
    },
    getOptionValue: {
      type: Function,
      default: function (option) {
        if (this.optionKey) {
          return option[this.optionKey]
        }

        if (this.optionLabel) {
          return option[this.optionLabel]
        }

        return option
      }
    },
    filterBy: {
      type: Function,
      default: function (option) {
        return true
      }
    }
  },
  mounted () {
    if (this.value && this.options.includes(this.value)) {
      this.selectedOption = this.value
    }
  },
  data () {
    return {
      selectedOption: null,
      dropdownOpen: false
    }
  },
  watch: {
    value (curr, prev) {
      this.selectedOption = curr
    },

    selectedOption (curr, prev) {
      this.$emit('input', curr)
    }
  },
  computed: {
    isRequired () {
      if (!this.required) {
        return ''
      }

      if (this.selectedOption) {
        return ''
      }

      return 'required'
    },
    matchingOptions () {
      return this.options.slice(0, this.maxResults)
    },
    plainOptions () {
      return this.options.map(v => this.getOptionValue(v))
    }
  },
  methods: {
    toggleDropdown () {
      this.dropdownOpen = !this.dropdownOpen
    },
    setOption (option) {
      if (!this.matchingOptions || !this.matchingOptions.length) {
        return
      }
      this.selectedOption = option
      this.dropdownOpen = false
    }
  }
}
</script>

<style scoped>
  .w-full {
    width: 100%;
  }
  .w-full.inline-block{
    max-width: calc(100% - 3rem);
  }
  .inline-block {
    display: inline-block;
  }
  .block {
    display: block;
  }
  .flex {
    display: flex;
  }
  .border {
    border-width: thin;
    border-style: solid;
  }
  .rounded {
    border-radius: 0.25em;
  }
  .text-black {
    color: #22292f;
  }
  .border-grey-lighter {
    border-color: #ced4da;
  }
  .bg-grey-lighter {
    background-color: #606f7b;
  }
  .bg-grey-light {
    background-color: #dae1e7;
  }
  .bg-grey-dark {
    background-color: #8795a1;
  }
  .bg-white {
    background-color: #fff;
  }
  .pin-r {
    right: 0;
  }
  .pin-y {
    top: 0;
    bottom: 0;
  }
  .absolute {
    position: absolute;
  }
  .relative {
    position: relative;
  }
  .items-center {
    align-items: center;
  }
  .p-0 {
    padding: 0;
  }
  .p-1 {
    padding: 0.25em;
  }
  .px-1 {
    padding-left: 0.25em;
    padding-right: 0.25em;
  }
  .py-2 {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
  .px-2 {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
  .mt-px {
    margin-top: 1px;
  }
  .leading-tight {
    line-height: 1.25;
  }
  .leading-normal {
    line-height: 1.5;
  }
  .text-left {
    text-align: left;
  }
  .w-full {
    width: 100%;
  }
  .shadow {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  }
  .list-reset {
    list-style: none;
    padding: 0;
  }
  .overflow-auto {
    overflow: auto;
  }
  .appearance-none {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  .w-1 {
    width: 0.25em;
  }
  .w-2 {
    width: 0.5em;
  }
  .w-3 {
    width: 0.75em;
  }
  .w-4 {
    width: 1em;
  }
  .h-4 {
    height: 1em;
  }
  .h-1 {
    height: 0.25em;
  }
  .h-2 {
    height: 0.5em;
  }
  .h-3 {
    height: 0.75em;
  }
  .fill-current {
    fill: currentColor;
  }
  .no-underline {
    text-decoration: none;
  }
  .hover\:no-underline:hover {
    text-decoration: none;
  }
  .outline-none {
    outline: 0;
  }
  .hover\:outline-none {
    outline: 0;
  }
  .hover\:bg-grey-light:hover {
    background-color: #dae1e7;
  }
  .shadow-md {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  }
  .search-input {
    display: block;
    width: 100%;
    padding: 0.375em 0.75em;
    font-size: 1em;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25em;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 3.2rem;
  }
  .icons {
    padding: 0 1em;
    right: 0;
    top: 0;
    bottom: 0;
    fill: #606f7b;
  }
  .icons svg {
    width: 0.75em;
    height: 0.75em;
  }
  .single-select-wrapper {
    display: flex;
    position: relative;
    margin-bottom: 0.5em;
  }

  #single-select {
    padding-right: 35px;
  }
  .required {
    _color: #721c24;
    _background-color: #f8d7da;
    border-color: #f5c6cb;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .dropdown {
    -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
    background-color: #fff;
    color: #606f7b;
    border-radius: 0.25em;
    line-height: 1.25;
    text-align: left;
  }
  .dropdown > li {
    padding: 0.5em 0.75em;
  }

  .dropdown > li:hover, .active {
    background: #dae1e7;
  }
</style>

<style lang="scss" rel="stylesheet/scss" scoped>
@import '~@/assets/styles/variables.scss';
  .single-select-wrapper .c-icon{
    margin: 5px 5px 0 0;
    color: $primary-color;
  }
</style>
