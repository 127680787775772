<template>
  <div class="c-tabswitch">
    <div
      class="c-tabswitch__item"
      v-for="(item, key) in items"
      :key="key"
    >
    <template v-if="multiselect">
      <input
        type="checkbox"
        class="c-tabswitch__radio"
        :name="key"
        :id="`train-${name}-${key}`"
        :checked="inputVal.includes(key)"
        :value="key"
        @change="checkboxChanged"
        :disabled="disabled.indexOf(key) !== -1"
      >
      <label
        :for="`train-${name}-${key}`"
        class="c-tabswitch__button"
        :class="{
          'u-muted': disabled.indexOf(key) !== -1
        }"
        v-html="item"
      />
    </template>
    <template v-else>
      <input
        type="radio"
        class="c-tabswitch__radio"
        :name="name"
        :id="`train-${name}-${key}`"
        :checked="key == checked"
        :value="key"
        :disabled="disabled.indexOf(key) !== -1"
        v-model="inputVal"
      >
      <label
        :for="`train-${name}-${key}`"
        class="c-tabswitch__button"
        v-html="item"
      />
    </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    checked: {
      type: [String, Array],
      default: null
    },
    disabled: {
      type: Array,
      default: function () {
        return []
      }
    },
    value: {
      type: [String, Array],
      default: ''
    },
    multiselect: {
      type: Boolean,
      default: false
    },
    emitOnCheck: {
      type: Function,
      default: null
    }
  },
  data () {
    return {
      inputVal: this.value
    }
  },
  watch: {
    inputVal (val) {
      this.$emit('input', val)
    },
    value (v) {
      this.inputVal = v || []
    }
  },
  methods: {
    checkboxChanged (event) {
      if (this.emitOnCheck) {
        return this.emitOnCheck(event.target.name, event.target.checked)
      }
      if (!this.inputVal) {
        this.inputVal = []
      }
      if (event.target.checked) {
        this.inputVal.push(event.target.name)
      } else {
        this.inputVal = (this.inputVal || []).filter(v => v !== event.target.name)
      }
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
@import '~@/assets/styles/variables.scss';

.c-tabswitch {
  display: flex;
  & + & {
    margin-top: 1.6rem;
  }
}

.c-tabswitch__item {
  flex: 1 1 auto;
  width: 50%;
}

.c-tabswitch__radio {
  display: none;
}

.c-tabswitch__button {
  border: 0.1rem solid $gray-100;
  border-left-width: 0;
  display: block;
  padding: 0.8rem;
  position: relative;
  text-align: center;

  .c-tabswitch__radio:checked + & {
    background: rgba($primary-color, 0.1);
    background: var(--primary-color-light);
    border-color: $primary-color;
    border-color:  var(--primary-color-overlay);
    border-left-width: 0.1rem;
    border-left-style: solid;
    color: $primary-color;
    color:  var(--primary-color-overlay);
  }
}

.c-tabswitch__item:not(:last-child)
  .c-tabswitch__radio:checked
  + .c-tabswitch__button {
  margin-right: -0.1rem;
  //z-index: 1;
}

.c-tabswitch__item:not(:first-child)
  .c-tabswitch__radio:checked
  + .c-tabswitch__button {
  margin-left: -0.1rem;
  //z-index: 1;
}

.c-tabswitch__item:first-child .c-tabswitch__button {
  border-radius: $border-radius-small 0 0 $border-radius-small;
  border-left-width: 0.1rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.c-tabswitch__item:last-child .c-tabswitch__button {
  border-radius: 0 $border-radius-small $border-radius-small 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.train-direction-homeWork, .train-direction-workHome {
  white-space: nowrap;
}
</style>
