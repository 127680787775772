<template>
  <h1
    class="c-title"
    :class="{
      'c-title--2': level === 2,
      'c-title--3': level === 3
    }"
  >
    <slot/>
  </h1>
</template>

<script>
export default {
  name: 'Title',
  props: {
    level: {
      type: Number,
      default: 1
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.c-title {
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 2.4rem;
  margin-bottom: 2.4rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.c-title--2 {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1.6rem;
}
</style>
