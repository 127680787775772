<template>
  <div
    class="c-base-textinput-wrapper"
    :class="{
      'c-base-textinput-wrapper--time': type === 'time',
      'c-base-textinput-wrapper--static': custom,
      'c-base-textinput-wrapper--primary': primary,
      'c-base-textinput-wrapper--nowrap': !label,
      'c-base-textinput-wrapper--readonly': readonly,
    }"
  >
    <label v-if="label" class="c-base-textinput-label" :for="uniqueId">{{
      label
      }}</label>
    <slot>
      <o-icon :icon="icon" v-if="icon"/>
      <div class="c-base-textinput-container">
      <input
        ref="input"
        :id="uniqueId"
        :type="type"
        class="c-base-textinput"
        v-model="storedValue"
        :placeholder="placeholder"
        :readonly="readonly"
        :autofocus="autofocus"
        :inputmode="inputmode"
        :step="step"
        @focus="hasFocus = true"
        @blur="deFocus"
      />
      </div>
      <o-icon
        class="c-base-textinput__chevron"
        icon="chevron-right"
        v-if="chevron"
      />
      <div
        class="c-base-textinput-autocomplete"
        v-if="autocompleteItems && hasFocus && storedValue.length > 3"
      >
        <a
          href="#"
          class="c-base-textinput-autocomplete__item"
          v-for="item in autocompleteItems"
          :key="item"
          @click.prevent="storedValue = item"
        >{{ item }}</a>
      </div>
    </slot>
  </div>
</template>

<script>
// @group Base Components
export default {
  name: 'OBaseTextInput',
  props: {
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: ''
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: null
    },
    readonly: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    },
    inputmode: {
      type: String,
      default: 'text'
    },
    autocompleteItems: {
      type: Array,
      default: null
    },
    custom: {
      type: Boolean,
      default: false
    },
    chevron: {
      type: Boolean,
      default: false
    },
    primary: {
      type: Boolean,
      default: false
    },
    step: {
      type: [Number, String],
      default: 1
    }
  },
  data () {
    return {
      uniqueId: '',
      storedValue: '',
      hasFocus: false
    }
  },
  methods: {
    generateUniqueId () {
      return (
        '_' +
        Math.random()
          .toString(36)
          .substr(2, 9)
      )
    },
    deFocus () {
      setTimeout(() => {
        this.hasFocus = false
      }, 150)
    }
  },

  created () {
    this.uniqueId = this.generateUniqueId()
    this.storedValue = this.value
  },

  watch: {
    value (newValue) {
      this.storedValue = newValue
    },
    storedValue (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="scss">
@import '~@/assets/styles/variables.scss';
.c-base-textinput-wrapper {
  align-items: center;
  display: flex;
  flex-wrap: wrap;

  .c-icon:not(.c-base-textinput__chevron):not(.c-base-textinput__info):not(.c-checkbox__checkmark--icon) {
    color: $primary-color;
    color: var(--primary-color);
    flex: none;
    margin-right: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.5rem;
  }
}

.c-base-textinput-wrapper--nowrap {
  flex-wrap: nowrap;
}

.c-base-textinput-wrapper--primary {
  color: $primary-color;
  color: var(--primary-color);

  .c-base-textinput-label {
    color: $primary-color;
    color: var(--primary-color);
  }
}

.c-base-textinput-wrapper--readonly {
  input {
    pointer-events: none;
  }
}

.c-base-textinput__chevron {
  flex: none;
  margin: 0;

  svg * {
    fill: $gray-500;
  }
}

.c-base-textinput__info {
  color: $primary-color;
  margin: 0 0 0 1.2rem;
}

.c-base-textinput-wrapper--static {
  display: block;
}

.c-base-textinput-wrapper--time {
  width: 16rem;
}

.c-base-textinput-container {
  flex: 1 1 0;
}

.c-base-textinput {
  appearance: none;
  background: none;
  border: 0;
  color: inherit;
  display: block;
  font: inherit;
  outline: 0;
  width: 100%;
  line-height: 1;

  &[readonly] {
    cursor: default;
  }
}

.c-base-textinput-label {
  color: $blue-kbc-cement;
  flex: none;
  font-size: 1.3rem;
  margin-bottom: 0.8rem;
  width: 100%;
}

.c-base-textinput-autocomplete {
  background: #fff;
  border-radius: $border-radius-small;
  box-shadow: $box-shadow-large;
  left: 1.6rem;
  max-height: 14.4rem;
  max-width: calc(100% - 3.2rem);
  min-width: 16rem;
  overflow-y: auto;
  padding: 0.8rem 0;
  position: absolute;
  top: calc(100% - 0.4rem);
  z-index: 100;
}

.c-base-textinput-autocomplete__item,
a.c-base-textinput-autocomplete__item {
  color: inherit;
  display: block;
  overflow: hidden;
  padding: 0.8rem 1.6rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.u-flex__flexible-between {
  justify-content: space-between;
}
</style>
