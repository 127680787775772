/**
 * @module PARKING API
 */
import { Api, appEndPoint } from './api'

export const get = (cb: Function) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/licenseplate/getByUser`)
}

export const create = (form: any, cb: Function) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/licenseplate/create`, form)
}

export const remove = ({ uuid, deleteGlobally = true }: {uuid: string, deleteGlobally: boolean}, cb: Function) => {
  const api = new Api(cb, {
    excludeMessageAlert: ['CONFIRM_PROFILE_PLATE_DELETE'],
    returnError: true
  })
  return api.query('post', `${appEndPoint}/licenseplate/delete`, { uuid, deleteGlobally })
}

export const getOnStreetList = (cb: Function) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/parking/ON_STREET/numberplate/list`)
}

export const createOnStreet = (form: any, cb: Function) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/parking/ON_STREET/numberplate/add`, form)
}

export const removeOnStreet = ({ uuid, deleteGlobally = true }: {uuid: string, deleteGlobally: boolean}, cb: Function) => {
  const api = new Api(cb, {
    excludeMessageAlert: ['CONFIRM_PROFILE_PLATE_DELETE'],
    returnError: true
  })
  return api.query('post', `${appEndPoint}/parking/ON_STREET/numberplate/delete`, { uuid, deleteGlobally })
}

export const getNumberplateList = (mrpOspId: string, cb = () => {}) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/parking/numberplate/list`, { mrpOspId })
}

export const getNumberplateListSimilar = (mrpOspId: String, cb = () => {}) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/parking/numberplate/listSimilar`, { mrpOspId })
}

export const configureNumberplate = (input: { mrpOspId: string, licensePlates: [string]}, cb: Function) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/parking/numberplate/configure`, input)
}
