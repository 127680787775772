<template>
  <div
    class="c-icon"
    :class="{
      'c-icon--colorize': !keepColors ,
      'c-icon--small': size === 'small',
      'c-icon--large': size === 'large'
    }"
    :style="style"
    v-html="html"
  >
  </div>
</template>

<script>

export default {
  name: 'OIcon',
  data () {
    return {
    }
  },
  props: {
    iconBase64: {
      type: String,
      required: false,
      default: null
    },
    icon: {
      type: String,
      required: false,
      default: null
    },
    keepColors: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: null
    }
  },
  computed: {
    html () {
      if (this.icon) {
        try {
          return require(`@/assets/icons/${this.icon}.svg`).default
        } catch (e) {
        }
      }
      return ''
    },
    style () {
      if (this.iconBase64) {
        return {
          'background-size': '85%',
          'background-image': `url(${this.iconBase64})`,
          'background-repeat': 'no-repeat',
          'background-position': 'center center'
        }
      } else {
        return {}
      }
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.c-icon {
  display: inline-block;
  height: 2.4rem;
  width: 2.4rem;
  vertical-align: middle;
  svg {
    width: 100%;
    max-height: 100%;
    max-width: 100%;
  }
}

.c-icon--small {
  height: 1.6rem;
  width: 1.6rem;
}

.c-icon--large {
  height: 3.2rem;
  width: 3.2rem;
}

.c-icon--colorize svg * {
  fill: currentColor;
}
</style>
