import { store } from '../store'
import flow from './Flow'
import { router } from './router'

export const startFlowHandler = async ({ routingParameters }) => {
  try {
    await router.push({ name: 'empty' })
  } catch (e) {
    console.error(e)
  }
  await store.dispatch('app/resetFlow')
  await store.dispatch('streetParking/resetState')
  if (routingParameters && routingParameters.flow) {
    store.commit('app/setFlow', routingParameters.flow)
  }
  await store.dispatch('app/defineParams', routingParameters)
  await flow.switchFlow()
  await flow.getStart(true)
  await store.dispatch('navigation/reset')
  return true
}
