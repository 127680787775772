/**
 * Created by Miran on 13.11.2018.
 */
import * as api from '../../api/'
// eslint-disable-next-line
import {extractParamsFromUrl} from '../../helpers'

import { get, has, forEach } from 'lodash'
import { store } from '../../store'
import Vue from 'vue'

const defaultState = () => {
  return {
    flow: '',
    flowResource: '',
    params: {},
    previousUrl: '',
    loader: 0,
    receiver: {},
    startRoute: ''
  }
}

export const app = {
  namespaced: true,
  state: {
    isMobile: true,
    isTablet: false,
    appKey: '',
    appVersion: null,
    authorizationCode: null,
    branding: {},
    base64Images: {},
    base64ImagesRequests: {},
    brokenImages: [],
    callbacks: {},
    clientId: null,
    customStyles: [],
    language: null,
    mapLoader: null,
    qrCode: '',
    requireSSO: false,
    hybridweb: false,
    keyboardShown: false,
    conditionsForm: {},
    ...defaultState()
  },
  getters: {
    flow: state => state.flow,
    appVersion: state => state.appVersion || '',
    isSSO: state => !!state.clientId && !state.hybridweb && state.clientId !== 'OlympusMobility',
    isNative: state => !!state.hybridweb || (!!state.clientId && state.clientId === 'OlympusMobility'),
    isMobile: state => state.isMobile,
    isTablet: state => state.isTablet,
    bgColor: state => {
      return state.branding.otherBgColor || '#ffffff'
    },
    brandColor: state => {
      return state.branding.brandColor || '#ffffff'
    },
    showHeader: state => {
      return state.branding.showHeader === undefined ? false : state.branding.showHeader
    },
    showWalletButton: state => {
      return state.branding.showTickets || false
    },
    showTicketsButton: state => {
      return state.branding.showPurchaseTicket || false
    },
    language: state => state.language,
    base64Images: state => state.base64Images,
    base64ImagesRequests: state => state.base64ImagesRequests,
    getCustomStyles: state => state.customStyles,
    appKey: state => state.appKey,
    branding: state => state.branding,
    showBrandMobilits: state => state.branding ? state.branding.showMobilits : false,
    isLoading: state => state.loader,
    mapLoader: state => state.mapLoader && !state.loader,
    qrCode: state => state.qrCode,
    params: state => state.params,
    context: state => {
      if (state.params.kbcContextData) {
        try {
          return JSON.parse(atob(state.params.kbcContextData))
        } catch (e) {
          console.error(e)
          return null
        }
      }
      return null
    },
    paramByName: state => paramName => {
      return get(state.params, paramName, null)
    },
    contextByName: state => name => {
      if (state.params.kbcContextData) {
        try {
          const context = JSON.parse(atob(state.params.kbcContextData))
          return get(context, name, null)
        } catch (e) {
          console.error(e)
          return undefined
        }
      }
      return undefined
    },
    startRoute: state => state.startRoute,
    showFavorites: state => state.branding ? state.branding.showFavorites : false,
    receiver: state => (key, defaultValue = null) => get(state.receiver, key, defaultValue),
    numberDelimiter: state => {
      // return state.language === 'en' ? '.' : ','
      return ','
    },
    keyboardShown: state => state.keyboardShown,
    findParam: state => (key, fallback = null) => get(state.params, key, fallback),
    conditionsForm: state => state.conditionsForm
  },
  actions: {
    // add to receiver to get back on another page
    addToReceiver ({ commit }, data) {
      const key = Date.now().toString()
      commit('addToReceiver', { key, data })
      return key
    },
    // deprecated
    addCallbackOnReceiveMessage ({ commit }, { api, cb }) {
      commit('addCallback', { api, cb })
    },
    // show full screen loader
    showLoader ({ commit }) {
      commit('showLoader')
    },
    // hide full screen loader
    hideLoader ({ commit }) {
      commit('hideLoader')
    },
    // show map loader
    showMapLoader ({ commit }) {
      commit('showMapLoader')
    },
    // hide map loader
    hideMapLoader ({ commit }) {
      commit('hideMapLoader')
    },
    checkSSORequirement ({ commit }, clientId) {
      return api.appAuthRequireSSO(clientId, response => {
        commit('setRequireSSO', response.requireSso)
      })
    },
    clearQRCode ({ commit }) {
      commit('setQRCode', '')
    },
    setLanguage ({ commit, dispatch }, lang) {
      commit('setLanguage', lang)
      return dispatch('checkLanguage')
    },
    checkLanguage ({ commit, state, rootState, rootGetters }) {
      const language = state.language
      // console.log(rootGetters['user/tokenLanguage'])
      const profileLang = rootGetters['user/tokenLanguage'] || null

      if (language && profileLang && language !== profileLang) {
        return this.dispatch('user/updateUserLanguage', language)
      } else if (!language && profileLang) {
        commit('setLanguage', profileLang)
      }
    },
    getBranding ({ state, commit, dispatch }, ospId) {
      return api.appBranding(ospId, response => {
        commit('setBranding', response)
        if (response.showFavorites) {
          dispatch('user/getFavorites', null, { root: true })
        }
      })
    },
    async getImage ({ commit, state }, imageUrl) {
      if (state.brokenImages.includes(imageUrl)) {
        return false
      }
      commit('setImageRequest', imageUrl)
      const response = await api.getImage(imageUrl)
      if (response && response.code !== 401 && response.status !== 404) {
        const img = Buffer.from(response.data, 'binary').toString('base64')
        commit('setImage', { url: imageUrl, base64: img, content: response.headers['content-type'] })
      } else {
        commit('setBrokenImage', imageUrl)
      }
      return true
    },
    async downloadImage ({ commit, state }, { imageUrl, serviceResourceOspId }) {
      if (state.brokenImages.includes(imageUrl)) {
        return false
      }
      commit('setImageRequest', imageUrl)
      const response = await api.imageDownload({ imageUrl, serviceResourceOspId }, () => {
      })
      if (response && response.code !== 401 && response.status !== 404) {
        const img = Buffer.from(response.data, 'binary').toString('base64')
        commit('setImage', { url: imageUrl, base64: img, content: response.headers['content-type'] })
      } else {
        commit('setBrokenImage', imageUrl)
      }
    },
    setStyles ({ commit, state }, styles) {
      commit('updateCustomStyles', styles)
    },
    refreshAppKey ({ commit, state }) {
      const key = atob('app' + Date.now().toString())
      commit('setAppKey', key)
    },
    // define params which are taken from init url query string
    defineParams ({ commit }, params = null) {
      const search = params || extractParamsFromUrl()
      const pathname = window.location.pathname
      const path = pathname.split('/')
      const ticketAction = path.includes('ticketAction')
      if (search) {
        commit('setParams', {
          ticketAction,
          ...search
        })
      }
      return search
    },
    // get parameter and remove it
    getParamOnce ({ commit, state }, paramName) {
      const params = state.params
      if (has(params, paramName) && params[paramName]) {
        const response = params[paramName]
        delete params[paramName]
        commit('setParams', params)
        return response
      }
      return null
    },
    // define start route
    setStartRoute ({ commit }, route) {
      commit('setStartRoute', route)
    },
    // reset state before start a new flow
    resetFlow ({ commit }) {
      commit('resetFlow', defaultState())
    },
    updateConditionsForm ({ commit }, conditionsForm) {
      commit('setConditionsForm', conditionsForm)
    }
  },
  mutations: {
    addCallback (state, { api, cb }) {
      Vue.set(state.callbacks, api, cb)
    },
    addToReceiver (state, { key, data }) {
      Vue.set(state.receiver, key, data)
    },
    setPreviousUrl (state, path) {
      state.previousUrl = path
    },
    toggleLoader (state, show) {
      state.loader = show
    },
    showLoader (state) {
      state.loader++
    },
    hideLoader (state) {
      if (state.loader > 0) state.loader--
    },
    resetFlow (state, data) {
      forEach(data, (value, key) => {
        Vue.set(state, key, value)
      })
    },
    showMapLoader (state) {
      state.mapLoader = true
    },
    hideMapLoader (state) {
      state.mapLoader = false
    },
    setAppVersion (state, appVersion) {
      state.appVersion = appVersion
    },
    setHybridweb (state, flag) {
      state.hybridweb = flag
    },
    setFlow (state, flow) {
      state.flow = flow
    },
    setFlowResource (state, resource) {
      state.flowResource = resource
    },
    async setQRCode (state, code) {
      if (code) {
        const response = await api.normalizeQr(code)
        code = response.normalizedValue || code
      }
      state.qrCode = code
    },
    setLanguage (state, language) {
      state.language = language
      store.dispatch('changeLang', language)
    },
    setClientId (state, clientId) {
      state.clientId = clientId
    },
    setRequireSSO (state, requireSSO) {
      state.requireSSO = requireSSO
    },
    setBranding (state, branding) {
      // branding.showTickets = !branding.showTickets
      state.branding = branding
    },
    setAuthorizationCode (state, data) {
      if (data.authorizationCode && data.authorizationCode.trim().length) {
        state.authorizationCode = data.authorizationCode.trim()
      }
    },
    setImage (state, { url, base64, content }) {
      if (url && base64) {
        Vue.set(state.base64Images, url, {
          origin: base64,
          content,
          date: Date.now().toString(),
          getBase64: `data:${content};base64,${base64}`
        })
      }
    },
    setBrokenImage (state, url) {
      if (url && !state.brokenImages.includes(url)) {
        state.brokenImages.push(url)
      }
    },
    setImageRequest (state, url) {
      if (url) {
        Vue.set(state.base64ImagesRequests, url, Date.now().toString())
      }
    },
    updateCustomStyles (state, styles) {
      Object.keys(styles).map((className, index) => {
        Vue.set(state.customStyles, className, styles[className])
      })
    },
    setAppKey (state, string) {
      state.appKey = string
    },
    setParams (state, json) {
      Vue.set(state, 'params', json)
    },
    setStartRoute (state, string) {
      state.startRoute = string
    },
    setKeyboardShown (state, status) {
      state.keyboardShown = status
    },
    setIsMobile (state, value) {
      state.isMobile = value
    },
    setIsTablet (state, value) {
      state.isTablet = value
    },
    setConditionsForm (state, conditionsForm) {
      state.conditionsForm = conditionsForm
    }
  }
}
