const lazyLoad = (component) => {
  // if (process.env.NODE_ENV && process.env.NODE_ENV !== 'production') {
  //   return require('../../components/onStreetParking/' + component)
  // } else {
  return () => import(/* webpackChunkName: "dakt" */ '../../components/dakt/' + component)
  // }
}

const BASE_PATH = '/sales/dakt/:resourceOspId'

export default [
  {
    path: BASE_PATH,
    name: 'dakt',
    component: lazyLoad('DaktPage')
  },
  {
    path: `${BASE_PATH}/cards`,
    name: 'fuelcard_overview',
    component: lazyLoad('FuelcardOverviewPage')
  },
  {
    path: `${BASE_PATH}/order`,
    name: 'fuelcard_choice',
    component: lazyLoad('FuelcardChoicePage')
  },
  {
    path: `${BASE_PATH}/order/:cardTypeCode`,
    name: 'fuelcard_order_details',
    component: lazyLoad('FuelcardOrderDetailsPage')
  },
  {
    path: `${BASE_PATH}/order/:cardTypeCode/driver`,
    name: 'fuelcard_order_driver',
    component: lazyLoad('FuelcardOrderDriverPage')
  },
  {
    path: `${BASE_PATH}/order/:cardTypeCode/fleet-manager`,
    name: 'fuelcard_order_fleet_manager',
    component: lazyLoad('FuelcardOrderFleetManagerPage')
  },
  {
    path: `${BASE_PATH}/order/:cardTypeCode/shipping`,
    name: 'fuelcard_order_shipping',
    component: lazyLoad('FuelcardOrderShippingPage')
  },
  {
    path: `${BASE_PATH}/order/:cardTypeCode/quote`,
    name: 'fuelcard_order_quote',
    component: lazyLoad('FuelcardOrderQuotePage')
  },
  {
    path: `${BASE_PATH}/order/:cardTypeCode/confirmation`,
    name: 'fuelcard_order_confirmation',
    component: lazyLoad('FuelcardOrderConfirmationPage')
  },
  {
    path: `${BASE_PATH}/cards/:cardExtId`,
    name: 'fuelcard_card_details',
    component: lazyLoad('FuelcardCardDetailsPage')
  },
  {
    path: `${BASE_PATH}/cards/:cardExtId/blocking`,
    name: 'fuelcard_card_blocking',
    component: lazyLoad('FuelcardCardBlockingPage')
  },
  {
    path: `${BASE_PATH}/cards/:cardExtId/blocking/replace`,
    name: 'fuelcard_card_blocking_replace',
    component: lazyLoad('FuelcardCardBlockingReplacePage')
  },
  {
    path: `${BASE_PATH}/cards/:cardExtId/blocking/payment`,
    name: 'fuelcard_card_blocking_payment',
    component: lazyLoad('FuelcardCardBlockingPaymentPage')
  },
  {
    path: `${BASE_PATH}/cards/:cardExtId/blocking/final`,
    name: 'fuelcard_card_blocking_final',
    component: lazyLoad('FuelcardCardBlockingFinalPage')
  },
  {
    path: `${BASE_PATH}/cards/:cardExtId/blocking/confirmation`,
    name: 'fuelcard_card_blocking_confirmation',
    component: lazyLoad('FuelcardCardBlockingConfirmationPage')
  },
  {
    path: `${BASE_PATH}/cards/:cardExtId/activation/confirmation`,
    name: 'fuelcard_card_activation_confirmation',
    component: lazyLoad('FuelcardCardActivationConfirmationPage')
  },
  {
    path: `${BASE_PATH}/invoices`,
    name: 'fuelcard_invoices_overview',
    component: lazyLoad('FuelcardInvoicesOverviewPage')
  },
  {
    path: `${BASE_PATH}/invoices/email-confirmation`,
    name: 'fuelcard_invoices_email_confirmation',
    component: lazyLoad('FuelcardInvoicesEmailConfirmationPage')
  },
  {
    path: `${BASE_PATH}/invoices/:invoiceNumber`,
    name: 'fuelcard_invoices_details',
    component: lazyLoad('FuelcardInvoicesDetailsPage')
  }
]
