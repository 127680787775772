import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { store } from '../store'
import { authHeader, trans, appLog, API_URL } from '../helpers'

Vue.use(VueAxios, axios)

export const TOKEN_EXPIRED = 'TOKEN_EXPIRED'
// eslint-disable-next-line
export const userEndPoint = '/user-service/v1'
// eslint-disable-next-line
export const appEndPoint = '/mobile-app-service/v1'
// eslint-disable-next-line
export const appEndPointV2 = '/mobile-app-service/v2'

export const appEndPointV3 = '/mobile-app-service/v3'

// let queue = new Set([])
// const MAX_REQUESTS = 10

export class Api {
  constructor (cb, { extraHeaders = {}, showError = true, loader = true, useApiUrl = true, responseType = 'json', dataOnly = true, returnError = false, excludeMessageAlert = [], importantMessages = [], timeout = 120000 } = {}) {
    this.cb = cb // callback function
    this.url = null
    this.data = null // request body
    this.method = null // e.g. "POST", "GET", etc
    this.isFirst = true // used to retry request if token is expired
    this.loader = loader // show full screen loader
    this.showError = showError // deprecated
    this.returnError = returnError // return error response
    this.apiUrl = useApiUrl ? API_URL : ''
    this.dataOnly = dataOnly // return data only without headers and so on
    this.excludeMessageAlert = excludeMessageAlert // don't show error message if error code or message is in list
    this.extraHeaders = extraHeaders // extra request headers
    this.responseType = responseType // e.g. "arraybuffer", "text", etc.
    this.importantMessages = importantMessages // show error message in modal screen instead of slide out screen
    this.timeout = timeout // request timeout
    if (loader) {
      store.dispatch('app/showLoader')
    }
    this.axios = this.initAxios()
  }

  initAxios () {
    this.config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        // window.location.hostname,
        ...authHeader(),
        ...this.extraHeaders
      },
      data: null
    }
    return axios.create({
      baseURL: `${this.apiUrl}/`,
      responseType: this.responseType,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        // window.location.hostname,
        ...authHeader(),
        ...this.extraHeaders
      },
      timeout: this.timeout
    })
  }

  /**
   * retry request if token is expired
   * @returns {boolean}
   */
  retry () {
    if (this.isFirst) {
      this.isFirst = false
      setTimeout(() => {
        this.axios = this.initAxios()
        return this.query(this.method, this.url, this.data)
      }, 50)
      // return this.query(this.method, this.url, this.data)
    }
    return false
  }

  query (method, url, data = {}) {
    this.method = method
    this.url = url
    this.data = data

    if (method.toUpperCase() === 'GET') {
      appLog(this.config)
      return this.axios[method](url, this.config)
        .then(this.onSuccess.bind(this))
        .catch(this.onFail.bind(this))
    }
    return this.axios[method](url, data)
      .then(this.onSuccess.bind(this))
      .catch(this.onFail.bind(this))
  }

  /**
   * Success response handler
   * @param response
   * @returns {*}
   */
  onSuccess (response) {
    this.loader && store.dispatch('app/hideLoader')
    this.messageOutConsole(response)
    let responseData = this.dataOnly ? response.data : response
    responseData = responseData || true
    if (responseData && this.cb) {
      this.cb(responseData)
    }
    return responseData
  }

  /**
   * Error response handler
   * @param error
   * @returns {Promise<boolean|*>}
   */
  async onFail (error) {
    appLog(error, 'QUERY ERROR')

    if (error.response && error.response.data) {
      const responseError = error.response.data

      // check if we need to refresh user auth token
      if ((responseError.status === 401 && responseError.code === TOKEN_EXPIRED) || error.response.status === 401) {
        await store.dispatch('user/authRefresh')
        return this.retry()
      }
      const errorMessage = error.response.data.message
      const errorCode = error.response.data.code
      const inExcludeList = this.excludeMessageAlert.includes(errorMessage) || this.excludeMessageAlert.includes(errorCode) || this.excludeMessageAlert.includes('*')
      if (responseError.errorLabel && !inExcludeList) {
        const message = trans(responseError.errorLabel)
        if (this.importantMessages.includes(errorMessage) && message) {
          store.dispatch('alert/showModalError', message)
        } else {
          store.dispatch('alert/error', message)
        }
      }
    }

    this.loader && store.dispatch('app/hideLoader')

    if (this.returnError) {
      const data = this.dataOnly && error.response && error.response.data ? error.response.data : error.response
      if (this.cb) {
        this.cb(data)
      }
      return data
    }
    return false
  }

  messageOutConsole (success) {
    console.log(this.url, success, 'messageOutConsole')
  }

  /**
   * Fake api response
   * @param data
   * @returns {Promise<unknown>}
   */
  fake (data) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(data)
        if (this.cb) {
          this.cb(data)
        }
        this.loader && store.dispatch('app/hideLoader')
      }, 500)
    })
  }
}
