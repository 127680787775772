/**
 * @module Information Wizard API
 */
import { Api, appEndPoint } from './api'
import { AOD_RESOURCE } from '../helpers'

export const getContract = ({ serviceResourceOspId, licensePlate }, cb) => {
  const api = new Api(cb, { returnError: true, excludeMessageAlert: ['NOT_FOUND'] })
  return api.query('post', `${appEndPoint}/aod/contract/get`, { serviceResourceOspId, licensePlate })
}

export const getPrequote = ({ serviceResourceOspId, licensePlate }, cb) => {
  const api = new Api(cb, { returnError: true, excludeMessageAlert: ['NOT_FOUND'] })
  return api.query('post', `${appEndPoint}/aod/sales/prequote`, { serviceResourceOspId, licensePlate })
}

export const getQuote = (form, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/aod/sales/quote`, form)
}

export const confirmQuote = (bookingId, cb) => {
  const api = new Api(cb, { loader: true })
  return api.query('post', `${appEndPoint}/aod/sales/quote/confirm`, { bookingId })
}

export const getAssistanceRequestStatus = ({ bookingId, serviceResourceOspId }, cb) => {
  const api = new Api(cb, { loader: false })
  return api.query('post', `${appEndPoint}/aod/assistancerequest/status/get`, { bookingId, serviceResourceOspId })
}

export const cancelAssistanceRequest = (bookingId, cb) => {
  const api = new Api(cb, { returnError: true })
  return api.query('post', `${appEndPoint}/aod/assistancerequest/cancel`, { bookingId })
}

export const getAssistanceRequestActiveAndRecent = ({
  getActive = false,
  getRecent = false,
  serviceResourceOspId = AOD_RESOURCE
}, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/aod/assistancerequest/activeAndRecent`, { getActive, getRecent, serviceResourceOspId })
}

export const getVehicleProperties = ({ serviceResourceOspId, vin }, cb) => {
  const api = new Api(cb, { returnError: true })
  // return api.fake({ manufacturer: 'Audi', model: 'A3' })
  return api.query('post', `${appEndPoint}/aod/vehicle/properties`, { serviceResourceOspId, vin })
}
