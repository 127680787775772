// eslint-disable-next-line
import moment from 'moment'
import Vue from 'vue'
import { store } from '../../store'
import { TRAIN_CLASS1_FLOW } from '../../helpers'
const FIRST_CLASS = 'FIRST_CLASS'
const SECOND_CLASS = 'SECOND_CLASS'

const baseForm = {
  origin: null,
  destination: null,
  outbound: moment().format('YYYY-MM-DD'),
  returnTrip: 'no',
  classOfService: null,
  guestBooking: 'myself',
  guestBirthDate: null,
  // guestBirthDate: moment().format('YYYY-MM-DD'),
  guestDiscounts: [],
  guestFirstName: '',
  guestLastName: ''
}

export const trainForm = {
  namespaced: true,
  state: {
    form: { ...baseForm }
  },
  getters: {
    form: state => {
      return {
        ...state.form,
        classOfService: state.form.classOfService || (store.state.app.flow === TRAIN_CLASS1_FLOW ? FIRST_CLASS : SECOND_CLASS)
      }
    }
  },
  actions: {
    resetForm ({ commit }) {
      commit('setForm', { ...baseForm })
    },
    updateOrigin ({ commit }, value) {
      commit('setOrigin', value)
    },
    updateDestination ({ commit }, value) {
      commit('setDestination', value)
    },
    updateOutbound ({ commit }, value) {
      commit('setOutbound', value)
    },
    updateReturn ({ commit }, value) {
      commit('setReturn', value)
    },
    updateClassOfService ({ commit }, value) {
      commit('setClassOfService', value)
    },
    updateGuestBooking ({ commit }, value) {
      commit('setGuestBooking', value)
    },
    updateGuestBirthDate ({ commit }, value) {
      commit('setGuestBirthDate', value)
    },
    updateGuestDiscounts ({ commit }, value) {
      commit('setGuestDiscounts', value)
    },
    updateGuestFirstName ({ commit }, value) {
      commit('setGuestFirstName', value)
    },
    updateGuestLastName ({ commit }, value) {
      commit('setGuestLastName', value)
    }
  },
  mutations: {
    setForm (state, form) {
      Vue.set(state, 'form', form)
    },
    setOrigin (state, value) {
      state.form.origin = value
    },
    setDestination (state, value) {
      state.form.destination = value
    },
    setOutbound (state, value) {
      state.form.outbound = value
    },
    setReturn (state, value) {
      state.form.returnTrip = value
    },
    setClassOfService (state, value) {
      state.form.classOfService = value
    },
    setGuestBooking (state, value) {
      state.form.guestBooking = value
    },
    setGuestBirthDate (state, value) {
      state.form.guestBirthDate = value
    },
    setGuestDiscounts (state, value) {
      state.form.guestDiscounts = value
    },
    setGuestFirstName (state, value) {
      state.form.guestFirstName = value
    },
    setGuestLastName (state, value) {
      state.form.guestLastName = value
    }
  }
}
