<template>
<o-modal
  class="o-alert-modal"
>
  <p slot="body" v-html="alert.message">
  </p>
  <template slot="footer">
    <o-button
      :label="$t('html5.global.close')"
      :is-floating="false"
      @click="hideModalError"
    />
  </template>
</o-modal>
</template>

<script>

import OModal from './OModal'
import { mapActions } from 'vuex'
export default {
  name: 'OAlertModal',
  components: {
    OModal
  },
  props: {
    alert: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    ...mapActions({
      hideModalError: 'alert/hideModalError'
    })
  }
}

</script>

<style lang="scss">
@import '~@/assets/styles/variables.scss';
  .o-alert-modal {
    color: $text-color;
    color: var(--text-color);
    font-size: 1.4rem;
    line-height: 2rem;
    z-index: 125;
    .modal-container {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      max-height: 90vh;
      padding-left: 0;
      padding-right: 0;
      .modal-body {
        flex: 1;
        overflow-y: auto;
        padding: 0 30px;
        margin-bottom: 10px;
      }
      p {
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: $text-color;
        color: var(--text-color);
        text-align: center;
      }
      .modal-footer {
        margin: 0 -31px -20px;
        .c-button {
          width: 100%;
          border: none;
          border-top: 0.1rem solid rgba(0,0,0,.1);
          border-radius: 0;
        }
      }
    }
    a {
      color: $primary-color;
      color: var(--primary-color);
      text-decoration: underline;
      -webkit-tap-highlight-color: transparent;
    }
  }

</style>
