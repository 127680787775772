/**
 * Created by Miran on 13.11.2018.
 */
import Vue from 'vue'
import Vuex from 'vuex'
import { alert } from './modules/alert'
import { aod } from './modules/aod'
import { app } from './modules/app'
import { biometrics } from './modules/biometrics'
import { budget } from './modules/budget'
import { cambio } from './modules/cambio'
import { carpool } from './modules/carpool'
import { convenientInvoicing } from './modules/convenient_invoicing'
import { fuel } from './modules/fuel'
import { fuelcard } from './modules/fuelcard'
import { navigation } from './modules/navigation'
import { payment } from './modules/payment'
import { picture } from './modules/picture'
import { plugin } from './modules/plugin'
import { poi } from './modules/poi'
import { sale } from './modules/sale'
import { season } from './modules/season'
import { settings } from './modules/settings'
import { streetParking } from './modules/streetParking'
import { train } from './modules/train'
import { trainForm } from './modules/train_form'
import { travel } from './modules/travel'
import { user } from './modules/user'
import { wizard } from './modules/wizard'

import i18n from '../i18n/store'

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    alert,
    aod,
    app,
    biometrics,
    budget,
    cambio,
    carpool,
    convenientInvoicing,
    fuel,
    fuelcard,
    i18n,
    navigation,
    payment,
    picture,
    plugin,
    poi,
    sale,
    season,
    settings,
    streetParking,
    train,
    trainForm,
    travel,
    user,
    wizard
  }
})
