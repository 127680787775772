<template>
  <div>
    <div
      class="c-bottom-overlay__cover"
      :class="{ 'c-bottom-overlay__cover--show': visible }"
      @click="close"
    />
    <div
      class="c-bottom-overlay"
      :class="{ 'c-bottom-overlay--open': visible }"
    >
      <h2 class="c-bottom-overlay__title" v-if="title">{{ title }}</h2>
      <o-icon
        class="c-bottom-overlay__close"
        icon="close"
        @click.native="close"
      />
      <div class="c-bottom-overlay__form" v-if="$slots.form">
        <slot name="form"/>
      </div>
      <div class="c-bottom-overlay__content" v-if="$slots.default">
        <slot/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OOverlay',

  props: {
    title: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      visible: false
    }
  },

  methods: {
    open () {
      this.visible = true
    },

    close () {
      this.visible = false
    }
  }
}
</script>

<style lang="scss">
@import '~@/assets/styles/variables.scss';
.c-bottom-overlay {
  background: #fff;
  border-radius: 1.2rem 1.2rem 0 0;
  box-shadow: $box-shadow-large, $box-shadow-large;
  bottom: 0;
  left: 0;
  max-height: 60vh;
  opacity: 0;
  overflow: auto;
  padding: 2.4rem;
  position: absolute;
  right: 0;
  transform: translateY(100%);
  transition: all 0.2s ease-in-out;
  z-index: 20;
}

.c-bottom-overlay__cover {
  background: rgba(#000, 0.4);
  bottom: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.1s linear;
  z-index: 10;
}

.c-bottom-overlay__cover--show {
  opacity: 1;
  pointer-events: auto;
}

.c-bottom-overlay--open {
  opacity: 1;
  transform: translateY(0);
}

.c-bottom-overlay__title {
  color: #000;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 2.4rem;
  margin-bottom: 1.6rem;
}

.c-bottom-overlay__form {
  margin: -0.8rem -2.4rem 1.6rem;
  overflow-y: auto;
}

.c-bottom-overlay__text,
.c-bottom-overlay__content p {
  line-height: 2.4rem;
  padding-right: 2.4rem;
}

.c-bottom-overlay__text:not(:last-child),
.c-bottom-overlay__content p:not(:last-child) {
  margin-bottom: 1.6rem;
}

.c-bottom-overlay__list {
  margin: 0 -1.6rem;
}

.c-bottom-overlay__close {
  cursor: pointer;
  position: absolute;
  right: 1.6rem;
  top: 1.6rem;
  z-index: 5;
}
</style>
