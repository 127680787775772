<template>
  <div class="c-spinner"></div>
</template>

<script>
export default {
  name: 'OSpinner'
}
</script>

<style scoped>
  @keyframes rotateSpinner {
    100% {
      transform: rotate(360deg);
    }
  }

  .c-spinner {
    animation: rotateSpinner 1s linear 0s infinite both;
    border: 0.3rem solid $primary-color;
    border: 0.3rem solid var(--primary-color);
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-radius: 50%;
    height: 4rem;
    width: 4rem;
  }
</style>
