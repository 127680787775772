import Vue from 'vue'
import { cloneDeep } from 'lodash'

const defaultState = () => ({
  abroad: false,
  highway: false,
  safe: false,
  barcode: null,
  isNewCustomer: true,
  form: {
    additionalInfo: undefined,
    breakdownCode: null,
    breakdownLocation: {
      additionalInfo: undefined,
      city: null,
      closedParking: false,
      coordinate: {
        latitude: null,
        longitude: null
      },
      box: null,
      houseNumber: null,
      postalCode: null,
      street: null,
      formattedAddress: undefined,
      customerInput: undefined
    },
    contactInfo: {
      box: undefined,
      city: undefined,
      country: undefined,
      email: undefined,
      firstName: null,
      houseNumber: undefined,
      lastName: null,
      postalCode: undefined,
      street: undefined,
      telephone: null
    },
    licensePlate: null,
    serviceResourceOspId: undefined,
    userOspId: undefined
  }
})
export const aod = {
  namespaced: true,
  state: {
    ...defaultState()
  },
  getters: {
    abroad: state => state.abroad,
    barcode: state => state.barcode,
    highway: state => state.highway,
    isNewCustomer: state => state.isNewCustomer,
    safe: state => state.safe,
    contactInfo: state => state.form.contactInfo,
    form: state => state.form
  },
  actions: {
    updateAbroad ({ commit }, val) {
      commit('setAbroad', val)
    },
    updateBarcode ({ commit }, val) {
      commit('setBarcode', val)
    },
    updateHighway ({ commit }, val) {
      commit('setHighway', val)
    },
    updateSafe ({ commit }, val) {
      commit('setSafe', val)
    },
    updateIsNewCustomer ({ commit }, val) {
      commit('setIsNewCustomer', val)
    },
    updateContactForm ({ commit }, val) {
      commit('setContactForm', val)
    },
    patchForm ({ commit, state }, newValue) {
      commit('setForm', { ...state.form, ...newValue })
    },
    resetState ({ commit }) {
      commit('resetState')
    }
  },
  mutations: {
    setAbroad (state, val) {
      state.abroad = val
    },
    setHighway (state, val) {
      state.highway = val
    },
    setBarcode (state, val) {
      state.barcode = val
    },
    setSafe (state, val) {
      state.safe = val
    },
    setIsNewCustomer (state, val) {
      state.isNewCustomer = val
    },
    setForm (state, form) {
      Vue.set(state, 'form', cloneDeep(form))
    },
    resetState (state) {
      for (const f in state) {
        Vue.set(state, f, defaultState()[f])
      }
    }
  }
}
