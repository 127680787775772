// const lazyLoad = (component) => () => import(/* webpackChunkName: "season" */ '../../components/season/' + component)
const lazyLoad = (component) => {
  if (process.env.NODE_ENV !== 'production') {
    return require('../../components/season/' + component).default
  } else {
    return () => import(/* webpackChunkName: "season-ticket-v2" */ '../../components/season/' + component)
  }
}

export const seasonRoutes = [
  {
    path: '/sales/season-ticket/v2/:resourceOspId',
    name: 'season_ticket',
    component: lazyLoad('SeasonTicketPage')
  },
  {
    path: '/sales/season-ticket/v2/:resourceOspId/quote',
    name: 'season_ticket_quote',
    component: lazyLoad('SeasonTicketQuotesPage')
  },
  {
    path: '/sales/season-ticket/v2/:resourceOspId/confirm',
    name: 'season_ticket_confirm',
    component: lazyLoad('SeasonConfirmationPage')
  },
  {
    path: '/sales/season-ticket/v2/:resourceOspId/products',
    name: 'season_ticket_products',
    component: lazyLoad('SeasonTicketProductsPage')
  },
  {
    path: '/sales/season-ticket/v2/:resourceOspId/national-number',
    name: 'season_ticket_national_number',
    component: lazyLoad('SeasonTicketNationalNumberPage')
  },
  {
    path: '/sales/season-ticket/v2/:resourceOspId/ask-mobib',
    name: 'season_ticket_ask_mobib',
    component: lazyLoad('SeasonTicketAskMobibPage')
  },
  {
    path: '/sales/season-ticket/v2/:resourceOspId/mobib-registration',
    name: 'season_ticket_mobib_registration',
    component: lazyLoad('SeasonTicketMobibRegistrationPage')
  }
]
