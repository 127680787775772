import { has } from 'lodash'

import postMessage from '../../Classes/postMessageService/postMessage'

export const biometrics = {
  namespaced: true,
  state: {
    // requestUuid: null,
    biometrics: {},
    interval: null
  },
  getters: {
    biometrics: state => state.biometrics
  },
  actions: {
    requestBiometrics ({ commit, getters }) {
      commit('RESET_BIOMETRICS')
      if (postMessage.confirmBiometrics()) {
      // if (sendPostMessage('confirmBiometrics')) {
        let start = 0
        const delay = 250
        const end = 5 * 60 * 1000 // 5 minutes
        return new Promise((resolve, reject) => {
          const interval = setInterval(() => {
            start += delay
            if (has(getters.biometrics, 'biometricResult')) {
              commit('SET_INTERVAL')
              resolve(getters.biometrics)
            } else if (start > end) {
              commit('SET_INTERVAL')
              reject(new Error('Timeout expired'))
            }
          }, delay)
          commit('SET_INTERVAL', interval)
        })
      }
    }
  },
  mutations: {
    RESET_BIOMETRICS: (state) => {
      state.biometrics = {}
    },
    SET_INTERVAL: (state, interval = null) => {
      state.interval && clearInterval(state.interval)
      state.interval = interval
    },
    SET_BIOMETRICS: (state, biometrics) => {
      state.biometrics = biometrics
    }
  }
}
