<template>
  <component
    :is="to ? 'router-link' : 'div'"
    :to="to ? to : null"
    :disabled="isDisabled"
    class="c-button"
    :class="{
      'c-button--primary': isPrimary,
      'c-button--secondary': isSecondary,
      'c-button--dangerous': isDangerous,
      'c-button--disabled': isDisabled,
      'c-button--rectangular': isRectangular,
      'c-button--floating': isFloating,
      'c-button--floating-secondary': isFloating && isSecondary,
      'c-button--relative': isRelative,
      'c-button--small': size === 'small'
    }"
    @click.stop="$emit('click')"
  >
    <slot>
      <o-icon
        v-if="prependIcon"
        :icon="prependIcon"
      />
      {{ label }}
    </slot>
  </component>
</template>

<script>
export default {
  name: 'OButton',
  props: {
    to: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    isPrimary: {
      type: Boolean,
      default: null
    },
    isSecondary: {
      type: Boolean,
      default: null
    },
    isDangerous: {
      type: Boolean,
      default: false
    },
    isFloating: {
      type: Boolean,
      default: null
    },
    isRectangular: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: null
    },
    isRelative: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: null
    },
    prependIcon: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">

</style>
