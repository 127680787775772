import * as api from '../../api'
import Vue from 'vue'
import _ from 'lodash'
import { appLog, asyncImageBase64, getNextBusinessDay, RETURN_TRIP } from '../../helpers'
// import moment from 'moment'
import Agreement from '../../Classes/carpool/Agreement'
import VueI18n from '../../i18n'

const AGREEMENTS_LIMIT = 3

export const carpool = {
  namespaced: true,
  state: {
    profile: {},
    serviceInfo: {},
    rides: {},
    ridesDetails: {},
    routes: {},
    matchList: {},
    agreements: {},
    rideList: {},
    newRideForm: {},
    newRideFormDirty: false,
    avatarShown: false
  },
  getters: {
    profile: state => mrpOspId => _.has(state.profile, mrpOspId) ? state.profile[mrpOspId] : null,
    serviceInfo: state => resourceOspId => _.has(state.serviceInfo, resourceOspId) ? state.serviceInfo[resourceOspId] : null,
    rides: state => resourceOspId => _.has(state.rides, resourceOspId) ? state.rides[resourceOspId] : null,
    ridesDetails: state => (resourceOspId, rideUuid) => {
      const details = _.has(state.ridesDetails, `${resourceOspId}`) ? state.ridesDetails[resourceOspId] : []
      return details.find(v => v.rideUuid === rideUuid)
    },
    routesList: state => (resourceOspId, rideUuid) => {
      return _.has(state.routes, `${resourceOspId}`)
        ? state.routes[resourceOspId].find(v => v.rideUuid === rideUuid)
        : {}
    },
    matchList: state => (resourceOspId, date) => {
      return _.has(state.matchList, [`${resourceOspId}`, `${date}`])
        ? state.matchList[resourceOspId][date]
        : []
    },
    agreements: state => mrpOspId => _.get(state.agreements, mrpOspId, []),
    rideList: state => mrpOspId => _.get(state.rideList, mrpOspId, []),
    newRideForm: state => state.newRideForm,
    newRideFormDirty: state => state.newRideFormDirty,
    avatarShown: state => state.avatarShown
  },
  actions: {
    async createRide ({ commit }, {
      serviceResourceOspId, carpoolRide, cb = () => {
      }
    }) {
      const response = await api.carpool.createRide({ serviceResourceOspId, carpoolRide }, ({ rideUuid }) => {
        commit('setRide', { serviceResourceOspId, rideUuid })
        cb(rideUuid)
      })
      return _.has(response, 'rideUuid') ? response.rideUuid : null
    },
    updateRide ({ commit }, {
      serviceResourceOspId, carpoolRide, cb = () => {
      }
    }) {
      return api.carpool.updateRide(carpoolRide, response => {
        cb(response)
      })
    },
    getAgreements ({ commit }, mrpOspId) {
      return api.carpool.agreementList({ mrpOspId, limit: AGREEMENTS_LIMIT }, response => {
        commit('setAgreements', { mrpOspId, response })
      })
    },
    getAgreementsSilent ({ commit }, mrpOspId) {
      return api.carpool.agreementList({ mrpOspId, limit: AGREEMENTS_LIMIT, silent: true }, response => {
        commit('setAgreements', { mrpOspId, response })
      })
    },
    getAvatarPhoto ({ commit }) {

    },
    getProfile ({ commit }, mrpOspId) {
      return api.carpool.getUserProfile(mrpOspId, response => {
        commit('setProfile', { mrpOspId, response })
      })
    },
    getServiceInfo ({ commit }, resourceOspId) {
      return api.carpool.getServiceInfo(resourceOspId, response => {
        commit('setServiceInfo', { resourceOspId, response })
      })
    },
    getRideDetails ({ commit }, {
      resourceOspId, rideUuid, cb = () => {
      }
    }) {
      return api.carpool.rideDetails(rideUuid, response => {
        commit('setRideDetails', { resourceOspId, rideUuid, response })
        cb()
      })
    },
    getRideList ({ commit }, mrpOspId) {
      return api.carpool.rideList(mrpOspId, response => {
        commit('setRideList', { mrpOspId, response })
      })
    },
    getRouteList ({ commit }, { resourceOspId, rideUuid, ...data }) {
      return api.carpool.routeList(data, response => {
        commit('setRouteList', { resourceOspId, rideUuid, response })
      })
    },
    getMatchList ({ commit, dispatch }, { resourceOspId, mrpOspId, date, rideDirection }) {
      return api.carpool.matchList({ mrpOspId, date, rideDirection }, async response => {
        if (response.error && response.code === 'NOT_FOUND') {
          dispatch('alert/error', VueI18n.t('html5.carpool.tabs.available_rides.no_ride_warning'), { root: true })
        }
        const promises = []
        _.get(response, 'carpoolAgreementList', []).forEach(v => {
          if (v.avatar) {
            promises.push(asyncImageBase64(v.avatar, {
              svg: false,
              download: true,
              serviceResourceOspId: resourceOspId
            }))
          }
        })
        await Promise.all(promises)
        commit('setMatchList', { resourceOspId, date, response: !response.error ? response : [] })
      })
    },
    deleteAgreement ({ commit }, { mrpOspId, agreementId }) {
      return api.carpool.agreementDelete(
        {
          mrpOspId,
          agreementId
        }, response => {
          // commit('deleteAgreement', { mrpOspId, agreementId })
        }
      )
    },
    approveAgreement ({ commit }, { mrpOspId, agreementId }) {
      return api.carpool.agreementAccept(
        {
          mrpOspId,
          agreementId
        }, response => {
          commit('acceptAgreement', { mrpOspId, agreementId })
        }
      )
    },
    declineAgreement ({ commit }, { mrpOspId, agreementId }) {
      return api.carpool.agreementDecline(
        {
          mrpOspId,
          agreementId
        }, response => {
          // commit('deleteAgreement', { mrpOspId, agreementId })
        }
      )
    },
    cancelAgreement ({ commit }, { mrpOspId, agreementId }) {
      return api.carpool.agreementCancel({ mrpOspId, agreementId })
    },
    resetNewRideForm ({ commit }, { driver = false, mrpOspId = null }) {
      const homeWorkRide = true
      const homeWorkRideTime = '08:00'
      const workHomeRide = true
      const repeatRide = true
      const workHomeRideTime = '18:00'
      const days = [1, 2, 3, 4, 5]
      const defaultDate = getNextBusinessDay()
      // let defaultRepeatUntil = defaultDate
      const form = {
        date: defaultDate,
        driver,
        // 'mrpOspId': this.mrpOspId,
        repeat: repeatRide,
        repeatDays: days,
        repeatUntil: defaultDate,
        rideDirection: RETURN_TRIP,
        // 'seats': 0,
        time: homeWorkRide ? homeWorkRideTime : null,
        timeReturn: workHomeRide ? workHomeRideTime : null,
        mrpOspId
      }
      commit('setNewRideForm', form)
    },
    toggleAvatarShown ({ commit }) {
      commit('setAvatarShown', true)
    }
  },
  mutations: {
    acceptAgreement (state, { mrpOspId, agreementId }) {
      const agreements = _.get(state.agreements, mrpOspId, []).map(v => {
        if (v.passengers.find(p => p.agreementId === `${agreementId}`)) {
          v.state = 'CONFIRMED'
        }
        return v
      })
      Vue.set(state.agreements, mrpOspId, agreements || [])
    },
    setAgreements (state, { mrpOspId, response }) {
      Vue.set(state.agreements, mrpOspId, response.map(v => new Agreement(v)))
    },
    deleteAgreement (state, { mrpOspId, agreementId }) {
      const agreements = _.get(state.agreements, mrpOspId, []).filter(v => {
        return !v.passengers.find(p => p.agreementId === `${agreementId}`)
      })
      Vue.set(state.agreements, mrpOspId, agreements || [])
    },
    setRideList (state, { mrpOspId, response }) {
      Vue.set(state.rideList, mrpOspId, response)
    },
    setProfile (state, { mrpOspId, response }) {
      if (!response.error) {
        Vue.set(state.profile, mrpOspId, response)
      } else {
        Vue.set(state.profile, mrpOspId, null)
      }
    },
    setServiceInfo (state, { resourceOspId, response }) {
      if (!response.error) {
        Vue.set(state.serviceInfo, resourceOspId, response)
      } else {
        Vue.set(state.serviceInfo, resourceOspId, null)
      }
    },
    setRide (state, { serviceResourceOspId, rideUuid }) {
      Vue.set(state.rides, serviceResourceOspId, rideUuid)
    },
    setRideDetails (state, { resourceOspId, rideUuid, response }) {
      if (!state.ridesDetails[resourceOspId]) {
        state.ridesDetails[resourceOspId] = []
      }
      const rides = state.ridesDetails[resourceOspId].filter(v => v.rideUuid !== rideUuid)

      rides.push({
        rideUuid,
        ...response
      })
      Vue.set(state.ridesDetails, resourceOspId, rides)
    },
    setRouteList (state, { resourceOspId, rideUuid, response }) {
      if (!state.routes[resourceOspId]) {
        state.routes[resourceOspId] = []
      }
      const routes = state.routes[resourceOspId]

      routes.push({
        rideUuid,
        ...response
      })
      Vue.set(state.routes, resourceOspId, routes)
    },
    setMatchList (state, { resourceOspId, date, response }) {
      appLog({ resourceOspId, date, response }, 'setMatchList')
      const matchList = { ...state.matchList }
      matchList[resourceOspId] = { ...matchList[resourceOspId] } || {}
      matchList[resourceOspId][date] = response.carpoolAgreementList || []
      Vue.set(state, 'matchList', { ...matchList })
    },
    setNewRideForm (state, form) {
      Vue.set(state, 'newRideForm', form)
      Vue.set(state, 'newRideFormDirty', true)
    },
    resetNewRideFormDirty (state) {
      Vue.set(state, 'newRideFormDirty', false)
    },
    setAvatarShown (state, value = true) {
      state.avatarShown = value
    }
  }
}
