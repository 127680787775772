/**
 * Created by Miran on 13.11.2018.
 */
export const alert = {
  namespaced: true,
  state: {
    type: '',
    message: '',
    modalError: {},
    timeoutId: null
  },
  getters: {
    modalError: state => state.modalError
  },
  actions: {
    // show success notification
    success ({ commit }, message) {
      commit('success', message)
    },
    // show error notification
    error ({ state, commit }, message) {
      commit('error', message)
      state.timeoutId = setTimeout(() => {
        commit('clear')
        clearTimeout(state.timeoutId)
      }, 10000)
    },
    // clear all notifications
    clear ({ state, commit }) {
      clearTimeout(state.timeoutId)
      commit('clear')
    },
    // show modal error message
    async showModalError ({ commit }, message) {
      await this.dispatch('alert/hideModalError')
      commit('modalError', message)
    },
    hideModalError ({ commit }) {
      commit('removeModalError')
    }
  },
  mutations: {
    success (state, message) {
      state.type = 'alert-success'
      state.message = message
    },
    error (state, message) {
      state.type = 'alert-danger'
      state.message = message
    },
    clear (state) {
      state.type = ''
      state.message = ''
    },
    modalError (state, message) {
      state.modalError = Object.assign({}, { message, type: 'alert-danger' })
    },
    removeModalError (state) {
      state.modalError = {}
    }
  }
}
