import { store } from '../store'
import { router } from './router'
import { Action } from './Action'
import { getResourceLink, getURLParameter } from './functions'
import { appLog, flowResourcePath } from './app'
import { RESOURCE_404 } from './constants'

let flowInstance = null

/**
 * Base Flow class
 */
class Flow {
  get defaultLink () {
    return store && (store.getters['app/isSSO'] || store.getters['app/isNative']) ? '/404' : '/'
  }

  /**
   * get link of the flow
   * @returns {string}
   */
  getLink () {
    return this.defaultLink
  }
}

/**
 * Resource flow class. It's initiated when service resource menu item is detected for auth user
 */
class ResourceFlow extends Flow {
  constructor (mobilityMenuItem) {
    super()
    this.provider = mobilityMenuItem.mobilityProvider.ospId || ''
    this.mode = mobilityMenuItem.mobilityMode || ''
    this.authorized = !!mobilityMenuItem.authorized
    this.type = mobilityMenuItem.appFlowType
    this.resource = mobilityMenuItem.resourceOspId
    this.menuItem = mobilityMenuItem
  }

  getLink () {
    const link = getResourceLink(this.menuItem)
    return router.resolve(link) ? link : this.defaultLink
  }

  toString () {
    return `${this.mode}_${this.resource}`.toUpperCase()
  }
}

/**
 * Menu flow class. It's initiated when service resource menu item is detected by serviceResourceOspId
 */
class MenuFlow extends Flow {
  constructor (mobilityMenuItem) {
    super()
    this.mode = mobilityMenuItem.mobilityMode || ''
    this.authorized = !!mobilityMenuItem.authorized
    this.type = mobilityMenuItem.appFlowType
    this.menuItem = mobilityMenuItem
    this.isMenu = true
  }

  getLink () {
    const link = '/sales/' + this.mode.toLowerCase()
    try {
      return router.resolve(link) ? link : RESOURCE_404
    } catch (e) {
      appLog(e)
      return this.defaultLink
    }
  }

  toString () {
    return this.mode.toUpperCase()
  }
}

/**
 * Custom flow class. It's initiated when custom flow is detected
 */
class CustomFlow extends Flow {
  flowTypes = {
    acceptPurchase: 'ACCEPT_PURCHASE',
    accountSettings: 'ACCOUNT_SETTINGS',
    aod: 'AOD',
    convenientInvoicing: 'RESELLERB2B',
    convenientInvoicingSettings: 'RESELLERB2BSETTINGS',
    dakt: 'DAKT',
    kmRegistration: 'KM_REGISTRATION',
    mobibSettings: 'MOBIB_SETTINGS',
    mobilityAssistant: 'MOBILITY_ASSISTANT',
    onStreetParking: '4411',
    timetable: 'TIMETABLE',
    trainClass1: 'TRAIN_CLASS1_SERVICE',
    trainClass2: 'TRAIN_CLASS2_SERVICE',
    wizard: 'WIZARD',
    payment: 'PAYMENT'
  }

  flowString = ''

  constructor (flowString = '') {
    super()
    this.flowString = Object.values(this.flowTypes).includes(flowString) ? flowString : ''
    this.menuItem = null
  }

  async getLink () {
    let next = null
    switch (this.flowString) {
    case this.flowTypes.trainClass1:
    case this.flowTypes.trainClass2:
      next = '/sales/train'
      break
    case this.flowTypes.accountSettings:
      next = '/settings'
      break
    case this.flowTypes.mobibSettings:
      next = '/settings/mobib'
      break
    case this.flowTypes.timetable:
      try {
        const mobilityModeMenu = store && store.getters['user/mobilityModeMenuNative']
        if (mobilityModeMenu && mobilityModeMenu.length) {
          const resourceFlow = mobilityModeMenu.find(v => {
            return v.appFlowType === this.flowTypes.timetable
          })
          if (resourceFlow) {
            next = getResourceLink(resourceFlow)
            this.menuItem = resourceFlow
          }
        }
        next = next || this.defaultLink
      } catch (e) {
        appLog(e)
        next = this.defaultLink
      }
      break
    case this.flowTypes.wizard:
      const wizardIdentifier = getURLParameter('wizardIdentifier')
      next = '/wizard/' + btoa(wizardIdentifier)
      break
    case this.flowTypes.onStreetParking:
      try {
        const mobilityModeMenu = store.getters['user/mobilityModeMenuNative']
        if (mobilityModeMenu && mobilityModeMenu.length) {
          const resourceFlow = mobilityModeMenu.find(v => {
            return v.appFlowType === 'ONSTREET_PARKING'
          })
          if (resourceFlow) {
            next = getResourceLink(resourceFlow)
            this.menuItem = resourceFlow
          }
        }
        next = next || this.defaultLink
      } catch (e) {
        appLog(e)
        next = this.defaultLink
      }
      break
    case this.flowTypes.acceptPurchase:
      const serviceResourceOspId = getURLParameter('serviceResourceOspId')
      const purchaseUuid = getURLParameter('purchaseUuid')
      next = `/accept-purchase/${btoa(serviceResourceOspId)}/${purchaseUuid}`
      break
    case this.flowTypes.dakt:
      try {
        const mobilityModeMenu = store.getters['user/mobilityModeMenuNative']
        if (mobilityModeMenu && mobilityModeMenu.length) {
          const resourceFlow = mobilityModeMenu.find(v => {
            return v.appFlowType === 'DAKT'
          })
          if (resourceFlow) {
            next = getResourceLink(resourceFlow)
            this.menuItem = resourceFlow
          }
        }
        next = next || this.defaultLink
      } catch (e) {
        appLog(e)
        next = this.defaultLink
      }
      break
    case this.flowTypes.kmRegistration:
      next = '/km-registration'
      break
    case this.flowTypes.aod:
      next = '/assistance-on-demand'
      break
    case this.flowTypes.convenientInvoicing:
      next = '/convenient-invoicing'
      break
    case this.flowTypes.convenientInvoicingSettings:
      next = '/convenient-invoicing-settings'
      break

    case this.flowTypes.payment:
      next = '/payment'
      break
    default:
      try {
        next = await flowResourcePath(this.flowString)
      } catch (e) {
        next = this.defaultLink
      }
    }
    return router.resolve(next) ? next : this.defaultLink
  }

  toString () {
    return this.flowString.toUpperCase()
  }
}

/**
 * Flow navigator class. It's used to initiate flow, detect router url and start
 */
class Navigator {
  flow = null
  start = '/'

  constructor () {
    if (!flowInstance) {
      this.checkAction()
      this.refresh()
      if (!this.checkAction()) {
        this.checkClickMenuItem()
      }
      flowInstance = this
    }
    return flowInstance
  }

  async defineFlow () {
    let clickMenuItem = null
    let flow = (store && store.getters['app/findParam']('flow', '')) || ''
    flow = flow === 'null' ? null : flow
    clickMenuItem = getURLParameter('hybridweb') ? this.getClickMenuItem() : null
    if (!clickMenuItem && !flow) {
      return new CustomFlow()
    }
    flow = flow.toUpperCase()
    // For MIVB flow we need to redirect it to the other MIVBSTIB
    if (flow === 'MIVB') {
      flow = 'MIVBSTIB'
    }
    store && store.commit('app/setFlow', flow)
    const mobilityModeMenu = store && store.getters['user/mobilityModeMenuNative']
    if (mobilityModeMenu && mobilityModeMenu.length) {
      let resourceFlow = null
      if (clickMenuItem) {
        resourceFlow = mobilityModeMenu.find(v => {
          return v.resourceOspId === clickMenuItem && v.authorized
        })
      }
      if (!resourceFlow) {
        resourceFlow = mobilityModeMenu.find(v => {
          if (!v.authorized) {
            return false
          }
          const mobilityProvider = v.mobilityProvider.ospId.toUpperCase() || ''
          const search = [
            mobilityProvider,
            `${v.mobilityMode}_${mobilityProvider}`
          ]
          return search.includes(flow) || search.includes(flow.replace('_', ''))
        })
      }
      if (resourceFlow) {
        try {
          return new ResourceFlow(resourceFlow)
        } catch (e) {
          console.log('e', e)
        }
      }
      const menuFlow = mobilityModeMenu.find(v => v.mobilityMode === flow && v.authorized)
      if (menuFlow) {
        return new MenuFlow(menuFlow)
      }
    }
    return new CustomFlow(flow)
  }

  /**
   * Define flow start url
   * @returns {Promise<*>}
   */
  async defineStart () {
    const next = await this.flow.getLink()
    store && store.commit('app/setStartRoute', next)
    return next
  }

  /**
   * Get flow base url or redirect to
   * @param redirect
   * @returns {Promise<string>}
   */
  async getStart (redirect = false) {
    if (!redirect) {
      return this.start
    }
    try {
      if (router.resolve(this.start)) {
        await router.push(this.start)
      }
    } catch (err) {
      appLog(err)
    }
  }

  checkFlow (type) {
    return this.flow.toString() === type
  }

  hasFlow () {
    return !!this.flow.toString()
  }

  getFlow () {
    return this.flow.toString()
  }

  /**
   * get clickMenuItem if it was defined
   * @returns {*|string}
   */
  getClickMenuItem () {
    return (store && store.getters['app/findParam']('clickMenuItem')) || ''
  }

  /**
   * Define flow resource based on clickMenuItem query param
   */
  checkClickMenuItem () {
    const item = this.getClickMenuItem()
    if (item) {
      store && store.commit('app/setFlowResource', item.toUpperCase())
    }
  }

  /**
   * Check if ticket action must be executed
   * @returns {boolean|boolean|void|Promise<boolean>|null}
   */
  checkAction () {
    let ticketAction = false
    try {
      ticketAction = store && store.getters['app/findParam']('ticketAction')
    } catch (e) {
      console.log(e)
    }
    if (ticketAction) {
      const action = new Action()
      return action.resolve()
    }
    return null
  }

  /**
   * Reinit flow
   * @returns {Promise<boolean>}
   */
  async refresh () {
    this.flow = await this.defineFlow()
    this.start = await this.defineStart()
    return true
  }

  /**
   * switch to another flow after one was defined mostly using postMessage "startFlow"
   * @returns {Promise<*>}
   */
  async switchFlow () {
    const action = this.checkAction()
    await this.refresh()
    if (!action) {
      this.checkClickMenuItem()
    }
    store && store.commit('app/setFlow', this.getFlow())
    store && store.commit('app/setAppKey', Date.now().toString())
    return this.getFlow()
  }
}

export default Navigator
