import { first, keyBy, values, get, flatten } from 'lodash'
import * as api from '../../api'
import Vue from 'vue'
import moment from 'moment'
export const travel = {
  namespaced: true,
  state: {
    dailyTravels: {},
    monthlyTravelTotals: {},
    commuteDistances: {},
    enabled: null,
    distances: {},
    lastOrigins: [],
    lastDestinations: [],
    maximumProcessedDate: null
  },
  getters: {
    dailyTravels: state => state.dailyTravels,
    monthlyTravelTotals: state => state.monthlyTravelTotals,
    maximumProcessedDate: state => state.maximumProcessedDate,
    commuteDistances: state => get(state, 'commuteDistances', []).filter(({ validFrom, validTo }) => moment().isSameOrAfter(moment(validFrom)) && (!validTo || moment().isSameOrBefore(moment(validTo)))),
    modeCommuteDistances: state => mode => get(state, 'commuteDistances', []).filter(({ mobilityMode, validFrom, validTo }) => mobilityMode === mode && moment().isSameOrAfter(moment(validFrom)) && (!validTo || moment().isSameOrBefore(moment(validTo)))),
    enabled: state => state.enabled,
    lastOrigins: state => state.lastOrigins,
    lastDestinations: state => state.lastDestinations,
    modeDistances: state => mobilityMode => get(state.distances, mobilityMode, []),
    allDistances: state => flatten(values(state.distances)) || [],
    findDistance: state => distanceId => (flatten(values(state.distances)) || []).find(({ commuteDistanceId }) => commuteDistanceId === distanceId)
  },
  actions: {
    fetchTravels ({ commit }, form) {
      return api.travel.getByFilter(form, ({ dailyTravels, monthlyTravelTotals, commuteDistances, maximumProcessedDate }) => {
        commit('setDailyTravels', dailyTravels)
        commit('setMonthlyTravelTotals', monthlyTravelTotals)
        commit('setCommuteDistances', commuteDistances)
        commit('setMaximumProcessedDate', maximumProcessedDate)
      })
    },
    fetchEnabled ({ commit }) {
      return api.travel.getEnabled(res => {
        commit('setEnabled', res)
      })
    },
    fetchDistances ({ commit }, mobilityMode) {
      return api.travel.getDistances(mobilityMode, distances => {
        commit('setDistances', { mobilityMode, distances })
      })
    },
    updateDailyTravel ({ commit }, form) {
      return api.travel.commuteUpdate(form, ({ dailyTravel, monthlyTravelTotals }) => {
        commit('updateDailyTravel', {
          date: form.date,
          dailyTravel
        })
        commit('setMonthlyTravelTotals', monthlyTravelTotals)
      })
    },
    updateHomeWork ({ commit }, form) {
      return api.travel.homeworkUpdate(form, ({ dailyTravel, monthlyTravelTotals }) => {
        commit('updateDailyTravel', {
          date: form.date,
          dailyTravel
        })
        commit('setMonthlyTravelTotals', monthlyTravelTotals)
      })
    },
    updateBusinessTrip ({ commit }, form) {
      return api.travel.businessTripUpdate(form, ({ dailyTravel, monthlyTravelTotals }) => {
        commit('updateDailyTravel', {
          date: form.date,
          dailyTravel
        })
        commit('setMonthlyTravelTotals', monthlyTravelTotals)
      })
    },
    deleteBusinessTrip ({ commit }, { date, uuid }) {
      return api.travel.businessTripDelete(uuid, ({ dailyTravel, monthlyTravelTotals }) => {
        commit('updateDailyTravel', {
          date,
          dailyTravel
        })
        commit('setMonthlyTravelTotals', monthlyTravelTotals)
      })
    },
    fetchLastAddresses ({ commit }) {
      const getOrigins = api.travel.businessTripLastOrigins(({ lastLocations }) => {
        commit('setLastOrigins', lastLocations)
      })
      const getDestinations = api.travel.businessTripLastDestinations(({ lastLocations }) => {
        commit('setLastDestinations', lastLocations)
      })
      return Promise.all([getOrigins, getDestinations])
    }
  },
  mutations: {
    updateDailyTravel (state, { date, dailyTravel }) {
      Vue.set(state.dailyTravels, date, dailyTravel)
    },
    setDailyTravels (state, dailyTravels) {
      state.dailyTravels = keyBy(dailyTravels, function (travel) {
        const { date, businessTrips } = first(values(travel).filter(v => v))
        return get(businessTrips, [0, 'date'], date)
      })
    },
    setMonthlyTravelTotals (state, data) {
      state.monthlyTravelTotals = data
    },
    setCommuteDistances (state, data) {
      state.commuteDistances = data
    },
    setMaximumProcessedDate (state, maximumProcessedDate) {
      state.maximumProcessedDate = maximumProcessedDate
    },
    setEnabled (state, data) {
      state.enabled = data
    },
    setDistances (state, { mobilityMode, distances }) {
      Vue.set(state.distances, mobilityMode, distances)
    },
    setLastOrigins (state, lastLocations) {
      state.lastOrigins = lastLocations
    },
    setLastDestinations (state, lastLocations) {
      state.lastDestinations = lastLocations
    }
  }
}
