import { assign, get, mapKeys, mapValues, upperFirst, isArray, isObject } from 'lodash'
import { API_URL, trans } from '../../helpers'

/**
 * Favorite resource base class
 */
class Favorite {
  Remark = ''
  Reason = ''

  constructor (object = {}) {
    assign(this, object)
  }

  static createForMobilityMode (mobilityMode) {
    return new this(this.getDefaultDataForMobilityMode(mobilityMode))
  }

  static createFromBase64 (base64Data) {
    const data = JSON.parse(atob(base64Data))
    return new this(data)
  }

  static getDefaultDataForMobilityMode (mobilityMode) {
    return {
      MobilityMode: mobilityMode.mobilityMode,
      ResourceProviderId: mobilityMode.resourceOspId,
      OspId: mobilityMode.mobilityProvider.ospId,
      Title: trans(mobilityMode.resourceName),
      AppFlowType: mobilityMode.appFlowType,
      ImageUrl: this.getImageUrl(mobilityMode)
    }
  }

  static getImageUrl ({ mobilityMode }) {
    return `${API_URL}/mobile-app-service/v1/images/mobilityModeIcon/${mobilityMode}/get`
  }

  static formatKeysTo (object, formatter = upperFirst) {
    const formatTypeTo = (payload, formatter) => {
      if (isArray(payload)) {
        return payload.map(v => formatTypeTo(v, formatter))
      }
      if (isObject(payload)) {
        return this.formatKeysTo(payload, formatter)
      }
      return payload
    }

    const data = mapKeys(object, (v, k) => formatter(k))
    return mapValues(data, v => formatTypeTo(v, formatter))
  }

  getForm () {
    return {}
  }

  getResourceOspId () {
    return this.ResourceProviderId
  }

  getRemark () {
    return this.Remark
  }

  getUsageReason () {
    return this.Reason
  }

  setRemark (remark) {
    this.Remark = remark
  }

  setUsageReason (reason) {
    this.Reason = reason
  }

  toString () {
    return btoa(JSON.stringify({ ...this }))
  }

  get keysForComparison () {
    return ['ResourceProviderId']
  }

  equalTo (favoriteData = new Favorite({})) {
    const keys = this.keysForComparison
    for (const key of keys) {
      const sourceValue = get(this, key) === null ? '' : get(this, key)
      const targetValue = get(favoriteData, key) === null ? '' : get(favoriteData, key)
      if (sourceValue !== targetValue) {
        return false
      }
    }
    return true
  }
}

export default Favorite
