const lazyLoad = (component) => {
  return () => import(/* webpackChunkName: "convenient-invoicing" */ '../../components/convenientInvoicing/' + component)
}

export default [
  {
    path: '/convenient-invoicing',
    name: 'convenient_invoicing',
    component: lazyLoad('ConvenientInvoicing')
  },
  {
    path: '/convenient-invoicing/index',
    name: 'convenient_invoicing_index',
    component: lazyLoad('ConvenientInvoicingPage')
  },
  {
    path: '/convenient-invoicing/quote',
    name: 'convenient_invoicing_quote',
    component: lazyLoad('ConvenientInvoicingQuotePage')
  },
  {
    path: '/convenient-invoicing/mobib',
    name: 'convenient_invoicing_mobib',
    component: lazyLoad('ConvenientInvoicingMobibPage')
  },
  {
    path: '/convenient-invoicing/number-plate',
    name: 'convenient_invoicing_number_plate',
    component: lazyLoad('ConvenientInvoicingNumberPlatePage')
  },
  {
    path: '/convenient-invoicing-settings',
    name: 'convenient_invoicing_settings',
    component: lazyLoad('ConvenientInvoicingSettingsPage')
  },
  {
    path: '/convenient-invoicing-settings-no-contract',
    name: 'convenient_invoicing_settings_no_contract',
    component: lazyLoad('ConvenientInvoicingSettingsNoContractPage')
  },
  {
    path: '/convenient-invoicing-enrollment',
    name: 'convenient_invoicing_enrollment',
    component: lazyLoad('ConvenientEnrollmentPage')
  }
]
