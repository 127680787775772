<template>
    <a
      href="#"
      class="c-navbar__back"
      @click="goBack"
    >
      <o-icon
        icon="back-ios"
        v-if="os !== 'android'"
      />
      <o-icon
        icon="back-android"
        v-if="os === 'android'"
      />
    </a>
</template>

<script>
export default {
  name: 'OBackButton',
  computed: {
    os () {
      const ua = navigator.userAgent.toLowerCase()
      const os = ua.indexOf('android') > -1 ? 'android' : 'ios'
      return os
    }
  },
  methods: {
    goBack (e) {
      e.preventDefault()
      this.$emit('go-back')
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
  .c-navbar__back {
    display: block;
    margin-right: 3.2rem;
    opacity: 0.6;
    .c-icon {
      display: block;
    }
  }
</style>
