import { store } from '../store'
import { appLog } from './app'
import sha1 from 'js-sha1'
import * as api from '../api'
import Flow from '../helpers/Flow'
import postMessage from '../Classes/postMessageService/postMessage'

/**
 * Reseller authorization
 * @param clientId
 * @param resolve
 * @returns {Promise<string|boolean>}
 */
export const sso = async (clientId, resolve) => {
  appLog('sso auth')
  // check if we should use SSO
  await store.dispatch('app/checkSSORequirement', clientId)

  // postMessage.pageLoaded()
  if (!store.state.app.requireSSO) {
    return false
  }
  try {
    appLog('awaitAuthorizationCode', 'start')
    await awaitAuthorizationCode()
    appLog('awaitAuthorizationCode', 'end')
    // try to authorise user using SSO
    await store.dispatch('user/authReseller')
    await getPostLoginData()
    const response = await new Flow().getStart(false)
    return response
  } catch (err) {
    appLog(err)
    return false
  }
}

/**
 * Native user authorization
 * @param resolve
 * @returns {Promise<string|boolean>}
 */
export const hybrid = async (resolve) => {
  appLog('hybrid auth')
  try {
    appLog('awaitApiToken', 'start')
    await awaitApiToken()
    appLog('awaitApiToken', 'end')
    // try to authorise user using SSO
    await store.dispatch('user/authHybrid')
    await getPostLoginData()
    const response = await new Flow().getStart(false)
    return response
  } catch (err) {
    appLog(err)
    return false
  }
}

/**
 * Bawe login-password authorization
 * @param username
 * @param passdigest
 * @returns {Promise<*|boolean>}
 */
export const user = async (username, passdigest) => {
  const response = await api.userAuth({
    username,
    passdigest: sha1(passdigest)
  }, response => {
    store.commit('user/setTokens', response)
  })
  if (!response) {
    return false
  }
  await getPostLoginData()
  await new Flow().getStart(true)
  return response
}

/**
 * Retrieve base auth user data and check user language
 * @returns {Promise<any>}
 */
const getPostLoginData = async () => {
  locationRequired() && postMessage.getLocation()
  // sendPostMessage('getLocation')
  await Promise.all([
    store.dispatch('user/getProfile'),
    store.dispatch('app/getBranding'),
    store.dispatch('user/checkConditionsStatus'),
    store.dispatch('user/getMobilityMenu')
  ])
  await store.dispatch('app/checkLanguage')
  await defineFlow()
  return store.dispatch('convenientInvoicing/checkContract')
}

/**
 * Define target flow
 * @returns {Promise<*>}
 */
const defineFlow = () => {
  return new Flow().switchFlow()
}

/**
 * Waiting for authorization code is sent by reseller app
 * @returns {Promise<unknown>}
 */
const awaitAuthorizationCode = async () => {
  let start = 0
  const delay = 100
  const end = process.env.VUE_APP_AWAIT_TIMER * 1000

  return new Promise((resolve, reject) => {
    const timer = setInterval(() => {
      start += delay
      if (store.state.app.authorizationCode) {
        clearInterval(timer)
        resolve(store.state.app.authorizationCode)
      }
      if (start > end) {
        clearInterval(timer)
        reject(new Error('No authorizationCode message.'))
      }
    }, delay)
  })
}

/**
 * Waiting for authorization token is sent by native app
 * @returns {Promise<unknown>}
 */
const awaitApiToken = async () => {
  let start = 0
  const delay = 100
  const end = process.env.VUE_APP_AWAIT_TIMER * 1000

  return new Promise((resolve, reject) => {
    const timer = setInterval(() => {
      start += delay
      if (store.state.user.token || store.state.user.refreshToken) {
        clearInterval(timer)
        resolve(true)
      }
      if (start > end) {
        clearInterval(timer)
        reject(new Error('No token message.'))
      }
    }, delay)
  })
}

const locationExcludeList = [
  'DELIJN',
  'DAKT',
  'RESELLERB2B',
  'RESELLERB2BSETTINGS'
]

/**
 * Check if user location is required
 * @returns {boolean}
 */
const locationRequired = () => {
  const flow = store.getters['app/findParam']('flow')
  return !(flow && locationExcludeList.includes(flow))
}
