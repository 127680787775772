<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container modal-container_confirmation">
          <div class="modal-header">
            <slot name="header">
            </slot>
          </div>
          <div class="modal-body">
            <slot name="body">
            </slot>
          </div>
          <div class="modal-footer">
            <slot name="footer">
              <o-button
                class="modal-container_confirmation--button-no"
                :label="labelNo || $t('html5.global.no')"
                :is-floating="false"
                @click="$emit('close')"
              />
              <o-button
                class="modal-container_confirmation--button-yes"
                :label="labelYes || $t('html5.global.yes')"
                :is-floating="false"
                @click="$emit('confirm')"
              />
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'OConfirmModal',
  data () {
    return {
    }
  },
  props: {
    labelNo: {
      type: String,
      default: ''
    },
    labelYes: {
      type: String,
      default: ''
    }
  }
}

</script>

<style lang="scss">
    .modal-mask {
      position: fixed;
      z-index: 9998;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, .5);
      display: table;
      transition: opacity .3s ease;
    }

    .modal-wrapper {
      display: table-cell;
      vertical-align: middle;
    }

    .modal-container.modal-container_confirmation {
      width: 300px;
      margin: 0px auto;
      padding: 20px 28px;
      background-color: #fff;
      border-radius: 20px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
      transition: all .3s ease;
      font-family: Helvetica, Arial, sans-serif;
      overflow: hidden;
      .modal-footer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: calc(100% + 62px);
        margin: 0 -31px -20px;
        .c-button {
          width: 50%;
          border: none;
          border-top: 0.1rem solid rgba(0,0,0,.1);
          border-left: 0.1rem solid rgba(0,0,0,.1);
          border-radius: 0;

          & + .c-button {
            margin-top: 0;
          }
        }
      }
      .modal-header h3 {
        margin-top: 0;
        line-height: 1.5em;
        font-size: 110%;
        font-weight: bold;
        text-align: center;
        /*color: #42b983;*/
      }

      .modal-body {
        margin: 20px 0;
        text-align: center;
        p {
          color: #777
        }
      }
    }
    .modal-default-button {
      float: right;
    }

    /*
     * The following styles are auto-applied to elements with
     * transition="modal" when their visibility is toggled
     * by Vue.js.
     *
     * You can easily play with the modal transition by editing
     * these styles.
     */

    .modal-enter {
      opacity: 0;
    }

    .modal-leave-active {
      opacity: 0;
    }

    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
</style>
