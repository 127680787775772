<template>
  <div>
    <!--      <transition name="fade">-->
    <div
      class="c-notification__cover"
      :class="{ 'c-notification__cover--show': showComponent }"
      @click="allowClose && onClick()"
    />
    <!--      </transition>-->

    <!--      <transition name="toggle">-->
    <div
      class="c-notification"
      :key="key"
      :class="[
        showComponent ? 'c-notification--open' : '',
        isTall ? 'c-notification__tall': '',
        isFlex ? 'c-notification__flex' : '',
        type
         ]"
    >
      <div class="c-notification__header">
        <h2 class="c-notification__title" v-html="title" v-if="title"></h2>
        <o-icon
          v-if="allowClose"
          class="c-notification__close"
          icon="close"
          @click.native="onClick"
        />
      </div>
      <div class="c-notification__search">
        <slot name="search"/>
      </div>
      <div class="c-notification__content" :class="{'c-notification__content--flex': isFlexContent}">
        <div class="c-notification__text" v-if="text">
          <p v-html="text"></p>
        </div>
        <div class="c-notification__list" v-else-if="isList">
          <slot name="list"></slot>
        </div>
        <slot/>
      </div>
    </div>
    <!--      </transition>-->
  </div>

</template>

<script>
export default {
  name: 'ONotification',
  data () {
    return {
      visible: false,
      key: ''
    }
  },
  props: {
    isList: {
      type: Boolean,
      default: false
    },
    show: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      required: false
    },
    title: {
      type: String,
      required: false
    },
    type: {
      type: String,
      default: '',
      required: false
    },
    isTall: {
      type: Boolean,
      default: false
    },
    isFlex: {
      type: Boolean,
      default: false
    },
    isFlexContent: {
      type: Boolean,
      default: false
    },
    allowClose: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    showComponent () {
      return this.show || this.visible
    }
  },
  methods: {
    onClick () {
      this.close()
      this.$emit('close')
    },
    open () {
      this.key = Date.now().toString()
      this.visible = true
    },
    close () {
      this.visible = false
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
@import '~@/assets/styles/variables.scss';
.c-notification {
  background: #fff;
  border-radius: 1.2rem 1.2rem 0 0;
  box-shadow: $box-shadow-large, $box-shadow-large;
  bottom: 0;
  left: 0;
  max-height: 95vh;
  overflow: auto;
  /*padding: 2.4rem;*/
  position: absolute;
  right: 0;
  opacity: 0;
  transform: translateY(100%);
  transition: all 0.2s ease-in-out;
  z-index: 126;

  display: flex;
  flex-direction: column;
  padding: 0;
  a {
    color: $primary-color;
    text-decoration: underline;
  }

  &__header {
    padding: 2.4rem 2.4rem 0 2.4rem;
    min-height: 4rem;
    margin-bottom: 0.8rem;
  }

  &__content {
    padding: 0 2.4rem 2.4rem;
    overflow-y: auto;
    flex: 0 1;

    p {
      line-height: 2.4rem;
      padding-right: 2.4rem;
    }

    p:not(:last-child) {
      margin-bottom: 1.6rem;
    }
    &--flex {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
  }

  /*transform: translateY(100%);*/
  &__cover {
    background: rgba(#000, 0.4);
    bottom: 0;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity 0.1s linear;
    z-index: 125;
    opacity: 0;

    &--show {
      opacity: 1;
      pointer-events: auto;
    }
  }

  &--open {
    opacity: 1;
    /*transform: translateY(0);*/
    transform: none;
  }

  &__title {
    font-weight: 600;
  }

  &__text {
    line-height: 2.4rem;
    padding-right: 2.4rem;
  }

  &__text:not(:last-child) {
    margin-bottom: 1.6rem;
  }

  &__list {
    margin: 0 -1.6rem;
  }

  &__close {
    cursor: pointer;
    position: absolute;
    right: 1.6rem;
    top: 1.6rem;
  }

  &__tall {
    max-height: 90%;
    min-height: 90%;
  }

  &__flex {
    max-height: 90%;
    min-height: 30%;
  }

  &.internal {
    height: 100%;
    max-height: 100%;
    border-radius: 0;
    padding: 0;
    overflow: hidden;

    .c-notification__iframe {
      height: 100%;
      width: 100%;

      iframe {
        height: 100%;
        width: 100%;
      }
    }
  }

  &.full-width {
    .c-notification__content {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.overflow-visible {
    overflow: visible;
  }
}

/*.fade-enter-active, .fade-leave-active {*/
/*  opacity: 1;*/
/*  pointer-events: auto;*/
/*}*/

/*.fade-enter, .fade-leave-to {*/
/*  opacity: 0;*/
/*  pointer-events: none;*/
/*}*/

/*.toggle-enter-active, .toggle-leave-active {*/
/*  opacity: 1;*/
/*  transform: translateY(0);*/
/*}*/

/*.toggle-enter, .toggle-leave-to {*/
/*  opacity: 0;*/
/*  transform: translateY(100%);*/
/*}*/

.c-tablet {
  .c-notification__cover {
    position: fixed;
  }
  .c-notification {
    position: fixed;
  }
}

</style>
