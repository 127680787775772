// const data = {
//   'Authorized': true,
//   'Coordinate': { 'Latitude': 50.853306, 'Longitude': 4.349708 },
//   'MobilityMode': 'PARKING',
//   'OspId': { 'OspId': 'BeMobile.RESOURCE.GEO_RESOURCE.PARKINGLOT.040867' },
//   'MrpOspId': { 'OspId': 'BeMobile' },
//   'Street': null,
//   'City': null,
//   'Zipcode': null,
//   'PhoneNumber': null,
//   'Name': { 'DefaultValue': '040867', 'LabelTranslations': [{ 'LanguageCode': 'fr', 'Text': '040867' }] },
//   'InfoText': null,
//   'Properties': [],
//   'ServiceResources': []
// }

import Favorite from './Favorite'
import { upperFirst } from 'lodash'

class PoiFavorite extends Favorite {
  static createForPoi (poi) {
    return new this(this.formatKeysTo(poi, upperFirst))
  }

  get keysForComparison () {
    return ['OspId.OspId']
  }

  getResourceOspId () {
    return this.OspId.OspId
  }
}

export default PoiFavorite
