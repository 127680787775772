import postMessageSender from './postMessageSender'
import { POST_MESSAGE, RESELLER_B2B_USER_ROLE } from '../../helpers'
import PostMessageObserver from './PostMessageObserver'
import {
  CameraOptions,
  EnableDeviceSettingsRequest,
  FutureNotificationRequest, GetDeviceStatusResponse, StringGeolocation,
  NextStepRequest,
  PostMessageSender as IPostMessageSender,
  SetGeofenceRequest, Geolocation, DefaultResponse
} from '@/Classes/postMessageService/postMessage.types'
import { mapValues, isString, trim } from 'lodash'

/**
 * Class response for registering possible postMessage APIs for sending them to the native app
 */
class PostMessage {
  private static instance: PostMessage
  private sender: IPostMessageSender

  private constructor () {
    this.sender = postMessageSender
  }

  public static getInstance (): PostMessage {
    if (!PostMessage.instance) {
      PostMessage.instance = new PostMessage()
    }
    return PostMessage.instance
  }

  /**
   * Deprecated
   * */
  pageLoaded () {
    return true
    // return this.sender.request(POST_MESSAGE.PAGE_LOADED)
  }

  pageReady () {
    return this.sender.request(POST_MESSAGE.PAGE_READY)
  }

  nextStep (request: NextStepRequest) {
    request = mapValues(request, v => isString(v) ? trim(v) : v) as NextStepRequest
    return this.sender.request(POST_MESSAGE.NEXT_STEP, { flow: 'TICKET', ...request })
  }

  setGeofence (request: SetGeofenceRequest) {
    const form = {
      range: 50,
      ...request,
      ...this.formatLocation(request)
    }
    return this.sender.request(POST_MESSAGE.SET_GEOFENCE, form)
  }

  getLocation () {
    return this.sender.request(POST_MESSAGE.GET_LOCATION)
  }

  refreshTickets () {
    return this.sender.request(POST_MESSAGE.REFRESH_TICKETS)
  }

  getPicture (request: CameraOptions) {
    return this.sender.request(POST_MESSAGE.GET_PICTURE, {
      cameraOptions: {
        destinationType: 0,
        mediaType: 0,
        quality: 50,
        encodingType: 0,
        ...request
      }
    })
  }

  refreshWallet () {
    return this.sender.request(POST_MESSAGE.REFRESH_WALLET)
  }

  scanBarcode (data?: {type: string}) {
    return this.sender.request(POST_MESSAGE.SCAN_BARCODE, data)
  }

  async startPayment (paymentData: {paymentToken?: string}) {
    if (paymentData.paymentToken) {
      return this.startPaymentToken(paymentData.paymentToken)
    }
    return this.sender.request(POST_MESSAGE.START_PAYMENT, paymentData)
  }

  // sendStartPayment (paymentData: {paymentToken?: string}) {
  //   if (paymentData.paymentToken) {
  //     return this.startPaymentToken(paymentData.paymentToken)
  //   }
  //   return this.sender.request(POST_MESSAGE.START_PAYMENT, paymentData)
  // }

  startPaymentToken (token: string) {
    return this.sender.token(POST_MESSAGE.START_PAYMENT_TOKEN, token)
  }

  openUrl (url: string) {
    return this.sender.request(POST_MESSAGE.OPEN_URL, { url })
  }

  sendEmail (email: string) {
    return this.sender.request(POST_MESSAGE.SEND_EMAIL, { email })
  }

  makePhoneCall (phoneNumber: string) {
    return this.sender.request(POST_MESSAGE.MAKE_PHONE_CALL, { phoneNumber })
  }

  openUrlEmbedded (url: string) {
    return this.sender.request(POST_MESSAGE.OPEN_URL_EMBEDDED, { url })
  }

  close (force?: boolean) {
    return this.sender.request(POST_MESSAGE.CLOSE, { force })
  }

  /**
   * @param options Object with location: true OR pushMessage: true
   * @returns {boolean}
   */
  enableDeviceSettings (options: EnableDeviceSettingsRequest) {
    return this.sender.request(POST_MESSAGE.ENABLE_DEVICE_SETTINGS, options)
  }

  getDeviceSettingsStatus (options = {
    pushMessage: true,
    location: true
  }): Promise<GetDeviceStatusResponse> {
    return new Promise((resolve, reject) => {
      PostMessageObserver.subscribe(POST_MESSAGE.GET_DEVICE_SETTINGS_STATUS, (response) => {
        if (response !== undefined) {
          return resolve(response)
        }
        reject(new Error('PostMessage response is undefined'))
      })
      this.sender.request(POST_MESSAGE.GET_DEVICE_SETTINGS_STATUS, options)
    })
  }

  setFutureNotifications (input: FutureNotificationRequest) {
    const data = {
      action: 'SET',
      input: {
        ...input,
        geolocation: input.geolocation && this.formatLocation(input.geolocation)
      }
    }
    return this.sender.request(POST_MESSAGE.MANAGE_FUTURE_NOTIFICATIONS, data)
  }

  clearFutureNotifications (input: Partial<FutureNotificationRequest> = {}) {
    const data = {
      action: 'CLEAR',
      input
    }
    return this.sender.request(POST_MESSAGE.MANAGE_FUTURE_NOTIFICATIONS, data)
  }

  navigateTo (code = 'OK') {
    return this.sender.response(POST_MESSAGE.NAVIGATE_TO, { code })
  }

  confirmBiometrics () {
    return this.sender.response(POST_MESSAGE.CONFIRM_BIOMETRICS)
  }

  keepAlive () {
    return this.sender.response(POST_MESSAGE.KEEP_ALIVE)
  }

  getAccount (): Promise<DefaultResponse> {
    return new Promise((resolve, reject) => {
      PostMessageObserver.subscribe(POST_MESSAGE.GET_ACCOUNT, (response) => {
        if (response !== undefined) {
          return resolve(response)
        }
        reject(new Error('PostMessage response is undefined'))
      }, 30)
      this.sender.request(POST_MESSAGE.GET_ACCOUNT)
    })
  }

  processTickets (ticketResponse: any) {
    return this.sender.response(POST_MESSAGE.PROCESS_TICKETS, { code: 'OK', ticketResponse })
  }

  protected formatLocation ({ latitude, longitude }: StringGeolocation): Geolocation {
    return {
      latitude: parseFloat(latitude),
      longitude: parseFloat(longitude)
    }
  }
}

export default PostMessage.getInstance()
