<template>
  <div class="c-picture-uploader" :class="'size-' + size">
    <div class="c-picture-uploader__label" v-if="label">{{ label }}</div>
    <o-icon class="c-picture-uploader__icon" icon="camera" v-if="!image" />
    <img
      class="c-picture-uploader__preview"
      :src="image"
      v-if="image"
    />
    <div class="c-error" v-if="!valid">{{ getErrorMessage($t('html5.validations.required'), label) }}</div>
  </div>
</template>

<script>
import { getErrorMessage } from '../../helpers'

export default {
  name: 'OPictureUploader',
  props: {
    image: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    valid: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: 'medium'
    }
  },
  methods: {
    getErrorMessage (message, label = '') {
      return getErrorMessage(message, label)
    }
  }
}
</script>

<style lang="scss">
@import '~@/assets/styles/variables.scss';
.c-picture-uploader {
  background: #fff;
  border: 0.1rem solid rgba($primary-color, 0.2);
  border-radius: 0.8rem;
  box-shadow: $box-shadow-list;
  cursor: pointer;
  height: 13.6rem;
  margin: 2.4rem auto;
  position: relative;
  width: 21.2rem;
  display: flex;
  justify-content: center;
  align-items: center;

  .c-error {
    position: absolute;
    bottom: 0;
    text-align: center;
    margin-bottom: 2px;
  }
  &.size-large {
    height: 20.4rem;
    width: 31.8rem;
    max-width: 90%;
  }
}

.c-picture-uploader__label {
  background: $background-color;
  border-radius: 0 0 0.4rem 0.4rem;
  color: $primary-color;
  color: var(--primary-color);
  font-size: 1rem;
  font-weight: 600;
  left: 50%;
  padding: 0.2rem 0.6rem;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  text-transform: uppercase;
  z-index: 2;
}

.c-picture-uploader__icon {
  color: $primary-color;
  color: var(--primary-color);
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.c-picture-uploader__preview {
  border-radius: 0.4rem;
  width: auto;
  height: auto;
  max-height: calc(100% - .8rem);
  max-width: calc(100% - .8rem);
  /*min-height: calc(100% - .8rem);*/
  /*min-width: calc(100% - .8rem);*/
  margin: .4rem;
}
</style>
