<template>
  <div
    class="c-button-group"
    :class="{
      'c-button-group--floating': isFloating,
      'c-button-group--relative': isRelative,
      'c-button-group--fixed': isFixed
    }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'OButtonGroup',
  props: {
    isFloating: {
      type: Boolean,
      default: null
    },
    isFixed: {
      type: Boolean,
      default: null
    },
    isRelative: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">

.c-button-group--floating {
  bottom: 1.6rem;
  left: 1.6rem;
  position: absolute;
  right: 1.6rem;
}

.c-button-group--fixed {
  bottom: 1.6rem;
  left: 1.6rem;
  position: fixed;
  right: 1.6rem;
}

.c-button-group--relative {
  margin: 15px;
  position: relative;
  width: auto;
  left: 0;
}
</style>
