/**
 * @module Fuel API
 */
import { Api, appEndPoint, appEndPointV3 } from './api'

const API_PREFIX = `${appEndPoint}/fuelcard`

/**
 * @description The name of the lotalty programme and the info text explaining what the loaylty programme is.
 * @param serviceResourceOspId
 * @param cb Callback
 * @apiResponse {
  "data": {
    "cards": [
      {
        "cardExtId": "string",
        "cardpan": "string",
        "driver": {
          "firstname": "string",
          "language": "aa",
          "lastname": "string",
          "title": "string"
        },
        "options": {
          "fuelTypeCode": "ALL",
          "fuelTypeLabel": "string",
          "limitCode": "BASE",
          "limitLabel": "string",
          "reportingCode": "PIN",
          "reportingLabel": "string"
        },
        "reference": "string",
        "statusCode": "ACTIVE",
        "statusLabel": "string",
        "typeCode": "KBCMM",
        "typeLabel": "string"
      }
    ],
    "companyNumber": "string",
    "contractExtId": "string",
    "embossingType": "CN",
    "startDate": "yyyy-MM-dd"
  },
  "status": "ACTIVE"
}
 * @return Promise
 * @exception NOT_FOUND
 */
export const contactSearch = (serviceResourceOspId, cb) => {
  // return new Promise(resolve => resolve(
  //   {
  //     'mrpOspId': 'string',
  //     'loyaltyProgrammeName': 'string',
  //     'loyaltyProgrammeInfoText': 'string',
  //     'loyaltyCodeInfoText': 'string',
  //     'poiQualificationRange': 200
  //   }
  // ))
  const api = new Api(cb)
  return api.query('post', `${API_PREFIX}/contract/search`, { serviceResourceOspId })
}

export const getInvoices = (contractId, cb = () => {}) => {
  // const response = [
  //   {
  //     creditNote: true,
  //     currency: 'AED',
  //     dueDate: '2022-01-30',
  //     invoiceDate: '2022-01-01',
  //     invoiceId: '3412214',
  //     invoiceNumber: '12',
  //     invoiceTypeCode: 'string',
  //     product: 'string',
  //     totalAmountGross: 12.2,
  //     totalDiscountGrossLast12Months: 32.3
  //   },
  //   {
  //     creditNote: true,
  //     currency: 'AED',
  //     dueDate: '2022-01-30',
  //     invoiceDate: '2022-01-01',
  //     invoiceId: '3412214',
  //     invoiceNumber: '13',
  //     invoiceTypeCode: 'string',
  //     product: 'string',
  //     totalAmountGross: 12.2,
  //     totalDiscountGrossLast12Months: 32.3
  //   }
  // ]
  // cb && cb(response)
  // return new Promise(resolve => resolve(response))
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/fuelcard/invoice/list`, { contractId })
}

export const getInvoiceDetails = ({ contractId, invoiceNumber }, cb = () => {}) => {
  // const response = [
  //   {
  //     cardType: 'string',
  //     cardpan: 'string',
  //     reference: 'string',
  //     transactions: {
  //       totalAmountGross: 0,
  //       totalAmountNet: 0,
  //       totalAmountVat: 120,
  //       transactions: [
  //         {
  //           appliedUnitDiscountNet: 0,
  //           appliedUnitPriceNet: 0,
  //           brand: 'string',
  //           country: 'AD',
  //           currency: 'AED',
  //           discountType: 'string',
  //           officialUnitPriceNet: 0,
  //           productName: 'string',
  //           pumpUnitPriceNet: 0,
  //           quantity: 0,
  //           stationName: 'string',
  //           timestamp: '2016-04-20T12:15:00',
  //           totalAmountGross: 0,
  //           totalDiscountGross: 0,
  //           voucherAmount: 0
  //         }
  //       ]
  //     }
  //   }
  // ]
  // cb && cb(response)
  // return new Promise(resolve => resolve(response))
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/fuelcard/invoice/detail`, { contractId, invoiceNumber })
}

export const getInvoiceDuplicate = ({ contractId, invoiceNumber }, cb = () => {}) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/fuelcard/invoice/duplicate`, { contractId, invoiceNumber })
}

/**
 * @description Get fuelcard prequote information
 * @param serviceResourceOspId
 * @param cb Callback
 * @apiResponse {
 *   "data": {
 *     "serviceCost": {
 *       "title": "string",
 *       "content": "string",
 *       "paymentStatus: "PAID" | "OPEN"
 *     },
 *     "cardCost": {
 *       "title": "string",
 *       "content": "string",
 *       "paymentStatus: "PAID" | "OPEN"
 *     },
 *     "cards": [
 *       {
 *         "typeCode": "string, e.g. 'KBCMM'",
 *         "typeLabel": "string, e.g. 'Multi brand card'",
 *         "description": "string, e.g. '"'Excluding fuel costs .....'"
 *       }
 *     ]
 *   }
 * }
 * @returns Promise
 */
export const getPrequote = (serviceResourceOspId, cb = () => {}) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPointV3}/sales/fuelcard/prequote`, { serviceResourceOspId })
}

export const activateCard = ({ cardExtId, contractExtId }, cb = () => {}) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/fuelcard/activate`, { cardExtId, contractExtId })
}

export const getStopConditions = ({ cardExtId, serviceResourceOspId }, cb = () => {}) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/fuelcard/stopconditions`, { cardExtId, serviceResourceOspId })
}

export const getOptions = ({ cardTypeCode, serviceResourceOspId }, cb = () => {}) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/fuelcard/options`, { cardTypeCode, serviceResourceOspId })
}

export const getQuote = (form, cb = () => {}) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPointV3}/sales/fuelcard/quote`, form)
}

export const stopCard = ({
  cardExtId,
  contractExtId,
  replaceCard,
  stopReasonCode
}, cb = () => {}) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/fuelcard/stop`, {
    cardExtId,
    contractExtId,
    replaceCard,
    stopReasonCode
  })
}
