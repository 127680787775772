/**
 * @module Budget API
 */
import { Api, appEndPoint, appEndPointV2, appEndPointV3 } from './api'

export const getBudgetTypes = cb => {
  const api = new Api(cb)
  return api.query('post', `${appEndPointV2}/budget/types/getForUser`)
}

export const getBudgetReasons = ({ id, type }, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/user/usageReasons/get`, {
    budgetOspId: id,
    budgetType: type
  })
}

export const getBudget = ({ type, timestamp }, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPointV2}/budget/overview/get`, {
    budgetType: type,
    requestTimestamp: timestamp
  })
}

export const getBudgetDetails = ({ mode, type, timestamp }, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPointV2}/budget/${mode}/detail`, {
    budgetType: type,
    requestTimestamp: timestamp
  })
}

export const updateBudgetDetails = ({ id, remark, reason }, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPointV2}/budget/detail/update`, {
    eventOspId: id,
    remark,
    usageReason: reason
  })
}

export const getAvailableBudgets = (serviceResourceOspId, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPointV2}/budget/getAvailable`, { serviceResourceOspId })
}

export const getBudgetSelectionDetails = (serviceResourceOspId, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPointV3}/budget/getBudgetSelectionDetails`, { serviceResourceOspId })
}

export const getAvailableForTransfer = (eventOspId, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPointV2}/budget/getAvailableForTransfer`, { eventOspId })
}

export const getDefaultBudgets = cb => {
  const api = new Api(cb)
  return api.query('post', `${appEndPointV2}/budget/getDefaultBudgets`)
}

export const updateDefaultBudget = ({ budgetOspId, serviceResourceOspId }, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPointV2}/budget/updateDefaultBudget`, {
    budgetOspId,
    serviceResourceOspId
  })
}

export const deleteDefaultBudget = (serviceResourceOspId, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPointV2}/budget/deleteDefaultBudget`, {
    serviceResourceOspId
  })
}
