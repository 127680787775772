const lazyLoad = (component) => {
  return () => import(/* webpackChunkName: "off-street-parking" */ '../../components/offstreetParking/' + component)
}

export default [
  {
    path: '/sales/off-street-parking/:resourceOspId',
    name: 'off_street_parking',
    component: lazyLoad('OffstreetParking')
  },
  {
    path: '/sales/off-street-parking/:resourceOspId/onboarding/license-plates',
    name: 'off_street_parking_onboarding_license_plates',
    component: lazyLoad('OffstreetParkingLicencePlatesPage'),
    meta: {
      onboarding: true
    }
  },
  {
    path: '/sales/off-street-parking/:resourceOspId/license-plates',
    name: 'off_street_parking_license_plates',
    component: lazyLoad('OffstreetParkingLicencePlatesPage'),
    meta: {
      onboarding: false
    }
  },
  {
    path: '/sales/off-street-parking/:resourceOspId/entrance-code',
    name: 'off_street_parking_entrance_code',
    component: lazyLoad('OffstreetParkingEntrancePage')
  },
  {
    path: '/sales/off-street-parking/:resourceOspId/onboarding/confirmation',
    name: 'off_street_parking_onboarding_confirmation',
    component: lazyLoad('OffstreetParkingOnboardingConfirmationPage')
  },
  {
    path: '/sales/off-street-parking/:resourceOspId/find-parking',
    name: 'off_street_parking_find',
    component: lazyLoad('OffstreetParkingFindPage')
  },
  {
    path: '/sales/off-street-parking/:resourceOspId/sessions',
    name: 'off_street_parking_sessions',
    component: lazyLoad('OffstreetParkingSessionsPage')
  },
  {
    path: '/sales/off-street-parking/:resourceOspId/sessions/:sessionUuid',
    name: 'off_street_parking_sessions_details',
    component: lazyLoad('OffstreetParkingSessionsDetailsPage')
  }
]
