import Vue from 'vue'
import Router from 'vue-router'
import { store } from '../store'
// eslint-disable-next-line
import { appLog, getConvenientInvoicingProviderId, MOBILITY_ASSISTANT_FLOW } from '../helpers'
import Flow from './Flow'
import { RESELLER_B2B_USER_ROLE } from '../helpers/constants'
import * as api from '../api'

import routes from './routes'
import postMessage from '@/Classes/postMessageService/postMessage'

Vue.use(Router)
export const router = new Router({
  routes
})

router.beforeEach(async (to, from, next) => {
  appLog(from, 'from')
  appLog(to, 'to')
  const flow = new Flow()
  const publicPages = ['/login', '/reseller/authorize'] // can be opened without user authorization
  const blockedByFlowPages = ['/', '/tickets']
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = store.getters['user/authorized']
  const acceptedConditions = store.getters['user/acceptedConditions']
  const showConvenientInvoicingEnrolment = store.getters['convenientInvoicing/enrollment']
  // set previous url
  const fromPath = from.path === '/' && from.name === null ? null : from.path
  store.commit('app/setPreviousUrl', fromPath)

  if (authRequired && !loggedIn) {
    return next('/login')
  }
  if (!acceptedConditions && loggedIn && to.path !== '/conditions' && to.path !== '/conditions-text') {
    return next('/conditions')
  }
  if (acceptedConditions && store.getters['user/hasRole'](RESELLER_B2B_USER_ROLE)) {
    if (showConvenientInvoicingEnrolment && to.path !== '/convenient-invoicing-enrollment') {
      return next('/convenient-invoicing-enrollment')
    }
    if (!store.getters['convenientInvoicing/status']) {
      await store.dispatch('convenientInvoicing/getConvenientInvoicingStatus')
    }
    const { showTokenTransferScreens } = store.getters['convenientInvoicing/status']
    if (showTokenTransferScreens && (to.path.indexOf('/convenient-invoicing') < 0)) {
      return postMessage.nextStep({ flow: 'RESELLERB2B', providerId: getConvenientInvoicingProviderId() })
    }
  }

  if (to.path === '/login' && loggedIn) {
    next('/')
  } else if ((to.path === '/conditions' || to.path === '/conditions-text') && acceptedConditions) {
    next('/')
  } else {
    if (blockedByFlowPages.includes(to.path)) {
      if (flow.hasFlow() && !flow.checkFlow(MOBILITY_ASSISTANT_FLOW)) {
        store.commit('app/setPreviousUrl', to.path)
        flow.getStart().then(start => next(start))
      } else {
        next()
      }
    } else {
      next()
    }
  }
})

router.afterEach((to, from) => {
  // Save custom route history
  store.dispatch('navigation/addRoute', { to, from })
})
