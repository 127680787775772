/**
 * @module OAuth API
 */
import {
  Api, appEndPoint
  // appEndPoint
} from './api'

export const verify = ({ redirectUri: mspRedirectUri, ...params }, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/oauth/ext/verify`, { mspRedirectUri, ...params })
}

export const authorize = ({ html5ClientId = null, ...parameters }, cb) => {
  const api = new Api(cb, { showAlert: false, returnError: true, showError: false, excludeMessageAlert: ['*'] })
  // return api.fake()
  return api.query('post', `${appEndPoint}/oauth/ext/authorize`, {
    html5ClientId,
    parameters
  })
}

export const revoke = (mrpOspId, cb) => {
  const api = new Api(cb, { returnError: true })
  return api.query('post', `${appEndPoint}/oauth/ext/revoke`, {
    mrpOspId
  })
}
