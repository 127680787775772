var render = function render(){var _vm=this,_c=_vm._self._c;return ((_vm.logo || _vm.showHeader) && false)?_c('div',{staticClass:"c-navbar",class:{
    'c-navbar--overlay': _vm.overlay,
    'c-navbar--android': _vm.os === 'android',
    'c-navbar--centered': _vm.isCentered,
    'c-navbar--extended': _vm.showHeader && _vm.isExtended,
    'c-navbar--background': _vm.backgroundImage !== null
  },style:({
    backgroundImage: _vm.backgroundImage ? `url('${_vm.backgroundImage}')` : 'none'
  })},[(_vm.showHeader && _vm.hasBackButton)?_c('o-back-button',{on:{"go-back":_vm.goBack}}):_vm._e(),(_vm.showHeader)?_c('h1',{staticClass:"c-navbar__title",domProps:{"innerHTML":_vm._s(_vm.title)}}):_vm._e(),(_vm.showHeader && (_vm.$store.state.showSupportButton || _vm.hasRefreshButton || _vm.hasCloseButton))?_c('div',{staticClass:"c-navbar__right"},[_vm._t("right",function(){return [(_vm.$store.state.showSupportButton)?_c('o-icon',{attrs:{"icon":"support"}}):_vm._e(),(_vm.showHeader && _vm.hasRefreshButton)?_c('o-refresh-button',{on:{"refresh":function($event){return _vm.$emit('refresh')}}}):_vm._e(),(_vm.hasCloseButton)?_c('router-link',{staticClass:"c-navbar__close",attrs:{"to":_vm.closeLink}},[_c('o-icon',{attrs:{"icon":"close"}})],1):_vm._e()]})],2):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }