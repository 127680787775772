<template>
  <div class="c-slider-button">
    <div class="c-slider-button__left" @click="$emit('clickleft')">
      <o-icon class="c-slider-button__icon" :icon="iconLeft" v-if="iconLeft" />
      <div class="c-slider-button__label">{{ labelLeft }}</div>
    </div>
    <div class="c-slider-button__right" @click="$emit('clickright')">
      <div class="c-slider-button__label">{{ labelRight }}</div>
      <o-icon class="c-slider-button__icon" :icon="iconRight" v-if="iconLeft" />
    </div>
    <vue-slider
      ref="slider"
      :key="sliderKey"
      v-model="value"
      v-bind="options"
    >
      <template v-slot:dot>
            <div class="c-slider-button__knob">
              <o-icon icon="arrow-left-right" />
            </div>
      </template>
      <template v-slot:tooltip>
      </template>
    </vue-slider>
<!--    <div class="c-slider-button__knob">-->
<!--      <o-icon icon="arrow-left-right" />-->
<!--    </div>-->
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

export default {
  name: 'SliderButton',
  components: { VueSlider },
  props: {
    labelLeft: String,
    iconLeft: String,
    labelRight: String,
    iconRight: String
  },
  data () {
    return {
      value: 1,
      sliderKey: null,
      options: {
        dotSize: 28,
        width: '100%',
        height: 0,
        contained: false,
        direction: 'ltr',
        data: null,
        min: 0,
        max: 2,
        interval: 1,
        disabled: false,
        clickable: true,
        duration: 0.5,
        adsorb: false,
        lazy: false,
        tooltip: null,
        tooltipPlacement: null,
        tooltipFormatter: null,
        useKeyboard: false,
        keydownHook: null,
        dragOnClick: false,
        enableCross: true,
        fixed: false,
        minRange: null,
        maxRange: null,
        order: true,
        marks: false,
        dotOptions: null,
        process: true,
        dotStyle: null,
        railStyle: null,
        processStyle: null,
        tooltipStyle: null,
        stepStyle: null,
        stepActiveStyle: null,
        labelStyle: null,
        labelActiveStyle: null
      }
    }
  },
  watch: {
    value (v) {
      switch (v) {
      case 0:
        this.$emit('clickleft')
        this.value = 1
        break
      case 2:
        this.$emit('clickright')
        this.value = 1
        break
      default:
        this.sliderKey = Date.now().toString()
      }
    }
  }
}
</script>

<style lang="scss">
@import '~@/assets/styles/variables.scss';
.c-slider-button {
  background: $background-color;
  border: 0.1rem solid rgba(#000, 0.1);
  border-radius: 2.4rem;
  display: flex;
  height: 4.8rem;
  position: relative;
  .vue-slider {
    position: absolute;
    width: 160%!important;
    height: 0!important;
    margin-left: -30%;
    margin-right: -30%;
    .vue-slider-dot-tooltip {
      display: none;
    }
  }
  &__label {
    @media (max-width: 360px) {
      font-size: 12px;
    }
  }
}

.c-slider-button__left,
.c-slider-button__right {
  align-items: center;
  color: $primary-color;
  coloor: var(--primary-color);
  display: flex;
  font-size: 1.6rem;
  font-weight: 600;
  padding: 1.2rem;

  & > * + * {
    margin-left: 0.8rem;
  }
}

.c-slider-button__left {
}

.c-slider-button__right {
  margin-left: auto;
}

.c-slider-button__knob {
  background: $primary-color;
  background: var(--primary-color);
  border-radius: 2.4rem;
  color: #fff;
  display: flex;
  height: 4.8rem;
  left: calc(50% - 2.4rem);
  position: absolute;
  width: 4.8rem;

  & > * {
    margin: auto;
  }
}
</style>
