import FavoriteItem from './FavoriteItem'
import NmbsFavorite from './NmbsFavorite'
import Favorite from './Favorite'
import OtherPurchasesFavorite from './OtherPurchasesFavorite'
import BusTramMetroFavorite from './BusTramMetroFavorite'
import CarshareFavorite from './CarshareFavorite'
import ParkingFavorite from './ParkingFavorite'
import PoiFavorite from './PoiFavorite'
import { store } from '@/store'

class FavoriteService {
  static getFavoriteItemForMobilityMode (mobilityMode, travelData = {}, favoriteType = 'ticket') {
    return new FavoriteItem({
      data: this.getFavoriteDataForMobilityMode(mobilityMode, travelData),
      mobilityMode: mobilityMode.mobilityMode,
      favoriteType
    })
  }

  static getFavoriteItemForPoi (poi) {
    return new FavoriteItem({
      mobilityMode: poi.mobilityMode,
      favoriteType: 'poi',
      data: this.getFavoriteDataForPoi(poi)
    })
  }

  static getFavoriteItemFromResponse (data) {
    return new FavoriteItem({
      ...data,
      data: this.getFavoriteDataFromBase64(data)
    })
  }

  static getFavoriteDataForMobilityMode (mobilityMode, travelData = {}) {
    return this.getDataClass(mobilityMode.mobilityMode).createForMobilityMode(mobilityMode, travelData)
  }

  static getFavoriteDataForPoi (poi) {
    return PoiFavorite.createForPoi(poi)
  }

  static getFavoriteDataFromBase64 ({ mobilityMode, data, favoriteType = 'ticket' }) {
    return this.getDataClass(mobilityMode, favoriteType).createFromBase64(data)
  }

  static getDataClass (mobilityMode, favoriteType = 'ticket') {
    if (favoriteType === 'poi') {
      return PoiFavorite
    }
    if (mobilityMode === 'TRAIN') {
      return NmbsFavorite
    }
    if (mobilityMode === 'OTHER_PURCHASES') {
      return OtherPurchasesFavorite
    }
    if (mobilityMode === 'BUS_TRAM_METRO') {
      return BusTramMetroFavorite
    }
    if (mobilityMode === 'CAR') {
      return CarshareFavorite
    }
    if (mobilityMode === 'PARKING') {
      return ParkingFavorite
    }
    return Favorite
  }

  static async checkForResource (resourceOspId, favoriteUuid) {
    if (!favoriteUuid) {
      return false
    }
    let favorites = store.getters['user/favorites']
    if (!favorites.length) {
      await store.dispatch('user/getFavorites')
    }
    favorites = store.getters['user/favorites']
    return favorites.find(favoriteItem => favoriteItem.getResourceOspId() === resourceOspId && favoriteItem.uuid === favoriteUuid) || false
  }

  static async getByUuid (favoriteUuid) {
    if (!favoriteUuid) {
      return false
    }
    let favorites = store.getters['user/favorites']
    if (!favorites.length) {
      await store.dispatch('user/getFavorites')
    }
    favorites = store.getters['user/favorites']
    return favorites.find(favoriteItem => favoriteItem.uuid === favoriteUuid) || false
  }
}

export default FavoriteService
