import * as fuelcardApi from './fuelcard_api'
import * as cambioApi from './cambio_api'
import * as wizardApi from './wizard_api'
import * as carpoolApi from './carpool_api'
import * as seasonApi from './season_api'
import * as oauthApi from './oauth_api'
import * as parkingApi from './parking_api'
import * as licenseplateApi from './licenseplate_api'
import * as pluginApi from './plugin_api'
import * as travelApi from './travel_api'
import * as aodApi from './aod_api'
import * as convenientInvoicingApi from './convenient_invoicing_api'
import * as paymentApi from './payment_api'

export * from './api'
export * from './general_api'
export * from './user_api'
export * from './poi_api'
export * from './quotes_api'
export * from './vehicle_usage_api'
export * from './fuel_api'
export * from './mobib_api'
export * from './tickets_api'
export * from './train_api'
export * from './budget_api'

export const fuelcard = fuelcardApi
export const cambio = cambioApi
export const wizard = wizardApi
export const carpool = carpoolApi
export const season = seasonApi
export const oauth = oauthApi
export const parking = parkingApi
export const licenseplate = licenseplateApi
export const plugin = pluginApi
export const travel = travelApi
export const aod = aodApi
export const convenientInvoicing = convenientInvoicingApi
export const payment = paymentApi
