<template>
  <div
    class="c-section"
    :class="{
      'c-section--transparent': isTransparent,
      'c-section--center': centerAlign,
      'c-section--compact': compact,
      'c-section--large-padding': largePadding,
      'c-section--highlighted': highlighted,
      'c-section--no-border': noBorder,
      'c-section--split': split,
    }"
  >
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'SectionBox',
  props: {
    isTransparent: {
      type: Boolean,
      default: false
    },
    largePadding: {
      type: Boolean,
      default: false
    },
    centerAlign: {
      type: Boolean,
      default: false
    },
    highlighted: {
      type: Boolean,
      default: false
    },
    compact: {
      type: Boolean,
      default: false
    },
    noBorder: {
      type: Boolean,
      default: false
    },
    split: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
@import '~@/assets/styles/variables.scss';
.c-section {
  background: #fff;
  padding: 1.6rem;
  position: relative;
  word-break: break-word;

  p {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  p:not(:last-child) {
    margin-bottom: 1.6rem;
  }

  & + & {
    border-top: 0.1rem solid $gray-100;
  }

  strong {
    font-weight: 600;
    letter-spacing: -0.033rem;
  }

  a {
    color: $primary-color;
    text-decoration: underline;
  }

  &.c-section--no-border {
    border-top: none;
  }

  &.c-section--split {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.c-section--transparent {
  background: none;
}

.c-section--center {
  text-align: center;
}

.c-section--compact {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.c-section--large-padding {
  padding: 2.4rem;
}

.c-section--highlighted {
  background-color: var(--primary-color-light);
}
</style>
