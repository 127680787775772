<template>
  <o-view
    class="c-sales-view"
    :class="{[parentClass]: !!parentClass, 'c-tablet-sales-view': isTablet}"
    :has-white-background="hasWhiteBackground"
    :no-scroll="true"
  >
    <div class="c-tablet-sales-view--header" v-if="isTablet">
      <div class="c-tablet-sales-view--header-wrapper">
        <slot name="header">
          <o-header-image :image="image" v-if="image"/>
        </slot>
      </div>

    </div>

    <o-view
      :double-bottom-padding="!!$slots.dblBtnGroup"
      :bottom-padding="!!$slots.btnGroup"
      :class="childClass"
      :isFlex="true"
      :horizontal-scroll="horizontalScroll"
      ref="view"
    >
      <!-- <o-nav-bar
        :title="navBarTitle"
        :has-back-button="hasBackButton"
        :emit-back-click="emitBackClick"
        :has-close-button="hasCloseButton"
        @back="$emit('back')"
      /> -->
      <slot name="header" v-if="!isTablet">
        <o-header-image :image="image" v-if="image"/>
      </slot>
      <slot name="section_header">
        <o-section-header :title="title" v-if="title"/>
      </slot>

      <slot/>
    </o-view>
    <slot name="btnGroup"/>
    <slot name="dblBtnGroup"/>
    <slot name="footer"/>
  </o-view>
</template>

<script>
import { appLog } from '../../helpers'
import OHeaderImage from '../utils/OHeaderImage'
import { mapGetters } from 'vuex'

export default {
  name: 'OLayout',
  components: { OHeaderImage },
  data () {
    return {}
  },
  props: {
    emitBackClick: {
      type: Boolean,
      default: false
    },
    hasWhiteBackground: {
      type: Boolean,
      default: true
    },
    hasBackButton: {
      type: Boolean,
      default: true
    },
    hasCloseButton: {
      type: Boolean,
      default: true
    },
    parentClass: {
      type: String,
      default: ''
    },
    childClass: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: ''
    },
    navBarTitle: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    horizontalScroll: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      isTablet: 'app/isTablet'
    })
  },
  mounted () {
    appLog(this.$slots.btnGroup)
  }
}
</script>

<style lang="scss">
.c-tablet-sales-view {
  margin-left: 50%;
  position: relative;
  .c-tablet-sales-view--header {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 50%;
  }
}
</style>
