/**
 * @module General API
 */
import { Api, appEndPoint, appEndPointV2, appEndPointV3 } from './api'
import { parseFloat } from '../helpers'

/**
 * App API
 */
export const appAuthRequireSSO = (clientId, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/client/profile`, { clientId })
}

export const appBranding = (ospId, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/skin/branding/get`, { ospId })
}

export const confirmPurchase = ({ salesRequest, mrpOspId, confirmFullPath = null }, cb) => {
  if (!salesRequest || !mrpOspId) {
    return false
  }
  const request = {
    ...salesRequest,
    priceVat: parseFloat(salesRequest.priceVat),
    priceVatExcl: parseFloat(salesRequest.priceVatExcl),
    priceVatIncl: parseFloat(salesRequest.priceVatIncl)
  }
  if (salesRequest.costComposition) {
    request.costComposition = {
      ...salesRequest.costComposition
    }
    if (salesRequest.costComposition.fixedCost) {
      request.costComposition.fixedCost = {
        ...salesRequest.costComposition.fixedCost,
        costVatIncl: parseFloat(salesRequest.costComposition.fixedCost.costVatIncl),
        costVatExcl: parseFloat(salesRequest.costComposition.fixedCost.costVatExcl),
        costVat: parseFloat(salesRequest.costComposition.fixedCost.costVat),
        vatPercentage: parseFloat(salesRequest.costComposition.fixedCost.vatPercentage)
      }
      if (salesRequest.costComposition.variableCost) {
        request.costComposition.variableCost = {
          ...salesRequest.costComposition.variableCost,
          costVatIncl: parseFloat(salesRequest.costComposition.variableCost.costVatIncl),
          costVatExcl: parseFloat(salesRequest.costComposition.variableCost.costVatExcl),
          costVat: parseFloat(salesRequest.costComposition.variableCost.costVat),
          vatPercentage: parseFloat(salesRequest.costComposition.variableCost.vatPercentage)
        }
      }
    }
  }
  const api = new Api(cb, { returnError: true, excludeMessageAlert: ['DUPLICATE_BOOKING'] })
  const path = confirmFullPath || `${appEndPointV3}/sales/${mrpOspId}/confirm`
  return api.query('post', path, request)
}

export const getZone = (filters, cb) => {
  const api = new Api(cb, { loader: false })
  return api.query('post', `${appEndPoint}/zone/get`, filters)
}

export const getImage = (imageUrl, cb) => {
  const useApiUrl = imageUrl.substr(0, 4) !== 'http'
  const api = new Api(cb, {
    useApiUrl,
    responseType: 'arraybuffer',
    dataOnly: false,
    loader: false,
    returnError: true
  })
  return api.query('get', imageUrl)
}

export const getReservationRouteStations = (serviceResourceOspId, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPointV3}/sales/reservation/route/prequote`, {
    serviceResourceOspId,
    pagination: {
      page: 0,
      size: 1000
    }
  })
}

export const getWebResourceInfo = (resourceOspId, cb) => {
  const api = new Api(cb, {
    responseType: 'text'
  })
  return api.query('post', `${appEndPoint}/resource/serviceResource/info`, {
    serviceResourceOspId: {
      ospId: resourceOspId
    }
  })
}

export const getSupport = (mrpOspId, cb) => {
  const api = new Api(cb, { loader: false, excludeMessageAlert: 'NOT_FOUND' })
  return api.query('post', `${appEndPoint}/support/get`, {
    mrpOspId
  })
}

/**
 * Sales API
 */
export const saleReservationQuote = (filters, cb) => {
  const api = new Api(cb, { showAlert: true, returnError: true, excludeMessageAlert: ['NO_AVAILABILITY'] })
  return api.query('post', `${appEndPointV3}/sales/reservation/route/quote`, filters)
}

export const reservationAlert = (filters, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPointV3}/sales/reservation/route/alert`, filters)
}

export const getBParkingInfo = cb => {
  const api = new Api(cb)
  return api.query('post', `${appEndPointV2}/parking/BParking/BPARKING_PARKEREN/info`)
}

/**
 * Settings API
 */
export const getLicensePlates = cb => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/settings/identificationToken/findAllLicensePlate`)
}

export const addLicensePlate = (lp, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/settings/identificationToken/addLicensePlate`, lp)
}

export const updateLicensePlate = (lp, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/settings/identificationToken/updateLicensePlate`, lp)
}

export const removeIdentificationToken = (id, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/settings/identificationToken/remove`, { tokenUuid: id })
}

export const getDiscounts = (data, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/user/discount/getAll`, data)
}

export const updateDiscounts = (discounts, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/user/discount/update`, discounts)
}

/**
 * Utils
 */
export const normalizeQr = (code, cb) => {
  const api = new Api(cb, { loader: true, excludeMessageAlert: ['*'] })
  return api.query('post', `${appEndPoint}/util/normalizeQr`, { sourceValue: code })
}

export const validatePhoneNumber = (phoneNumber, cb) => {
  const api = new Api(cb, { loader: true, excludeMessageAlert: ['*'] })
  return api.query('post', `${appEndPoint}/util/phone/validate`, { phoneNumber })
}

export const getLabel = (path, cb) => {
  const api = new Api(cb, { loader: true, excludeMessageAlert: ['*'] })
  return api.query('get', path + '?key=' + Date.now().toString())
}

export const getPublicPage = (page, cb) => {
  const api = new Api(cb)
  return api.query('get', `${appEndPoint}/html/public_pages?page=${page}`)
}

export const imageDownload = ({ imageUrl, serviceResourceOspId }, cb) => {
  const api = new Api(cb, { responseType: 'arraybuffer', dataOnly: false, loader: false, returnError: true })
  return api.query('post', `${appEndPoint}/images/download`, { imageUrl, serviceResourceOspId })
}

export const autocompleteLocation = ({ input, location, includeAddress }, cb) => {
  const api = new Api(cb, { loader: false })
  return api.query('post', `${appEndPoint}/autocomplete/location`, { input, location, includeAddress })
}

export const travelDistance = ({ destinationAddress, originAddress, mobilityMode, destinationCoordinate, originCoordinate }, cb) => {
  const api = new Api(cb, { returnError: true })
  return api.query('post', `${appEndPoint}/util/travelDistance`, { destinationAddress, originAddress, mobilityMode, destinationCoordinate, originCoordinate })
}

export const getReverseLocation = (coordinate, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/util/reverse-geocode`, { coordinate })
}

export const getGeocode = (address, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/util/geocode`, { address })
}

export const getKateParameters = (token, cb) => {
  const api = new Api(cb)
  return api.query('post', `${appEndPoint}/kate/parameters/get `, token)
}
