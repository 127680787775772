<template>
  <div class="c-alert-simple" :class="type">
    <slot>
      <p v-html="message" />
    </slot>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: 'OAlertSimple',
  props: {
    message: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
@import '~@/assets/styles/variables.scss';
  .c-alert-simple {
    background: rgba($primary-color, 0.1);
    background: var(--primary-color-light);
    border-radius: $border-radius-small;
    color: $text-color;
    color: var(--text-color);
    /*font-size: 1.4rem;*/
    line-height: 2rem;
    padding: 1.2rem 1.6rem;

    &.c-alert--top-margin {
      margin-top: 1.6rem;
    }
    &.c-alert--center {
      text-align: center;
    }
    &.c-alert--bottom-margin {
      margin-bottom: 1.6rem;
    }
  }
</style>
