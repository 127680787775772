<template>
  <div class="c-section-header">
    <h2 class="c-section-header__title">{{ title }}</h2>
    <template v-if="$slots.right">
      <div class="c-section-header__right">
        <slot name="right" />
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'OSectionHeader',
  props: {
    title: String
  }
}
</script>

<style lang="scss">
@import '~@/assets/styles/variables.scss';
.c-section-header {
  align-items: center;
  background: $background-color;
  display: flex;
  flex: none;
  padding: 1.2rem 1.6rem;
  min-height: 24px;
}

.c-section-header__title {
  color: $text-color;
  color: var(--text-color);
  font-weight: 600;
  line-height: 24px;
}

.c-section-header__right {
  height: 24px;
  margin-left: auto;

  .c-icon {
    color: $primary-color;
    color: var(--primary-color);
    cursor: pointer;
  }

  .c-icon + .c-icon {
    margin-left: 1.6rem;
  }
}
</style>
