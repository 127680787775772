/**
 * Class response for register custom listener to get specific postMessage and send it to callback function
 */
type Callback = (data?: {}) => void
type CallbackWrapper = (event: MessageEvent) => void
export default class PostMessageObserver {
  postMessageApi: string
  cb: CallbackWrapper | void
  subscribed = false

  constructor (postMessageApi: string, cb: Callback, options = false) {
    this.postMessageApi = postMessageApi
    this.cb = this.getCallback(postMessageApi, cb)
    this.unsubscribe().then(() => {
      this.subscribed = true
      this.createSubscription(options)
    })
  }

  createSubscription (options: {}) {
    if (window.removeEventListener) {
      // @ts-ignore
      window.removeEventListener('message', this.cb, false)
    } else {
      // @ts-ignore
      window.detachEvent('onmessage', this.cb)
    }
    if (window.addEventListener) {
      // @ts-ignore
      window.addEventListener('message', this.cb, options)
    } else {
      // @ts-ignore
      window.attachEvent('onmessage', this.cb)
    }
  }

  static subscribe (postMessageApi: string, callback: (data: any) => void, timeout = 10) {
    const instance = new this(postMessageApi, callback)
    this.unsubscribeWithTimeout(instance, timeout)
    return instance
  }

  getSubscribed () {
    return this.subscribed
  }

  static unsubscribeWithTimeout (instance: PostMessageObserver, timeout: number) {
    timeout && setTimeout(() => {
      if (instance.getSubscribed()) {
        instance.unsubscribe()
        throw new Error('Post Message Timeout')
      }
    }, timeout * 1000)
  }

  getCallback (postMessageApi: string, cb: Callback): CallbackWrapper | void {
    return (e: MessageEvent) => {
      try {
        const message = JSON.parse(e.data)
        if (message && message.api === postMessageApi) {
          console.log(postMessageApi + ' callback')
          this.unsubscribe()
          return cb(message.response || message.data)
        }
      } catch (e) {
        console.error(e)
      }
    }
  }

  async unsubscribe () {
    if (window.removeEventListener) {
      // @ts-ignore
      window.removeEventListener('message', this.cb, false)
    } else {
      // @ts-ignore
      window.detachEvent('onmessage', this.cb)
    }
    this.subscribed = false
    return true
  }
}
