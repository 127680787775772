const lazyLoad = (component) => {
  // if (process.env.NODE_ENV && process.env.NODE_ENV !== 'production') {
  //   return require('../../components/onStreetParking/' + component)
  // } else {
  return () => import(/* webpackChunkName: "on-street-parking" */ '../../components/streetParking/' + component)
  // }
}

export const onStreetParkingRoutes = [
  {
    path: '/sales/on-street-parking/:resourceOspId',
    name: 'on_street_parking',
    component: lazyLoad('StreetParking')
  },
  {
    path: '/sales/on-street-parking/:resourceOspId/new-session',
    name: 'on_street_parking_new_session',
    component: lazyLoad('NewSessionPage')
  },
  {
    path: '/sales/on-street-parking/:resourceOspId/sessions/:sessionUuid',
    name: 'on_street_parking_session_details',
    component: lazyLoad('SessionDetailsPage')
  },
  {
    path: '/sales/on-street-parking/:resourceOspId/sessions',
    name: 'on_street_parking_all_sessions',
    component: lazyLoad('AllSessionsPage')
  },
  {
    path: '/stop-session-confirmation',
    name: 'stop_session_confirmation',
    component: lazyLoad('StopSessionConfirmationPage')
  }
]
